import { assign, createMachine } from 'xstate';

import type { ActionButtonState } from 'components/ActionButton';
import { sendToast } from 'components/Toast';

import {
	requestAddUser,
	requestGetUser,
	requestGetUsers,
	requestInviteUserToEcom,
	requestRemoveUser,
	requestRevokeUserFromEcom,
} from './userManagement.services';
import type {
	UserManagementMachineContext,
	UserManagementMachineEvents,
} from './userManagement.types';

export const userManagementMachine = createMachine(
	{
		id: 'userManagementMachine',
		schema: {
			context: {} as UserManagementMachineContext,
			events: {} as UserManagementMachineEvents,
		},
		context: {
			userList: null,
			selectedUser: null,
			invitationUpdated: false,
			userWasAdded: false,
			addUserButtonState: 'idle',
			inviteButtonState: 'idle',
			removeUserButtonState: 'idle',
		},
		tsTypes: {} as import('./userManagement.machine.typegen').Typegen0,
		// predictableActionArguments: true,
		preserveActionOrder: true,
		type: 'parallel',
		states: {
			listingUsers: {
				description: 'Listing users state',
				initial: 'idle',
				states: {
					idle: {
						on: {
							GET_USER_LIST: {
								target: 'loadUserList',
								description: 'Get user list',
							},
						},
					},
					loadUserList: {
						description: 'State which loads the user list',
						id: 'loadingUserList',
						invoke: {
							src: 'getUsers',
							id: 'getUsers',
							onDone: {
								target: 'idle',
								actions: ['setUserList'],
							},
							onError: {
								target: 'idle',
							},
						},
					},
				},
			},
			addUserFlow: {
				initial: 'idle',
				description: 'State for handeling adding new users',
				states: {
					idle: {
						description: 'Waiting for events',
						on: {
							ADD_USER: {
								target: 'addingUser',
								actions: [
									'resetValidationErrors',
									'setAddUserButtonStateLoading',
								],
							},
						},
					},
					addingUser: {
						invoke: {
							src: 'addUser',
							id: 'addUser',
							onDone: [
								{
									target: 'doneAddingUser',
								},
							],
							onError: [
								{
									cond: 'eventHasValidationErrors',
									target: 'validationError',
									actions: 'setValidationErrors',
								},
								{
									cond: 'eventUserNotCreated',
									target: 'errorAddingUser',
								},
								{
									cond: 'hasCriticalError',
									target: 'criticalError',
								},
								{ target: 'errorAddingUser' },
							],
						},
						description: 'Adding a new user',
					},
					doneAddingUser: {
						entry: ['setUserWasAdded'],
						description: 'Successfully added the user',
						tags: 'addUserRequestCompleted',
						after: {
							'500': {
								target: 'idle',
								actions: 'setAddUserButtonStateSuccess',
								internal: false,
							},
						},
					},
					validationError: {
						description: 'Some validation failed',
						tags: 'addUserRequestCompleted',
						entry: 'setAddUserButtonStateFailure',
						on: {
							ADD_USER: {
								target: 'addingUser',
								actions: [
									'resetValidationErrors',
									'setAddUserButtonStateLoading',
								],
							},
						},
					},
					errorAddingUser: {
						description: 'The request to add user failed',
						tags: 'addUserRequestCompleted',
						after: {
							'500': {
								target: 'idle',
								actions: [
									'showToastMessageFailure',
									'setAddUserButtonStateFailure',
								],
								internal: false,
							},
						},
					},

					criticalError: {
						tags: 'addUserRequestCompleted',
						entry: ['showCriticalErrorToast', 'setAddUserButtonStateFailure'],
					},
				},
			},
			viewingUser: {
				description: 'Viewing user state',
				initial: 'idle',
				states: {
					idle: {
						on: {
							GET_USER: {
								target: 'viewUserFlow',
								description: 'Get user by ID',
							},
						},
					},
					viewUserFlow: {
						initial: 'loadingUser',
						id: 'viewUserFlow',
						description: 'State which loads a single user',
						states: {
							loadingUser: {
								invoke: {
									src: 'getUser',
									id: 'getUser',
									onDone: [
										{
											target: 'doneGettingUser',
											actions: ['setSelectedUser'],
										},
									],
									onError: [
										{
											target: 'errorGettingUser',
										},
									],
								},
								description: 'Loads a single user',
							},
							doneGettingUser: {
								initial: 'idle',
								states: {
									idle: {
										on: {
											REMOVE_USER: {
												target: 'removeUserFlow',
											},
											INVITE_TO_ECOM: {
												target: 'inviteToEcomFlow',
											},
											REVOKE_FROM_ECOM: {
												target: 'revokeFromEcomFlow',
											},
										},
									},
									removeUserFlow: {
										description: 'Removing a user',
										entry: ['setRemoveUserButtonStateLoading'],
										invoke: {
											src: 'removeUser',
											id: 'removeUser',
											onDone: {
												target: 'idle',
												actions: [
													'setUserList',
													'setRemoveUserButtonStateSuccess',
													'redirectToUserList',
												],
											},
											onError: {
												target: 'idle',
												actions: 'setRemoveUserButtonStateError',
											},
										},
									},
									inviteToEcomFlow: {
										entry: ['setInviteButtonStateLoading'],
										invoke: {
											src: 'inviteUserToEcom',
											id: 'inviteUserToEcom',
											onDone: {
												target: 'reFetchUser',
												actions: [
													'setInvitationUpdated',
													'setInviteButtonStateSuccess',
												],
											},
											onError: {
												target: 'idle',
												actions: 'setInviteButtonStateError',
											},
										},
										description: 'Invites a user to ecom',
									},
									revokeFromEcomFlow: {
										description: 'Remove user permission to use ecom',
										entry: ['setInviteButtonStateLoading'],
										invoke: {
											src: 'revokeUserFromEcom',
											id: 'revokeUserFromEcom',
											onDone: {
												target: 'reFetchUser',
												actions: [
													'setInvitationUpdated',
													'setInviteButtonStateSuccess',
												],
											},
											onError: {
												target: 'idle',
												actions: ['setInviteButtonStateError'],
											},
										},
									},
									reFetchUser: {
										after: {
											'500': {
												target: '#viewUserFlow',
											},
										},
									},

									// loadUserPurchasesFlow: {
									// 	description: 'Not yet implemented',
									// },
								},
							},
							errorGettingUser: {
								description: 'The request for getting the user failed',
							},
						},
					},
				},
			},
		},
	},
	{
		services: {
			getUsers: async (_context, _event) => requestGetUsers(),
			addUser: async (_context, event) => requestAddUser(event),
			getUser: async (context, _event) => {
				const event = _event as any;
				const customerContactId =
					event.customerContactId || context.selectedUser?.customerContactId;
				return requestGetUser(customerContactId);
			},
			removeUser: async (_context, _event) => {
				const event = _event as any;
				return requestRemoveUser(event.customerContactId);
			},
			inviteUserToEcom: async (_context, _event) => {
				const event = _event as any;
				return requestInviteUserToEcom(event.customerContactId);
			},
			revokeUserFromEcom: async (_context, _event) => {
				const event = _event as any;
				return requestRevokeUserFromEcom(event.customerContactId);
			},
		},
		actions: {
			setUserList: assign({
				userList: (_context, _event) => {
					const event = _event as any;
					return event.data;
				},
			}),
			setSelectedUser: assign({
				selectedUser: (_context, _event) => {
					const event = _event as any;
					return event.data;
				},
			}),
			setUserWasAdded: assign({
				userWasAdded: (_context, _event) => true,
			}),
			setInvitationUpdated: assign({
				invitationUpdated: (_context, _event) => true,
			}),
			setInviteButtonStateSuccess: assign({
				inviteButtonState: (_context, _event) => 'success' as ActionButtonState,
			}),
			setInviteButtonStateError: assign({
				inviteButtonState: (_context, _event) => 'failure' as ActionButtonState,
			}),
			setInviteButtonStateLoading: assign({
				inviteButtonState: (_context, _event) => 'loading' as ActionButtonState,
			}),
			setRemoveUserButtonStateSuccess: assign({
				removeUserButtonState: (_context, _event) =>
					'success' as ActionButtonState,
			}),
			setRemoveUserButtonStateError: assign({
				removeUserButtonState: (_context, _event) =>
					'failure' as ActionButtonState,
			}),
			setRemoveUserButtonStateLoading: assign({
				removeUserButtonState: (_context, _event) =>
					'loading' as ActionButtonState,
			}),
			redirectToUserList: () => {},
			showToastMessageFailure: () => {
				sendToast('julapro_myusers_add_user_failure', 'error');
			},
			showCriticalErrorToast: () => {
				sendToast('general_critical_error_text', 'error');
			},
			setAddUserButtonStateSuccess: assign({
				addUserButtonState: (_context) => 'success' as ActionButtonState,
			}),
			setAddUserButtonStateFailure: assign({
				addUserButtonState: (_context) => 'failure' as ActionButtonState,
			}),
			setAddUserButtonStateLoading: assign({
				addUserButtonState: (_context) => 'loading' as ActionButtonState,
			}),
			resetValidationErrors: assign({
				errors: (_context, _event) => undefined,
			}),
			setValidationErrors: assign({
				errors: (_context, _event) => {
					const event = _event as any;
					const { data } = event;
					return data;
				},
			}),
		},
		guards: {
			eventHasValidationErrors: (_context, _event) => {
				const event = _event as any;
				const { data } = event;
				return data?.status === 400;
			},
			eventUserNotCreated: (_context, _event) => {
				const event = _event as any;
				const { data } = event;
				return data?.status === 404;
			},
			hasCriticalError: (_context, _event) => {
				const event = _event as any;
				const { data } = event;
				return data?.status === 500 || data?.status === 403;
			},
		},
	},
);
