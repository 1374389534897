import React from 'react';

import ArrowLink from 'components/ArrowLink';
import ComponentPlaceholder from 'components/ComponentPlaceholder';
import { LayoutContainer } from 'components/Layout';
import { useSitecoreContext } from 'hooks';
import { JulaComponentProps } from 'lib/component-props';
import { SitecoreLink } from 'models/link';
import { getEditorMargin } from 'utils/business-logic';

type Props = JulaComponentProps & {
	fields?: {
		link?: {
			value?: SitecoreLink;
		};
	};
};

export default function ContentLink({ params, fields }: Props) {
	const { sitecoreContext } = useSitecoreContext();
	if (!fields) {
		return null;
	}
	const { link } = fields;
	if (!link || !link.value || !link.value.text || !link.value.href) {
		return sitecoreContext?.pageEditing ? (
			<ComponentPlaceholder componentName="ContentLink" />
		) : null;
	}

	return (
		<LayoutContainer
			outerClassName={getEditorMargin(params)}
			id={params?.anchor}
			className="grid grid-cols-4 gap-4 md:grid-cols-12 md:gap-6"
		>
			<div className="col-span-4 md:col-span-6 md:col-start-4">
				<ArrowLink
					href={link.value.href}
					text={link.value.text}
					target={link.value.target}
				/>
			</div>
		</LayoutContainer>
	);
}
ContentLink.displayName = 'ContentLink';
