import React from 'react';
import { Field, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import clsx from 'clsx';

import { Teaser } from 'components/BlockPromoTeaser';
import ComponentPlaceholder from 'components/ComponentPlaceholder';
import { JulaComponentProps } from 'lib/component-props';
import { DigizuiteAssetList } from 'models/asset';
import { getEditorMargin } from 'utils/business-logic';
import { getAsset } from 'utils/helpers';

export type PromoTeaserItemProps = JulaComponentProps & {
	fields: {
		description?: Field<string>;
		heading: Field<string>;
		link: any;
		portraitImage: DigizuiteAssetList;
	};
};

function PromoTeaserItem({ fields, params }: PromoTeaserItemProps) {
	const { sitecoreContext }: { sitecoreContext: any } = useSitecoreContext();
	const isEditing = sitecoreContext?.pageEditing;

	if (!fields) {
		return null;
	}

	const { heading, link, portraitImage } = fields;
	const { heading: headingLevel } = params || {};
	const portraitImg = getAsset('Templated 3:4', portraitImage);

	if (!portraitImg && !isEditing) return null;

	if (isEditing) {
		return (
			<ComponentPlaceholder
				componentName="PromoTeaserItem"
				className={clsx(getEditorMargin({ margin: `None` }))}
				isDeprecated
			/>
		);
	}

	return (
		<Teaser
			heading={heading?.value}
			headingLevel={headingLevel}
			link={link?.value?.href}
			linkText={link?.value?.text || ''}
			imageSrc={portraitImg?.src}
			imageAlt={portraitImg?.alt}
		/>
	);
}
PromoTeaserItem.displayName = 'PromoTeaserItem';

export default PromoTeaserItem;
