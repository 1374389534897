import React from 'react';
import clsx from 'clsx';

import { Divider } from 'components/BlockMedia';
import ComponentPlaceholder from 'components/ComponentPlaceholder';
import { LayoutContainer } from 'components/Layout';
import { useSitecoreContext } from 'hooks';
import { JulaComponentProps } from 'lib/component-props';
import { DigizuiteAssetList } from 'models/asset';
import { getEditorMargin } from 'utils/business-logic';
import { getAsset } from 'utils/helpers';

type MediaDividerProps = JulaComponentProps & {
	params?: {
		margin: 'None' | 'Single' | 'Double';
	};
	fields: {
		smallImage: DigizuiteAssetList;
		largeImage: DigizuiteAssetList;
	};
};

function MediaDivider({ params, fields }: MediaDividerProps) {
	const { sitecoreContext } = useSitecoreContext();
	const isEditing = sitecoreContext?.pageEditing;

	if (!fields) return null;

	const { smallImage, largeImage } = fields;

	const largeImg = getAsset('Templated 4:1', largeImage);
	const smallImg = getAsset('Templated 4:3', smallImage);

	if ((!largeImg || !smallImg) && !isEditing) return null;

	if (isEditing && !largeImg) {
		return (
			<ComponentPlaceholder
				componentName="MediaDivider"
				className={getEditorMargin(params)}
			/>
		);
	}

	return (
		<LayoutContainer
			gutters={false}
			id={params?.anchor}
			outerClassName={clsx(getEditorMargin(params), 'sm:mx-4 md:mx-6')}
			className="grid grid-cols-4 gap-4  md:grid-cols-12 md:gap-6"
		>
			<Divider
				largeImgSrc={largeImg?.src}
				largeImgHeight={largeImg?.height}
				largeImgWidth={largeImg?.width}
				largeImgAlt={largeImg?.alt}
				smallImageSrc={smallImg?.src}
				smallImageHeight={smallImg?.height}
				smallImageWidth={smallImg?.width}
				smallImageAlt={smallImg?.alt}
				className="col-span-full"
			/>
		</LayoutContainer>
	);
}
MediaDivider.displayName = 'MediaDivider';

export default MediaDivider;
