import type { ActorRef, State } from 'xstate';
import { Machine } from 'xstate';

interface TransitionStateType {
	states: {
		entering: Record<string, undefined>;
		entered: Record<string, undefined>;
		exiting: Record<string, undefined>;
		exited: Record<string, undefined>;
	};
}

type TransitionMachineEvents = { type: 'ENTER' } | { type: 'EXIT' };

type TransitionMachineContext = { delay: number };

export type TransitionMachineState = State<
	TransitionMachineContext,
	TransitionMachineEvents
>;
export type TransitionMachineActor = ActorRef<
	TransitionMachineEvents,
	TransitionMachineState
>;

export const transitionMachine = Machine<
	TransitionMachineContext,
	TransitionStateType,
	TransitionMachineEvents
>(
	{
		id: 'transition',
		initial: 'exited',
		context: {
			delay: 500,
		},
		states: {
			exited: {
				on: {
					ENTER: 'entering',
				},
			},
			entering: {
				after: {
					// after 0.5 second, transition to open
					DELAY: 'entered',
				},
			},
			entered: {
				on: {
					EXIT: 'exiting',
				},
			},
			exiting: {
				after: {
					// after 0.5 second, transition to closed
					DELAY: 'exited',
				},
			},
		},
	},
	{
		delays: {
			DELAY: (context) => context.delay,
		},
	},
);
