import React from 'react';
import clsx from 'clsx';

import Accordion from 'components/Accordion';
import ArrowLink from 'components/ArrowLink';
import ComponentPlaceholder from 'components/ComponentPlaceholder';
import { LayoutContainer } from 'components/Layout';
import Text from 'components/Text';
import type { JulaComponentProps } from 'lib/component-props';
import type { FaqArticle } from 'models/faqArticle';
import type { FaqCategory, FaqCategoryArticle } from 'models/faqCategory';
import { getEditorMargin } from 'utils/business-logic';
import { is } from 'utils/helpers';

type Props = JulaComponentProps & {
	fields?: {
		categories?: FaqCategory;
		articles?: FaqArticle[];
	};
};

function FAQAccordionArticles({
	articles,
	className,
	items,
}: {
	articles: FaqArticle[];
	className?: string;
	items: FaqCategoryArticle[];
}) {
	return (
		<ul className={className}>
			{items.map(({ articleId, shortDesc }) => {
				const articleData = articles.find(
					(article) => article.fields?.id?.value === articleId,
				);

				if (!articleData) return null;

				return (
					<li key={articleId} className="mb-4 last:mb-0">
						<ArrowLink text={shortDesc} href={articleData.url} />
					</li>
				);
			})}
		</ul>
	);
}
FAQAccordionArticles.displayName = 'FAQAccordionArticles';

export default function FAQOverview({ params, fields }: Props) {
	if (!fields) {
		return null;
	}

	const { categories, articles } = fields;

	if (!categories?.categories?.length || !articles?.length) {
		return (
			<ComponentPlaceholder
				componentName="FAQOverview"
				description="Missing categories or articles"
				className={getEditorMargin(params)}
			/>
		);
	}

	return (
		<LayoutContainer
			id={params?.anchor}
			outerClassName={getEditorMargin(params)}
			withGrid
		>
			<div className="col-span-4 md:col-span-6">
				{categories?.categories?.map(
					({
						id,
						name,
						articles: categoryArticles,
						categories: subCategories,
					}) => (
						<Accordion
							key={id}
							title={name}
							id={`faq-accordion-${id}`}
							size="heading"
						>
							{is.arrayWithLength(categoryArticles) && (
								<FAQAccordionArticles
									articles={articles}
									items={categoryArticles}
									className="mb-4 mt-2"
								/>
							)}
							{subCategories?.map(
								(subCategory) =>
									is.arrayWithLength(subCategory.articles) && (
										<div
											key={subCategory.name}
											className="mt-8 first:mt-2 last:mb-4"
										>
											<Text as="h4" className="mb-2">
												{subCategory.name}
											</Text>
											{subCategory.articles && (
												<FAQAccordionArticles
													articles={articles}
													items={subCategory.articles}
												/>
											)}
										</div>
									),
							)}
						</Accordion>
					),
				)}
			</div>
		</LayoutContainer>
	);
}
FAQOverview.displayName = 'FAQOverview';
