import React from 'react';

import type { ActionButtonState } from 'components/ActionButton';
import type { FormFields, Section } from 'models/form';
import type {
	CustomerInfo,
	Organization,
	Worksite,
} from 'state-machines/createJulaProCustomer';
import { FormattedErrors } from 'utils/helpers';

import { renderDescription, renderHeader } from './helpers';
import StepOneContent from './StepOneContent';
import StepThreeContent from './StepThreeContent';
import StepTwoContent from './StepTwoContent';

interface JulaProStepperProps {
	submitOrganizationSearch: (searchSstring: string) => void;
	selectOrganization: (id: string) => void;
	submitCustomerId: ({ customerId }: { customerId: string }) => void;
	resetToSelectOrganization: () => void;
	resetToSearchOrganization: () => void;
	resetFromStepThree: () => void;
	creditFunctionsEnabled: boolean;
	sendNewSearchInProgress: () => void;
	isInWaitingForNewOrganizationSearch: boolean;
	isInSearchOrganizationSuccess: boolean;
	addCustomerButtonState: ActionButtonState;
	isSearchingOrganizations: boolean;
	selectCredit: (credit: number) => void;
	isStepOne: boolean;
	isStepTwo: boolean;
	isStepThree: boolean;
	showCustomerIdForm: boolean;
	selectedCredit: number | null;
	submitBecomeProMemberForm: (
		form: FormFields,
		values: Record<string, unknown>,
	) => Promise<FormattedErrors['fieldValidationErrors']>;
	organizationList?: Organization[] | null;
	selectedWorksite: Worksite | null;
	searchingOrganizationFailed: boolean;
	selectOrganizationButtonState: ActionButtonState;
	submitCustomerIdButtonState: ActionButtonState;
	customerInfo: CustomerInfo | null;
	sections: Section[];
}

export default function JulaProStepper({
	submitOrganizationSearch,
	selectOrganization,
	isInSearchOrganizationSuccess,
	resetToSelectOrganization,
	creditFunctionsEnabled,
	isInWaitingForNewOrganizationSearch,
	resetToSearchOrganization,
	submitCustomerId,
	isStepOne,
	showCustomerIdForm,
	selectedCredit,
	submitBecomeProMemberForm,
	organizationList,
	isStepTwo,
	sendNewSearchInProgress,
	isStepThree,
	addCustomerButtonState,
	isSearchingOrganizations,
	selectCredit,
	selectedWorksite,
	searchingOrganizationFailed,
	selectOrganizationButtonState,
	submitCustomerIdButtonState,
	resetFromStepThree,
	customerInfo,
	sections,
}: JulaProStepperProps) {
	return (
		<div>
			{isStepOne && (
				<StepOneContent
					resetToSearchOrganization={resetToSearchOrganization}
					isSearchingOrganizations={isSearchingOrganizations}
					renderDescription={renderDescription}
					creditFunctionsEnabled={creditFunctionsEnabled}
					renderHeader={renderHeader}
					isInWaitingForNewOrganizationSearch={
						isInWaitingForNewOrganizationSearch
					}
					submitOrganizationSearch={submitOrganizationSearch}
					sendNewSearchInProgress={sendNewSearchInProgress}
					resetToSelectOrganization={resetToSelectOrganization}
					selectOrganization={selectOrganization}
					selectedWorksite={selectedWorksite}
					isInSearchOrganizationSuccess={isInSearchOrganizationSuccess}
					organizationList={organizationList}
					showCustomerIdForm={showCustomerIdForm}
					submitCustomerId={submitCustomerId}
					searchingOrganizationFailed={searchingOrganizationFailed}
					selectOrganizationButtonState={selectOrganizationButtonState}
					submitCustomerIdButtonState={submitCustomerIdButtonState}
					companySearchSection={sections[0]}
					customerIdInputSection={sections[1]}
				/>
			)}
			{isStepTwo && (
				<StepTwoContent
					renderDescription={renderDescription}
					resetToSelectOrganization={resetToSelectOrganization}
					renderHeader={renderHeader}
					selectCredit={selectCredit}
					selectedCredit={selectedCredit}
					selectedWorksite={selectedWorksite}
					section={sections[2]}
				/>
			)}
			{isStepThree && (
				<StepThreeContent
					renderHeader={renderHeader}
					creditFunctionsEnabled={creditFunctionsEnabled}
					selectedWorksite={selectedWorksite}
					selectedCredit={selectedCredit}
					submitBecomeProMemberForm={submitBecomeProMemberForm}
					addCustomerButtonState={addCustomerButtonState}
					resetFromStepThree={resetFromStepThree}
					customerInfo={customerInfo}
					section={sections[3]}
				/>
			)}
		</div>
	);
}
JulaProStepper.displayName = 'AccountJulaProSignupForm_JulaProStepper';
