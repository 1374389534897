import React from 'react';
import clsx from 'clsx';

import ComponentPlaceholder from 'components/ComponentPlaceholder';
import Img from 'components/Img';
import { useSitecoreContext } from 'hooks';
import { JulaComponentProps } from 'lib/component-props';
import { DigizuiteAssetList } from 'models/asset';
import { getEditorMargin } from 'utils/business-logic';
import { getAsset } from 'utils/helpers';

type Props = JulaComponentProps & {
	fields: {
		primaryImage: DigizuiteAssetList;
		secondaryImage: DigizuiteAssetList;
	};
};

export default function FocusGridMedia({ fields, params }: Props) {
	const { sitecoreContext } = useSitecoreContext();

	if (!fields) {
		return null;
	}

	const primaryImage = getAsset('Templated 2:1', fields.primaryImage, {
		width: 822,
		height: 411,
	});
	const secondaryImage = getAsset('Templated 2:1', fields.secondaryImage, {
		width: 822,
		height: 411,
	});

	if (sitecoreContext?.pageEditing && !primaryImage && !secondaryImage) {
		return (
			<ComponentPlaceholder
				componentName="FocusGridMedia"
				className={getEditorMargin(params)}
			/>
		);
	}

	if (!primaryImage || !secondaryImage) {
		return null;
	}

	return (
		<div id={params?.anchor} className={clsx('flex', getEditorMargin(params))}>
			<Img
				src={primaryImage.src}
				alt={primaryImage.alt}
				width={primaryImage.width}
				height={primaryImage.height}
				service="nextjs"
				className="h-auto w-1/2 object-cover max-sm:w-full"
			/>
			<Img
				src={secondaryImage.src}
				alt={secondaryImage.alt}
				width={secondaryImage.width}
				height={secondaryImage.height}
				service="nextjs"
				className="h-auto w-1/2 object-cover max-sm:hidden"
			/>
		</div>
	);
}
FocusGridMedia.displayName = 'FocusGridMedia';
