/**
 * BlockThemedIntroductionChips
 */

import React from 'react';
import type { Field } from '@sitecore-jss/sitecore-jss-nextjs';

import Chip from 'components/Chip';
import Img from 'components/Img';
import { LayoutContainer } from 'components/Layout';
import Text from 'components/Text';
import ThemedBlockContainer from 'components/ThemedBlockContainer';
import type { HeadingLevel } from 'lib/component-props';
import type { SimpleLink } from 'models/link';

interface Props {
	bottomRightImageSrc: string | undefined;
	centerImageAlt: string | undefined;
	centerImageSrc: string | undefined;
	className?: string;
	color: string;
	heading?: Field<string>;
	headingLevel?: HeadingLevel;
	id?: string;
	links: SimpleLink[];
	text?: Field<string>;
	topLeftImageSrc: string | undefined;
}

export default function BlockThemedIntroductionChips({
	bottomRightImageSrc,
	centerImageAlt,
	centerImageSrc,
	className,
	color,
	heading,
	headingLevel = 'h2',
	id,
	links,
	text,
	topLeftImageSrc,
}: Props) {
	return (
		<LayoutContainer className={className} id={id}>
			<Text
				as={headingLevel}
				field={heading}
				className={text ? 'mb-2' : 'mb-4'}
			/>
			<Text as="p" field={text} className="mb-6" />

			<ThemedBlockContainer
				color={color}
				topLeftImageSrc={topLeftImageSrc}
				bottomRightImageSrc={bottomRightImageSrc}
				bottomRightImageClassName="max-md:hidden"
				className="max-md:-mx-4"
				contentClassName="py-6 md:py-12 md:grid md:grid-cols-12 md:items-center md:gap-x-6"
			>
				{centerImageSrc && (
					<>
						<Img
							src={centerImageSrc}
							alt={centerImageAlt}
							width={360}
							height={180}
							service="nextjs"
							jpgOptimized={false}
							className="mx-auto mb-6 md:hidden"
						/>
						<Img
							src={centerImageSrc}
							alt={centerImageAlt}
							width={810}
							height={405}
							service="nextjs"
							jpgOptimized={false}
							className="col-span-6 max-md:hidden"
						/>
					</>
				)}
				<div className="flex flex-wrap items-center gap-4 max-md:px-4 md:col-span-5 md:col-start-7">
					{links.map((link) => (
						<Chip
							key={link.href}
							href={link.href}
							text={link.text}
							color="grey"
						/>
					))}
				</div>
			</ThemedBlockContainer>
		</LayoutContainer>
	);
}
BlockThemedIntroductionChips.displayName = 'BlockThemedIntroductionChips';
