import React from 'react';

import Icon from 'components/Icon';
import Link from 'components/Link';
import LoadMoreList from 'components/LoadMoreList';
import Price from 'components/Price';
import Text from 'components/Text';
import { useSitecoreContext } from 'hooks';
import { Order } from 'models/api';
import { formatDate } from 'utils/format';
import { empty } from 'utils/helpers';
import { useI18n } from 'utils/i18n';
import { joinPath } from 'utils/url';

interface Props {
	hasLoadMoreButton?: boolean;
	isLoadingMore?: boolean;
	onLoadMoreClick?: () => void;
	orders: Order[] | undefined;
}

export default function OrderList({
	hasLoadMoreButton = false,
	isLoadingMore,
	orders,
	onLoadMoreClick,
}: Props) {
	const { t } = useI18n();
	const { sitecoreContext } = useSitecoreContext();
	const { globalLinks } = sitecoreContext;

	if (!orders || orders.length === 0) {
		return null;
	}

	return (
		<LoadMoreList
			buttonAlignment="center"
			buttonClassName="min-w-60 mt-6"
			buttonText={t('load_more_orders_button')}
			buttonVariant="secondary"
			hasLoadMoreButton={hasLoadMoreButton}
			isLoading={isLoadingMore}
			listTag="ul"
			onLoadMoreClick={onLoadMoreClick ?? empty.func}
		>
			{orders.map((order) => (
				<li key={order.id}>
					<Link
						href={joinPath(globalLinks?.accountOrders, order.id)}
						className="mb-2 flex items-center rounded border border-grey p-4 text-sm hover:border-greyDark"
					>
						<div className="mr-auto">
							<Text as="h2" styleAs="h6" className="mb-2">
								{order.pointOfPurchase}
							</Text>
							<time>{formatDate(order.purchaseDate)}</time>
						</div>
						{/* Space for screen readers */}{' '}
						<Price
							price={order.totalSum.displayValue}
							currency={order.totalSum.displaySymbol}
							size="mini"
						/>
						<Icon icon="arrow" direction="right" className="ml-6" />
					</Link>
				</li>
			))}
		</LoadMoreList>
	);
}
OrderList.displayName = 'AccountPurchases_OrderList';
