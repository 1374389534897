import React from 'react';
import clsx from 'clsx';

import BlockPromoCampaign from 'components/BlockPromoCampaign';
import BlockPromoCampaignMobile from 'components/BlockPromoCampaignMobile';
import ComponentPlaceholder from 'components/ComponentPlaceholder';
import Link from 'components/Link';
import ProductAnimation from 'components/ProductAnimation';
import { useSitecoreContext } from 'hooks';
import { JulaComponentProps } from 'lib/component-props';
import { DigizuiteAssetList } from 'models/asset';
import { SitecoreLink } from 'models/link';
import { getEditorMargin } from 'utils/business-logic';
import { getAsset } from 'utils/helpers';

type PromoCampaignProps = JulaComponentProps & {
	fields: {
		darkIcon: {
			value: boolean;
		};
		darkText: {
			value: boolean;
		};
		itemsShown: number;
		largeImage: DigizuiteAssetList;
		link: {
			value: SitecoreLink;
		};
		products: [];
		smallImage: DigizuiteAssetList;
		total: number;
	};
};

function PromoCampaign({ params, fields }: PromoCampaignProps) {
	const { sitecoreContext } = useSitecoreContext();
	const isEditing = sitecoreContext?.pageEditing;

	if (!fields) return null;

	const { smallImage, largeImage, products, link, darkIcon, darkText } = fields;

	const linkTextColor = darkText?.value ? 'greyDarker' : 'white';
	const iconColor = darkIcon?.value ? 'black' : 'white';

	const largeImg = getAsset('Templated 4:1', largeImage);
	const smallImg = getAsset('Templated 4:3', smallImage);
	if ((!largeImg || !smallImg) && !isEditing) return null;

	if (isEditing) {
		return (
			<ComponentPlaceholder
				componentName="PromoCampaign"
				className={getEditorMargin(params)}
				isDeprecated
			/>
		);
	}

	return (
		<div
			id={params?.anchor}
			className={clsx(getEditorMargin(params), 'overflow-hidden')}
		>
			{largeImg && (
				<div className="max-sm:hidden">
					<Link href={link?.value?.href || '#'} passHref legacyBehavior>
						<BlockPromoCampaign
							linkText={link?.value?.text}
							linkTextColor={linkTextColor}
							url={link?.value?.href}
							imageSrc={largeImg.src}
							imageAlt={largeImg.alt}
							iconColor={iconColor}
							animatedProduct={
								<ProductAnimation
									isBlock
									animatedProductArray={products}
									isPCampaign={false}
								/>
							}
						/>
					</Link>
				</div>
			)}
			<div className="sm:hidden">
				{smallImg && (
					<Link href={link?.value?.href || '#'} passHref legacyBehavior>
						<BlockPromoCampaignMobile
							imageSrc={smallImg.src}
							imageAlt={smallImg.alt}
							href={link?.value?.href}
							linkText={link?.value?.text}
							animatedProduct={
								<ProductAnimation
									animatedProductArray={products}
									isBlock
									isPCampaign={false}
								/>
							}
						/>
					</Link>
				)}
			</div>
		</div>
	);
}

PromoCampaign.displayName = 'PromoCampaign';

export default PromoCampaign;
