import React, { useEffect, useMemo, useState } from 'react';
import { Field } from '@sitecore-jss/sitecore-jss-nextjs';
import clsx from 'clsx';

import ActionButton, { type ActionButtonState } from 'components/ActionButton';
import Button from 'components/Button';
import Icon from 'components/Icon';
import InfoBox from 'components/InfoBox';
import { LayoutContainer } from 'components/Layout';
import Map from 'components/Map';
import ScreenReaderAnnouncementText from 'components/ScreenReaderAnnouncementText';
import StoreListItem from 'components/StoreListItem';
import Text from 'components/Text';
import { withRequiredProps } from 'hoc';
import { useMinWidth } from 'hooks';
import type { JulaComponentProps } from 'lib/component-props';
import type { Store, StoreMarkerModel } from 'models/store';
import { useI18n } from 'utils/i18n';
import { type Coordinate, getDistanceBetween } from 'utils/math';

type StoreListProps = JulaComponentProps & {
	fields: {
		allStoresHeading: Field<string>;
		heading: Field<string>;
		nearbyStoresHeading: Field<string>;
		stores: Store[];
	};
};

/** Component for listing and displaying stores. */
function StoreList({ fields }: StoreListProps) {
	const [userPosition, setUserPosition] = useState<Coordinate>();
	const { t } = useI18n();

	// Show/hide behavior for a component would normally be done with CSS but the
	// map component loads a big chunk of JS when first rendered so only show it
	// when needed. It defaults to hidden so it's not loaded right away on small
	// screens and the size check is done in an effect since it can't be known
	// on the server.
	const [isMapVisible, setIsMapVisible] = useState(false);
	const isMinMd = useMinWidth('md');
	useEffect(() => {
		// Always show map on larger screens.
		if (isMinMd) {
			setIsMapVisible(true);
		}
	}, [isMinMd]);

	// Memo since it's used as an effect dependency.
	const stores: StoreMarkerModel[] = useMemo(
		() =>
			fields.stores.map((store) => ({
				id: store.id,
				name: store.name,
				openHours: store.todaysOpeningHours?.description || '',
				isOpen: store?.todaysOpeningHours?.state?.toUpperCase() === 'OPEN',
				address: `${store.streetAddress}, ${store.postalCode}, ${store.city}`,
				storeArea: store.storeArea,
				url: store.url,
				latitude: Number(store.latitude),
				longitude: Number(store.longitude),
			})),
		[fields.stores],
	);

	const [nearbyStores, setNearbyStores] = useState<StoreMarkerModel[]>([]);
	const hasNearbyStores = nearbyStores.length > 0;
	useEffect(() => {
		if (userPosition) {
			const closestStores = [...stores].sort((a, b) => {
				const aPos = getDistanceBetween(userPosition, {
					lat: a.latitude,
					long: a.longitude,
				});
				const bPos = getDistanceBetween(userPosition, {
					lat: b.latitude,
					long: b.longitude,
				});
				return aPos < bPos ? -1 : 1;
			});
			setNearbyStores(closestStores.slice(0, 2));
		}
	}, [stores, userPosition]);

	const [findPositionButtonState, setFindPositionButtonState] =
		useState<ActionButtonState>('idle');
	const [userPositionError, setUserPositionError] =
		useState<GeolocationPositionError>();
	const handleFindPositionClick = () => {
		setFindPositionButtonState('loading');
		navigator.geolocation.getCurrentPosition(
			(pos) => {
				setFindPositionButtonState('success');
				setUserPosition({
					lat: pos.coords.latitude,
					long: pos.coords.longitude,
				});
			},
			(positionError) => {
				setFindPositionButtonState('failure');
				setUserPositionError(positionError);
			},
		);
	};

	return (
		<LayoutContainer
			withGrid
			outerClassName="mt-8 md:mt-12"
			className="gap-y-12"
		>
			<div className="col-span-4 md:col-span-5 lg:col-span-4">
				<Text as="h1" className="mb-2">
					{t('stores_list_heading')}
				</Text>

				<div className="mt-4">
					<div className="flex items-center justify-between">
						<ActionButton
							customState={findPositionButtonState}
							onClick={handleFindPositionClick}
							variant="primary"
						>
							<Icon icon="location" />
							{t('stores_nearby_stores_button')}
						</ActionButton>

						<Button
							onClick={() => setIsMapVisible((current) => !current)}
							variant="text"
							className="md:hidden"
						>
							{isMapVisible
								? t('stores_show_as_list_button')
								: t('stores_show_on_map_button')}
						</Button>
					</div>
					<ScreenReaderAnnouncementText
						as="div"
						text={
							findPositionButtonState === 'failure' ? (
								<InfoBox
									className="mt-4"
									icon="error"
									variant="error"
									message={
										userPositionError?.PERMISSION_DENIED
											? t('stores_geolocation_permission_error_text')
											: t('stores_geolocation_general_error_text')
									}
								/>
							) : undefined
						}
					/>
				</div>

				<div className={clsx('mt-8', isMapVisible && 'max-md:hidden')}>
					<ScreenReaderAnnouncementText
						as="div"
						text={
							hasNearbyStores ? (
								<>
									<Text as="h2" className="mb-4">
										{t('stores_nearby_stores_button')}
									</Text>
									<p className="sr-only">
										{nearbyStores.map(({ name }) => name).join(', ')}
									</p>
								</>
							) : undefined
						}
					/>
					{hasNearbyStores && (
						<ul className="mb-8 flex flex-col gap-y-2">
							{nearbyStores.map((store) => (
								<StoreListItem
									key={store.id}
									name={store.name}
									url={store.url}
									openHours={store.openHours}
									isOpen={store.isOpen}
									address={store.address}
									storeArea={store.storeArea}
								/>
							))}
						</ul>
					)}

					<Text as="h2" className="mb-4">
						{t('stores_store_list_heading')}
					</Text>

					<ul className="flex flex-col gap-y-2">
						{stores.map((store) => (
							<StoreListItem
								key={store.id}
								name={store.name}
								url={store.url}
								openHours={store.openHours}
								isOpen={store.isOpen}
								address={store.address}
								storeArea={store.storeArea}
							/>
						))}
					</ul>
				</div>
			</div>
			<div className="col-span-4 md:col-span-7 md:col-start-6">
				{isMapVisible && (
					<div className="flex h-[700px] max-h-[calc(100vh-10rem)] items-center justify-center bg-greyLighter md:sticky md:top-28">
						<Map
							nearbyStores={nearbyStores}
							userPosition={userPosition}
							stores={stores}
							className="h-full w-full"
						/>
					</div>
				)}
			</div>
		</LayoutContainer>
	);
}
StoreList.displayName = 'StoreList';

export default withRequiredProps(StoreList, 'fields');
