import React from 'react';

import BlockMedia from 'components/BlockMedia/';
import ComponentPlaceholder from 'components/ComponentPlaceholder';
import { JulaComponentProps } from 'lib/component-props';
import { DigizuiteAssetList } from 'models/asset';
import { ProductCard as ProductCardType } from 'models/productCard';
import { getEditorMargin } from 'utils/business-logic';
import { getAsset } from 'utils/helpers';

type MediaSquareProps = JulaComponentProps & {
	fields: {
		squareImage: DigizuiteAssetList;
		products: ProductCardType[];
	};
};

function MediaSquare({ params, fields }: MediaSquareProps) {
	if (!fields) return null;
	const { squareImage, products } = fields;

	const squareImg = getAsset('Templated 1:1', squareImage);

	if (!squareImg) {
		return (
			<ComponentPlaceholder
				componentName="ShortcutImages"
				className={getEditorMargin(params)}
			/>
		);
	}

	return (
		<div id={params?.anchor} className={getEditorMargin(params)}>
			<BlockMedia
				variant="square"
				imgSrc={squareImg?.src}
				imgAlt={squareImg?.alt}
				products={products}
			/>
		</div>
	);
}

MediaSquare.displayName = 'MediaSquare';

export default MediaSquare;
