/**
 * SwipeWrapper
 */

import React from 'react';
import clsx from 'clsx';

export type SwipeWrapperSpacing = 0.5 | 1 | 1.5;

interface Props {
	/** Classes for the container in active state. */
	activeClassName?: string;

	/** React children */
	children?: React.ReactNode;

	/** Extra class names for the container, for both active and inactive state. */
	className?: string;

	/** Classes for the container in inactive state. */
	inactiveClassName?: string;

	/** Pull the wrapper over horizontal gutters to the edge of the screen */
	pullGutters?: boolean;

	/** Spacing between child components */
	spacing?: SwipeWrapperSpacing;
}

/**
 * A wrapper that can be used to list components on one row and allow users
 * to swipe/scroll horizontally.
 *
 * Pass an inactive class name to render an additional container without any
 * scrolling classes.
 */
export default function SwipeWrapper({
	activeClassName,
	children,
	className,
	inactiveClassName,
	pullGutters = false,
	spacing = 0.5,
}: Props) {
	return (
		<>
			<div
				className={clsx(
					className,
					activeClassName,
					'scrolling-touch flex overflow-x-scroll whitespace-nowrap pb-2 pt-0.5',
					spacing === 0.5 && 'gap-2',
					spacing === 1 && 'gap-4',
					spacing === 1.5 && 'gap-6',
					// Match spacing in LayoutContainer
					pullGutters && '-mx-4 px-4 md:-mx-6 md:px-6',
				)}
			>
				{children}
			</div>
			{inactiveClassName && (
				<div className={clsx(className, inactiveClassName)}>{children}</div>
			)}
		</>
	);
}
SwipeWrapper.displayName = 'SwipeWrapper';
