import React from 'react';

import InfoBox from 'components/InfoBox';
import Link from 'components/Link';
import { Skeleton, SkeletonItem } from 'components/Skeleton';
import Text from 'components/Text';
import { useI18n } from 'utils/i18n';

type InvoiceAddress = {
	city: string;
	co: string;
	country: string;
	name: string;
	postalCode: string;
	street: string;
};

interface Props {
	contactEmail?: string;
	contactPhoneNumber?: string;
	editDetailsLink: string;
	id?: string;
	identificationNumber?: string;
	invoiceAddress?: InvoiceAddress;
	isLoading: boolean;
	hasError: boolean;
}

export default function OrganizationDetails({
	id,
	identificationNumber,
	invoiceAddress,
	contactEmail,
	contactPhoneNumber,
	isLoading,
	hasError,
	editDetailsLink,
}: Props) {
	const { t } = useI18n();
	const city = invoiceAddress?.city;
	const name = invoiceAddress?.name;
	const postalCode = invoiceAddress?.postalCode;
	const street = invoiceAddress?.street;
	const co = invoiceAddress?.co;
	const loadedAndOk = !isLoading && !hasError;
	return (
		<div className="overflow-hidden">
			<div className="flex justify-between">
				<Text className="mb-2" as="h2">
					{t('julapro_organization_details_heading')}
				</Text>
				{editDetailsLink && (
					<Link href={editDetailsLink} className="my-auto underline">
						<Text as="span">
							{t('julapro_organization_details_edit_button')}
						</Text>
					</Link>
				)}
			</div>
			{loadedAndOk && (
				<>
					<div className="mb-6">
						{name && <Text as="p" text={name} />}
						{co && <Text as="p" text={co} />}
						{street && <Text as="p" text={street} />}
						{postalCode && city && (
							<Text className="mb-2" as="p" text={`${postalCode} ${city}`} />
						)}
					</div>
					{(identificationNumber || id) && (
						<div className="mb-6">
							{identificationNumber && (
								<Text
									as="p"
									text={`${t(
										'julapro_organization_number',
									)}: ${identificationNumber}`}
								/>
							)}
							{id && (
								<Text
									as="p"
									className="mb-2"
									text={`${t('julapro_customer_id')}: ${id}`}
								/>
							)}
						</div>
					)}
					{contactEmail && <Text as="p" text={contactEmail} />}
					{contactPhoneNumber && (
						<Text className="mb-2" as="p" text={contactPhoneNumber} />
					)}
				</>
			)}
			{isLoading && (
				<Skeleton>
					<SkeletonItem height="2rem" className="mt-2" />
					<SkeletonItem height="1rem" className="mt-4" />
					<SkeletonItem height="1rem" className="mt-2" />
					<SkeletonItem height="1rem" className="mb-8 mt-2" />
					<SkeletonItem height="1rem" className="mt-4" />
					<SkeletonItem height="1rem" className="mb-8 mt-2" />
					<SkeletonItem height="1rem" className="mt-4" />
					<SkeletonItem height="1rem" className="mb-8 mt-2" />
				</Skeleton>
			)}
			{hasError && (
				<InfoBox
					icon="error"
					variant="error"
					message={t('account_generic_fetch_error_text')}
				/>
			)}
		</div>
	);
}
OrganizationDetails.displayName = 'AccountJulaProOverview_OrganizationDetails';
