import React from 'react';
import clsx from 'clsx';

import Icon from 'components/Icon';

interface Step {
	text: string;
	completed: boolean;
}

interface Props {
	steps: Step[];
}

export default function ProgressSteps({ steps }: Props) {
	const currentStep = steps.findIndex((s) => s.completed === false);
	const totalStepCount = steps.length;

	return (
		<ol className="relative flex flex-col">
			{steps.map(({ text, completed }, index) => {
				const isLastItem = index === totalStepCount - 1;
				const isCurrentStep =
					index === currentStep - 1 && index !== totalStepCount - 1;

				return (
					<li
						className={clsx(
							'relative pb-6 pl-10 font-bold',
							isCurrentStep && 'min-h-20',
							'before:absolute before:top-1 before:z-2 before:inline-flex before:justify-center before:rounded-full',
							!completed &&
								'before:left-[8px] before:h-4 before:w-4 before:bg-grey after:bg-grey',
							!isLastItem &&
								'after:absolute after:left-[15px] after:top-1 after:inline-block after:h-full after:w-[2px] after:bg-grey',
							completed && !isCurrentStep && 'after:bg-julaRed',
							completed && 'pt-1',
						)}
						// Could also be that the next step should be set as aria-current,
						// to indicate that's where work is currently happening.
						// The design has some examples like 'package ready for pickup'
						// though, where it could give the impression from aria-current
						// that a package is ready when it isn't. Probably safest to have
						// the last completed step as the current.
						aria-current={isCurrentStep ? 'step' : undefined}
						key={text}
					>
						{completed && (
							<Icon
								icon="check"
								color="white"
								backgroundColor="julaRed"
								hasBackgroundPadding
								className="absolute left-0 top-0 z-2"
							/>
						)}
						{isCurrentStep && (
							<>
								<span className="absolute left-[8px] top-1/2 z-1 block h-4 w-4 translate-y-1/2 animate-pulse rounded-full bg-julaRedLight" />
								<span className="absolute left-[12px] top-1/2 z-2 mt-1 block h-2 w-2 translate-y-full animate-pulse rounded-full bg-julaRed" />
								<span className="absolute left-[15px] top-4 z-1 block h-1/2 w-[2px] bg-julaRed" />
							</>
						)}
						{text}
					</li>
				);
			})}
		</ol>
	);
}
ProgressSteps.displayName = 'ProgressSteps';
