/**
 * StockBalance
 */

import React from 'react';

import Button from 'components/Button';
import Icon, { IconType } from 'components/Icon';
import InfoBox from 'components/InfoBox';
import { Skeleton, SkeletonItem } from 'components/Skeleton';
import Text from 'components/Text';
import { useI18n } from 'utils/i18n';

import StockStatus from './StockStatus';

interface StoreInfo {
	articleInfo: {
		department?: string;
		shelf?: string;
		artno?: string;
		color?: string;
	};
	openingHours: string;
}
interface StockStatusInfo {
	inStock: boolean;
	statusText: string;
	statusInfo?: string;
}
interface Props {
	/** Stock location header */
	header?: string;
	/** Stock location icon */
	locationIcon: IconType;
	/** Stock status info with inStock, stock status text and info about status */
	stockStatus: StockStatusInfo;
	/** Store info if status is a for a store with department, shelf, artno and opening hours */
	storeInfo?: StoreInfo;
	/** General info about stock */
	infoText?: string;
	/** click handler for store changer, watchable signup and more */
	onClick?: () => void;
	/** text for the button */
	buttonText?: string;
	/** Should the component display a loading state */
	loading?: boolean;
	statusType: string;
}

/** Component to display stock balance in product details sidebar */
export default function StockBalance({
	header,
	locationIcon,
	storeInfo,
	infoText,
	onClick,
	buttonText,
	loading,
	stockStatus,
	statusType,
}: Props) {
	const { t } = useI18n();

	if (loading) {
		return (
			<div className="p-4">
				<Skeleton>
					<div className="mb-4 flex items-center">
						<SkeletonItem height="1.5rem" width="1.5rem" className="mr-2" />
						<SkeletonItem height="1.5rem" width="75%" />
					</div>
					<div className="pl-8">
						<div className="mb-4 flex items-center">
							<SkeletonItem
								height="1.5rem"
								width="1.5rem"
								className="mr-2 !rounded-full"
							/>
							<SkeletonItem height="1.5rem" width="50%" />
						</div>
						<SkeletonItem height="1.5rem" width="50%" className="mb-6" />
						<SkeletonItem height="1.5rem" />
					</div>
				</Skeleton>
			</div>
		);
	}

	return (
		<div className="p-4">
			<div className="mb-4 flex items-center">
				<Icon icon={locationIcon} color="black" className="mr-2" />
				<Text as="span" className="font-bold">
					{header}
				</Text>
			</div>
			<div className="pl-8">
				<StockStatus
					inStock={stockStatus.inStock}
					text={stockStatus.statusText}
				/>
				{stockStatus.statusInfo && (
					<Text as="pXSmall" text={stockStatus.statusInfo} className="mt-2" />
				)}
				{storeInfo && (
					<>
						<div
							className="my-4 pl-2"
							style={{
								borderLeft: `4px solid ${storeInfo.articleInfo.color}`,
							}}
						>
							<Text
								as="p"
								text={`${t(
									'product_details_store_accordion_department_text',
								)} ${storeInfo.articleInfo.department}`}
							/>
							<Text
								as="p"
								text={`${t('product_details_store_accordion_shelf_text')} ${
									storeInfo.articleInfo.shelf
								}`}
							/>
							<Text
								as="p"
								text={`${t('product_details_store_accordion_art_no_text')} ${
									storeInfo.articleInfo.artno
								}`}
							/>
						</div>
						<Text as="p" text={storeInfo.openingHours} />
					</>
				)}
				{onClick && (
					<Button
						className="mt-4"
						variant="text"
						size="medium"
						onClick={onClick}
					>
						{buttonText}
					</Button>
				)}
			</div>
			{infoText && <InfoBox icon="info" message={infoText} className="mt-4" />}
		</div>
	);
}
StockBalance.displayName = 'StockBalance';
