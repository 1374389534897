/**
 * ProductCarousel
 */

import React from 'react';

import Slider from 'components/Slider';

interface Props {
	children?: React.ReactNode;
	onNext?: (nextIndex: number) => void;
}

export default function ProductCarousel({ onNext, children }: Props) {
	return <Slider onNext={onNext} items={React.Children.toArray(children)} />;
}
ProductCarousel.displayName = 'ProductCarousel';
