/**
 * BlockHeroPromoMobile
 */

import React from 'react';
import clsx from 'clsx';

import Icon from 'components/Icon';
import Img from 'components/Img';
import Text from 'components/Text';

import styles from './BlockPromoCampaignMobile.module.css';

interface Props {
	/** Src for promo hero image */
	imageSrc: string;

	/** Alt-text for promo hero image */
	imageAlt: string;

	/** Check if there is any video, if not render fallback image */
	video?: string;

	/** The link text presented below the image */
	linkText?: string;

	/** The animated product prop that sends an component if information is filled about the price and has an image. */
	animatedProduct?: any;
	/** Url as a string */
	href?: string;

	/** onClick needed for integration with next/link */
	onClick?: any;
}

/** Promo campaign block for mobile. */
export default function BlockPromoCampaignMobile({
	imageSrc,
	imageAlt,
	href,
	onClick,
	video,
	linkText,
	animatedProduct,
}: Props) {
	return (
		<>
			<div className="relative w-full">
				<div className="relative block overflow-hidden">
					<a
						href={href}
						onClick={onClick}
						className="relative m-auto block cursor-pointer hover:underline"
					>
						{video && (
							<div className="relative">
								<video
									muted
									autoPlay
									loop
									playsInline
									className={clsx(
										'block',
										'w-full',
										'object-cover',
										'max-h-[30rem]',
										styles.videobreakpoint,
									)}
								>
									<source src={video} />
								</video>
								{imageSrc && (
									<div className="relative m-auto">
										<Img
											src={imageSrc}
											alt={imageAlt || ''}
											width={720}
											height={540}
											service="nextjs"
											className="w-full object-contain"
										/>
									</div>
								)}
							</div>
						)}
						{!video && imageSrc && (
							<div className="relative">
								<Img
									src={imageSrc}
									alt={imageAlt || ''}
									width={720}
									height={540}
									service="nextjs"
									className="w-full object-contain"
								/>
							</div>
						)}
					</a>
					{animatedProduct && (
						<div className="absolute left-1/2 top-2/4 h-1/2 w-1/2 translate-x-[-4.81rem] translate-y-[-2.8rem] transform sm:w-1/3 sm:translate-x-[-11.3rem] sm:translate-y-[-7rem] ">
							{animatedProduct}
						</div>
					)}
				</div>
			</div>
			<a
				href={href}
				onClick={onClick}
				className="relative m-auto block cursor-pointer hover:underline"
			>
				<div className="my-4 flex items-center justify-center">
					<Icon
						aria-hidden
						icon="arrow"
						color="white"
						className="mr-2 rounded-[50%] bg-julaRed"
					/>
					<Text as="p">{linkText}</Text>
				</div>
			</a>
		</>
	);
}
BlockPromoCampaignMobile.displayName = 'BlockPromoCampaignMobile';
