/**
 * ThumbnailList
 */

import React, { type MouseEventHandler } from 'react';
import clsx from 'clsx';

import Img from 'components/Img';

interface Props {
	/** Image alt text */
	alt?: string;

	className?: string;

	/** If the item should be displayed as active/selected */
	isSelected?: boolean;

	/** Text label for accessibility */
	label: string;

	/** Callback when the button is clicked */
	onClick?: MouseEventHandler<HTMLButtonElement>;

	/** Image source */
	src: string;
}

export default function ThumbnailItem({
	alt,
	className,
	isSelected = false,
	label,
	onClick,
	src,
}: Props) {
	return (
		<button
			type="button"
			onClick={onClick}
			aria-label={label}
			className={clsx(
				'group/thumbnail w-16 rounded-button border p-2',
				isSelected && 'border-grey hover:border-greyDarken',
				!isSelected && 'border-greyLight hover:border-greyLightDarken',
				className,
			)}
		>
			<Img
				src={src}
				alt={alt}
				className="w-full transform transition-transform group-hover/thumbnail:scale-[1.07]"
				service="nextjs"
				width={46}
				height={46}
			/>
		</button>
	);
}
ThumbnailItem.displayName = 'ThumbnailItem';
