import React from 'react';
import { useSelector } from '@xstate/react';
import { waitFor } from 'xstate/lib/waitFor';

import AddGiftCard from 'components/AddGiftCard';
import GiftCard from 'components/GiftCard';
import InfoBox from 'components/InfoBox';
import { useCheckoutContext } from 'contexts';
import type { ApiJulaModelsCartCartErrorModel } from 'models/api';
import {
	selectAllowGiftCard,
	selectGiftCardBonusActor,
} from 'state-machines/checkout';
import {
	selectGiftCardAmountInfo,
	selectGiftCardDisabledWithSelectedPaymentMethod,
	selectGiftCards,
} from 'state-machines/checkout/giftCardBonus';
import { is } from 'utils/helpers';

interface Props {
	className?: string;
}

function getErrorMessages(
	errorList: ApiJulaModelsCartCartErrorModel[] | undefined,
	errorTypes: string[],
) {
	const errors = errorList
		?.filter((error) => errorTypes.includes(error.type ?? ''))
		.map((error) => error.description);
	if (is.arrayWithLength(errors)) {
		return errors;
	}
	return undefined;
}

export default function GiftCardSection({ className }: Props) {
	const { checkoutService } = useCheckoutContext();
	const giftCardBonusActor = useSelector(
		checkoutService,
		selectGiftCardBonusActor,
	);
	const { send } = giftCardBonusActor;
	const giftCards = useSelector(giftCardBonusActor, selectGiftCards);
	const allowGiftCard = useSelector(checkoutService, selectAllowGiftCard);
	const giftCardAmountInfo = useSelector(
		giftCardBonusActor,
		selectGiftCardAmountInfo,
	);
	const giftCardInvalidWithInvoice = useSelector(
		giftCardBonusActor,
		selectGiftCardDisabledWithSelectedPaymentMethod,
	);

	const handleGiftCardSubmit = async ({ giftCardNr, pinCode }) => {
		send({
			type: 'ADD_REMOVE_GIFT_CARD',
			giftCard: { id: giftCardNr, pinCode },
			variant: 'add',
		});
		const giftCardBonusMachineState = await waitFor(
			giftCardBonusActor,
			(state) => state.matches('idle'),
		);

		const currentGftCardError =
			giftCardBonusMachineState.context.errorList.filter(
				(error) => error.key === giftCardNr,
			);

		if (is.arrayWithLength(currentGftCardError)) {
			const giftCardNrErrorMessages = getErrorMessages(currentGftCardError, [
				'GiftCardNotFound',
				'GiftCardExpired',
				'GiftCardInvalid',
			]);
			const pinCodeErrorMessages = getErrorMessages(currentGftCardError, [
				'GiftCardInvalidPIN',
			]);
			const giftCardErrorMessages = getErrorMessages(currentGftCardError, [
				'GiftCardZeroBalance',
			]);
			return {
				giftCardNr: giftCardNrErrorMessages,
				pinCode: pinCodeErrorMessages,
				giftCardErrorMessages,
			};
		}
		return undefined;
	};

	if (!allowGiftCard && !giftCardInvalidWithInvoice) {
		return null;
	}

	return (
		<div className={className}>
			<AddGiftCard
				onSubmit={handleGiftCardSubmit}
				giftCardInvalidWithInvoice={giftCardInvalidWithInvoice?.description}
			/>

			{giftCards && (
				<div className="mt-4 flex flex-col gap-4">
					{giftCards
						.filter((card) => card.balance?.displayValue)
						.map((card) => (
							<div key={card.id} className="mt-2">
								<GiftCard
									id={card.id}
									balance={card.balance}
									amountToUse={card.amountToUse}
									balanceAfterPurchase={card.balanceAfterPurchase}
									onRemoveClick={() => {
										send({
											type: 'ADD_REMOVE_GIFT_CARD',
											giftCard: card,
											variant: 'remove',
										});
									}}
								/>
							</div>
						))}
				</div>
			)}

			{giftCardAmountInfo && (
				<div className="mb-10">
					<InfoBox
						className="min-h-[6.5rem] w-full"
						icon="info"
						variant="information"
						message={giftCardAmountInfo.description}
					/>
				</div>
			)}
		</div>
	);
}
GiftCardSection.displayName = 'GiftCardSection';
