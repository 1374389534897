import type { UserManagementMachineState } from './userManagement.types';

export const selectStateIsIdle = (state: UserManagementMachineState) =>
	state.matches('idle');

export const selectStateIsLoadingUserList = (
	state: UserManagementMachineState,
) => state.matches('listingUsers.loadUserList');

export const selectStateIsErrorGettingUserList = (
	state: UserManagementMachineState,
) => state.matches('listingUsers.userListFlow.errorGettingUserList');

export const selectUserList = (state: UserManagementMachineState) =>
	state.context.userList;

export const selectErrors = (state: UserManagementMachineState) =>
	state.context.errors;

export const selectUserAdded = (state: UserManagementMachineState) =>
	state.context.userWasAdded;

export const selectStateAddUserIdle = (state: UserManagementMachineState) =>
	state.matches('listingUsers.addUserFlow.idle');

export const selectSubmitAddUserButtonState = (
	state: UserManagementMachineState,
) => state.context.addUserButtonState;

export const selectStateIsLoadingUser = (state: UserManagementMachineState) =>
	state.matches('viewingUser.viewUserFlow.loadingUser');

export const selectStateIsErrorGettingUser = (
	state: UserManagementMachineState,
) => state.matches('viewingUser.viewUserFlow.errorGettingUser');

export const selectUser = (state: UserManagementMachineState) =>
	state.context.selectedUser;

export const selectInviteButtonState = (state: UserManagementMachineState) =>
	state.context.inviteButtonState;

export const selectRemoveUserButtonState = (
	state: UserManagementMachineState,
) => state.context.removeUserButtonState;
