import { useEffect, useState } from 'react';

import { useLayoutServicePlaceholder } from 'hooks';
import type {
	Link as LinkType,
	MainLink,
	MainLinkFields,
	PageHeaderFields,
} from 'models/pageHeader';
import { empty, is } from 'utils/helpers';

export const SCROLL_LOCK_WHITELIST_CLASS = 'page-header-scroll-child';

export function getStripeGradient(colors: string[]): string {
	const percentage = 100 / colors.length;
	const stop = (color: string, step: number) =>
		// Slice to remove unnecessary decimals.
		`${color} ${String(step * percentage).slice(0, 5)}%`;
	const stops = colors
		.map((color, i) => `${stop(color, i)}, ${stop(color, i + 1)}`)
		.join(', ');
	return `linear-gradient(to right, ${stops})`;
}

/**
 * Tailwind needs static class names.
 */
export const GRID_ROWS = {
	7: 'grid-rows-7',
	8: 'grid-rows-8',
	9: 'grid-rows-9',
	10: 'grid-rows-10',
	11: 'grid-rows-11',
	12: 'grid-rows-12',
	13: 'grid-rows-13',
	14: 'grid-rows-14',
	15: 'grid-rows-15',
	16: 'grid-rows-16',
	17: 'grid-rows-17',
	18: 'grid-rows-18',
	19: 'grid-rows-19',
	20: 'grid-rows-20',
};

/**
 * Get the maximum nesting level for a list of links.
 */
function getMaxLevel(allLinks: MainLinkFields[], _max: number = 1): number {
	let newMax = 0;
	for (const link of allLinks) {
		if (link.links) {
			newMax = getMaxLevel(link.links, _max + 1);
		}
	}

	return Math.max(_max, newMax);
}

/**
 * Check if a list of links for a dropdown will need a large one.
 *
 * In practice this is currenly the product dropdown.
 */
export function isLargeDropdown(allLinks?: MainLinkFields[]): boolean {
	return allLinks ? getMaxLevel(allLinks) > 1 : false;
}

export function getLinkListId(baseId: string, linkId: string | undefined) {
	return linkId ? `${baseId}-link-list-${linkId}` : undefined;
}

export function isLinkVisible({ fields }: LinkType): boolean {
	return Boolean(fields?.link?.href);
}

export function isMainLinkVisible({ fields }: MainLink): boolean {
	return Boolean(
		(fields.link ?? fields.links) && (fields.title || fields.link?.text),
	);
}

/**
 * Get the parents → children 'path' to the specified link ID.
 *
 * @example
 *
 * // Real objects will have more data.
 * const allLinks = [
 *   {
 *     id: '1',
 *     text: 'One',
 *     links: [{ id: '1.1', text: 'One-1' }, { id: '1.2', text: 'One-2' }]
 *   },
 *   {
 *     id: '2',
 *     text: 'Two',
 *     links: [{ id: '2.1', text: 'Two-1' }, { id: '2.2', text: 'Two-2' }]
 *   },
 * ];
 *
 * getLinkPath('1', allLinks);
 * // => [{ id: '1', text: 'One', links: [...] }]
 *
 * getLinkPath('2.2', allLinks);
 * // => [{ id: '2', text: 'Two', links: [...] }, { id: '2.2', text: 'Two-2' }]
 *
 * getLinkPath('3', allLinks);
 * // => []
 */
export function getLinkPath(
	linkId: string,
	allLinks: MainLinkFields[],
	_path: MainLinkFields[] = [],
): MainLinkFields[] {
	if (!linkId) {
		return [];
	}

	for (const link of allLinks) {
		if (link.id === linkId) {
			return [..._path, link];
		}
		if (link.links) {
			const childPath = getLinkPath(linkId, link.links, [..._path, link]);
			if (childPath[0]) {
				return childPath;
			}
		}
	}

	return [];
}

/**
 * Get the page header main menu.
 *
 * Caches main menu items globally to handle the menu being excluded from data
 * requests (see x-nextjs-data part in layout-service-factory.rest.ts).
 * The `mainMenu` field on PageHeader will have data from the initial browser
 * request but will be empty on any subsequent navigation requests. Caching
 * locally with `useRef` will not work since the entire header may be remounted
 * on navigation.
 * If the menu is missing from both the global cache and props, do a manual
 * fallback fetch to layout service.
 */
export function useMainMenu(mainMenuField: MainLink[] | undefined) {
	const [hasMainMenuFallback, setHasMainMenuFallback] = useState(false);
	const { component } = useLayoutServicePlaceholder<PageHeaderFields>(
		'jula-header',
		{
			componentName: 'PageHeader',
			isActive: hasMainMenuFallback,
		},
	);
	const mainMenuFallback = component?.mainMenu ?? empty.array;

	useEffect(() => {
		if (
			mainMenuField &&
			mainMenuField.length > 0 &&
			(!window.mainMenuItems || window.mainMenuItems.length === 0)
		) {
			window.mainMenuItems = mainMenuField.filter(isMainLinkVisible);
		} else if (
			(!mainMenuField || mainMenuField.length === 0) &&
			(!window.mainMenuItems || window.mainMenuItems.length === 0) &&
			!hasMainMenuFallback
		) {
			setHasMainMenuFallback(true);
		}
	}, [hasMainMenuFallback, mainMenuField]);

	if (
		typeof window !== 'undefined' &&
		is.arrayWithLength(window.mainMenuItems)
	) {
		return window.mainMenuItems;
	}

	return (
		is.arrayWithLength(mainMenuField) ? mainMenuField : mainMenuFallback
	).filter(isMainLinkVisible);
}
