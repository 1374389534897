import type { Error } from 'models/api';

import type { CreateJulaClubCustomerMachineState } from './createJulaClubCustomer.types';

const alreadyMemberErrorKeys = [
	'PhoneNumberConflictValidation',
	'EmailConflictValidation',
	'CustomerWithUniqueIdentifierAlreadyExists',
];
const customerCreationErrorKeys = ['UnknownError'];

export function handledBusinessLogicErrors(error: Error) {
	return ![...alreadyMemberErrorKeys, ...customerCreationErrorKeys].includes(
		error.key,
	);
}

export const selectCreatingCustomer = (
	state: CreateJulaClubCustomerMachineState,
) => state.hasTag('loading');

export const selectCustomerCreationDone = (
	state: CreateJulaClubCustomerMachineState,
) => state.hasTag('doneCreatingMember');

export const selectCustomerNotCreatedState = (
	state: CreateJulaClubCustomerMachineState,
) => {
	if (state.context?.errors?.businessLogicErrors) {
		const errorKeys = state.context.errors.businessLogicErrors.map(
			(obj) => obj.key,
		);
		return customerCreationErrorKeys.some((alreadyMemberError) =>
			errorKeys.includes(alreadyMemberError),
		);
	}
	return state.matches('customerNotCreated');
};

export const selectAlreadyMember = (
	state: CreateJulaClubCustomerMachineState,
) =>
	Boolean(
		state.context?.errors?.businessLogicErrors?.some((error) =>
			alreadyMemberErrorKeys.includes(error.key),
		),
	);

export const selectFirstName = (state: CreateJulaClubCustomerMachineState) =>
	state.context.customerFirstName;

export const selectAddCustomerButtonState = (
	state: CreateJulaClubCustomerMachineState,
) => state.context?.addCustomerButtonState;

export const selectDisplayBankIdSigningModal = (
	state: CreateJulaClubCustomerMachineState,
) => state.context.shouldDisplaySignIframeModal;

export const selectBankIdSignUrl = (
	state: CreateJulaClubCustomerMachineState,
) => state.context.bankIdSignUrl;

export const selectErrorOpeningSignWindow = (
	state: CreateJulaClubCustomerMachineState,
) => state.context.errorOpeningSignWindow;

export const selectCreditApplicationData = (
	state: CreateJulaClubCustomerMachineState,
) => state.context.resolvedTicket?.data;

export const selectCurrentSection = (
	state: CreateJulaClubCustomerMachineState,
) => state.context.currentSection;
