import React from 'react';

import BlockMedia from 'components/BlockMedia/';
import ComponentPlaceholder from 'components/ComponentPlaceholder';
import { useSitecoreContext } from 'hooks';
import { JulaComponentProps } from 'lib/component-props';
import { DigizuiteAssetList } from 'models/asset';
import { getEditorMargin } from 'utils/business-logic';
import { getAsset } from 'utils/helpers';

type MediaLandscapeProps = JulaComponentProps & {
	fields: {
		landscapeImage: DigizuiteAssetList;
	};
};

function MediaInspirationLandscape({ fields, params }: MediaLandscapeProps) {
	const { sitecoreContext } = useSitecoreContext();
	const isEditing = sitecoreContext?.pageEditing;

	if (!fields) {
		return null;
	}
	const { landscapeImage } = fields;

	const landscapeImg = getAsset('Templated 2:1', landscapeImage);

	if (isEditing) {
		return (
			<ComponentPlaceholder
				componentName="MediaInspirationLandscape"
				className={getEditorMargin(params)}
				isDeprecated
			/>
		);
	}
	if (!landscapeImg) {
		return (
			<ComponentPlaceholder
				componentName="MediaInspirationLandscape"
				className={getEditorMargin(params)}
			/>
		);
	}

	return (
		<div id={params?.anchor} className={getEditorMargin(params)}>
			<BlockMedia
				variant="landscape"
				imgSrc={landscapeImg?.src}
				imgAlt={landscapeImg?.alt}
			/>
		</div>
	);
}

MediaInspirationLandscape.displayName = 'MediaInspirationLandscape';

export default MediaInspirationLandscape;
