/**
 * MixAndMatchFixedPrice
 */

import React from 'react';

import ActionButton, { ActionButtonState } from 'components/ActionButton';
import Icon from 'components/Icon';
import Img from 'components/Img';
import Link from 'components/Link';
import Price from 'components/Price';
import Text from 'components/Text';
import { CampaignResponse, CampaignVariantResponse } from 'models/product';
import { getProductImageFormat } from 'utils/business-logic';
import cn from 'utils/cn';
import { formatDate } from 'utils/format';
import { useI18n } from 'utils/i18n';

interface ProductRowProps extends CampaignVariantResponse {}

function ProductRow({
	title,
	quantity,
	url,
	listImageFormats,
}: ProductRowProps) {
	const imgSrc = getProductImageFormat(
		'Templated square transparent',
		listImageFormats,
	)?.location;
	const { t } = useI18n();

	return (
		<div className="flex items-center">
			<Text
				as="p"
				text={t('product_details_in_stock_label', { amount: quantity })}
				className="mr-4 flex-shrink-0 font-bold"
			/>
			<Link
				href={url}
				className="flex items-center underline hover:no-underline"
			>
				<Img
					src={imgSrc}
					width={40}
					height={40}
					service="nextjs"
					useFallbackOnError
					className="mr-2 h-10 w-10 shrink-0"
				/>
				<Text as="p" text={title} />
			</Link>
		</div>
	);
}

ProductRow.displayName = 'MixAndMatchFixedPrice_ProductRow';

interface Props
	extends Omit<
		CampaignResponse,
		'discountType' | 'salesAttributes' | 'id' | 'subtitle'
	> {
	onAddToCartClick: (() => void) | undefined;
	addToCartButtonDisabled: boolean;
	addToCartButtonDisabledText: string;
	buttonState: ActionButtonState;
	className?: string;
}

/** Product page mix and match offer */
export default function MixAndMatchFixedPrice({
	title,
	price,
	variants,
	validTo,
	onAddToCartClick,
	buttonState,
	addToCartButtonDisabled,
	addToCartButtonDisabledText,
	className,
}: Props) {
	const { t } = useI18n();
	return (
		<div className={cn('rounded-border border-1 border-grey p-6', className)}>
			<Text as="h3" text={title} className="mb-4" />
			<div className="mb-6 flex flex-col justify-between gap-y-4 md:flex-row">
				<div className="flex flex-col">
					{variants.map((variant) => (
						<ProductRow key={variant.id} {...variant} />
					))}
				</div>
				<Price
					price={price.displayValue}
					currency={price.displaySymbol}
					priceLabel={t('mix_and_match_fixed_price_label')}
					theme="campaign"
					size="small"
					priceLabelAlign="left"
					className="ml-4"
				/>
			</div>
			{validTo && (
				<Text
					as="p"
					className="mb-4 text-julaRed"
					text={t('product_details_campaign_valid_to_text', {
						validTo: formatDate(validTo),
					})}
				/>
			)}
			{onAddToCartClick && (
				<>
					<ActionButton
						data-cid="addToCart"
						displayWidth="full"
						size="large"
						variant="cta"
						customState={buttonState}
						onClick={onAddToCartClick}
						disabled={addToCartButtonDisabled}
					>
						<Icon
							icon={addToCartButtonDisabled ? 'cartDisabled' : 'cart'}
							className="mr-2"
						/>
						{t('product_details_buy_online_button')}
					</ActionButton>
					{addToCartButtonDisabled && addToCartButtonDisabledText && (
						<div className="mt-4 flex items-start">
							<Icon
								icon="closeOutline"
								size={16}
								color="julaRed"
								className="mr-2 mt-0.5"
							/>
							<Text as="p" text={addToCartButtonDisabledText} />
						</div>
					)}
				</>
			)}
		</div>
	);
}
MixAndMatchFixedPrice.displayName = 'MixAndMatchFixedPrice';
