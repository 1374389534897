import React from 'react';

import Button from 'components/Button';
import { Skeleton, SkeletonItem } from 'components/Skeleton';
import { StockStatus } from 'components/StockBalance/';
import { useSelectedStore } from 'contexts';
import {
	getProductCardStoreStockStatus,
	getProductCardWebStockStatus,
	ProductCardStoreStockStatusType,
	ProductCardWebStockStatusType,
} from 'utils/business-logic/stock';
import cn from 'utils/cn';
import { sendGlobalEvent } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

export interface StoreStock {
	inStock: boolean;
	isSellableInStore: boolean;
	isSellableOnWeb: boolean;
	name: string | undefined;
	productInStockAtStores: number;
}

export interface WebStock {
	isSellable: boolean;
	isSellableInStore: boolean;
	isSellableOnWeb: boolean;
	productReStockWeb: boolean;
}

export interface StockInfo {
	storeStock: StoreStock | undefined;
	webStock: WebStock;
}

interface Props {
	className?: string;
	productId: string;
	stockInfo: StockInfo;
	storeStockVisible: boolean;
}

export default function ProductCardStockInfo({
	className,
	productId,
	storeStockVisible,
	stockInfo: { storeStock, webStock },
}: Props) {
	const { t } = useI18n();
	const { selectedStore } = useSelectedStore();
	const hasSelectedStore = Boolean(selectedStore);

	const getOnlineStockTranslation = (status: ProductCardWebStockStatusType) => {
		switch (status) {
			case 'InStock':
				return t('stock_online_in_stock_general');
			case 'OutOfStock':
				return t('stock_online_out_of_stock_general');
			case 'ReStock-NotWatchable':
				return t('stock_online_re_stock');
			case 'InStoreOnly':
				return t('stock_online_store_only');
			default:
				return '';
		}
	};

	const getStoreStockTranslation = ({
		status,
		stock,
	}: {
		status: ProductCardStoreStockStatusType;
		stock: StoreStock;
	}) => {
		switch (status) {
			case 'Store-InStock':
				return t('stock_store_in_stock_general', {
					store: stock.name,
				});
			case 'Store-OutOfStock':
				return t('stock_store_out_of_stock_general', {
					store: stock.name,
				});
			case 'NoStore-InStock':
			case 'NoStore-OutOfStock':
				return t('stock_stores_amount_exact', {
					amount: stock.productInStockAtStores,
				});
			default:
				return '';
		}
	};

	function renderStoreStockStatus(stock: StoreStock) {
		const status = getProductCardStoreStockStatus({
			storeSelected: Boolean(stock?.name),
			...stock,
		});
		const inStock = status === 'Store-InStock' || status === 'NoStore-InStock';

		if (!stock) {
			return (
				<Skeleton>
					<SkeletonItem height="0.9rem" width="5rem" />
				</Skeleton>
			);
		}

		const rawText = getStoreStockTranslation({ status, stock });
		const text =
			!hasSelectedStore && !rawText.endsWith('.') ? `${rawText}.` : rawText;

		return (
			<StockStatus
				textSize="small"
				inStock={inStock}
				text={
					<>
						{hasSelectedStore && text}
						{!hasSelectedStore && (
							<Button
								variant="text-inline"
								size="small"
								underline={false}
								className="group/stock-btn min-h-4 py-0"
								hasExpandedHitArea
								onClick={() => {
									sendGlobalEvent('open-stock-store-selector', { productId });
								}}
							>
								{text}&nbsp;
								<span className="inline-block underline group-hover/stock-btn:no-underline">
									{t('popover_choose_warehouse_header')}
								</span>
							</Button>
						)}
					</>
				}
			/>
		);
	}

	return (
		<div
			className={cn(
				'flex flex-col gap-1',
				!hasSelectedStore && 'relative z-3',
				className,
			)}
		>
			<StockStatus
				textSize="small"
				inStock={getProductCardWebStockStatus(webStock) === 'InStock'}
				text={getOnlineStockTranslation(getProductCardWebStockStatus(webStock))}
			/>
			{storeStockVisible && storeStock && renderStoreStockStatus(storeStock)}
		</div>
	);
}
ProductCardStockInfo.displayName = 'ProductCard_ProductCardStockInfo';
