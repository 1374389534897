/**
 * BlockShortcutImages
 */

import React, { type ReactNode } from 'react';
import clsx from 'clsx';

import type { ItemSize } from './types';

interface Props {
	children: ReactNode;
	className?: string;
	size?: ItemSize;
}

/** Grid for the items. */
export default function ImagesGrid({
	children,
	className,
	size = 'regular',
}: Props) {
	return (
		<div
			className={clsx(
				'grid items-start gap-4 md:gap-6',
				size === 'regular' && 'grid-cols-2 sm:grid-cols-3 md:grid-cols-6',
				size === 'large' && 'grid-cols-1 sm:grid-cols-2 md:grid-cols-4',
				className,
			)}
		>
			{children}
		</div>
	);
}
ImagesGrid.displayName = 'BlockShortcutImages_ImagesGrid';
