import React from 'react';

import MixAndMatchFixedPrice from 'components/MixAndMatchFixedPrice';
import Slider from 'components/Slider';
import Text from 'components/Text';
import { CampaignResponse } from 'models/product';
import { ButtonStateWithId } from 'state-machines/cart';
import { is } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

interface Props {
	campaigns: CampaignResponse[] | undefined;
	onMixAndMatchBuyAllFixedPriceAddToCartClick:
		| ((campaign: CampaignResponse) => void)
		| undefined;
	purchaseButtonState: ButtonStateWithId;
}

export default function ProductDetailsCampaign({
	campaigns,
	onMixAndMatchBuyAllFixedPriceAddToCartClick,
	purchaseButtonState,
}: Props) {
	const { t } = useI18n();

	const renderItem = (campaign: CampaignResponse, className?: string) => {
		const buttonId = `addCampaignToCart-${campaign.title}-${campaign.validTo}`;
		const buttonState =
			purchaseButtonState.buttonId === buttonId
				? purchaseButtonState.state
				: 'idle';

		const onlyAvailableInStore =
			!campaign.salesAttributes.isSellableOnWeb &&
			campaign.salesAttributes.isSellableInStore;
		const maybeAvailableInStore =
			!campaign.salesAttributes.isSellable &&
			campaign.salesAttributes.isSellableOnWeb;

		return (
			<MixAndMatchFixedPrice
				key={campaign.id}
				className={className}
				title={campaign.title}
				validTo={campaign.validTo}
				price={campaign.price}
				variants={campaign.variants}
				addToCartButtonDisabled={onlyAvailableInStore || maybeAvailableInStore}
				addToCartButtonDisabledText={
					maybeAvailableInStore
						? t('product_details_campaign_maybe_available_in_store_text')
						: t('product_details_campaign_only_available_in_store_text')
				}
				buttonState={buttonState}
				onAddToCartClick={() =>
					onMixAndMatchBuyAllFixedPriceAddToCartClick?.(campaign)
				}
			/>
		);
	};

	if (!is.arrayWithLength(campaigns)) {
		return null;
	}

	const hasMultipleCampaigns = campaigns.length > 1;

	return (
		<div className="mb-14">
			<Text
				as="h2"
				text={t('product_details_campaign_section_heading')}
				className="mb-6"
			/>
			{hasMultipleCampaigns && (
				<Slider
					sizeClasses="w-5/6 sm:w-2/3 md:w-5/6 lg:w-1/2"
					items={campaigns.map((campaign) => renderItem(campaign, 'h-full'))}
				/>
			)}
			{!hasMultipleCampaigns &&
				campaigns[0] &&
				renderItem(campaigns[0], 'w-full lg:w-8/12')}
		</div>
	);
}
ProductDetailsCampaign.displayName = 'ProductDetailsCampaign';
