/**
 * SelectBox
 */

import React, { type ReactNode } from 'react';
import clsx from 'clsx';

import { Radio } from 'components/FormUi';
import Img from 'components/Img';
import Text from 'components/Text';
import { RowSum } from 'models/api';
import cn from 'utils/cn';
import { is } from 'utils/helpers';
import { formatPriceText } from 'utils/price';

interface Props {
	children?: ReactNode;

	/** Heading text presented in bold */
	heading: string;

	/** Id of the component */
	id: string | number;

	/** Name of the radio button */
	name: string;

	/** Value of the radio button */
	value: string;

	/** Text presented below the input field */
	description?: ReactNode;

	/** Additional text below the description, only visible when selected */
	selectedDescription?: ReactNode;

	/** Price of the list item */
	price?: RowSum;

	/** Changes the styling when selected is true */
	isSelected?: boolean;

	/** Image URLs, probably logos */
	primaryImages?: string[];

	/** Image URLs, probably logos */
	secondaryImages?: string[];

	/** Type of max width sizing to use for the images */
	imagesWidthType?: 'default' | 'wide';

	/** CSS classNames */
	className?: string;

	/** If SelectBox is disabled */
	disabled?: boolean;

	/** If image should be rendered beneath text instead of to the right */
	imagesBelowTextOnSmallScreen?: boolean;

	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

/** The select box component. */
export default function SelectBox({
	heading,
	id,
	name,
	value,
	isSelected,
	primaryImages,
	secondaryImages,
	imagesWidthType = 'default',
	price,
	className,
	children,
	description,
	selectedDescription,
	disabled,
	imagesBelowTextOnSmallScreen = false,
	onChange,
}: Props) {
	const imageClassNames = clsx(
		'max-h-6 min-h-4 md:max-h-8 md:min-h-5',
		imagesWidthType === 'default' && 'max-w-[60px] md:max-w-[90px]',
		imagesWidthType === 'wide' && 'max-w-[80px] md:max-w-[110px]',
	);
	const primaryImageElements = is.arrayWithLength(primaryImages) ? (
		<>
			{primaryImages.map((src) => (
				<Img key={src} src={src} className={imageClassNames} />
			))}
		</>
	) : null;
	const secondaryImageElements = is.arrayWithLength(secondaryImages) ? (
		<>
			{secondaryImages.map((src) => (
				<Img key={src} src={src} className={imageClassNames} />
			))}
		</>
	) : null;

	return (
		<div
			className={cn(
				'relative space-y-1 p-4 md:p-6',
				disabled && 'opacity-50',
				className,
			)}
		>
			<div className="flex items-start justify-between">
				<Radio
					label={heading}
					id={`selectbox-${id}`}
					name={name}
					disabled={disabled}
					value={value}
					defaultChecked={isSelected && !disabled}
					onChange={onChange}
					color="green"
					labelClassName={clsx(
						'font-bold',
						// Stretch the label over the entire box with a pseudo element
						// and set the border on that.
						'before:absolute before:inset-0 before:rounded-border before:border',
						!isSelected && 'before:border-grey',
						// Add a shadow for the thicker border instead of increasing the
						// border width. This prevents layout shifting when selecting.
						isSelected &&
							'before:border-cta before:ring-1 before:ring-inset before:ring-cta',
						!isSelected && !disabled && 'hover:before:border-greyDarker',
					)}
				/>
				{primaryImageElements && (
					<div
						className={clsx(
							'flex grow flex-wrap items-center justify-end gap-3 pl-2',
							imagesBelowTextOnSmallScreen && 'max-sm:hidden',
							price && 'mr-10',
						)}
					>
						{primaryImageElements}
					</div>
				)}
				{price && (
					<Text
						as="p"
						className="min-w-10 whitespace-nowrap text-right font-bold"
					>
						{formatPriceText(price.displayValue, price.displaySymbol)}
					</Text>
				)}
			</div>
			<div className="ml-8 flex max-w-[28rem] flex-col gap-3 md:ml-7">
				{description && <div className="text-sm">{description}</div>}
				{isSelected && selectedDescription && (
					<div className="text-sm">{selectedDescription}</div>
				)}
				{primaryImageElements && imagesBelowTextOnSmallScreen && (
					<div className="flex items-center gap-2 sm:hidden">
						{primaryImageElements}
					</div>
				)}
				{secondaryImageElements && (
					<div className="flex items-center gap-2 sm:gap-3">
						{secondaryImageElements}
					</div>
				)}
				{isSelected && !disabled && children}
			</div>
		</div>
	);
}
SelectBox.displayName = 'SelectBox';
