import React, { useEffect, useState } from 'react';
import { Field } from '@sitecore-jss/sitecore-jss-nextjs';
import { useSelector } from '@xstate/react';
import clsx from 'clsx';

import ActionButton, { type ActionButtonState } from 'components/ActionButton';
import Button from 'components/Button';
import GdprAccordion from 'components/GdprAccordion';
import GenericForm from 'components/GenericForm';
import {
	ErrorMessageInfoBox,
	useGlobalPopoverContext,
} from 'components/GlobalPopover';
import Img from 'components/Img';
import InfoBox from 'components/InfoBox';
import { Skeleton, SkeletonItem } from 'components/Skeleton';
import Text from 'components/Text';
import { useGlobalStateContext } from 'contexts';
import { useContactInformation, useCustomerInformation } from 'hooks';
import type { JulaComponentProps } from 'lib/component-props';
import type { FileField, FormFields, SelectField } from 'models/form';
import {
	selectCustomerContactId,
	selectCustomerType,
	selectIsAuthenticatedUser,
} from 'state-machines/authentication';
import { selectIsClosing } from 'state-machines/global-popover.machine';
import fetchData, { API_URL } from 'utils/fetchData';
import { is } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

type ContactFormProps = JulaComponentProps & {
	fields: {
		description: Field<string>;
		form: FormFields;
		heading: Field<string>;
		name: Field<string>;
		submitText: Field<string>;
	};
};

// TODO: <QuestionsAndAnswersForm> and <ContactForm> have very similar structure
// and error/submit handling so we could look in to something combined for either
// the form or the error/submit handling, relates to TODO in <GenericForm>

function ContactForm(props: ContactFormProps) {
	const popoverContext = useGlobalPopoverContext();
	const { globalPopoverService, userService } = useGlobalStateContext();
	const customerType = useSelector(userService, selectCustomerType);
	const customerContactId = useSelector(userService, selectCustomerContactId);
	const isAuthenticatedUser = useSelector(
		userService,
		selectIsAuthenticatedUser,
	);

	const isClosing = useSelector(globalPopoverService, selectIsClosing);

	const {
		customerInformation,
		isLoading: isLoadingCustomerInformation,
		error: errorCustomerInformation,
	} = useCustomerInformation(isAuthenticatedUser);
	const { fullName, cellPhoneNumberFormatted, emailAddress } =
		customerInformation || {};

	const isPro = customerType === 'Pro';
	const {
		contactInformation,
		isLoading: isLoadingContactInformation,
		error: errorContactInformation,
	} = useContactInformation(customerContactId || '', isPro);

	let phone = cellPhoneNumberFormatted?.default;
	let email = emailAddress;

	if (isPro && contactInformation) {
		phone = contactInformation.phoneNumber?.default;
		email = contactInformation.email;
	}

	const customerInformationLoadedOk =
		!isLoadingCustomerInformation && !errorCustomerInformation;
	const contactInformationLoadedOk =
		!isLoadingContactInformation && !errorContactInformation;

	const missingEmail =
		(!isPro && !emailAddress && customerInformationLoadedOk) ||
		(isPro && !email && contactInformationLoadedOk);

	const isLoading =
		(isAuthenticatedUser && !isPro && isLoadingCustomerInformation) ||
		(isAuthenticatedUser && isPro && isLoadingContactInformation);

	const { heading, description, form, submitText } = props.fields;
	const [submitButtonState, setSubmitButtonState] =
		useState<ActionButtonState>('idle');

	const [isSuccess, setIsSuccess] = useState(false);
	const { t } = useI18n();

	const onSubmit = async (genericFormData) => {
		popoverContext?.setTopMessage(undefined);
		const formData = { ...genericFormData };

		setSubmitButtonState('loading');

		const selectFields = form.filter(
			(field) => field.type === 'SelectField',
		) as SelectField[];
		const fileField = form.find(
			(field): field is FileField => field.type === 'FileField',
		);

		selectFields.forEach((selectField) => {
			if (selectField.sendLabel) {
				const categoryId = formData[selectField.name];
				const selectedOption = selectField.options.find(
					(option) => option.value === categoryId,
				);
				formData[selectField.labelName] = selectedOption?.label;
			}
		});

		const attachments = (
			formData[`${fileField?.name}-data`] as {
				fileName: string;
				id: string;
			}[]
		)?.map((attachmentData) => attachmentData.id);

		if (attachments?.length > 0) {
			delete formData[`${fileField?.name}-data`];
			formData.attachments = attachments;
		}

		const response = await fetchData(`${API_URL}CustomerService/postForm`, {
			method: 'POST',
			body: JSON.stringify(formData),
			headers: {
				'Content-Type': 'application/json',
			},
			credentials: 'include',
		}).catch((error) => error);

		if (response?.fieldValidationErrors || response?.businessLogicErrors) {
			setSubmitButtonState('failure');
			popoverContext?.setTopMessage(
				<ErrorMessageInfoBox errors={response.businessLogicErrors} />,
			);
			if (is.objectWithKeys(response.fieldValidationErrors)) {
				return response.fieldValidationErrors;
			}
			return { FORM_ERROR: 'businessLogicErrors' };
		}
		setSubmitButtonState('success');
		setIsSuccess(true);
	};

	useEffect(() => {
		setIsSuccess(false);
		popoverContext?.setTopMessage(undefined);
	}, [isClosing]);

	if (!props.fields) return null;

	return (
		<>
			<div className={clsx('pb-8', isSuccess && 'hidden')}>
				<Text className="mb-2" as="h2" field={heading} />
				<Text className="mb-2" as="p" field={description} />
				<GdprAccordion />
				{isLoading && (
					<Skeleton>
						<SkeletonItem height="5rem" className="mt-4" />
						<SkeletonItem height="10rem" className="mt-4" />
						<SkeletonItem height="20rem" className="mt-4" />
					</Skeleton>
				)}
				{!isLoading && (
					<>
						{isAuthenticatedUser && (
							<>
								<Text className="mb-2" as="h3">
									{t('contact_form_details_heading')}
								</Text>
								<Text className="mb-2" as="p">{`${t(
									'contact_form_details_name_label',
								)}: ${fullName}`}</Text>
								<Text className="mb-2" as="p">{`${t(
									'contact_form_details_phone_label',
								)}: ${phone}`}</Text>
								<Text className="mb-2" as="p">{`${t(
									'contact_form_details_email_label',
								)}: ${email}`}</Text>
							</>
						)}
						{isAuthenticatedUser && email && (
							<InfoBox
								icon="info"
								variant="information"
								heading={t('contact_form_check_details_heading')}
								message={t('contact_form_check_details_text')}
							/>
						)}
						{isAuthenticatedUser && missingEmail && (
							<InfoBox
								icon="error"
								variant="error"
								heading={t('contact_details_missing_email_heading')}
								message={t('contact_details_missing_email_text')}
							/>
						)}
						<GenericForm
							inputSpace="mt-4"
							fields={form}
							className="mt-4"
							fileUploadUrl={`${API_URL}CustomerService/attachments`}
							onSubmitCallback={onSubmit}
							resetForm
							button={
								<ActionButton
									className="mt-6"
									displayWidth="full"
									size="large"
									type="submit"
									variant="cta"
									customState={submitButtonState}
									minimunLoadingTime={0}
								>
									{submitText.value}
								</ActionButton>
							}
						/>
					</>
				)}
			</div>
			<div
				className={clsx(
					'flex h-full flex-col content-between',
					!isSuccess && 'hidden',
				)}
			>
				<div>
					<Img
						src="/assets/images/graphic-signin-mail.svg"
						className="mx-auto mt-8 block h-20 w-20 object-cover"
					/>
					<Text as="h2" className="mt-8">
						{t('contact_form_thank_you_heading')}
					</Text>

					<Text as="p" className="mt-6">
						{t('contact_form_feedback_interval_text')}
					</Text>

					<div className="mt-8">
						<Button
							variant="primary"
							displayWidth="full"
							className="my-4"
							onClick={() => globalPopoverService.send('CLOSE')}
						>
							{t('contact_form_ok_button')}
						</Button>
					</div>
				</div>
			</div>
		</>
	);
}
ContactForm.displayName = 'ContactForm';

export default ContactForm;
