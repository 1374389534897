import React from 'react';
import type { Field } from '@sitecore-jss/sitecore-jss-nextjs';
import clsx from 'clsx';

import BlockMedia from 'components/BlockMedia';
import ComponentPlaceholder from 'components/ComponentPlaceholder';
import { LayoutContainer } from 'components/Layout';
import type { ColorField, JulaComponentProps } from 'lib/component-props';
import type { DigizuiteAssetList } from 'models/asset';
import type { ProductCard } from 'models/productCard';
import { getEditorMargin } from 'utils/business-logic';
import { getAsset } from 'utils/helpers';

type Props = JulaComponentProps & {
	fields?: {
		backgroundColor?: ColorField;
		isWide?: Field<boolean>;
		landscapeImage?: DigizuiteAssetList;
		preventAutoplay?: Field<boolean>;
		products?: ProductCard[];
		video?: DigizuiteAssetList;
	};
};

function MediaSingle({ params, fields }: Props) {
	if (!fields) {
		return null;
	}

	const { isWide, products, preventAutoplay } = fields;
	const landscapeImg = getAsset('Templated 2:1', fields.landscapeImage);
	const video = getAsset('Source Copy', fields.video);

	if (!landscapeImg && !video) {
		return (
			<ComponentPlaceholder
				componentName="MediaSingle"
				className={getEditorMargin(params)}
			/>
		);
	}

	return (
		<LayoutContainer
			withGrid
			backgroundColor={fields.backgroundColor?.fields?.colorHex?.value}
			outerClassName={getEditorMargin(params)}
			id={params?.anchor}
			className="mx-auto"
		>
			<BlockMedia
				className={clsx(
					!isWide?.value &&
						'relative col-span-full md:col-span-6 md:col-start-4',
					isWide?.value && 'col-span-full',
				)}
				variant="landscapeWide"
				imgSrc={landscapeImg?.src}
				imgAlt={landscapeImg?.alt}
				imgHeight={landscapeImg?.height}
				imgWidth={landscapeImg?.width}
				video={video}
				products={products}
				preventAutoplay={preventAutoplay?.value}
			/>
		</LayoutContainer>
	);
}
MediaSingle.displayName = 'MediaSingle';

export default MediaSingle;
