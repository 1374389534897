import React from 'react';
import { Field } from '@sitecore-jss/sitecore-jss-nextjs';

import InfoBox from 'components/InfoBox';
import Price from 'components/Price';
import Text from 'components/Text';
import { JulaComponentProps } from 'lib/component-props';
import { PriceSize } from 'models/price';

export type StaffPriceProps = JulaComponentProps & {
	fields: {
		icon: Field<string>;
		heading: Field<string>;
		staffMessage: Field<string>;
		price: Field<string | number>;
		currency: Field<string>;
		priceSize: Field<string>;
	};
};

function StaffPrice({ fields, params }: StaffPriceProps) {
	if (!fields) {
		return null;
	}

	const { heading: headingLevel = 'h2' } = params || {};

	return (
		<InfoBox icon={fields?.icon.value as string}>
			<Text as={headingLevel} styleAs="p" className="mt-0.5 font-bold">
				{fields?.heading.value as string}
			</Text>
			<Text as="p" className="mt-2">
				{fields?.staffMessage.value as string}
			</Text>
			<Price
				className="mt-4"
				price={fields?.price.value as string}
				currency={fields?.currency.value as string}
				size={fields?.priceSize.value as PriceSize}
			/>
		</InfoBox>
	);
}
StaffPrice.displayName = 'StaffPrice';

export default StaffPrice;
