/**
 * MicroContent - Media Link
 */

import React, { type AnchorHTMLAttributes } from 'react';
import clsx from 'clsx';

import Icon from 'components/Icon';
import Img from 'components/Img';
import Link from 'components/Link';
import Text from 'components/Text';

interface Props extends AnchorHTMLAttributes<HTMLAnchorElement> {
	className?: string;
	displayType?: 'inline' | 'block';
	href: string;
	imgSrc: string;
	text: string;
}

/** Small image and text with arrow icon */
export default function MicroContentMediaLink({
	className,
	displayType = 'inline',
	href,
	imgSrc,
	text,
	...attrs
}: Props) {
	return (
		<Link
			{...attrs}
			href={href}
			className={clsx(
				'group/media-link overflow-hidden rounded-border bg-julaRed text-white hover:bg-julaRedDarken',
				displayType === 'inline' && 'inline-flex align-middle',
				displayType === 'block' && 'flex',
				className,
			)}
		>
			{/* Skip alt text on purpose since the link has a proper text below. */}
			<Img
				src={imgSrc}
				service="nextjs"
				width={72}
				height={72}
				className="w-18 object-cover"
			/>
			<Text
				as="span"
				styleAs="pSmall"
				className="flex grow items-center px-4 py-2"
			>
				<span className="grow group-hover/media-link:underline">{text}</span>
				<Icon
					icon="arrow"
					color="black"
					backgroundColor="white"
					className="ml-4"
					hasBackgroundPadding
				/>
			</Text>
		</Link>
	);
}
MicroContentMediaLink.displayName = 'MicroContentMediaLink';
