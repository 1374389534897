/**
 * ProductAnimation
 */

import React, { useEffect, useState } from 'react';
import { useMachine } from '@xstate/react';
import clsx from 'clsx';

import Img from 'components/Img';
import Link from 'components/Link';
import Price from 'components/Price';
import Text from 'components/Text';
import {
	useIsLargeOrLarger,
	useIsMedium,
	useIsMediumOrSmaller,
	useIsSmall,
	useIsXSmall,
} from 'hooks';
import { ProductCard } from 'models/productCard';
import { transitionMachine } from 'state-machines/transition.machine';
import {
	checkSupercheap,
	getPriceProps,
	getProductCardImage,
} from 'utils/business-logic';

interface Props {
	/** The array with the product with image and price for the animation array */
	animatedProductArray?: ProductCard[];

	className?: string;

	/** Image size on xs screens * */
	defaultSizeClass?: string;

	/** Image size on full sized screens, prefix with full: * */
	fullSizeClass?: string;

	/** The single image alt text */
	imageAlt?: string;

	/** The single image src */
	imageSrc?: string;

	isBlock: boolean;

	/** Promo campaign flag * */
	isPCampaign: boolean;

	/** Image size on large screens, prefix with lg: * */
	largeSizeClass?: string;

	/** Image size on medium screens, prefix with md: * */
	mediumSizeClass?: string;

	shouldPlay?: boolean;

	/** Image size on small screens, prefix with sm: * */
	smallSizeClass?: string;
}

// TODO: also remove mediaQuery hooks that might only be used here when removing this
/** The product animation component. */
function ProductAnimation({
	animatedProductArray,
	isBlock,
	isPCampaign,
	defaultSizeClass = 'h-40 w-40',
	smallSizeClass = 'sm:h-80 sm:w-80',
	mediumSizeClass = 'md:h-56 md:w-56',
	largeSizeClass = 'lg:h-64 lg:w-64',
	fullSizeClass = 'full:h-96 full:w-96',
	shouldPlay = true,
}: Props) {
	const items = animatedProductArray || [];
	const [activeIndex, setActiveIndex] = useState(0);
	const isSmall = useIsSmall();
	const isXsmall = useIsXSmall();
	const isMedium = useIsMedium();
	const isMediumOrSmaller = useIsMediumOrSmaller();
	const isLargeOrLarger = useIsLargeOrLarger();
	const [state, send] = useMachine(transitionMachine);
	useEffect(() => {
		if (shouldPlay) {
			if (state.value === 'exited') {
				setActiveIndex((activeIndex) => (activeIndex + 1) % items.length);
				send('ENTER');
			}

			if (state.value === 'entered') {
				const interval = setInterval(() => {
					send('EXIT');
				}, 2500);
				return () => {
					clearInterval(interval);
				};
			}
		}
	}, [state.value, isMediumOrSmaller, shouldPlay, send, items.length]);

	return (
		<div className="relative">
			{items.map((item, i) => (
				<div className="relative" key={i}>
					<div
						className={clsx(
							!isPCampaign &&
								activeIndex === i && [
									'absolute md:right-1/2 md:mt-0 md:pr-0 lg:mt-8 full:right-32 full:mt-2',
									defaultSizeClass,
									smallSizeClass,
									mediumSizeClass,
									largeSizeClass,
									fullSizeClass,
								],
							activeIndex !== i && 'hidden',
						)}
					>
						<Link
							href={item.url}
							className={clsx(
								'absolute',
								state.value === 'exiting' && 'animate-moveOutLeftShrink',
								state.value === 'entering' && 'animate-moveInRightNoShrink',
							)}
						>
							<Img
								src={
									getProductCardImage(item) ||
									'/assets/images/placeholder-image.png'
								}
								width={393}
								height={393}
								service="nextjs"
								jpgOptimized={false}
								alt={item.title || ''}
							/>
							{isPCampaign && (
								<Text
									className="mt-2 text-white md:mt-4 lg:mt-6"
									as={isLargeOrLarger ? 'p' : 'pSmall'}
								>
									{item.title}
								</Text>
							)}
						</Link>
						<div
							className={clsx(
								isPCampaign
									? 'absolute -right-4 -top-3'
									: 'absolute -right-12 -top-3',
								state.value === 'exiting' && 'animate-moveOutTop',
								state.value === 'entering' && 'animate-moveInAndFadeTop',
							)}
						>
							{item.listPrice && (
								<>
									{isPCampaign ? (
										<Price
											{...getPriceProps(
												item.listPrice,
												false,
												checkSupercheap(item),
											)}
											size={isXsmall || isSmall || isMedium ? 'small' : 'large'}
										/>
									) : isBlock ? (
										<Price
											{...getPriceProps(
												item.listPrice,
												false,
												checkSupercheap(item),
											)}
											size={isLargeOrLarger || isSmall ? 'large' : 'small'}
										/>
									) : (
										<Price
											{...getPriceProps(
												item.listPrice,
												false,
												checkSupercheap(item),
											)}
											size={isXsmall ? 'small' : isSmall ? 'large' : 'small'}
										/>
									)}
								</>
							)}
						</div>
					</div>
				</div>
			))}
		</div>
	);
}

export default ProductAnimation;
ProductAnimation.displayName = 'ProductAnimation';
