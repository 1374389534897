/**
 * Skeleton
 */

import React from 'react';
import clsx from 'clsx';

import { useI18n } from 'utils/i18n';

interface Props {
	/** React children */
	children?: React.ReactNode;

	/** Optional classname */
	className?: string;
}

/** A loading skeleton. */
export function Skeleton({ children, className }: Props) {
	const { t } = useI18n();

	return (
		<div
			role="progressbar"
			aria-busy="true"
			aria-label={t('general_loading_text')}
			className={className}
		>
			{children}
		</div>
	);
}
Skeleton.displayName = 'Skeleton';

interface ItemProps extends Props {
	/** React children */
	children?: React.ReactNode;

	/** Height of skeleton item */
	height?: string;

	/** Width of skeleton item */
	width?: string;
}

export function SkeletonItem({
	children,
	height,
	width,
	className,
}: ItemProps) {
	const inlineStyles: any = {};

	if (height) {
		inlineStyles.height = height;
	}
	if (width) {
		inlineStyles.width = width;
	}

	return (
		<div
			className={clsx(
				'block h-full animate-shimmer rounded-border leading-none',
				'bg-greyLight bg-[size:200px_100%] bg-no-repeat text-transparent',
				'bg-gradient-to-r from-greyLight via-greyLighter to-greyLight',
				className,
			)}
			style={inlineStyles}
		>
			{children}
		</div>
	);
}
SkeletonItem.displayName = 'SkeletonItem';
