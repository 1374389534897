import { FormApi } from 'final-form';

import { ApiJulaModelsCartCartDeliveryAddress } from 'models/api';

export function updateAddress({
	formName,
	cartLatestAddress,
	batch,
	change,
}: {
	formName: string;
	cartLatestAddress: ApiJulaModelsCartCartDeliveryAddress | undefined;
	batch: FormApi['batch'];
	change: FormApi['change'];
}) {
	const inputsToUpdate = ['city', 'postalCode'];
	batch(() => {
		inputsToUpdate.forEach((inputName) => {
			change(`${formName}.${inputName}`, cartLatestAddress?.[inputName]);
		});
	});
}
