/**
 * Wishlist
 */

import React from 'react';
import { useSelector } from '@xstate/react';

import Button from 'components/Button';
import CartListItem from 'components/CartListItem';
import Icon from 'components/Icon';
import { LayoutContainer } from 'components/Layout';
import LoadingSpinner from 'components/LoadingSpinner';
import PriceRow from 'components/PriceRow';
import Text from 'components/Text';
import TotalWithVat from 'components/TotalWithVat';
import {
	StoreIdName,
	useFeatureToggle,
	useGlobalStateContext,
	useSelectedStore,
} from 'contexts';
import {
	formatWishlistdataForCart,
	formatWishListDataForGTM,
} from 'jsscomponents/Wishlist/helpers';
import { Wishlist as WishlistModel, WishlistVariant } from 'models/api';
import { selectIsLoading } from 'state-machines/wishlist';
import { getPriceProps, getProductImageFormat } from 'utils/business-logic';
import { useI18n } from 'utils/i18n';
import { formatPriceText } from 'utils/price';

interface ShareAndPrintButtonProps {
	copiedToClipboard: boolean | undefined;
	onShare: (() => void) | undefined;
	className?: string;
}

function ShareAndPrintButton({
	copiedToClipboard,
	onShare,
	className,
}: ShareAndPrintButtonProps) {
	const { t } = useI18n();
	return (
		<div className={className}>
			<div>
				<div className="flex justify-center gap-4">
					{onShare && (
						<Button rounded onClick={onShare}>
							<Icon
								icon={copiedToClipboard ? 'check' : 'copyLink'}
								className="mr-2"
							/>
							{copiedToClipboard
								? t('wishlist_link_copied_button')
								: t('wishlist_share_wishlist_button')}
						</Button>
					)}
					<Button onClick={() => window.print()} rounded>
						<Icon icon="printer" className="mr-2" />
						{t('wishlist_print_wishlist_button')}
					</Button>
				</div>
			</div>
		</div>
	);
}

ShareAndPrintButton.displayName = 'ShareAndPrintButton';

export interface WishlistBlockProps {
	heading: string;
	sharedText?: string;
	storesEnabled: boolean;
	onShare?: () => void;
	isLoading: boolean;
	onEmptyWishlist: (() => void) | undefined;
	onMoveAllVariantsToCart: (() => void) | undefined;
	onUpdateVariantQty?: (variant: WishlistVariant, newQuantity: number) => void;
	onRemoveVariant?: (variant: WishlistVariant) => void;
	onMoveVariantToCart: (variant: WishlistVariant) => void;
	onlineCommerceEnabled: boolean;
	wishlist: WishlistModel | undefined;
	selectedStore: StoreIdName | undefined;
	copiedToClipboard: boolean | undefined;
	moveAllVariantsToCartButtonText: string;
	moveVariantToCartButtonText: string;
}

/** Wishlist layout component */
export function WishlistBlock({
	heading,
	sharedText,
	storesEnabled,
	onShare,
	copiedToClipboard,
	isLoading,
	onEmptyWishlist,
	onMoveAllVariantsToCart,
	moveAllVariantsToCartButtonText,
	onUpdateVariantQty,
	onRemoveVariant,
	onMoveVariantToCart,
	moveVariantToCartButtonText,
	selectedStore,
	wishlist,
	onlineCommerceEnabled,
}: WishlistBlockProps) {
	const { t } = useI18n();
	const { categories, summaries, savingSummaries } = wishlist || {};
	const getSummary = (sumType: string) =>
		summaries?.find((summary) => summary.sumType === sumType);
	const getSummaries = (sumType: string) =>
		summaries?.filter((summary) => summary.sumType === sumType);

	return (
		<LayoutContainer withGrid className="mt-4 md:mt-10">
			<Text className="col-span-full" as="h1">
				{heading}
			</Text>
			{sharedText && (
				<Text as="p" className="col-span-full mt-4">
					{sharedText}
				</Text>
			)}

			<div className="col-span-full mt-6 flex items-start gap-4 md:mt-8">
				{onShare && (
					<Button rounded onClick={onShare}>
						<Icon
							icon={copiedToClipboard ? 'check' : 'copyLink'}
							className="mr-2"
						/>
						{copiedToClipboard
							? t('wishlist_link_copied_button')
							: t('wishlist_share_wishlist_button')}
					</Button>
				)}
				<Button
					className="max-md:hidden"
					onClick={() => window.print()}
					rounded
				>
					<Icon icon="printer" className="mr-2" />
					{t('wishlist_print_wishlist_button')}
				</Button>
				{onEmptyWishlist && (
					<div className="place-self-center">
						<Button variant="text" size="small" onClick={onEmptyWishlist}>
							{t('wishlist_empty_wishlist_button')}
						</Button>
					</div>
				)}
			</div>

			<div className="relative col-span-full mt-6 md:mt-14 print:block">
				{isLoading && (
					<LoadingSpinner
						variant="dashing"
						spinnerColor="julaRed"
						trackColor="transparent"
						className="z-1000 absolute left-1/2 top-1/2 -ml-20 -mt-20"
					/>
				)}
				<div className="divide-y divide-grey border-y border-grey">
					{categories?.flatMap((category) =>
						category.variants?.map((variant) => {
							const image = getProductImageFormat(
								'Templated square transparent',
								variant.listImageFormats,
							);

							const getSelectedStoreStock = () => {
								const stock = variant.storeStock?.stocks?.find(
									(store) => store.store.id === selectedStore?.id,
								);
								if (!stock) {
									return undefined;
								}
								return {
									name: selectedStore?.name!,
									categoryName: category.name,
									categoryColor: category.categoryColor,
									...stock,
								};
							};

							return (
								<CartListItem
									key={variant.id}
									link={variant.url}
									imageSrc={
										image?.location || '/assets/images/placeholder-image.png'
									}
									imageAlt="image"
									heading={variant.title}
									id={variant.id}
									price={getPriceProps(variant.price, false, variant.isHero)}
									rowSum={variant.rowSum}
									quantity={variant.qty}
									onUpdateVariantQty={
										onlineCommerceEnabled && onUpdateVariantQty
											? (qty) => onUpdateVariantQty(variant, qty)
											: undefined
									}
									selectedStoreStock={
										storesEnabled ? getSelectedStoreStock() : undefined
									}
									allStoresInStockCount={
										storesEnabled ? variant.storeStock?.inStockCount : undefined
									}
									webStock={variant.webStock}
									moveVariantButtonText={moveVariantToCartButtonText}
									onMoveVariant={
										onlineCommerceEnabled && variant?.webStock?.inStock
											? () => onMoveVariantToCart(variant)
											: undefined
									}
									onRemoveVariant={
										onlineCommerceEnabled && onRemoveVariant
											? () => onRemoveVariant(variant)
											: undefined
									}
									removeVariantButtonText={t(
										'wishlist_summary_product_remove_product_button',
									)}
									volumePrice={variant.volumePrice}
								/>
							);
						}),
					)}
				</div>

				<div className="flex flex-col justify-between pt-8 md:pt-14">
					<PriceRow
						title={getSummary('Total')?.title}
						price={getSummary('Total')?.displayValue}
						priceSymbol={getSummary('Total')?.displaySymbol}
					/>
					{getSummaries('QtyBasedOffer')?.map((offer, i) => (
						<PriceRow
							key={i}
							variant="minusPrice"
							title={offer.title}
							price={offer.displayValue}
							priceSymbol={offer.displaySymbol}
						/>
					))}
					<TotalWithVat
						title={getSummary('AmountToPay')?.title}
						price={getSummary('AmountToPay')?.displayValue}
						priceSymbol={getSummary('AmountToPay')?.displaySymbol}
						vat={getSummary('Vat')?.displayValue}
						vatCurrencySymbol={getSummary('Vat')?.displaySymbol}
					/>
					<div className="mt-1 flex justify-end">
						{savingSummaries?.map((item) => {
							if (item.sumType !== 'SavingTotalSum') {
								return null;
							}
							return (
								<div key={item.title} className="mt-2 flex items-center">
									<Icon
										icon="offer"
										color="julaRed"
										className="max-md:size-4"
									/>
									<Text as="p" className="ml-2 text-julaRed">
										<span className="mr-1">
											{' '}
											{t('wishlist_total_savings_label')}
										</span>
										<span>
											{formatPriceText(item.displayValue, item.displaySymbol)}
										</span>
									</Text>
								</div>
							);
						})}
					</div>
				</div>
			</div>
			{onMoveAllVariantsToCart && (
				<div className="col-span-full mb-4 mt-6 md:place-self-end print:hidden">
					<Button
						variant="cta"
						size="large"
						displayWidth="full"
						disabled={!onlineCommerceEnabled}
						onClick={onMoveAllVariantsToCart}
					>
						{moveAllVariantsToCartButtonText}
					</Button>
				</div>
			)}
		</LayoutContainer>
	);
}

WishlistBlock.displayName = 'WishlistBlock';

interface Props {
	heading: string;
	onEmptyWishlist?: () => void;
	onShare?: () => void;
	sharedText?: string;
	copiedToClipboard?: boolean;
	wishlist: WishlistModel | undefined;
	onUpdateVariantQty?: (variant: WishlistVariant, newQuantity: number) => void;
	onRemoveVariant?: (variant: WishlistVariant) => void;
	moveAllVariantsToCartButtonText: string;
	moveVariantToCartButtonText: string;
}

export default function Wishlist({
	heading,
	onEmptyWishlist,
	onShare,
	sharedText,
	copiedToClipboard,
	wishlist,
	onUpdateVariantQty,
	onRemoveVariant,
	moveAllVariantsToCartButtonText,
	moveVariantToCartButtonText,
}: Props) {
	const { cartService, wishlistService } = useGlobalStateContext();
	const { storesEnabled, onlineCommerceEnabled } = useFeatureToggle();
	const { selectedStore } = useSelectedStore();
	const isLoading = useSelector(wishlistService, selectIsLoading);
	const hasUnsellableItems = wishlist?.categories?.some((category) =>
		category.variants?.some((variant) => !variant.webStock?.inStock),
	);
	async function moveAllVariantsToCart(list: WishlistModel | undefined) {
		if (!list) return;
		cartService.send({
			type: 'ADD_MULTIPLE_VARIANTS_MINI',
			variants: formatWishlistdataForCart(list),
			GTMData: {
				type: 'add_multiple_to_cart',
				payload: { productsData: [...formatWishListDataForGTM(list)] },
			},
		});
		wishlistService.send({ type: 'REMOVE_ALL' });
	}
	const moveVariantToCart = (variant: WishlistVariant) => {
		if (!variant.id) return;
		cartService.send({
			type: 'ADD_REMOVE_VARIANT',
			variantId: variant.id,
			qty: variant.qty,
			distinctValue: false,
			GTMData: {
				type: 'add_to_cart',
				payload: { productData: { product: variant, quantity: variant.qty } },
			},
		});
		wishlistService.send({
			type: 'REMOVE_VARIANT',
			variantId: variant.id,
		});
	};

	return (
		<WishlistBlock
			heading={heading}
			onShare={onShare}
			sharedText={sharedText}
			copiedToClipboard={copiedToClipboard}
			storesEnabled={storesEnabled}
			isLoading={isLoading}
			onEmptyWishlist={onEmptyWishlist}
			onMoveAllVariantsToCart={
				hasUnsellableItems ? undefined : () => moveAllVariantsToCart(wishlist)
			}
			onMoveVariantToCart={moveVariantToCart}
			onUpdateVariantQty={onUpdateVariantQty}
			onRemoveVariant={onRemoveVariant}
			onlineCommerceEnabled={onlineCommerceEnabled}
			wishlist={wishlist}
			selectedStore={selectedStore}
			moveVariantToCartButtonText={moveVariantToCartButtonText}
			moveAllVariantsToCartButtonText={moveAllVariantsToCartButtonText}
		/>
	);
}
Wishlist.displayName = 'Wishlist';
