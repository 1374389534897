/**
 * Count
 */

import React from 'react';
import clsx from 'clsx';

import { HTMLAttributes } from 'types';

export interface Props extends Omit<HTMLAttributes<HTMLSpanElement>, 'color'> {
	/** The number to display. */
	amount: string | number | undefined;

	/** Additional class names. */
	className?: string;

	/**
	 * Background or text color, depending on variant. `auto` defaults to grey
	 * for text and red for badge.
	 */
	color?: 'red' | 'green' | 'white' | 'grey' | 'inherit' | 'auto';

	/** A default margin. */
	margin?: 'left' | 'right' | 'none';

	/** Size, `auto` defaults badge to small and text to regular. */
	size?: 'auto' | 'small' | 'regular';

	/** The style variant. */
	variant?: 'text' | 'badge';
}

/** A number in plain text or as a rounded badge. */
export default function Count({
	amount,
	className,
	color = 'auto',
	margin = 'left',
	size = 'auto',
	variant = 'text',
	...attrs
}: Props) {
	if (amount === '' || amount === undefined) {
		return null;
	}

	return (
		<span
			{...attrs}
			className={clsx(
				className,
				'flex-shrink-0 font-standard',

				margin === 'left' && 'ml-2',
				margin === 'right' && 'mr-2',

				variant === 'badge' && [
					'inline-flex items-center justify-center rounded-full px-1 font-bold',

					(size === 'small' || size === 'auto') &&
						'h-5 min-w-[1.25rem] text-xs',
					size === 'regular' && 'h-6 min-w-[1.5rem] text-base',

					(color === 'red' || color === 'auto') && 'bg-julaRed text-white',
					color === 'white' && 'bg-white text-greyDarker',
					color === 'grey' && 'bg-greyLight text-greyDarker',
					color === 'green' && 'bg-cta text-white',
				],

				variant === 'text' && [
					size === 'small' && 'text-xs',
					(size === 'regular' || size === 'auto') && 'text-base',

					color !== 'inherit' && [
						(color === 'grey' || color === 'auto') && 'text-greyDarkenPlus',
						color === 'white' && 'text-white',
						color === 'red' && 'text-julaRed',
						color === 'green' && 'text-cta',
					],
				],
			)}
		>
			{/* Count is often used right next to texts in places like buttons,
			    ensure there is always a space for screen readers to avoid them
			    getting texts like "The button7" */}
			<span className="sr-only"> </span>
			{amount}
		</span>
	);
}
Count.displayName = 'Count';
