import React from 'react';
import clsx from 'clsx';
import { marked } from 'marked';

import type { Price, PriceType } from 'models/price';
import type { HTMLAttributes } from 'types';
import { useI18n } from 'utils/i18n';
import { formatPriceText } from 'utils/price';

// Price types that should NOT display the 'lowest recent price' text, instead
// defaulting to the regular base price text.
const BLOCKED_LOWEST_RECENT_PRICE_TYPES: PriceType[] = [
	'JulaClubsmart',
	'JulaPro',
	'Base',
	'Staff',
];

interface Props extends HTMLAttributes<HTMLParagraphElement> {
	basePrice: Price | undefined;
	basePriceKey?: string;
	displayedPriceType: PriceType | undefined;
	recentLowestPriceKey: string;
}

/**
 * Displays different texts describing the base price, depending on the price
 * type and the current market.
 *
 * - A 'lowest recent price' text may exist via the recentLowestPriceKey but
 *   the key can also contain the key itself as the text, in which case it
 *   should not be displayed.
 * - The 'lowest recent price' text should only be displayed for certain
 *   price types.
 * - A fallback 'base price' text may exist via the basePriceKey key.
 */
export default function BasePriceText({
	basePrice,
	basePriceKey,
	className,
	displayedPriceType,
	recentLowestPriceKey,
	...attrs
}: Props) {
	/* eslint-disable react/no-danger */

	const { t } = useI18n();

	if (!basePrice || !displayedPriceType) {
		return null;
	}

	const tParams = {
		basePrice: formatPriceText(
			basePrice.priceIncVat?.displayValue,
			basePrice.priceIncVat?.displaySymbol,
		),
	};

	// A markdown strikethrough results in a del element. If the text is fully
	// wrapped in del, it's likely just the base price without any context. Add
	// a screen reader label in this case.
	const getStrikethroughScreenReaderProps = (
		priceText: string,
	): HTMLAttributes<HTMLParagraphElement> | null => {
		if (!priceText.startsWith('<del>') || !priceText.endsWith('</del>')) {
			return null;
		}
		const key = 'product_recent_lowest_price_screen_reader_label';
		const label = t(key, tParams);
		if (label === key) {
			return null;
		}
		return { 'role': 'img', 'aria-label': label };
	};

	const lowestRecentText = t(recentLowestPriceKey, tParams);

	if (
		// Key is not set in every market, in which case the base text should be
		// displayed instead (depending on prop, see further down).
		lowestRecentText !== recentLowestPriceKey &&
		!BLOCKED_LOWEST_RECENT_PRICE_TYPES.includes(displayedPriceType)
	) {
		const parsedText = marked.parseInline(lowestRecentText).trim();
		return (
			<p
				{...attrs}
				className={clsx('font-standard', className)}
				dangerouslySetInnerHTML={{ __html: parsedText }}
				{...getStrikethroughScreenReaderProps(parsedText)}
			/>
		);
	}

	if (basePriceKey) {
		const baseText = marked.parseInline(t(basePriceKey, tParams)).trim();
		return (
			<p
				{...attrs}
				className={clsx('font-standard', className)}
				dangerouslySetInnerHTML={{ __html: baseText }}
				{...getStrikethroughScreenReaderProps(baseText)}
			/>
		);
	}

	return null;
}
BasePriceText.displayName = 'BasePriceText';
