/**
 * BlockHero
 */

import React from 'react';
import clsx from 'clsx';

import BlockMedia from 'components/BlockMedia';
import Chip from 'components/Chip';
import type { DigizuiteAsset } from 'models/asset';
import type { SimpleLink } from 'models/link';
import type { ProductCard } from 'models/productCard';
import { is } from 'utils/helpers';

interface Props {
	/** Background color for any base price text */
	basePriceTextBackground?: string;

	/** Image src for large devices */
	largeImageSrc: string;

	/** Alt for large image */
	largeImageAlt: string | undefined;

	/** Links to render as chips */
	links?: SimpleLink[];

	/** Don't autoplay video and/or product slideshow */
	preventAutoplay?: boolean;

	/** Products to display in an animated slideshow */
	products?: ProductCard[];

	/** Image src for small devices */
	smallImageSrc: string;

	/** Alt for small image */
	smallImageAlt: string | undefined;

	/** Video, takes precedence over image */
	video: DigizuiteAsset | undefined;
}

/** Block hero component. */
export default function BlockHero({
	basePriceTextBackground,
	largeImageAlt,
	largeImageSrc,
	links,
	preventAutoplay,
	products,
	smallImageAlt,
	smallImageSrc,
	video,
}: Props) {
	return (
		<div className="relative overflow-hidden">
			<BlockMedia
				variant="landscapeWide"
				video={video}
				imgSrc={largeImageSrc}
				imgAlt={largeImageAlt}
				products={products}
				productsDisplayType="campaignSpinner"
				preventAutoplay={preventAutoplay}
				basePriceTextBackground={basePriceTextBackground}
				className="max-md:hidden"
				productAnimationPlacementClassName="absolute bottom-8 top-12 right-0 w-2/4 flex justify-center"
			/>
			<BlockMedia
				variant="landscapeWide"
				video={video}
				imgSrc={smallImageSrc}
				imgAlt={smallImageAlt}
				products={products}
				productsDisplayType="campaignSpinner"
				preventAutoplay={preventAutoplay}
				basePriceTextBackground={basePriceTextBackground}
				className="md:hidden"
				productAnimationPlacementClassName="absolute inset-x-24 top-24 bottom-8 flex justify-center"
			/>
			{is.arrayWithLength(links) && (
				<div
					className={clsx(
						'z-3 flex w-full gap-4 px-4 pt-4',
						'max-md:flex-col max-md:items-start',
						'md:absolute md:bottom-4 md:items-center md:justify-center',
					)}
				>
					{links.map((link) => (
						<Chip
							key={link.href}
							color="grey"
							href={link.href}
							text={link.text}
							target={link.target}
						/>
					))}
				</div>
			)}
		</div>
	);
}
BlockHero.displayName = 'BlockHero';
