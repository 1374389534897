/**
 * Login
 */

import React, { useEffect, useState } from 'react';
import { useSelector } from '@xstate/react';

import AuthJulaConnect from 'components/AuthJulaConnect';
import Popover from 'components/Popover';
import Tabs from 'components/Tabs';
import { useFeatureToggle, useGlobalStateContext } from 'contexts';
import { useSitecoreContext } from 'hooks';
import { LoginMethod } from 'models/connect/oauth';
import {
	selectErrorMessage,
	selectIsAuthenticatedUser,
	selectIsAwaitingAuthAction,
} from 'state-machines/authentication';
import { LoginPanelType } from 'types';
import { useI18n } from 'utils/i18n';

import ClubLogin from './ClubLogin';
import ProLogin from './ProLogin';

interface Props {
	/** True if the log in should be open. */
	isOpen: boolean;

	/** The Popover id */
	id: string;

	/** The heading in the log in. */
	header: string;

	/** Callback when login closes */
	onClose: () => void;

	initialTab?: LoginPanelType;
}

/** Login popover panel. */
export default function Login({
	isOpen,
	id,
	header,
	onClose,
	initialTab = 'LoginJulaClub',
}: Props) {
	const { authService, userService, globalPopoverService } =
		useGlobalStateContext();
	const isAwaitingAuthAction = useSelector(
		authService,
		selectIsAwaitingAuthAction,
	);
	const { julaProEnabled, julaClubEnabled } = useFeatureToggle();

	const errorMessage = useSelector(authService, selectErrorMessage);

	const isAuthenticatedUser = useSelector(
		userService,
		selectIsAuthenticatedUser,
	);

	const { t } = useI18n();
	const [activeTab, setActiveTab] = useState<LoginPanelType>(initialTab);
	const { sitecoreContext } = useSitecoreContext();
	const { globalLinks } = sitecoreContext;

	useEffect(() => {
		setActiveTab(initialTab);
	}, [initialTab]);

	const sendGlobalPopoverOpen = (target: string, heading: string) => {
		if (target) {
			globalPopoverService.send({
				type: 'OPEN',
				target,
				heading,
			});
			// Close the login popover
			onClose();
		}
	};

	const popoverHeaderId = `popover-${id}-title`;
	const clubTabId: LoginPanelType = 'LoginJulaClub';
	const proTabId: LoginPanelType = 'LoginJulaPro';
	return (
		<Popover
			isOpen={isOpen}
			title={header}
			id={`popover-${id}`}
			titleId={popoverHeaderId}
			onClose={() => {
				onClose();
				if (!isAuthenticatedUser) {
					authService.send('RELOAD_IFRAME');
				}
			}}
		>
			<div className="h-full">
				{!isAuthenticatedUser && !isAwaitingAuthAction && <AuthJulaConnect />}
				{isAwaitingAuthAction && (
					<Tabs
						tabListLabelledBy={popoverHeaderId}
						activeTabId={activeTab}
						onTabChange={(tab) => {
							setActiveTab(tab as LoginPanelType);
						}}
						items={[
							julaClubEnabled && {
								id: clubTabId,
								title: 'JulaClub',
								content: (
									<ClubLogin
										errorMessage={errorMessage}
										onSelectLoginMethod={(loginMethod: LoginMethod) => {
											authService.send({
												type: 'SELECT_LOGIN_CLUB_STAFF',
												loginMethod,
											});
										}}
										onBecomeMemberClick={() => {
											sendGlobalPopoverOpen(
												globalLinks.signUpJulaClub,
												t('account_julaclub_become_member_button'),
											);
										}}
									/>
								),
							},
							julaProEnabled && {
								id: proTabId,
								title: 'JulaPro',
								content: (
									<ProLogin
										errorMessage={errorMessage}
										onSelectLoginMethod={(loginMethod: LoginMethod) => {
											authService.send({
												type: 'SELECT_LOGIN_PRO',
												loginMethod,
											});
										}}
										onBecomeMemberClick={() => {
											sendGlobalPopoverOpen(
												globalLinks.signUpJulaPro,
												t('account_julapro_become_member_button'),
											);
										}}
									/>
								),
							},
						]}
					/>
				)}
			</div>
		</Popover>
	);
}
Login.displayName = 'Login';
