import React from 'react';

import ActionButton, {
	ActionButtonState,
} from 'components/ActionButton/ActionButton';
import GdprAccordion from 'components/GdprAccordion';
import GenericForm from 'components/GenericForm';
import Text from 'components/Text';
import { FormFields } from 'models/form';

interface Props {
	heading: string;
	description: string;
	fields: FormFields;
	submitButtonState: ActionButtonState;
	submitButtonText: string;
	onSubmitCallback: (genericFormData) => Promise<any>;
	className?: string;
}
export default function FormView({
	heading,
	className,
	fields,
	submitButtonText,
	submitButtonState,
	onSubmitCallback,
	description,
}: Props) {
	return (
		<div className={className}>
			<div className="mb-4">
				<Text className="mb-2" as="h2" text={heading} />
				<Text as="p" text={description} />
			</div>
			<GdprAccordion className="mb-4" />
			<GenericForm
				fields={fields}
				button={
					<ActionButton
						data-cid="julaClubBecomeMember"
						displayWidth="full"
						type="submit"
						size="large"
						variant="cta"
						minimunLoadingTime={0}
						customState={submitButtonState}
						className="m-0"
					>
						{submitButtonText}
					</ActionButton>
				}
				onSubmitCallback={onSubmitCallback}
				className="space-y-6 pb-4"
			/>
		</div>
	);
}
FormView.displayName = 'FormView';
