import React, { type RefObject, useState } from 'react';
import clsx from 'clsx';

import ErrorBoundary from 'components/ErrorBoundary';
import IconButton from 'components/IconButton';
import JulaLogo from 'components/JulaLogo';
import { LayoutContainer } from 'components/Layout';
import Link from 'components/Link';
import { StoreSelectorButton } from 'components/StoreSelector/';
import {
	HEADER_MAIN_NAVIGATION_ID,
	HEADER_SEARCH_FIELD_ID,
	HEADER_SEARCH_FIELD_MOBILE_ID,
} from 'constants/ids';
import { useFeatureToggle } from 'contexts';
import { useIsStickyStuck, useMinWidth, useValueChangeEffect } from 'hooks';
import { is } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

import PageHeaderSearch from './PageHeaderSearch';

interface Props {
	/** Number of products in the cart */
	cartQuantity?: number;

	/** If the search bar should be hidden */
	hideSearchBar?: boolean;

	/** If the user is logged in */
	isAuthenticatedUser?: boolean;

	/** If the user is being initialised */
	isInitialisingUser?: boolean;

	/** If the small screen navigation menu is open */
	isToggledMenuOpen: boolean;

	/** Small screen navigation menu toggle button ref */
	menuToggleRef: RefObject<HTMLButtonElement>;

	/** Click handler for logged in account button */
	onAccountClick: () => void;

	/** Click handler for login button */
	onLoginClick: () => void;

	/** Click handler for the small screen navigation menu button */
	onMenuToggleClick: () => void;

	/** Mousedown handler for the small screen navigation menu button */
	onMenuToggleMouseDown: () => void;

	/** Click handler for the store selector on small screens */
	onStoreSelectorClick: () => void;

	/** Wishlist product quantity */
	wishlistQuantity?: number;
}

/** Header main part with logo, search and icon buttons */
export default function PageHeaderMain({
	cartQuantity,
	hideSearchBar = false,
	isAuthenticatedUser = false,
	isToggledMenuOpen,
	menuToggleRef,
	onAccountClick,
	onLoginClick,
	onMenuToggleClick,
	onMenuToggleMouseDown,
	wishlistQuantity = 0,
	onStoreSelectorClick,
	isInitialisingUser = false,
}: Props) {
	const { t } = useI18n();
	const isMinMd = useMinWidth('md');
	const { julaClubEnabled, julaProEnabled, wishlistEnabled } =
		useFeatureToggle();

	const hasWishlistButton = wishlistEnabled;
	const hasAccountButton = julaClubEnabled || julaProEnabled;

	const runAccountClickCallback = () => {
		if (isAuthenticatedUser) {
			onAccountClick();
		} else {
			onLoginClick();
		}
	};

	// Since there is a single user button for the login panel and account menu,
	// the user info must be loaded to know which callback to trigger (i.e. is
	// the current user logged in or not). If the user presses the button before
	// that info is loaded, set a loading state to show UI feedback and trigger
	// the relevant callback when the info becomes available.
	const [hasVisualAccountLoading, setHasVisualAccountLoading] = useState(false);
	const handleAccountClick = () => {
		if (isInitialisingUser) {
			if (!hasVisualAccountLoading) {
				setHasVisualAccountLoading(true);
			}
			return;
		}
		runAccountClickCallback();
	};
	useValueChangeEffect(isInitialisingUser, (prevIsLoadingUser) => {
		if (prevIsLoadingUser && !isInitialisingUser && hasVisualAccountLoading) {
			runAccountClickCallback();
			setHasVisualAccountLoading(false);
		}
	});

	// Would prefer rem. Each Tailwind unit is 4 px. Match with top/padding diff
	// on container below.
	const [containerRef, isStuck] = useIsStickyStuck<HTMLDivElement>(
		isMinMd ? -12 : -4,
	);

	return (
		<>
			<div
				ref={containerRef}
				// No page-header-part class here, the stickyPageHeader z-index does
				// what's needed.
				className={clsx(
					// Match top/padding with stuck hook above.
					'sticky z-stickyPageHeader bg-white transition-shadow',
					'-top-2 pb-2 pt-4',
					'headerRow:pb-3',
					'max-md:mb-1',
					'md:-top-3 md:pb-3 md:pt-6',
					isStuck && 'shadow-summaryShadow',
				)}
			>
				<LayoutContainer className="flex flex-wrap items-center sm:flex-nowrap">
					<Link
						href="/"
						// Pointless but pretty rounded — match focus outline with logo radius.
						className="inline-block flex-shrink-0 rounded-[9px] sm:mr-8 headerRow:rounded-[10px]"
					>
						<JulaLogo className="headerRow:w-[120px]" />
					</Link>

					{!hideSearchBar && (
						<ErrorBoundary className="grow self-center max-headerRow:hidden">
							<PageHeaderSearch
								id={HEADER_SEARCH_FIELD_ID}
								className="grow max-headerRow:hidden"
							/>
						</ErrorBoundary>
					)}

					<div
						className={clsx(
							// Pull right edge to visually align icon.
							'-mr-3 ml-auto flex gap-2 max-headerRow:-my-1',
							!hideSearchBar && 'headerRow:ml-5',
						)}
					>
						{hasAccountButton && (
							<IconButton
								onClick={handleAccountClick}
								icon="account"
								isLoading={hasVisualAccountLoading}
								text={
									isInitialisingUser
										? t('general_loading_text')
										: isAuthenticatedUser
											? t('account_account_heading')
											: t('account_login_button')
								}
								data-cid={
									isInitialisingUser
										? undefined
										: isAuthenticatedUser
											? 'pageHeaderMyAccountButton'
											: 'pageHeaderLoginButton'
								}
							/>
						)}
						{hasWishlistButton && (
							<IconButton
								icon="shoppinglist"
								href="/wishlist"
								text={t('wishlist_button_screenreader')}
								badge={wishlistQuantity || undefined}
							/>
						)}
						<IconButton
							icon="cart"
							href="/cart"
							text={t('cart_button_screenreader')}
							badge={is.positiveNumber(cartQuantity) ? cartQuantity : undefined}
							badgeProps={{ 'data-cid': 'PageHeaderCartAmount' }}
						/>
						<IconButton
							ref={menuToggleRef}
							className="md:hidden"
							icon="menu"
							text={t('screenreader_text_mobile_menu_link')}
							onClick={onMenuToggleClick}
							onMouseDown={onMenuToggleMouseDown}
							aria-controls={HEADER_MAIN_NAVIGATION_ID}
							aria-expanded={isToggledMenuOpen}
						/>
					</div>
				</LayoutContainer>

				{/* Internal overlay to isolate search field when that's open, see globals.css. */}
				<div className="page-header-overlay" />
			</div>

			{!hideSearchBar && (
				<ErrorBoundary className="mx-4 mb-4 block headerRow:hidden">
					<PageHeaderSearch
						id={HEADER_SEARCH_FIELD_MOBILE_ID}
						className="mb-4 headerRow:hidden"
					/>
				</ErrorBoundary>
			)}
			<StoreSelectorButton
				onClick={onStoreSelectorClick}
				className="mx-4 -mt-1 mb-2 md:hidden"
			/>

			{/* Ugly use of presentational div, but doing it properly on existing
			    elements got really clunky. */}
			<div
				className={clsx(
					'border-b border-b-greyLighter transition-border-color md:hidden',
					isStuck && 'headerRow:border-b-transparent',
				)}
			/>
		</>
	);
}
PageHeaderMain.displayName = 'PageHeaderMain';
