import React from 'react';
import type { Field } from '@sitecore-jss/sitecore-jss-nextjs';
import clsx from 'clsx';

import BlockMedia from 'components/BlockMedia';
import ComponentPlaceholder from 'components/ComponentPlaceholder';
import { LayoutContainer } from 'components/Layout';
import Text from 'components/Text';
import type { ColorField, JulaComponentProps } from 'lib/component-props';
import type { DigizuiteAssetList } from 'models/asset';
import type { ProductCard } from 'models/productCard';
import { getEditorMargin } from 'utils/business-logic';
import { getAsset } from 'utils/helpers';

type Props = JulaComponentProps & {
	fields?: {
		backgroundColor?: ColorField;
		description?: Field<string>;
		heading?: Field<string>;
		mirror?: Field<boolean>;
		products?: ProductCard[];
		squareImage: DigizuiteAssetList;
	};
};

export default function ContentGuide({ fields, params }: Props) {
	if (!fields) {
		return null;
	}

	const { squareImage, products, heading, description, mirror } = fields;
	const { heading: headingLevel = 'h2' } = params || {};

	const image = getAsset('Templated 1:1', squareImage);
	const isMirrored = Boolean(mirror?.value);

	if (!image) {
		return (
			<ComponentPlaceholder
				componentName="ContentGuide"
				description="Requires an image"
				className={getEditorMargin(params)}
			/>
		);
	}

	return (
		<LayoutContainer
			backgroundColor={fields.backgroundColor?.fields?.colorHex?.value}
			outerClassName={getEditorMargin(params)}
			id={params?.anchor}
			className="items-start"
			withGrid
		>
			<BlockMedia
				variant="square"
				imgSrc={image?.src}
				imgAlt={image?.alt}
				imgHeight={image?.height}
				imgWidth={image?.width}
				products={products}
				className={clsx(
					'col-span-4 md:col-span-2',
					!isMirrored && 'md:col-start-4',
					isMirrored && 'md:col-start-8',
				)}
			/>
			<div
				className={clsx(
					'col-span-4 mt-4 md:mt-6',
					isMirrored && 'md:order-first md:col-start-4',
				)}
			>
				<Text as={headingLevel} field={heading} className="mb-2" />
				<Text as="p" field={description} />
			</div>
		</LayoutContainer>
	);
}
ContentGuide.displayName = 'ContentGuide';
