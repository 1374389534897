import React, { useState } from 'react';

import Popover from 'components/Popover';
import { StockBalance } from 'components/StockBalance';
import { WatchableSignup } from 'components/WatchableSignup';
import { WebStock } from 'models/inventory';
import { Product } from 'models/product';
import {
	getFormattedStockAmount,
	getProductDetailsWebStockStatus,
} from 'utils/business-logic/stock';
import { useI18n } from 'utils/i18n';

interface WatchableVariantPopoverProps {
	isOpen: boolean;
	onClose: () => void;
	productId: string;
}

function WatchableVariantPopover({
	productId,
	isOpen,
	onClose,
}: WatchableVariantPopoverProps) {
	const { t } = useI18n();
	return (
		<Popover
			isOpen={isOpen}
			title={t('product_watchable_popover_heading')}
			onClose={onClose}
		>
			<WatchableSignup onClose={onClose} variantId={productId} />
		</Popover>
	);
}

WatchableVariantPopover.displayName =
	'ProductDetails_StockInformation_WatchableVariantPopover';

interface Props {
	isLoadingInitialStock: boolean;
	product: Product;
	webStock: WebStock | undefined;
}

export default function WebStockBalance({
	product,
	webStock,
	isLoadingInitialStock,
}: Props) {
	const { t } = useI18n();
	const { salesAttributes } = product;
	const [watchablePopoverOpen, setWatchablePopoverOpen] = useState(false);
	if (!salesAttributes) return null;

	if (isLoadingInitialStock) {
		return (
			<StockBalance
				statusType="loading"
				loading
				locationIcon="parcel"
				header={t('product_details_online_accordion_available_heading')}
				stockStatus={{ inStock: false, statusText: '' }}
			/>
		);
	}

	const status = getProductDetailsWebStockStatus({
		isSellable: salesAttributes.isSellable,
		isSellableOnWeb: salesAttributes.isSellableOnWeb,
		isClickAndCollectOnly: salesAttributes.isClickAndCollectOnly,
		isWatchableIfOutOfStock: salesAttributes.isWatchableIfOutOfStock,
		reStockWithinAcceptableTimespan:
			webStock?.reStockWithinAcceptableTimespan || false,
		nextDeliveryInMonth: webStock?.nextDeliveryInMonth || '',
		availableStockLevel: webStock?.availableStockLevel || 0,
	});
	switch (status) {
		case 'InStock':
			return (
				<StockBalance
					statusType={status}
					locationIcon="parcel"
					header={t('product_details_online_accordion_available_heading')}
					stockStatus={{
						inStock: true,
						statusText: t('stock_general_amount_exact', {
							amount: getFormattedStockAmount(webStock?.availableStockLevel),
						}),
					}}
				/>
			);
		case 'ClickAndCollect':
			return (
				<StockBalance
					statusType={status}
					locationIcon="parcel"
					header={t('product_details_online_accordion_available_heading')}
					stockStatus={{
						inStock: true,
						statusText: t('stock_general_amount_exact', {
							amount: getFormattedStockAmount(webStock?.availableStockLevel),
						}),
					}}
					infoText={t(
						'product_details_online_accordion_click_and_collect_text',
					)}
				/>
			);
		case 'OutOfStock':
			return (
				<StockBalance
					statusType={status}
					locationIcon="parcel"
					header={t('product_details_online_accordion_available_heading')}
					stockStatus={{
						inStock: false,
						statusText: t('stock_general_out_of_stock'),
						statusInfo: t('product_details_online_accordion_out_of_stock_text'),
					}}
				/>
			);
		case 'ReStock-Watchable':
			return (
				<div>
					<StockBalance
						statusType={status}
						locationIcon="parcel"
						header={t('product_details_online_accordion_available_heading')}
						stockStatus={{
							inStock: false,
							statusText: t('stock_general_re_stock'),
							statusInfo: t(
								'product_details_online_accordion_re_stock_watchable_text',
								{ month: webStock?.nextDeliveryInMonth },
							),
						}}
						onClick={() => {
							setWatchablePopoverOpen(true);
						}}
						buttonText={t('product_watchable_popover_button')}
					/>
					<WatchableVariantPopover
						productId={product.id}
						isOpen={watchablePopoverOpen}
						onClose={() => {
							setWatchablePopoverOpen(false);
						}}
					/>
				</div>
			);
		case 'ReStock-Watchable-Delayed':
			return (
				<div>
					<StockBalance
						statusType={status}
						locationIcon="parcel"
						header={t('product_details_online_accordion_available_heading')}
						stockStatus={{
							inStock: false,
							statusText: t('stock_general_re_stock'),
							statusInfo: t('product_details_store_re_stock_delayed_text'),
						}}
						onClick={() => {
							setWatchablePopoverOpen(true);
						}}
						buttonText={t('product_watchable_popover_button')}
					/>
					<WatchableVariantPopover
						productId={product.id}
						isOpen={watchablePopoverOpen}
						onClose={() => {
							setWatchablePopoverOpen(false);
						}}
					/>
				</div>
			);
		case 'ReStock-NotWatchable':
			return (
				<StockBalance
					statusType={status}
					locationIcon="parcel"
					header={t('product_details_online_accordion_available_heading')}
					stockStatus={{
						inStock: false,
						statusText: t('stock_general_re_stock'),
						statusInfo: t('product_details_online_accordion_re_stock_text', {
							month: webStock?.nextDeliveryInMonth,
						}),
					}}
				/>
			);
		case 'ReStock-NotWatchable-Delayed':
			return (
				<StockBalance
					statusType={status}
					locationIcon="parcel"
					header={t('product_details_online_accordion_available_heading')}
					stockStatus={{
						inStock: false,
						statusText: t('stock_general_re_stock'),
						statusInfo: t('product_details_store_re_stock_delayed_text'),
					}}
				/>
			);
		case 'InStoreOnly':
			return (
				<StockBalance
					statusType={status}
					locationIcon="parcel"
					header={t('product_details_online_accordion_available_heading')}
					stockStatus={{
						inStock: false,
						statusText: t('stock_online_store_only'),
					}}
				/>
			);
		default:
			return null;
	}
}

WebStockBalance.displayName = 'ProductDetails_StockInformation_WebStockBalance';
