/**
 * LoadMoreList
 */

import React, {
	type KeyboardEventHandler,
	type ReactNode,
	useMemo,
	useRef,
	useState,
} from 'react';
import clsx from 'clsx';

import ActionButton from 'components/ActionButton';
import type { ButtonVariant } from 'components/Button';
import { useValueChangeEffect } from 'hooks';
import type { HTMLTagName } from 'types';
import { getFocusedElement, selectTabbable } from 'utils/dom';
import { is } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

interface Props {
	afterListContent?: ReactNode;
	buttonAlignment?: 'left' | 'center';
	buttonClassName?: string;
	buttonText?: string;
	buttonVariant?: ButtonVariant;
	children?: ReactNode;
	className?: string;
	hasLoadMoreButton?: boolean;
	isLoading?: boolean;
	listClassName?: string;
	listTag?: HTMLTagName;
	onLoadMoreClick: () => unknown;
}

/**
 * A list with a 'load more' button that handles tabbing order.
 *
 * IMPORTANT! Children should only be the primary items of the list that has
 * the 'load more' behavior, any additional stuff like information messages
 * should be placed outside. Otherwise the tab order calculations may be
 * incorrect.
 */
export default function LoadMoreList({
	afterListContent,
	buttonAlignment = 'left',
	buttonClassName,
	buttonText,
	buttonVariant = 'primary',
	children,
	className,
	hasLoadMoreButton = true,
	isLoading = false,
	listClassName,
	listTag: ListTag = 'div',
	onLoadMoreClick,
}: Props) {
	const { t } = useI18n();
	const rootRef = useRef<HTMLDivElement>(null);
	const listRef = useRef<HTMLElement>(null);
	const loadMoreButtonRef = useRef<HTMLButtonElement>(null);
	const loadMoreTabCatcherRef = useRef<HTMLDivElement>(null);
	const [itemIndex, setItemIndex] = useState<number | undefined>(undefined);

	// Save the current number of items before more are added.
	const handleLoadMore = () => {
		setItemIndex(listRef.current?.childElementCount);
		onLoadMoreClick();
	};

	// Focus the first available element among the newly added items, if possible.
	const handleLoadMoreKeydown: KeyboardEventHandler<HTMLElement> = (e) => {
		if (e.key === 'Tab' && itemIndex && listRef.current) {
			// Only handle the first tabbing.
			setItemIndex(undefined);
			// Ignore backwards tabbing.
			if (!e.shiftKey) {
				// The first new item may not have any tabbable elements, go through
				// each new item in order.
				const possibleTargets = [...listRef.current.children].slice(itemIndex);
				for (const target of possibleTargets) {
					const targetTabbables = is.instance(target, HTMLElement)
						? selectTabbable(target)
						: [];
					if (targetTabbables[0]) {
						e.preventDefault();
						targetTabbables[0].focus();
						break;
					}
				}
			}
		}
	};

	// Ensure there is no tab handling state left if focus returns to the button.
	const handleLoadMoreFocus = () => {
		setItemIndex(undefined);
	};

	const [didLoadFromButtom, setDidLoadFromButtom] = useState(false);
	const loadingStatus = useMemo(
		(): [boolean, boolean] => [hasLoadMoreButton, isLoading],
		[hasLoadMoreButton, isLoading],
	);
	// Prevent focus loss if the load more button disappears. Feels overly
	// complicated but all parts have a purpose.
	// - Must ensure there is no currently focused element before moving to the
	//   tab catcher. The user could have tabbed away while the button was in a
	//   loading state in which case they should stay where they are.
	// - Must ensure the loading was triggered from the load more button.
	//   Otherwise focus can be moved to the bottom of the list while the user
	//   is doing something unrelated like filtering (e.g. filtering on a brand
	//   that only has a few products = button disappears).
	// - The loading button flag must be reset right after it's been checked
	//   or a load more press would just 'activate' the same issue the flag is
	//   used to solve (e.g. load more → was-from-button = true → filter list to
	//   make the button disappear → was-from-button is still true, move focus).
	useValueChangeEffect(
		loadingStatus,
		([prevHasLoadMoreButton, prevIsLoading]) => {
			// Set if loading was triggered by the button.
			if (!prevIsLoading && isLoading) {
				setDidLoadFromButtom(
					Boolean(
						loadMoreButtonRef.current &&
							loadMoreButtonRef.current === getFocusedElement(),
					),
				);
			}

			// Capture focus when relevant.
			if (
				prevHasLoadMoreButton &&
				!hasLoadMoreButton &&
				didLoadFromButtom &&
				!getFocusedElement()
			) {
				loadMoreTabCatcherRef.current?.focus();
			}

			// Reset the load button flag after it's been checked above.
			if (prevIsLoading && !isLoading && didLoadFromButtom) {
				setTimeout(() => {
					setDidLoadFromButtom(false);
				}, 100);
			}
		},
	);

	return (
		<div ref={rootRef} className={className}>
			<ListTag ref={listRef} className={listClassName}>
				{children}
			</ListTag>

			{afterListContent}

			{hasLoadMoreButton && (
				<ActionButton
					ref={loadMoreButtonRef}
					onClick={handleLoadMore}
					onKeyDown={handleLoadMoreKeydown}
					onFocus={handleLoadMoreFocus}
					variant={buttonVariant}
					className={clsx(
						buttonClassName,
						buttonAlignment === 'center' && 'mx-auto flex',
					)}
					customState={isLoading ? 'loading' : 'idle'}
					showSuccess={false}
					minimunLoadingTime={250}
				>
					{buttonText || t('generic_show_more_button_text')}
				</ActionButton>
			)}

			{/* This is specifically a hack for keyboard users. */}
			{/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
			<div
				ref={loadMoreTabCatcherRef}
				tabIndex={-1}
				onKeyDown={handleLoadMoreKeydown}
				className="outline-none"
			/>
		</div>
	);
}
LoadMoreList.displayName = 'LoadMoreList';
