import React from 'react';

import Icon from 'components/Icon';
import ListItem from 'components/ListItemNew';
import { StockStatus } from 'components/StockBalance';
import { getFormattedStockAmount } from 'utils/business-logic/stock';
import { useI18n } from 'utils/i18n';

interface Props {
	availableStockLevel: number;
	inStock: boolean;
	isSelected?: boolean;
	onClick: () => void;
	title: string;
}

export default function StockListItem({
	availableStockLevel,
	inStock,
	isSelected,
	onClick,
	title,
}: Props) {
	const { t } = useI18n();

	return (
		<ListItem
			as="li"
			isSelected={isSelected}
			onClick={onClick}
			title={title}
			endContent={<Icon icon="arrow" className="self-center" />}
		>
			<StockStatus
				textSize="small"
				inStock={inStock}
				text={t('product_details_in_stock_label', {
					amount: getFormattedStockAmount(availableStockLevel),
				})}
			/>
		</ListItem>
	);
}
StockListItem.displayName = 'StockListItem';
