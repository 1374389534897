import React from 'react';

import { LoginMethod } from 'models/connect/oauth';
import { getLoginOptions } from 'utils/business-logic';
import { useI18n } from 'utils/i18n';

import LoginOptions from './LoginOptions';

interface Props {
	errorMessage?: string | null;
	onBecomeMemberClick?: () => void;
	onSelectLoginMethod: (loginMethod: LoginMethod) => void;
}

export default function ClubLogin({
	errorMessage,
	onBecomeMemberClick,
	onSelectLoginMethod,
}: Props) {
	const { t } = useI18n();

	return (
		<LoginOptions
			introHeading={t('account_club_login_heading')}
			introText={t('account_club_login_intro_text')}
			errorMessage={errorMessage}
			loginOptions={getLoginOptions('Club Staff')}
			onSelectLoginMethod={onSelectLoginMethod}
			becomeMemberButtonText={
				onBecomeMemberClick
					? t('account_julaclub_become_member_button')
					: undefined
			}
			onBecomeMemberClick={onBecomeMemberClick}
		/>
	);
}
ClubLogin.displayName = 'ClubLogin';
