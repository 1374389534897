import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-nextjs';

import { JulaComponentProps } from 'lib/component-props';
import { getEditorMargin } from 'utils/business-logic';

type MediaCaptionProps = JulaComponentProps & {
	fields: {};
};

function MediaCaption({ params, rendering }: MediaCaptionProps) {
	return (
		<div id={params?.anchor} className={getEditorMargin(params)}>
			<Placeholder name="jula-media-caption" rendering={rendering} />
		</div>
	);
}

export default MediaCaption;
MediaCaption.displayName = 'MediaCaption';
