import React from 'react';
import clsx from 'clsx';

import Accordion from 'components/Accordion';
import Button from 'components/Button';
import ErrorBoundary from 'components/ErrorBoundary';
import ExpandableDescription from 'components/ExpandableDescription';
import Icon from 'components/Icon';
import Img from 'components/Img';
import InfoBox from 'components/InfoBox';
import { LayoutContainer } from 'components/Layout';
import { OptionalLink, ScrollLink } from 'components/Link';
import ProductCard from 'components/ProductCard';
import ProductCarousel from 'components/ProductCarousel';
import {
	PRODUCT_ACCESSORIES_ID,
	PRODUCT_BELONGS_TO_ID,
	PRODUCT_IMPORTANT_INFO_ID,
	PRODUCT_REVIEWS_ID,
	PRODUCT_SIMILAR_PRODUCTS_ID,
	PRODUCT_SPARE_PARTS_ID,
	PRODUCT_TECHNICAL_ATTRIBUTES_ID,
} from 'components/ProductDetails/';
import { Document } from 'components/ProductDetails/helpers/productDataHelpers';
import Reviews from 'components/Reviews';
import RichText from 'components/RichText';
import TechnicalSpecification from 'components/TechnicalSpecification';
import Text from 'components/Text';
import WarningSymbols from 'components/WarningSymbols';
import { StoreIdName } from 'contexts';
import { AdditionalText } from 'models/additionalText';
import {
	Question,
	QuestionSummary,
	Review,
	TechnicalAttributeCategory,
} from 'models/api';
import { AssetFormat } from 'models/asset';
import { InventoryItem, Stock, StockStore } from 'models/inventory';
import { CampaignResponse, Product } from 'models/product';
import type {
	AccessoryCard,
	ProductCard as ProductCardModel,
} from 'models/productCard';
import { ButtonStateWithId } from 'state-machines/cart';
import { WarningSymbol } from 'utils/business-logic';
import { is, sendGlobalEvent } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

import ExpiredProductInfo from './ExpiredProductInfo';
import ProductDetailsAdditionalSales from './ProductDetailsAdditionalSales';
import ProductDetailsCampaign from './ProductDetailsCampaign';
import ProductDetailsImage from './ProductDetailsImage';
import ProductDetailsSidebar from './ProductDetailsSidebar';
import ProductQuestions from './ProductQuestions';
import RelatedProductsAccordion from './RelatedProductsAccordion';

export interface Props {
	accessoriesHasNextPage: boolean;
	accessoriesIsLoading: boolean;
	accessoriesIsLoadingMore: boolean;
	accessoriesProducts: ProductCardModel[] | undefined;
	additionalSalesIsOpen: boolean;
	additionalSalesProducts: AccessoryCard[] | undefined;
	additionalSalesToastText: string;
	additionalTexts: AdditionalText[];
	allStoresStock: Stock[] | undefined;
	belongsToHasNextPage: boolean;
	belongsToIsLoading: boolean;
	belongsToIsLoadingMore: boolean;
	belongsToProducts: ProductCardModel[] | undefined;
	brandLogoSrc: string | undefined;
	creditSimulationPopoverIsOpen: boolean;
	currentProductStock: InventoryItem | undefined;
	currentStoreStock: Stock | undefined;
	descriptionAdditionalTexts: AdditionalText[];
	documents: Document[];
	fit3Summary: QuestionSummary | undefined;
	handleReviewGradeClick: (score: number) => void;
	handleReviewGradeResetClick: () => void;
	hasChemicalStatements: boolean;
	hasMoreReviews: boolean;
	isAboutBrandOpen: boolean;
	isBelongsToOpen: boolean;
	isImportantInfoOpen: boolean;
	isLoadingFit: boolean;
	isLoadingInitialReviews: boolean;
	isLoadingInitialStock: boolean;
	isLoadingMoreReviews: boolean;
	isLoadingNearbyStoresStock: boolean;
	isLoadingStock: boolean;
	isLoadingVariantsStock: boolean;
	isProductQuestionsOpen: boolean;
	isReviewsOpen: boolean;
	isSparePartsOpen: boolean;
	isTechnicalAttributesOpen: boolean;
	nearbyStoresStock: Stock[] | undefined;
	onAboutBrandClick: () => void;
	onAccessoriesLoadMoreClick: () => void;
	onAdditionalSalesButtonClick: (product: AccessoryCard) => void;
	onAdditionalSalesClose: () => void;
	onBelongsToClick: () => void;
	onBelongsToLoadMoreClick: () => void;
	onCampaignPopoverPurchaseButtonClick: (variant: ProductCardModel) => void;
	onCreditSimulationClick: () => void;
	onCreditSimulationPopoverClose: () => void;
	onImportantInfoClick: () => void;
	onLoadMoreReviewsClick: () => void;
	onMainPurchaseButtonClick: () => void;
	onMainWishlistButtonClick: () => void;
	onMixAndMatchBuyAllFixedPriceAddToCartClick:
		| ((campaign: CampaignResponse) => void)
		| undefined;
	onProductQuestionsClick: () => void;
	onProductQuestionsLoadMoreClick: () => void;
	onReviewsClick: () => void;
	onReviewsLinkClick: () => void;
	onReviewsSortOptionChange: (value: string | undefined) => void;
	onSizeGuideClick: () => void;
	onSparePartsClick: () => void;
	onSparePartsLoadMoreClick: () => void;
	onStockInformationOpen: () => void;
	onStockInformationTabChange: (tab) => void;
	onTechnicalAttributesClick: () => void;
	onUpdateSelectedStore: (store: StockStore) => void;
	onVariantPickerOpen: () => void;
	onWishlistAdditionalSalesButtonClick: (product: AccessoryCard) => void;
	onWishlistAdditionalSalesClose: () => void;
	product: Product;
	productQuestions: Question[] | undefined;
	productQuestionsHasNextPage: boolean;
	productQuestionsIsLoading: boolean;
	productQuestionsIsLoadingMore: boolean;
	productQuestionsNewQuestionsAllowed: boolean;
	purchaseButtonDisabled: boolean;
	purchaseButtonState: ButtonStateWithId;
	questionsSummary: QuestionSummary[] | undefined;
	reviewPolicyUrl: string;
	reviews: Review[] | undefined;
	reviewsSortOption: string | undefined;
	selectedStore: StoreIdName | undefined;
	sparePartsHasNextPage: boolean;
	sparePartsIsLoading: boolean;
	sparePartsIsLoadingMore: boolean;
	sparePartsProducts: ProductCardModel[] | undefined;
	symbolImageUrls: string[];
	technicalCategories: TechnicalAttributeCategory[] | undefined;
	variantsStock: InventoryItem[] | undefined;
	verticalEnergyLabel: AssetFormat | undefined;
	warningImages: WarningSymbol[] | undefined;
	wishListButtonState: ButtonStateWithId;
	wishlistAdditionalSalesIsOpen: boolean;
	wishlistAdditionalSalesProducts: AccessoryCard[] | undefined;
	wishlistAdditionalSalesToastText: string | undefined;
}

export default function ProductDetails({
	product,
	onCreditSimulationPopoverClose,
	creditSimulationPopoverIsOpen,
	fit3Summary,
	isLoadingFit,
	allStoresStock,
	currentProductStock,
	onCampaignPopoverPurchaseButtonClick,
	currentStoreStock,
	isLoadingInitialStock,
	isLoadingNearbyStoresStock,
	purchaseButtonState,
	onMainPurchaseButtonClick,
	onMainWishlistButtonClick,
	onStockInformationTabChange,
	onStockInformationOpen,
	onSizeGuideClick,
	onVariantPickerOpen,
	onCreditSimulationClick,
	variantsStock,
	selectedStore,
	isLoadingVariantsStock,
	purchaseButtonDisabled,
	onUpdateSelectedStore,
	nearbyStoresStock,
	isLoadingStock,
	brandLogoSrc,
	hasChemicalStatements,
	descriptionAdditionalTexts,
	handleReviewGradeResetClick,
	handleReviewGradeClick,
	warningImages,
	verticalEnergyLabel,
	symbolImageUrls,
	documents,
	technicalCategories,
	isLoadingMoreReviews,
	reviews,
	isReviewsOpen,
	isTechnicalAttributesOpen,
	isImportantInfoOpen,
	onImportantInfoClick,
	isAboutBrandOpen,
	onAboutBrandClick,
	isSparePartsOpen,
	onSparePartsClick,
	isBelongsToOpen,
	onBelongsToClick,
	hasMoreReviews,
	onLoadMoreReviewsClick,
	additionalTexts,
	onTechnicalAttributesClick,
	onReviewsClick,
	onReviewsLinkClick,
	reviewPolicyUrl,
	questionsSummary,
	onWishlistAdditionalSalesButtonClick,
	onAdditionalSalesButtonClick,
	onWishlistAdditionalSalesClose,
	onAdditionalSalesClose,
	wishlistAdditionalSalesToastText,
	wishlistAdditionalSalesProducts,
	wishlistAdditionalSalesIsOpen,
	additionalSalesToastText,
	additionalSalesProducts,
	additionalSalesIsOpen,
	wishListButtonState,
	onMixAndMatchBuyAllFixedPriceAddToCartClick,
	productQuestions,
	productQuestionsNewQuestionsAllowed,
	onProductQuestionsLoadMoreClick,
	isProductQuestionsOpen,
	productQuestionsIsLoading,
	productQuestionsIsLoadingMore,
	onProductQuestionsClick,
	belongsToProducts,
	belongsToIsLoadingMore,
	belongsToIsLoading,
	belongsToHasNextPage,
	onBelongsToLoadMoreClick,
	sparePartsProducts,
	sparePartsIsLoadingMore,
	sparePartsIsLoading,
	sparePartsHasNextPage,
	onSparePartsLoadMoreClick,
	accessoriesProducts,
	accessoriesIsLoadingMore,
	accessoriesIsLoading,
	accessoriesHasNextPage,
	onAccessoriesLoadMoreClick,
	productQuestionsHasNextPage,
	reviewsSortOption,
	onReviewsSortOptionChange,
	isLoadingInitialReviews,
}: Props) {
	const {
		title,
		productId,
		brand,
		chemicalInformation,
		reviewScore,
		salesAttributes,
		similarProducts,
		usPs,
		webTextFormatted,
		hasAccessories,
		hasSpareParts,
		hasBelongsTo,
		campaigns,
		packageSize,
		id,
	} = product;
	const contentClassName = 'max-w-[50rem]';
	const { t } = useI18n();

	return (
		<>
			<ProductDetailsAdditionalSales
				productId={id}
				onWishlistAdditionalSalesButtonClick={
					onWishlistAdditionalSalesButtonClick
				}
				onWishlistAdditionalSalesClose={onWishlistAdditionalSalesClose}
				wishlistAdditionalSalesToastText={wishlistAdditionalSalesToastText}
				wishlistAdditionalSalesProducts={wishlistAdditionalSalesProducts}
				onAdditionalSalesButtonClick={onAdditionalSalesButtonClick}
				onAdditionalSalesClose={onAdditionalSalesClose}
				wishlistAdditionalSalesIsOpen={wishlistAdditionalSalesIsOpen}
				wishlistButtonState={wishListButtonState}
				purchaseButtonState={purchaseButtonState}
				additionalSalesToastText={additionalSalesToastText}
				additionalSalesProducts={additionalSalesProducts}
				additionalSalesIsOpen={additionalSalesIsOpen}
			/>
			<LayoutContainer className="grid-cols-12 gap-x-6 [grid-template-areas:'image_image_image_image_image_image_image_._sidebar_sidebar_sidebar_sidebar''content_content_content_content_content_content_content_._sidebar_sidebar_sidebar_sidebar'] md:grid">
				<ExpiredProductInfo
					className={salesAttributes?.expired ? 'md:hidden' : 'hidden'}
					shouldShowSimilarProductsLink={is.arrayWithLength(similarProducts)}
				/>

				<>
					<div className="[grid-area:image]">
						<ProductDetailsImage product={product} />
					</div>
					<ErrorBoundary>
						<div className="[grid-area:sidebar]">
							<ProductDetailsSidebar
								product={product}
								onEnergyLabelClick={onTechnicalAttributesClick}
								onRatingClick={onReviewsLinkClick}
								onSizeFitClick={onReviewsLinkClick}
								fit3Summary={fit3Summary}
								isLoadingFit={isLoadingFit}
								allStoresStock={allStoresStock}
								currentProductStock={currentProductStock}
								onCampaignPopoverPurchaseButtonClick={
									onCampaignPopoverPurchaseButtonClick
								}
								currentStoreStock={currentStoreStock}
								isLoadingInitialStock={isLoadingInitialStock}
								isLoadingNearbyStoresStock={isLoadingNearbyStoresStock}
								purchaseButtonState={purchaseButtonState}
								onMainPurchaseButtonClick={onMainPurchaseButtonClick}
								wishlistButtonState={wishListButtonState}
								onWishlistButtonClick={onMainWishlistButtonClick}
								onStockInformationTabChange={onStockInformationTabChange}
								onStockInformationOpen={onStockInformationOpen}
								onSizeGuideClick={onSizeGuideClick}
								onVariantPickerOpen={onVariantPickerOpen}
								creditSimulationPopoverIsOpen={creditSimulationPopoverIsOpen}
								onCreditSimulationPopoverClose={onCreditSimulationPopoverClose}
								onCreditSimulationClick={onCreditSimulationClick}
								variantsStock={variantsStock}
								selectedStore={selectedStore}
								isLoadingVariantsStock={isLoadingVariantsStock}
								purchaseButtonDisabled={purchaseButtonDisabled}
								onUpdateSelectedStore={onUpdateSelectedStore}
								nearbyStoresStock={nearbyStoresStock}
								isLoadingStock={isLoadingStock}
							/>
							<ExpiredProductInfo
								className={
									salesAttributes?.expired ? 'max-md:hidden' : 'hidden'
								}
								shouldShowSimilarProductsLink={is.arrayWithLength(
									similarProducts,
								)}
							/>
						</div>
					</ErrorBoundary>
					<div className="[grid-area:content]">
						<div className="mb-4 sm:mb-6">
							<ProductDetailsCampaign
								campaigns={campaigns?.filter(
									(campaign) => campaign.discountType === 'BuyAllFixedPrice',
								)}
								onMixAndMatchBuyAllFixedPriceAddToCartClick={
									onMixAndMatchBuyAllFixedPriceAddToCartClick
								}
								purchaseButtonState={purchaseButtonState}
							/>
							<Text
								as="h2"
								text={t('product_details_about_product_section_heading')}
							/>
							<div className="mt-6 flex flex-wrap-reverse items-end justify-between">
								{is.arrayWithLength(usPs) && (
									<ul className="inline-flex list-inside list-disc flex-col gap-2 rounded-xl bg-greyLighter p-4 font-bold">
										{usPs.map((usp) => (
											<li key={usp}>{usp}</li>
										))}
									</ul>
								)}
								<div className="flex flex-col items-start max-md:mb-4 md:items-end">
									{brandLogoSrc && (
										<OptionalLink href={brand?.relativeUrl}>
											<Img
												src={brandLogoSrc}
												alt={brand?.title}
												useFallbackOnError
												className="mb-4 max-h-16 max-w-[10rem]"
											/>
										</OptionalLink>
									)}
									<Text as="p">
										<span className="font-bold">
											{t('product_details_article_number_label')}:
										</span>{' '}
										{id}
									</Text>
								</div>
							</div>
						</div>
						<div id="videoly-videobox-placeholder" />
						<div className="productPage" />
						<div className="hidden">
							<div id="videoly-product-brand">{brand?.title}</div>
							<div id="videoly-product-id">{id}</div>
							<div id="videoly-product-sku">{productId}</div>
						</div>
						<ExpandableDescription
							heading={title}
							description={webTextFormatted}
							textLengthBreakPoint={600}
							open={hasChemicalStatements}
							descriptionClassName={contentClassName}
							beforeDescription={
								<>
									{symbolImageUrls.length > 0 && (
										<div className="mb-4 flex flex-wrap items-start sm:mb-6">
											{symbolImageUrls.map((url) => (
												<Img key={url} src={url} className="mr-4 h-14" />
											))}
										</div>
									)}
									{descriptionAdditionalTexts.map((text) => (
										<InfoBox
											key={text.id}
											icon="info"
											heading={text.title}
											className="mb-2 max-w-[25rem]"
										>
											{text.textFormatted && (
												<RichText
													html={text.textFormatted}
													onlyInternalMargin
													className="text-sm"
												/>
											)}
										</InfoBox>
									))}
								</>
							}
							afterDescription={
								chemicalInformation?.hazardPictogram && (
									<div className="mb-4 sm:mb-6">
										<WarningSymbols
											heading={chemicalInformation.signalWord}
											text={chemicalInformation.hazardStatements}
											images={warningImages}
										/>
										<Text as="p">
											<ScrollLink
												className="underline hover:no-underline"
												anchor={PRODUCT_IMPORTANT_INFO_ID}
												onClick={onImportantInfoClick}
											>
												{t('product_details_show_safety_instructions_button')}
												<Icon icon="arrow" direction="down" className="ml-1" />
											</ScrollLink>
										</Text>
									</div>
								)
							}
						/>
						{(is.arrayWithLength(technicalCategories) ||
							is.arrayWithLength(documents) ||
							packageSize) && (
							<Accordion
								id={PRODUCT_TECHNICAL_ATTRIBUTES_ID}
								title={t('product_details_technical_specification_heading')}
								titleTag="h2"
								isOpen={isTechnicalAttributesOpen}
								onClick={onTechnicalAttributesClick}
							>
								<TechnicalSpecification
									className={clsx(contentClassName, 'my-4')}
									categories={technicalCategories}
									packageSize={packageSize}
									energyLabelSrc={verticalEnergyLabel?.url?.location}
									documents={documents}
								/>
							</Accordion>
						)}
						{reviewScore && (
							<Accordion
								id={PRODUCT_REVIEWS_ID}
								title={`${t('product_details_reviews_heading')} (${
									reviewScore.count
								})`}
								titleTag="h2"
								isOpen={isReviewsOpen}
								onClick={onReviewsClick}
							>
								<Reviews
									className={contentClassName}
									isLoadingMoreReviews={Boolean(isLoadingMoreReviews)}
									hasMoreReviews={hasMoreReviews}
									onGradeClick={handleReviewGradeClick}
									onGradeResetClick={handleReviewGradeResetClick}
									onLoadMoreClick={onLoadMoreReviewsClick}
									ratingsPerGrade={[
										reviewScore.countPerGrade.grade5,
										reviewScore.countPerGrade.grade4,
										reviewScore.countPerGrade.grade3,
										reviewScore.countPerGrade.grade2,
										reviewScore.countPerGrade.grade1,
									]}
									reviewCount={reviewScore.count}
									reviewItems={reviews}
									reviewPolicyUrl={reviewPolicyUrl}
									score={reviewScore.score}
									totalReviews={reviewScore.count || 0}
									questionsSummary={questionsSummary}
									sortOption={reviewsSortOption}
									onSortOptionChange={onReviewsSortOptionChange}
									isLoading={isLoadingInitialReviews}
								/>
							</Accordion>
						)}
						{(is.arrayWithLength(productQuestions) ||
							productQuestionsNewQuestionsAllowed) && (
							<ProductQuestions
								questions={productQuestions}
								newQuestionsAllowed={productQuestionsNewQuestionsAllowed}
								hasNextPage={productQuestionsHasNextPage}
								onLoadMoreClick={onProductQuestionsLoadMoreClick}
								isOpen={isProductQuestionsOpen}
								isLoading={productQuestionsIsLoading}
								isLoadingMore={productQuestionsIsLoadingMore}
								onClick={onProductQuestionsClick}
								productName={product.title}
								productId={product.id}
							/>
						)}
						{hasAccessories && (
							<RelatedProductsAccordion
								products={accessoriesProducts}
								isLoadingInitial={accessoriesIsLoading}
								isLoadingMore={accessoriesIsLoadingMore}
								hasNextPage={accessoriesHasNextPage}
								onLoadMoreClick={onAccessoriesLoadMoreClick}
								title={t('product_details_accessories_heading')}
								accordionId={PRODUCT_ACCESSORIES_ID}
								contentClassName={contentClassName}
								initialOpen
								onAddToCart={() => {
									sendGlobalEvent('engagement', {
										type: 'addAccessoryToCart',
									});
								}}
							/>
						)}
						{hasSpareParts && (
							<RelatedProductsAccordion
								products={sparePartsProducts}
								isLoadingInitial={sparePartsIsLoading}
								isLoadingMore={sparePartsIsLoadingMore}
								hasNextPage={sparePartsHasNextPage}
								onLoadMoreClick={onSparePartsLoadMoreClick}
								title={t('product_details_spare_parts_heading')}
								accordionId={PRODUCT_SPARE_PARTS_ID}
								contentClassName={contentClassName}
								isOpen={isSparePartsOpen}
								onClick={onSparePartsClick}
							/>
						)}
						{hasBelongsTo && (
							<RelatedProductsAccordion
								products={belongsToProducts}
								isLoadingInitial={belongsToIsLoading}
								isLoadingMore={belongsToIsLoadingMore}
								hasNextPage={belongsToHasNextPage}
								onLoadMoreClick={onBelongsToLoadMoreClick}
								title={t('product_details_belong_to_heading')}
								accordionId={PRODUCT_BELONGS_TO_ID}
								contentClassName={contentClassName}
								isOpen={isBelongsToOpen}
								onClick={onBelongsToClick}
								afterListContent={
									<InfoBox
										icon="info"
										message={t(
											'product_details_belongs_to_accordion_may_have_more_products_text',
										)}
										className="mt-4"
									/>
								}
							/>
						)}
						{brand?.descriptionFormatted && (
							<Accordion
								id="about-brand"
								title={t('product_details_about_brand_heading')}
								titleTag="h2"
								isOpen={isAboutBrandOpen}
								onClick={onAboutBrandClick}
							>
								<div className={clsx(contentClassName, 'mb-6 md:mb-6')}>
									<div className="mb-6">
										{brandLogoSrc && (
											<Img
												src={brandLogoSrc}
												alt={brand.title}
												useFallbackOnError
												className="mb-6 max-h-16 max-w-[10rem]"
											/>
										)}
										<Text as="h3" className="mb-2">
											{brand.longTitle}
										</Text>
										<RichText html={brand?.descriptionFormatted} />
									</div>
									<Button href={brand.relativeUrl} variant="primary">
										{t('product_details_read_more_about_brand_button', {
											brand: brand.title,
										})}
									</Button>
								</div>
							</Accordion>
						)}
						{(is.arrayWithLength(additionalTexts) || hasChemicalStatements) && (
							<Accordion
								id={PRODUCT_IMPORTANT_INFO_ID}
								title={t('product_details_important_information_heading')}
								titleTag="h2"
								isOpen={isImportantInfoOpen}
								onClick={onImportantInfoClick}
							>
								<div className={contentClassName}>
									{is.arrayWithLength(additionalTexts) && (
										<div className="richtext">
											{additionalTexts.map((text) => (
												<React.Fragment key={text.id}>
													{text.title && (
														<Text as="h3" className="pb-2">
															{text.title}
														</Text>
													)}
													{text.textFormatted && (
														<RichText
															html={text.textFormatted}
															className="text-base"
														/>
													)}
												</React.Fragment>
											))}
										</div>
									)}
									{hasChemicalStatements && chemicalInformation && (
										<>
											{chemicalInformation.hazardStatements && (
												<Text as="h4" styleAs="h6" className="mb-2">
													{chemicalInformation.hazardStatements}
												</Text>
											)}
											{chemicalInformation.precationaryStatements && (
												<Text as="pSmall" className="mb-2">
													{chemicalInformation.precationaryStatements}
												</Text>
											)}
											{chemicalInformation.supplementalHazardStatements && (
												<Text as="pSmall" className="mb-2">
													{chemicalInformation.supplementalHazardStatements}
												</Text>
											)}
										</>
									)}
								</div>
							</Accordion>
						)}
					</div>
				</>
			</LayoutContainer>
			{similarProducts && similarProducts.length > 0 && (
				<ErrorBoundary isPageWidth>
					<LayoutContainer
						id={PRODUCT_SIMILAR_PRODUCTS_ID}
						outerClassName="mt-14 md:mt-10"
					>
						<Text as="h2" className="mb-2 md:mb-6">
							{t('product_details_similar_products_heading')}
						</Text>
						<ProductCarousel>
							{similarProducts.map((similarProduct) => (
								<ProductCard
									key={similarProduct.id}
									product={similarProduct}
									className="pr-4 md:pr-6"
								/>
							))}
						</ProductCarousel>
					</LayoutContainer>
				</ErrorBoundary>
			)}
		</>
	);
}
ProductDetails.displayName = 'ProductDetails';
