/**
 * String helpers.
 */

/** Unicode characters. https://symbl.cc */
export const ENTITIES = {
	// Not the same as hyphen.
	minus: '\u2212',
	narrowNoBreakSpace: '\u202F',
	noBreakSpace: '\u00A0',
	softHyphen: '\u00AD',
	zeroWidthSpace: '\u200B',
};

// 00AD = soft hyphen, 200B = zero width space.
export const LINE_BREAK_OPPORTUNITY_REGEX = /[\u00AD\u200B]/;
const WHITESPACE_REPLACEMENTS = [
	['-/', `-${ENTITIES.zeroWidthSpace}/`],
	['/', `/${ENTITIES.zeroWidthSpace}`],
] as const;

/**
 * Add invisible whitespace next to punctuation to enable line breaking.
 *
 * @example
 *
 * addPunctuationBreak('Förvaringshyllor/-lådor')
 * // => 'Förvaringshyllor/[hidden whitespace]-lådor'
 */
export function addPunctuationBreak(label: string): string {
	// Already has places for breaks, avoid the risk of duplicates.
	if (LINE_BREAK_OPPORTUNITY_REGEX.test(label)) {
		return label;
	}
	for (const [search, replace] of WHITESPACE_REPLACEMENTS) {
		if (label.includes(search)) {
			return label.replace(search, replace);
		}
	}
	return label;
}

/**
 * Replace regular spaces with no-break ones.
 */
export function makeSpacesNoBreak(str: string): string {
	return str.replaceAll(' ', ENTITIES.noBreakSpace);
}

/**
 * Check if all letters in a string are uppercase.
 *
 * @example
 *
 * isUppercase('HEY')
 * // => true
 *
 * isUppercase('IS 7 LUCKY?')
 * // => true
 *
 * isUppercase('No, it's NOT!')
 * // => false
 */
export function isUppercase(str: string) {
	return str === str.toUpperCase();
}

/**
 * Uppercase the first letter of the string (the rest of the string is not
 * affected).
 *
 * @example
 *
 * upperFirst('hello')
 * // => 'Hello'
 *
 * upperFirst('HEY')
 * // => 'HEY'
 */
export function upperFirst(str: string) {
	return str ? `${str[0]?.toUpperCase()}${str.slice(1)}` : '';
}

// Non-exhaustive map of characters that aren't handled by the normalization
// regex replacement. Keys are lowercase.
const SLUGIFY_CHARACTER_MAP = {
	æ: 'ae',
	ø: 'o',
	ð: 'd',
	þ: 'th',
	ł: 'l',
	ß: 'ss',
};
const SLUGIFY_REGEXP = {
	diacriticalMarks: /[\u0300-\u036F]/g,
	// Matches punctuation ranges in the Basic Latin block and the full
	// General Punctuation block.
	punctuation:
		/[\u0021-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u007F\u2000-\u206F]/g,
	characterMap: new RegExp(
		`[${Object.keys(SLUGIFY_CHARACTER_MAP).join('')}]`,
		'g',
	),
};

/**
 * Slugify a string.
 *
 * @example
 *
 * slugify('Déjà-vu, les alcaloïdes!')
 * // => 'deja-vu-les-alcaloides'
 */
export function slugify(str: string, separator: string = '-'): string {
	return (
		str
			// Decompose combined characters to separate Unicode code points.
			.normalize('NFKD')
			// Remove diacritical marks.
			.replaceAll(SLUGIFY_REGEXP.diacriticalMarks, '')
			// Replace punctuation with spaces, to make them match the separator
			// replacement below.
			.replaceAll(SLUGIFY_REGEXP.punctuation, ' ')
			.trim()
			// Consecutive whitespace to a single separator.
			.replaceAll(/\s+/g, separator)
			.toLowerCase()
			.replaceAll(
				SLUGIFY_REGEXP.characterMap,
				(char) => SLUGIFY_CHARACTER_MAP[char],
			)
	);
}

const CLEAN_ALT_TEXT_REGEXP = /(svg|logo|image)/gi;

/**
 * Remove junk from an image alt text.
 *
 * Should be the responsibility of content editors but in practice it will
 * never be perfect, might as well try to improve things a little bit for
 * screen readers.
 */
export function cleanAltText(alt: string | undefined) {
	return alt?.replaceAll(CLEAN_ALT_TEXT_REGEXP, '').trim() || '';
}
