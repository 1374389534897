import React from 'react';

import ComponentPlaceholder from 'components/ComponentPlaceholder';
import Img from 'components/Img';
import ProductAnimation from 'components/ProductAnimation';
import { useSitecoreContext } from 'hooks';
import { JulaComponentProps } from 'lib/component-props';
import { DigizuiteAssetList } from 'models/asset';
import { ProductCard as ProductCardType } from 'models/productCard';
import { getEditorMargin } from 'utils/business-logic';
import { getAsset } from 'utils/helpers';

type Props = JulaComponentProps & {
	fields: {
		products: ProductCardType[];
		squareImage: DigizuiteAssetList;
	};
};

function ProductCampaign({ params, fields }: Props) {
	const { sitecoreContext } = useSitecoreContext();
	const isEditing = sitecoreContext?.pageEditing;
	if (!fields) return null;
	const { squareImage, products } = fields;
	const squareImg = getAsset('Templated 1:1', squareImage, {
		width: 720,
		height: 720,
	});

	if (isEditing) {
		return (
			<ComponentPlaceholder
				componentName="ProductCampaign"
				className={getEditorMargin(params)}
				isDeprecated
			/>
		);
	}
	if (!squareImg) {
		return (
			<ComponentPlaceholder
				componentName="ProductCampaign"
				className={getEditorMargin(params)}
			/>
		);
	}

	return (
		<div id={params?.anchor} className={getEditorMargin(params)}>
			<div className="relative h-full w-full overflow-hidden">
				<Img
					src={squareImg?.src || ''}
					alt={squareImg?.alt || ''}
					width={squareImg?.width}
					height={squareImg?.height}
					service="nextjs"
					className="h-full w-full object-contain hover:cursor-pointer"
				/>
				{products && (
					<div className="absolute bottom-10 left-10 right-10 top-10 md:bottom-12 md:left-12 md:right-12 md:top-12 lg:bottom-16 lg:left-16 lg:right-16 lg:top-16">
						<ProductAnimation
							animatedProductArray={products}
							isBlock={false}
							isPCampaign
						/>
					</div>
				)}
			</div>
		</div>
	);
}

ProductCampaign.displayName = 'ProductCampaign';

export default ProductCampaign;
