/**
 * MicroContent - Text
 */

import React from 'react';
import clsx from 'clsx';

import Text from 'components/Text';

interface Props {
	className?: string;
	text: string;
	title: string;
}

/** Title and text on a background */
export default function MicroContentText({ className, text, title }: Props) {
	return (
		<Text
			as="div"
			className={clsx(
				'rounded-border bg-greyLighter px-4 py-6 text-base sm:px-6 sm:py-8',
				className,
			)}
		>
			<p className="mb-2 font-bold">{title}</p>
			<p className="max-sm:text-sm">{text}</p>
		</Text>
	);
}
MicroContentText.displayName = 'MicroContentText';
