/**
 * AuthJulaConnect
 */

import React from 'react';
import { useSelector } from '@xstate/react';

import Button from 'components/Button';
import LoadingSpinner from 'components/LoadingSpinner';
import Text from 'components/Text';
import { useGlobalStateContext } from 'contexts';
import {
	selectIframeSrc,
	selectIframeType,
	selectIsLoadingGuiFull,
	selectIsLoadingGuiIframe,
} from 'state-machines/authentication/';
import { useI18n } from 'utils/i18n';

/** Creates an iframe for embedding JulaConnect to handle login. */
function AuthJulaConnect() {
	const { authService } = useGlobalStateContext();

	const isLoadingGuiIframe = useSelector(authService, selectIsLoadingGuiIframe);
	const isLoadingGuiFull = useSelector(authService, selectIsLoadingGuiFull);

	const iframeSrc = useSelector(authService, selectIframeSrc);

	const iframeType = useSelector(authService, selectIframeType);
	const { t } = useI18n();

	return (
		<div className="flex h-full flex-col items-center max-sm:h-[26rem]">
			{isLoadingGuiIframe && (
				<LoadingSpinner
					variant="dashing"
					spinnerColor="julaRed"
					trackColor="transparent"
					size="medium"
				/>
			)}

			{isLoadingGuiFull && (
				<>
					<Text
						as="h2"
						styleAs="h2"
						className="mb-2 mt-4 text-greyDarker sm:mb-4 sm:mt-4"
					>
						{t('account_waiting_for_login_text')}
					</Text>

					<LoadingSpinner
						variant="dashing"
						spinnerColor="julaRed"
						trackColor="transparent"
						size="medium"
					/>

					<Button
						variant="text"
						className="!text-center"
						displayWidth="full"
						onClick={() => {
							authService.send('ABORT_AUTHENTICATION');
						}}
					>
						{t('account_cancel_login_button')}
					</Button>
				</>
			)}

			{iframeSrc && iframeType === 'gui_iframe' && (
				<iframe
					src={iframeSrc}
					className="h-full w-full"
					data-cid="jula-connect-iframe"
					title="jula-connect-iframe"
					onLoad={() => {
						authService.send('LOGIN_FORM_LOADED');
					}}
				/>
			)}
		</div>
	);
}

export default AuthJulaConnect;
AuthJulaConnect.displayName = 'AuthJulaConnect';
