import React from 'react';
import type { Field } from '@sitecore-jss/sitecore-jss-nextjs';

import CreditApplication from 'components/CreditApplication';
import type { JulaComponentProps } from 'lib/component-props';
import type { FormFields } from 'models/form';
import { useI18n } from 'utils/i18n';

type Props = JulaComponentProps & {
	fields: {
		heading: Field<string>;
		description: Field<string>;
		content: Field<string>;
		name: Field<string>;
		submitText: Field<string>;
		form: FormFields;
	};
};

export default function AccountJulaClubCreditApplicationForm({
	fields,
}: Props) {
	const { heading, description, content, submitText, form } = fields;
	const { t } = useI18n();
	return (
		<CreditApplication
			heading={heading.value}
			description={description.value}
			content={content?.value}
			form={form}
			eventType="JULA_CLUB_APPLY_FOR_CREDIT"
			submitText={submitText?.value}
			successHeading={t('account_details_apply_credit_success_heading')}
			successCreditLimitKey="account_details_credit_limit_text"
			successText={t('account_details_apply_credit_success_text')}
			failHeading={t('account_details_apply_credit_fail_heading')}
			failTextKey={t('account_details_apply_credit_fail_text')}
			signingCancelText={t('account_details_apply_credit_signing_cancel_text')}
			signingCancelHeading={t('account_details_apply_credit_fail_heading')}
			cancelButtonText={t('account_details_cancel_button')}
			continueButtonText={t('account_details_continue_button')}
			loadingText={t('account_details_credit_apply_load_text')}
		/>
	);
}
AccountJulaClubCreditApplicationForm.displayName =
	'AccountJulaClubCreditApplicationForm';
