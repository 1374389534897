import React from 'react';

import { LayoutContainer } from 'components/Layout';
import Text from 'components/Text';
import { JulaComponentProps } from 'lib/component-props';
import { getEditorMargin } from 'utils/business-logic';

type ContentIntroductionProps = JulaComponentProps & {
	fields?: {
		heading: {
			value: string;
			editable?: string;
		};
		description: {
			value: string;
			editable?: string;
		};
	};
};

function ContentIntroduction({ fields, params }: ContentIntroductionProps) {
	if (!fields) return null;
	const { heading, description } = fields;
	return (
		<LayoutContainer
			id={params?.anchor}
			withGrid
			outerClassName={getEditorMargin(params)}
		>
			<div className="col-span-4 sm:col-span-6 sm:col-start-2 md:col-span-6 md:col-start-4">
				<Text as="h1" field={heading} />
				<Text as="pLarge" field={description} />
			</div>
		</LayoutContainer>
	);
}

export default ContentIntroduction;
ContentIntroduction.displayName = 'ContentIntroduction';
