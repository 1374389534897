import React, {
	type KeyboardEventHandler,
	type MouseEventHandler,
	type RefObject,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import clsx from 'clsx';

import IconButton from 'components/IconButton';
import { LayoutContainer } from 'components/Layout';
import Link from 'components/Link';
import { Skeleton, SkeletonItem } from 'components/Skeleton';
import { StoreSelectorButton } from 'components/StoreSelector';
import { HEADER_MAIN_NAVIGATION_ID } from 'constants/ids';
import { useMinWidth, useRouteChange } from 'hooks';
import type { Link as LinkType, MainLink } from 'models/pageHeader';
import { is } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

import {
	getLinkPath,
	isLargeDropdown,
	isLinkVisible,
	SCROLL_LOCK_WHITELIST_CLASS,
	useMainMenu,
} from './helpers';
import PageHeaderDropdownToggle, {
	type CloseHandler as DropdownItemCloseHandler,
	type OpenHandler as DropdownItemOpenHandler,
} from './PageHeaderDropdownToggle';
import type { DropdownLinkClickHandler } from './PageHeaderLinkListItem';

const MENU_ITEM_CLASSES =
	'flex w-full pl-8 pr-5 py-3 md:p-3 md:pb-6 hover:underline';
const MAIN_MENU_ITEM_CLASSES = `${MENU_ITEM_CLASSES} items-center justify-between font-bold`;
const MENU_OVERLAY_CLASS = 'menu-overlay';

interface Props {
	/** If the small screen navigation menu is open */
	isToggledMenuOpen: boolean;

	/** Primary menu tree */
	mainMenu?: MainLink[];

	/** Click handler for store selector button */
	onStoreSelectorClick: () => void;

	/** Click handler for the small screen navigation menu close button */
	onToggledMenuCloseClick: MouseEventHandler<HTMLButtonElement>;

	/** Secondary menu links */
	secondaryMenu?: LinkType[];

	/** Extra secondary menu links on small screens */
	smallScreenSecondaryMenuExtra?: LinkType[];

	/** Ref for the small screen navigation menu close button */
	toggledMenuCloseButtonRef: RefObject<HTMLButtonElement>;

	/**
	 * Ref for the main part of the small screen navigation menu that should
	 * close when clicking outside of it
	 */
	toggledMenuContainerRef: RefObject<HTMLDivElement>;
}

/** Create a callback for array find/findIndex that matches the link text. */
const makeFindMainLinkByText =
	(text: string): ((link: MainLink) => boolean) =>
	({ fields }) =>
		[fields.link?.text, fields.title].includes(text);

const makeSecondaryItemMapper =
	(isSmallScreen: boolean) => (link: LinkType) => ({
		link,
		isSmallScreen,
	});

/** Header main navigation */
export default function PageHeaderNav({
	isToggledMenuOpen,
	mainMenu,
	onToggledMenuCloseClick,
	secondaryMenu,
	smallScreenSecondaryMenuExtra,
	toggledMenuCloseButtonRef,
	toggledMenuContainerRef,
	onStoreSelectorClick,
}: Props) {
	const { t } = useI18n();
	const rootNavRef = useRef<HTMLElement>(null);
	const toggledMenuBackButtonRef = useRef<HTMLButtonElement>(null);
	const menuItemsRef = useRef<(HTMLAnchorElement | HTMLButtonElement | null)[]>(
		[],
	);

	// Text for a top level link that has triggered a dropdown menu. The top level
	// menu items don't have an ID to save.
	const [openDropdownLinkText, setOpenDropdownLinkText] = useState('');
	// ID for a link inside a dropdown menu.
	const [selectedDropdownLinkId, setSelectedDropdownLinkId] = useState('');
	const isLargeScreen = useMinWidth('md');
	const hasToggledMenuBackButton = Boolean(
		!isLargeScreen && openDropdownLinkText,
	);

	const mainMenuItems = useMainMenu(mainMenu);
	const mainSecondaryLinks = (secondaryMenu ?? []).filter(isLinkVisible);
	const smallScreenSecondaryLinks = (
		smallScreenSecondaryMenuExtra ?? []
	).filter(isLinkVisible);
	const secondaryMenuItems = [
		...mainSecondaryLinks.map(makeSecondaryItemMapper(false)),
		...smallScreenSecondaryLinks.map(makeSecondaryItemMapper(true)),
	];
	const hasMenuItems =
		mainMenuItems.length > 0 || secondaryMenuItems.length > 0;

	// The currently selected main item's link tree.
	const selectedDropdownLinkTree = useMemo(
		() =>
			openDropdownLinkText
				? mainMenuItems.find(makeFindMainLinkByText(openDropdownLinkText))
						?.fields.links ?? []
				: [],
		[openDropdownLinkText, mainMenuItems],
	);
	// The currently selected links inside the dropdown.
	const selectedDropdownLinksPath = useMemo(
		() => getLinkPath(selectedDropdownLinkId, selectedDropdownLinkTree),
		[selectedDropdownLinkId, selectedDropdownLinkTree],
	);

	const resetDropdown = useCallback(() => {
		setOpenDropdownLinkText('');
		setSelectedDropdownLinkId('');
	}, []);

	// Close the dropdown on navigation.
	useRouteChange('routeChangeStart', resetDropdown);

	// Body scroll lock on small screens
	useEffect(() => {
		if (!isLargeScreen && isToggledMenuOpen && rootNavRef.current) {
			disableBodyScroll(rootNavRef.current, {
				allowTouchMove: (el) =>
					el ? Boolean(el.closest(`.${SCROLL_LOCK_WHITELIST_CLASS}`)) : false,
			});
		} else {
			clearAllBodyScrollLocks();
		}
	}, [isLargeScreen, isToggledMenuOpen]);

	// Page overlay on large screens
	useEffect(() => {
		if (isLargeScreen && openDropdownLinkText) {
			document.body.classList.add(MENU_OVERLAY_CLASS);
		} else {
			document.body.classList.remove(MENU_OVERLAY_CLASS);
		}
	}, [isLargeScreen, openDropdownLinkText]);

	const handleDropdownOpen: DropdownItemOpenHandler = useCallback(
		(linkText) => {
			setOpenDropdownLinkText(linkText);
		},
		[],
	);

	const handleDropdownClose: DropdownItemCloseHandler = useCallback(() => {
		// Close callback for 'click outside' and such. Only react to it on large
		// screens since the small screen menu has a back button for the dropdowns
		// and its own close button and 'click outside' handling.
		if (isLargeScreen) {
			resetDropdown();
		}
	}, [isLargeScreen, resetDropdown]);

	const handleDropdownLinkClick: DropdownLinkClickHandler = useCallback(
		(linkId) => {
			// Behave like a toggle button where activating the currently active one
			// closes it. Do it by setting the 'previous step' as active, if any.
			// If an index is found, the pressed link is part of the currently
			// selected path.
			const selectedIndex = selectedDropdownLinksPath.findIndex(
				(link) => link.id === linkId,
			);
			setSelectedDropdownLinkId(
				selectedIndex === -1
					? linkId
					: selectedDropdownLinksPath[selectedIndex - 1]?.id || '',
			);
		},
		[selectedDropdownLinksPath],
	);

	// Dropdown menu item on large screens, move focus out of the dropdown
	// onto the next link.
	const handleLargeScreenMenuDropdownLastLinkTab: KeyboardEventHandler = (
		e,
	) => {
		if (!e.shiftKey) {
			const currentIndex = mainMenuItems.findIndex(
				makeFindMainLinkByText(openDropdownLinkText),
			);
			const target =
				currentIndex === -1 ? null : menuItemsRef.current[currentIndex + 1];
			if (target) {
				e.preventDefault();
				target.focus();
			}
		}
	};

	// The first level is the main menu itself, subsequent levels drill into
	// a dropdown like the 'products' menu.
	const handleToggledMenuBackClick = () => {
		if (selectedDropdownLinkId) {
			const selectedLevel = selectedDropdownLinksPath.length;
			// Level - 1 for index, - 1 again for previous level.
			setSelectedDropdownLinkId(
				selectedLevel > 1
					? selectedDropdownLinksPath[selectedLevel - 2]?.id || ''
					: '',
			);
		} else if (openDropdownLinkText) {
			setOpenDropdownLinkText('');
			// Button will disappear, move focus to close button.
			toggledMenuCloseButtonRef.current?.focus();
		}
	};

	// Back or close button.
	const handleToggledMenuFirstButtonKeyDown: KeyboardEventHandler = (e) => {
		if (e.key === 'Tab' && e.shiftKey) {
			e.preventDefault();
			// The last link in the currently visible list can be located several
			// component levels down. Find it via DOM selection to avoid some really
			// convoluted ref management.
			if (openDropdownLinkText) {
				const openMenuItem =
					menuItemsRef.current[
						mainMenuItems.findIndex(
							makeFindMainLinkByText(openDropdownLinkText),
						)
					];
				if (openMenuItem) {
					const openListLinks = openMenuItem.parentNode
						?.querySelector('[data-open="true"]')
						?.querySelectorAll('a');
					if (openListLinks && openListLinks.length > 0) {
						openListLinks[openListLinks.length - 1]?.focus();
					}
				}
			} else {
				menuItemsRef.current[menuItemsRef.current.length - 1]?.focus();
			}
		}
	};

	// Top level menu item on small screens, loop focus back to the top of the menu.
	const handleToggledMenuLastItemKeyDown: KeyboardEventHandler = (e) => {
		if (e.key === 'Tab' && !e.shiftKey) {
			e.preventDefault();
			toggledMenuCloseButtonRef.current?.focus();
		}
	};

	// Dropdown menu item on small screens, loop focus back to the top of the menu.
	const handleToggledMenuDropdownLastLinkTab: KeyboardEventHandler = (e) => {
		if (!e.shiftKey) {
			e.preventDefault();
			if (hasToggledMenuBackButton) {
				toggledMenuBackButtonRef.current?.focus();
			} else {
				toggledMenuCloseButtonRef.current?.focus();
			}
		}
	};

	// Hide-ish the top level links when a dropdown panel is open on small
	// screens, to prevent focus from going behind it.
	const menuItemProps = hasToggledMenuBackButton
		? { 'tabIndex': -1, 'aria-hidden': true }
		: {};

	// On large screens it's a horizontal bar of links.
	// On small screens it's a toggled menu panel that slides in from the side.
	return (
		<nav
			ref={rootNavRef}
			className={clsx(
				'page-header-part',
				[
					'max-md:fixed',
					'max-md:inset-0',
					'max-md:z-toggledMenu',
					'max-md:transition-toggledMenuContainer',
					!isToggledMenuOpen && [
						'max-md:pointer-events-none',
						'max-md:invisible',
						'max-md:bg-blackOpacity0',
						'max-md:delay-closedToggledMenuContainer',
					],
					isToggledMenuOpen && 'max-md:bg-blackOpacity20',
				],
				[
					'md:relative',
					'md:border-b',
					'md:border-b-greyLighter',
					'md:bg-white',
				],
			)}
			id={HEADER_MAIN_NAVIGATION_ID}
			aria-label={t('header_primary_menu_label')}
			role={isLargeScreen ? undefined : 'dialog'}
			aria-modal={isLargeScreen ? undefined : 'true'}
		>
			<LayoutContainer
				ref={toggledMenuContainerRef}
				outerClassName={clsx(
					'h-full bg-white',
					[
						'max-md:shadow-center',
						'max-md:w-11/12',
						'max-md:max-w-[400px]',
						'max-md:ml-auto',
						'max-md:mr-0',
						'max-md:overflow-hidden',
						'max-md:transition-toggledMenuPanel',
					],
					!isToggledMenuOpen && [
						'max-md:invisible',
						'max-md:translate-x-5',
						'max-md:opacity-0',
						'max-md:delay-closedToggledMenuPanel',
					],
				)}
				className="relative h-full max-md:flex max-md:flex-col"
			>
				{/* Small screen menu buttons */}
				<div className="flex border-b border-b-greyLight p-2 md:hidden">
					<IconButton
						ref={toggledMenuBackButtonRef}
						icon="arrow"
						iconDirection="left"
						text={t('header_product_listings_back_button')}
						visibleText
						onClick={handleToggledMenuBackClick}
						onKeyDown={handleToggledMenuFirstButtonKeyDown}
						className={clsx(
							'transition-toggledMenuPanel',
							!hasToggledMenuBackButton &&
								'invisible translate-x-3 opacity-0 delay-closedToggledMenuPanel',
						)}
						textClassName="text-sm"
					/>
					<IconButton
						ref={toggledMenuCloseButtonRef}
						icon="close"
						text={t('mobile_menu_close_text')}
						className="ml-auto"
						onClick={onToggledMenuCloseClick}
						onKeyDown={
							hasToggledMenuBackButton
								? undefined
								: handleToggledMenuFirstButtonKeyDown
						}
					/>
				</div>

				{!hasMenuItems && (
					<Skeleton className="flex h-full items-center pb-6 pt-3 max-md:hidden">
						{[120, 70, 100, 85, 110].map((width, i) => (
							<SkeletonItem
								// eslint-disable-next-line react/no-array-index-key
								key={i}
								width={`${width}px`}
								height="1.5625rem"
								className="mr-8"
							/>
						))}
					</Skeleton>
				)}

				{hasMenuItems && (
					<div
						className={clsx(
							'max-md:relative max-md:grow max-md:overflow-x-hidden max-md:overscroll-contain max-md:py-5 md:flex md:flex-wrap md:items-center',
							openDropdownLinkText && 'max-md:overflow-y-hidden',
							!openDropdownLinkText && 'max-md:overflow-y-auto',
							SCROLL_LOCK_WHITELIST_CLASS,
						)}
					>
						<ul className="md:-ml-3 md:flex md:items-center md:gap-x-2">
							{mainMenuItems.map(({ fields }, i) => {
								const { inspirationLink, link, links, title } = fields;
								const linkText = title || link?.text;
								if (!linkText) {
									return null;
								}
								const hasDropdown = is.arrayWithLength(links);

								return (
									<li
										key={title || link?.id}
										className={clsx(
											'text-lg',
											hasDropdown && !isLargeDropdown(links) && 'md:relative',
										)}
									>
										{hasDropdown && (
											<PageHeaderDropdownToggle
												ref={(el) => {
													menuItemsRef.current[i] = el;
												}}
												className={MAIN_MENU_ITEM_CLASSES}
												dropdownLinks={links}
												inspirationLink={inspirationLink}
												isLargeScreen={isLargeScreen}
												isOpen={openDropdownLinkText === linkText}
												onClose={handleDropdownClose}
												onLinkClick={handleDropdownLinkClick}
												onOpen={handleDropdownOpen}
												onLargeScreenMenuLastLinkTab={
													handleLargeScreenMenuDropdownLastLinkTab
												}
												onToggledMenuLastLinkTab={
													handleToggledMenuDropdownLastLinkTab
												}
												selectedDropdownLinkId={selectedDropdownLinkId}
												text={linkText}
												{...menuItemProps}
											/>
										)}
										{!hasDropdown && link?.href && (
											<Link
												href={link.href}
												ref={(el) => {
													menuItemsRef.current[i] = el;
												}}
												target={link.target}
												className={MAIN_MENU_ITEM_CLASSES}
												{...menuItemProps}
											>
												{linkText}
											</Link>
										)}
									</li>
								);
							})}
						</ul>

						<ul className="max-md:mt-6 md:ml-auto md:flex md:items-center md:gap-x-2">
							{secondaryMenuItems.map(({ link, isSmallScreen }, i) => {
								const { fields } = link;
								if (!fields?.link?.href) {
									return null;
								}
								const lastIndexSmall = secondaryMenuItems.length - 1;
								return (
									<li
										key={fields.link.id || fields.link.text}
										className={clsx('text-base', isSmallScreen && 'md:hidden')}
									>
										<Link
											href={fields.link.href}
											ref={(el) => {
												menuItemsRef.current[mainMenuItems.length + i] = el;
											}}
											onKeyDown={
												!isLargeScreen && i === lastIndexSmall
													? handleToggledMenuLastItemKeyDown
													: undefined
											}
											target={fields.link.target}
											className={MENU_ITEM_CLASSES}
											{...menuItemProps}
										>
											{fields.link.text}
										</Link>
									</li>
								);
							})}
							<StoreSelectorButton
								container="li"
								onClick={onStoreSelectorClick}
								// Pull the right edge to visually align it.
								className="max-md:hidden md:-mr-3"
								buttonClassName={MENU_ITEM_CLASSES}
							/>
						</ul>
					</div>
				)}
			</LayoutContainer>
		</nav>
	);
}
PageHeaderNav.displayName = 'PageHeaderNav';
