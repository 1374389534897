/**
 * BlockHero
 */

import React from 'react';
import clsx from 'clsx';

import Icon from 'components/Icon';
import Img from 'components/Img';

import styles from './BlockPromoCampaign.module.css';

interface Props {
	/** Description of this property */
	linkText?: string;

	/** Color of link text */
	linkTextColor?: 'white' | 'greyDarker';

	/** Description of this property */
	url?: string;

	/** Check if there is any video, if not render fallback image */
	video?: string;

	/** Src for image used for fallback if there is no video, also used in block hero for large devices */
	imageSrc: string;

	/** Alt for image */
	imageAlt: string;

	/** Color of rounded arrow */
	iconColor?: 'black' | 'white';

	/** The animated product prop that sends an component if information is filled about the price and has an image. */
	animatedProduct?: any;

	onClick?: any;
}

/** Promo campaign block component. */
export default function BlockPromoCampaign({
	linkText,
	linkTextColor = 'white',
	url,
	video,
	imageSrc,
	imageAlt,
	iconColor = 'white',
	animatedProduct,
	onClick,
}: Props) {
	return (
		<div className="relative w-full">
			<div className="relative block overflow-hidden">
				{video && (
					<div className="relative">
						<video
							muted
							autoPlay
							loop
							playsInline
							className={clsx(
								'w-full',
								'block',
								'object-cover',
								'max-h-[30rem]',
								styles['video-sizing'],
							)}
						>
							<source src={video} />
							{imageSrc && (
								<div className={clsx('w-full', styles['image-sizing'])}>
									<a href={url || '#'} onClick={onClick}>
										<Img
											src={imageSrc}
											alt={imageAlt}
											width={1920}
											height={480}
											service="nextjs"
											priority
											className="max-h-[30rem] w-full object-contain hover:cursor-pointer"
										/>
									</a>
								</div>
							)}
						</video>
					</div>
				)}

				{!video && imageSrc && (
					<div
						className={clsx('w-full max-sm:hidden', styles['image-wrapper'])}
					>
						<a href={url || '#'} onClick={onClick}>
							<Img
								src={imageSrc}
								alt={imageAlt}
								width={1920}
								height={480}
								service="nextjs"
								priority
								className="max-h-[30rem] w-full object-cover hover:cursor-pointer"
							/>
						</a>
					</div>
				)}
				<div className="relative bottom-0 left-0 right-0 top-0 flex justify-center">
					<div className="absolute flex w-full justify-center">
						<div className="absolute bottom-4 inline-flex max-h-16 max-w-[60rem] items-center text-transparent hover:cursor-pointer">
							<a href={url || '#'} onClick={onClick}>
								<Icon
									icon="arrow"
									color={iconColor === 'white' ? 'white' : 'black'}
									className={clsx(
										iconColor === 'white' ? 'bg-julaRed' : 'bg-white',
										'rounded-full',
										'mr-2',
									)}
								/>
								<span
									className={clsx(
										'text-base hover:underline',
										linkTextColor === 'white'
											? 'text-white'
											: 'text-greyDarker',
									)}
								>
									{linkText}
								</span>
							</a>
						</div>
					</div>
				</div>
			</div>
			{animatedProduct && (
				<div
					className={clsx(
						'absolute max-sm:hidden',
						styles['animated-product-wrapper'],
					)}
				>
					{animatedProduct}
				</div>
			)}
		</div>
	);
}
BlockPromoCampaign.displayName = 'BlockPromoCampaign';
