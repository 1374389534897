import React, { useState } from 'react';

import Button from 'components/Button';
import Popover from 'components/Popover';
import Price from 'components/Price';
import ProductCard from 'components/ProductCard';
import { Skeleton, SkeletonItem } from 'components/Skeleton';
import { useCampaignProducts } from 'hooks';
import { CampaignResponse } from 'models/product';
import { ProductCard as ProductCardType } from 'models/productCard';
import { ButtonStateWithId } from 'state-machines/cart';
import { formatDate } from 'utils/format';
import { range } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

interface Props {
	campaigns: CampaignResponse[] | undefined;
	onPurchaseButtonClick: (variant: ProductCardType) => void;
	purchaseButtonState: ButtonStateWithId;
}
export default function ProductDetailsCampaignPopover({
	campaigns,
	onPurchaseButtonClick,
	purchaseButtonState,
}: Props) {
	const { t } = useI18n();
	const [selectedCampaign, setSelectedCampaign] = useState<
		CampaignResponse | undefined
	>();
	const filteredCampaigns = campaigns?.filter(
		(campaign) => campaign.discountType !== 'BuyAllFixedPrice',
	);

	const { items, isLoading } = useCampaignProducts(
		selectedCampaign?.id,
		Boolean(selectedCampaign),
	);

	if (!filteredCampaigns) {
		return null;
	}

	return (
		<div className="mt-4 flex flex-col gap-4">
			{filteredCampaigns.map((campaign) => (
				<div key={campaign.id} className="flex flex-col gap-4">
					<Price
						price={campaign.subtitle}
						size="microCompact"
						theme="superCheap"
					/>
					<span>
						<span className="font-bold">
							{t('product_details_campaign_button')}
						</span>{' '}
						<Button
							variant="text"
							onClick={() => {
								setSelectedCampaign(campaign);
							}}
						>
							{campaign.title}
						</Button>
					</span>
					{campaign.validTo && (
						<p className="text-julaRed">
							{t('product_details_campaign_valid_to_text', {
								validTo: formatDate(campaign.validTo),
							})}
						</p>
					)}
				</div>
			))}
			<Popover
				isOpen={Boolean(selectedCampaign)}
				onClose={() => setSelectedCampaign(undefined)}
				id={selectedCampaign?.id}
				title={selectedCampaign?.title || ''}
			>
				{isLoading && (
					<Skeleton className="space-y-8">
						{range(3).map((i) => (
							<div key={i} className="flex items-start gap-4">
								<SkeletonItem width="96px" height="96px" />
								<SkeletonItem height="7.5rem" className="grow" />
							</div>
						))}
					</Skeleton>
				)}
				{!isLoading &&
					items?.map((product) => (
						<ProductCard
							orientation="row"
							product={product}
							key={product.id}
							showAddToCartButton
							actionButtonState={
								product.id === purchaseButtonState.buttonId
									? purchaseButtonState.state
									: undefined
							}
							onPurchaseButtonClick={() => onPurchaseButtonClick(product)}
						/>
					))}
			</Popover>
		</div>
	);
}

ProductDetailsCampaignPopover.displayName = 'ProductDetailsCampaignPopover';
