import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-nextjs';

import { LayoutContainer } from 'components/Layout';
import { JulaComponentProps } from 'lib/component-props';
import { getEditorMargin } from 'utils/business-logic';

function Splitter({ params, rendering }: JulaComponentProps) {
	return (
		<div id={params?.anchor} className={getEditorMargin(params)}>
			<LayoutContainer>
				<div className="grid grid-flow-col grid-cols-4 gap-4 gap-y-0 sm:grid-cols-8 sm:gap-6 sm:gap-y-0 lg:grid-cols-12">
					<div className="col-span-full sm:col-span-4 sm:col-start-1 lg:col-start-3">
						<Placeholder name="jula-layout-left" rendering={rendering} />
					</div>
					<div className="col-span-full sm:col-span-4 sm:col-start-5 lg:col-start-7">
						<Placeholder name="jula-layout-right" rendering={rendering} />
					</div>
				</div>
			</LayoutContainer>
		</div>
	);
}

Splitter.displayName = 'Splitter';

export default Splitter;
