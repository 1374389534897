/**
 * Summary
 */

import React from 'react';
import clsx from 'clsx';

type Variant = 'cart' | 'checkout' | 'confirmation';

interface Props {
	/** React children */
	children?: React.ReactNode;

	/** Sets the variant of the wrapper of the summary */
	variant: Variant;

	/** Pass optional classnames for the html */
	className?: string;
}

/** The cart summary component used in the cart page. */
export default function Summary({ variant, className, children }: Props) {
	return (
		<div
			className={clsx(
				variant === 'cart' &&
					'-mt-8 flex flex-col rounded-lg md:mt-0 md:border md:border-grey md:px-6 md:pb-6 md:pt-6',
				variant === 'checkout' && 'rounded-lg md:p-6 md:shadow-summaryShadow',
				variant === 'confirmation' &&
					'rounded border-greyLighterDarkenPlus md:border md:px-6 md:py-8',
				className,
			)}
		>
			{children}
		</div>
	);
}
Summary.displayName = 'Summary';
