/**
 * Confirmation
 */

import React from 'react';

import Link from 'components/Link';
import Text from 'components/Text';
import {
	ApiJulaModelsCartCartResponseDiscountModel,
	ApiJulaModelsCartCartResponseGiftCardModel,
	ApiJulaModelsCartSummaryModel,
} from 'models/api';

import PriceSummaries from './PriceSummaries';

interface Props {
	/** React children */
	children?: React.ReactNode;

	/** Heading text of the confirmation summary component */
	heading?: string;

	/** The heading of the link text in the header */
	headingLinkText?: string;

	/** Summaries for the cart, used by the summary component */
	summaries: ApiJulaModelsCartSummaryModel[] | undefined;
	/** All gift cards added to the cart, used by the summary component */
	giftCards: ApiJulaModelsCartCartResponseGiftCardModel[] | undefined;
	/** All bonus discount codes added to the cart, used by the summary component */
	bonusDiscounts: ApiJulaModelsCartCartResponseDiscountModel[] | undefined;
	/** Total savings, if any, used by the summary component */
	savingTotalSumSummary: ApiJulaModelsCartSummaryModel | undefined;
}

/** The confirmation summary component used in the cart page. */
export default function ConfirmationSummary({
	heading,
	headingLinkText,
	children,
	summaries,
	giftCards,
	bonusDiscounts,
	savingTotalSumSummary,
}: Props) {
	return (
		<>
			<div className="flex items-baseline justify-between">
				<Text as="h3" styleAs="h5" className="font-bold text-greyDarker ">
					{heading}
				</Text>
				{headingLinkText && (
					<Link href="/varukorg">
						<Text
							as="pSmall"
							className="text-greyDarker  underline hover:no-underline"
						>
							{headingLinkText}
						</Text>
					</Link>
				)}
			</div>

			{children}

			<PriceSummaries
				className="mt-4"
				summaries={summaries}
				bonusDiscounts={bonusDiscounts}
				savingTotalSumSummary={savingTotalSumSummary}
				giftCards={giftCards}
				summaryLargeTitle
			/>
		</>
	);
}
ConfirmationSummary.displayName = 'ConfirmationSummary';
