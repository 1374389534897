/**
 * GiftCardButton
 */

import React, { type MouseEventHandler } from 'react';

import Button from 'components/Button';
import Icon from 'components/Icon';

interface Props {
	isExpanded: boolean;
	onClick: MouseEventHandler<HTMLButtonElement>;
	text: string;
}

/** Button component with plus/arrow icon */
export default function GiftCardButton({ isExpanded, onClick, text }: Props) {
	return (
		<Button variant="text" onClick={onClick} className="group/gift-card-button">
			<Icon
				icon={isExpanded ? 'arrow' : 'plus'}
				color={isExpanded ? 'white' : 'black'}
				direction="up"
				backgroundColor={isExpanded ? 'julaRed' : 'greyLight'}
				className={
					isExpanded
						? 'group-hover/gift-card-button:bg-julaRedDarken'
						: 'group-hover/gift-card-button:bg-greyLightDarken'
				}
			/>
			{text}
		</Button>
	);
}
GiftCardButton.displayName = 'GiftCardButton';
