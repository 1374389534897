import React from 'react';

import ActionLink from 'components/ActionLink';
import Link from 'components/Link';

import { type VisibleLink } from './helpers';

interface Props {
	className?: string;
	itemClassName?: string;
	links: VisibleLink[];
}

/** A list of regular and/or action links. */
export default function PageFooterLinks({
	className,
	itemClassName,
	links,
}: Props) {
	if (links.length === 0) {
		return null;
	}

	return (
		<ul className={className}>
			{links.map((link) => (
				<li key={`${link.text}-${link.href}`} className={itemClassName}>
					{'type' in link ? (
						<ActionLink
							key={`${link.type}-${link.text}`}
							buttonVariant="text"
							className="text-left"
							{...link}
						/>
					) : (
						<Link
							key={`${link.href}-${link.text}`}
							href={link.href}
							target={link.target}
							// Flex and items-center looks unnecessary here but it's used
							// to make text alignment the same as ActionLink. Otherwise
							// it's off by a pixel or so in some browsers.
							className="inline-flex min-h-[2rem] items-center py-1 hover:underline"
						>
							{link.text}
						</Link>
					)}
				</li>
			))}
		</ul>
	);
}
PageFooterLinks.displayName = 'PageFooterLinks';
