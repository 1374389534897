import React from 'react';
import { Field } from 'react-final-form';
import clsx from 'clsx';

import ActionButton from 'components/ActionButton';
import Button from 'components/Button';
import GenericForm from 'components/GenericForm';
import { Step, Stepper } from 'components/Stepper';
import type { Section } from 'models/form';
import type { Worksite } from 'state-machines/createJulaProCustomer';
import { empty } from 'utils/helpers';

import {
	renderDescription as baseRenderDescription,
	renderHeader as baseRenderHeader,
} from './helpers';

interface Props {
	renderDescription: typeof baseRenderDescription;
	renderHeader: typeof baseRenderHeader;
	selectCredit: (credit: number) => void;
	selectedCredit: number | null;
	resetToSelectOrganization: () => void;
	selectedWorksite: Worksite | null;
	section: Section | undefined;
}

export default function StepTwoContent({
	renderHeader,
	resetToSelectOrganization,
	renderDescription,
	selectCredit,
	selectedCredit,
	selectedWorksite,
	section,
}: Props) {
	if (!section) {
		return null;
	}

	const {
		submitText,
		submitTextVariation,
		heading,
		description,
		form,
		backText,
	} = section;

	const withCreditButtonText = submitText.value;
	const withoutCreditButtonText = submitTextVariation.value;

	return (
		<>
			<div className="mx-8 mt-6">
				<Stepper className="mt-4 flex items-center">
					<Step number={1} status="done" />
					<Step number={2} status="active" />
					<Step number={3} status="pending" />
				</Stepper>
			</div>
			{renderHeader(heading.value, 'mt-9')}
			{renderHeader(selectedWorksite?.companyName, 'mb-2')}
			{renderDescription(
				description.value,
				'',
				'mb-8 p-0 text-greyDarker sm:py-0',
			)}

			<GenericForm
				inputSpace="my-4"
				fields={form}
				initialValues={
					selectedCredit ? { creditLimit: selectedCredit } : undefined
				}
				onSubmitCallback={empty.func}
				button={
					<Field name="creditLimit">
						{({ input: { value } }) => (
							<ActionButton
								data-cid="julaproContinue"
								displayWidth="full"
								type="submit"
								size="large"
								variant="cta"
								className="my-6"
								onClick={() => selectCredit(Number.parseInt(value, 10))}
							>
								{value > 0 ? withoutCreditButtonText : withCreditButtonText}
							</ActionButton>
						)}
					</Field>
				}
			/>

			<div className="flex justify-center">
				<Button
					variant="text"
					size="small"
					onClick={() => resetToSelectOrganization()}
				>
					{backText.value}
				</Button>
			</div>
		</>
	);
}
StepTwoContent.displayName = 'AccountJulaProSignupForm_StepTwoContent';
