import React from 'react';

import type { MainLinkFields } from 'models/pageHeader';
import { is } from 'utils/helpers';

interface Props {
	level: number;
	links: MainLinkFields[];
}

/** Hidden recursive tree of links, for SEO purposes */
export default function HiddenLinkList({ level, links }: Props) {
	return (
		<ul className="hidden">
			{links.map((link) => (
				<li key={link.id}>
					{/* No need for next/link here since it's hidden. */}
					<a href={link.href}>{link.text}</a>
					{is.arrayWithLength(link.links) && level < 3 && (
						<HiddenLinkList links={link.links} level={level + 1} />
					)}
				</li>
			))}
		</ul>
	);
}
HiddenLinkList.displayName = 'HiddenLinkList';
