/**
 * JulaClubSignupForm
 */

import React, { useEffect } from 'react';
import { useSelector } from '@xstate/react';
import clsx from 'clsx';
import { waitFor } from 'xstate/lib/waitFor';

import type { ActionButtonState } from 'components/ActionButton';
import { getValuesForSubmit } from 'components/GenericForm';
import { ErrorMessageInfoBox } from 'components/GlobalPopover';
import Popover from 'components/Popover';
import { useGlobalStateContext } from 'contexts';
import { FormFields } from 'models/form';
import {
	CreateJulaClubCustomerMachineActor,
	handledBusinessLogicErrors,
	selectAddCustomerButtonState,
	selectAlreadyMember,
	selectBankIdSignUrl,
	selectCreatingCustomer,
	selectCreditApplicationData,
	selectCustomerCreationDone,
	selectCustomerNotCreatedState,
	selectDisplayBankIdSigningModal,
	selectErrorOpeningSignWindow,
	selectFirstName,
} from 'state-machines/createJulaClubCustomer';
import { is } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

import AlreadyMemberView from './AlreadyMemberView';
import CustomerCreationDoneView from './CustomerCreationDoneView';
import CustomerNotCreatedView from './CustomerNotCreatedView';
import FormView from './FormView';
import LoadingView from './LoadingView';

interface SignUpFormProps {
	displayBankIdSigningModal: boolean;
	signFrameHeading: string;
	bankIdSignUrl: string | undefined;
	alreadyMember: boolean;
	waitingForCustomerToBeCreated: boolean;
	customerNotCreated: boolean;
	customerCreationDone: boolean;
	formHeading: string;
	formDescription: string;
	formSubmitButtonText: string;
	fields: FormFields;
	customState: ActionButtonState;
	onSubmitCallback: (genericFormData) => Promise<any>;
	membershipCreatedHeading: string;
	creditApplicationData:
		| {
				CustomerId?: string | undefined;
				CreditResult?:
					| 'Approved'
					| 'Denied'
					| 'Canceled'
					| 'Failed'
					| undefined;
				CreditLimit?: string | undefined;
		  }
		| undefined;
	membershipWithCreditApprovedText: string;
	membershipWithCreditDeniedText: string;
	membershipCreationCanceledText: string;
	membershipCreatedText: string;
	membershipCreatedButtonText: string;
	membershipCreationErrorHeading: string;
	membershipCreationErrorText: string;
	membershipCreationErrorButtonText: string;
	alreadyMemberHeading: string;
	alreadyMemberText: string;
	alreadyMemberButtonText: string;
	membershipCreationUnderWayText: string;
	onModalClose?: () => void;
	onLoad: () => void;
	onContinue: () => void;
	onReset: () => void;
	onClose: () => void;
	hasErrorOpeningSignWindow: boolean;
	onRetryOpenSignWindow: () => void;
	errorOpeningSignWindowText: string;
	onRetryOpenSignWindowButtonText: string;
}

export function SignupForm({
	displayBankIdSigningModal,
	onModalClose,
	signFrameHeading,
	bankIdSignUrl,
	onLoad,
	alreadyMember,
	waitingForCustomerToBeCreated,
	customerNotCreated,
	customerCreationDone,
	formHeading,
	formDescription,
	fields,
	customState,
	formSubmitButtonText,
	onSubmitCallback,
	membershipCreatedHeading,
	creditApplicationData,
	membershipWithCreditApprovedText,
	membershipWithCreditDeniedText,
	membershipCreationCanceledText,
	membershipCreatedText,
	onContinue,
	membershipCreatedButtonText,
	membershipCreationErrorHeading,
	membershipCreationErrorText,
	onReset,
	membershipCreationErrorButtonText,
	alreadyMemberHeading,
	alreadyMemberText,
	onClose,
	alreadyMemberButtonText,
	membershipCreationUnderWayText,
	hasErrorOpeningSignWindow,
	onRetryOpenSignWindow,
	errorOpeningSignWindowText,
	onRetryOpenSignWindowButtonText,
}: SignUpFormProps) {
	return (
		<div>
			{displayBankIdSigningModal && (
				<Popover
					isOpen
					onClose={onModalClose}
					variant="window"
					title={signFrameHeading}
					headerColor="red"
					padContent={false}
				>
					{bankIdSignUrl && (
						<iframe
							src={bankIdSignUrl}
							title="BankID"
							className="h-full w-full border-0 sm:min-h-[39rem]"
							onLoad={onLoad}
						/>
					)}
				</Popover>
			)}
			<FormView
				heading={formHeading}
				description={formDescription}
				fields={fields}
				submitButtonState={customState}
				submitButtonText={formSubmitButtonText}
				onSubmitCallback={onSubmitCallback}
				className={clsx(
					(alreadyMember ||
						waitingForCustomerToBeCreated ||
						customerNotCreated ||
						customerCreationDone) &&
						'hidden',
				)}
			/>

			<CustomerCreationDoneView
				membershipCreatedHeading={membershipCreatedHeading}
				creditResult={creditApplicationData?.CreditResult}
				creditLimit={creditApplicationData?.CreditLimit}
				creditApplicationApprovedText={membershipWithCreditApprovedText}
				creditApplicationFailedOrDeniedText={membershipWithCreditDeniedText}
				creditApplicationCanceledText={membershipCreationCanceledText}
				membershipCreatedText={membershipCreatedText}
				onAcceptMembershipAndContinue={onContinue}
				membershipCreatedButtonText={membershipCreatedButtonText}
				className={clsx(!customerCreationDone && 'hidden')}
			/>
			<CustomerNotCreatedView
				membershipCreationErrorHeading={membershipCreationErrorHeading}
				membershipCreationErrorText={membershipCreationErrorText}
				onTryAgain={onReset}
				membershipCreationErrorButtonText={membershipCreationErrorButtonText}
				className={clsx(!customerNotCreated && 'hidden')}
			/>
			<AlreadyMemberView
				alreadyMemberHeading={alreadyMemberHeading}
				alreadyMemberText={alreadyMemberText}
				onContinueShopping={onClose}
				alreadyMemberButtonText={alreadyMemberButtonText}
				className={clsx(!alreadyMember && 'hidden')}
			/>

			<LoadingView
				loadingText={membershipCreationUnderWayText}
				hasErrorOpeningSignWindow={hasErrorOpeningSignWindow}
				onRetryOpenSignWindow={onRetryOpenSignWindow}
				errorOpeningSignWindowText={errorOpeningSignWindowText}
				onRetryOpenSignWindowButtonText={onRetryOpenSignWindowButtonText}
				className={clsx('mt-6', !waitingForCustomerToBeCreated && 'hidden')}
			/>
		</div>
	);
}

SignupForm.displayName = 'SignupForm';

interface Props {
	formSection: string | undefined;
	unregisteredToken: string | undefined;
	businessLogicErrorsRenderCallback: (
		component: React.ReactNode | undefined,
	) => void;
	heading: string;
	submitText: string;
	description: string;
	form: FormFields;
	creatJulaClubCustomerActor: CreateJulaClubCustomerMachineActor;
}

export default function JulaClubSignupForm({
	formSection,
	unregisteredToken,
	businessLogicErrorsRenderCallback,
	form,
	submitText,
	heading,
	description,
	creatJulaClubCustomerActor,
}: Props) {
	const { t } = useI18n();

	const { globalPopoverService } = useGlobalStateContext();

	const customerNotCreated = useSelector(
		creatJulaClubCustomerActor,
		selectCustomerNotCreatedState,
	);

	const waitingForCustomerToBeCreated = useSelector(
		creatJulaClubCustomerActor,
		selectCreatingCustomer,
	);

	const alreadyMember = useSelector(
		creatJulaClubCustomerActor,
		selectAlreadyMember,
	);

	const customerCreationDone = useSelector(
		creatJulaClubCustomerActor,
		selectCustomerCreationDone,
	);
	const firstName = useSelector(creatJulaClubCustomerActor, selectFirstName);
	const addCustomerButtonState = useSelector(
		creatJulaClubCustomerActor,
		selectAddCustomerButtonState,
	);
	const displayBankIdSigningModal = useSelector(
		creatJulaClubCustomerActor,
		selectDisplayBankIdSigningModal,
	);
	const bankIdSignUrl = useSelector(
		creatJulaClubCustomerActor,
		selectBankIdSignUrl,
	);
	const creditApplicationData = useSelector(
		creatJulaClubCustomerActor,
		selectCreditApplicationData,
	);
	const errorOpeningSignWindow = useSelector(
		creatJulaClubCustomerActor,
		selectErrorOpeningSignWindow,
	);
	const { send } = creatJulaClubCustomerActor;

	// if user has become menber we make sure they are properly signed in
	// by seding CONTINUE to createCustomer on cleanup
	useEffect(() => {
		send('RESET');

		return () => {
			businessLogicErrorsRenderCallback(undefined);
			send('CONTINUE');
		};
	}, []);

	const onSubmit = async (genericFormData) => {
		businessLogicErrorsRenderCallback(undefined);
		send({
			type: 'CREATE_CUSTOMER',
			formData: {
				...getValuesForSubmit(form, genericFormData),
				formSection,
				token: unregisteredToken,
			},
		});
		const doneData = await waitFor(
			creatJulaClubCustomerActor,
			(state) => state.hasTag('customerCreationStartedOrFailed'),
			{ timeout: 120_000 },
		);

		if (
			doneData?.context?.errors?.businessLogicErrors ||
			doneData.context?.errors?.fieldValidationErrors
		) {
			if (is.arrayWithLength(doneData?.context?.errors?.businessLogicErrors)) {
				businessLogicErrorsRenderCallback(
					<ErrorMessageInfoBox
						errors={doneData.context.errors.businessLogicErrors.filter(
							handledBusinessLogicErrors,
						)}
					/>,
				);
			}
			if (is.objectWithKeys(doneData?.context?.errors?.fieldValidationErrors)) {
				return doneData?.context?.errors?.fieldValidationErrors;
			}

			return { FORM_ERROR: 'businessLogicErrors' };
		}
		return undefined;
	};

	return (
		<SignupForm
			displayBankIdSigningModal={displayBankIdSigningModal}
			signFrameHeading={t('sign_frame_heading')}
			bankIdSignUrl={bankIdSignUrl}
			onLoad={() => send('SIGN_FRAME_LOAD_SUCCESS')}
			alreadyMember={alreadyMember}
			waitingForCustomerToBeCreated={waitingForCustomerToBeCreated}
			customerNotCreated={customerNotCreated}
			customerCreationDone={customerCreationDone}
			formHeading={heading}
			formDescription={description}
			fields={form}
			customState={addCustomerButtonState}
			formSubmitButtonText={submitText}
			onSubmitCallback={onSubmit}
			membershipCreatedHeading={t('account_membership_created_heading', {
				firstName,
			})}
			creditApplicationData={creditApplicationData}
			membershipWithCreditApprovedText={t(
				'account_membership_credit_approved_text',
				{
					creditLimit: creditApplicationData?.CreditLimit,
				},
			)}
			membershipWithCreditDeniedText={t(
				'account_membership_credit_denied_text',
			)}
			membershipCreationCanceledText={t(
				'account_membership_credit_canceled_text',
			)}
			membershipCreatedText={t('account_membership_created_text')}
			onContinue={() => send('CONTINUE')}
			membershipCreatedButtonText={t('account_continue_shopping_button')}
			membershipCreationErrorHeading={t(
				'account_error_creating_membership_heading',
			)}
			membershipCreationErrorText={t('account_try_again_text')}
			onReset={() => send('RESET')}
			membershipCreationErrorButtonText={t('account_try_again_button')}
			alreadyMemberHeading={t('account_already_member_heading')}
			alreadyMemberText={t('account_already_member_text')}
			onClose={() => globalPopoverService.send('CLOSE')}
			alreadyMemberButtonText={t('account_already_member_button')}
			membershipCreationUnderWayText={t('account_creating_membership_text')}
			hasErrorOpeningSignWindow={errorOpeningSignWindow}
			onRetryOpenSignWindow={() => send('RETRY_OPEN_SIGN_WINDOW')}
			onRetryOpenSignWindowButtonText={t('account_try_again_button')}
			errorOpeningSignWindowText={t('account_error_opening_sign_window_text')}
		/>
	);
}
JulaClubSignupForm.displayName = 'JulaClubSignupForm';
