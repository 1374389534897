import React from 'react';
import { Form } from 'react-final-form';

import ActionButton from 'components/ActionButton';
import { TextInput } from 'components/FinalForm';
import { composeValidators, required } from 'components/GenericForm';
import Text from 'components/Text';
import { useI18n } from 'utils/i18n';

interface Props {
	onSubmit: (values: { email: string; postalCode: string }) => void;
	userInfoActorEmail: string | null | undefined;
	userInfoActorPostalCode: string | null | undefined;
}

export default function CheckoutCustomerIdentification({
	onSubmit,
	userInfoActorEmail,
	userInfoActorPostalCode,
}: Props) {
	const { t } = useI18n();

	return (
		<>
			<Text as="h1" className="mb-4">
				{t('checkout_identification_heading')}
			</Text>

			<Form
				onSubmit={onSubmit}
				keepDirtyOnReinitialize
				initialValues={{
					email: userInfoActorEmail,
					postalCode: userInfoActorPostalCode,
				}}
				render={({ handleSubmit, submitting, hasSubmitErrors }) => (
					<form onSubmit={handleSubmit}>
						<div>
							<TextInput
								id="email"
								type="email"
								validate={composeValidators(required(t('FieldIsRequired')))}
								name="email"
								className="mb-4"
								label={t('checkout_identification_email_input_label')}
							/>
						</div>
						<div>
							<TextInput
								id="postalCode"
								validate={composeValidators(required(t('FieldIsRequired')))}
								name="postalCode"
								className="mb-4"
								label={t('checkout_identification_postal_code_input_label')}
							/>
						</div>
						<ActionButton
							color="white"
							variant="cta"
							displayWidth="full"
							type="submit"
							minimunLoadingTime={500}
							customState={
								submitting ? 'loading' : hasSubmitErrors ? 'failure' : 'idle'
							}
						>
							{t('checkout_identification_continue_to_payment_button')}
						</ActionButton>
					</form>
				)}
			/>

			<Text className="w-10/11 mt-8" as="p">
				{t('checkout_identification_preamble_text')}
			</Text>
		</>
	);
}
CheckoutCustomerIdentification.displayName = 'CheckoutCustomerIdentification';
