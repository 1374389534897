/**
 * Bullets
 */

import React from 'react';
import clsx from 'clsx';

interface Bullet<IdT> {
	id: IdT;
	label: string;
}

interface Props<IdT, ButtonT> {
	/** The currently selected bullet button's ID */
	activeId?: IdT;

	/** Data for each bullet button */
	buttons: ButtonT[];

	/** Extra class names for the container */
	className?: string;

	/** Click event callback for every bullet */
	onClick?: (clicked: ButtonT) => void;
}

/** Horizontal bullet buttons. */
export default function Bullets<
	IdT extends string | number,
	ButtonT extends Bullet<IdT>,
>({ buttons, activeId, onClick, className = '' }: Props<IdT, ButtonT>) {
	return (
		<ul className={clsx('flex', className)}>
			{buttons.map((button) => (
				<li key={button.id}>
					<button
						type="button"
						onClick={() => {
							onClick?.(button);
						}}
						aria-label={button.label}
						className="group/bullet inline-flex h-8 w-6 items-center justify-center align-top"
					>
						<span
							className={clsx(
								'inline-block rounded-full transition-all',
								button.id === activeId
									? 'size-3 bg-greyDarker group-hover/bullet:bg-greyDark'
									: 'size-2 bg-greyLight group-hover/bullet:bg-greyLightDarken',
							)}
						/>
					</button>
				</li>
			))}
		</ul>
	);
}
Bullets.displayName = 'Bullets';
