import React from 'react';
import clsx from 'clsx';

import { StoreIdName, useFeatureToggle } from 'contexts';
import { InventoryItem, Stock, StockStore } from 'models/inventory';
import { Product } from 'models/product';

import StoreStockBalance from './StockInformation.StoreStockBalance';
import WebStockBalance from './StockInformation.WebStockBalance';

interface Props {
	className?: string;
	currentStoreStock: Stock | undefined;
	allStoresStock: Stock[] | undefined;
	nearbyStoresStock: Stock[] | undefined;
	onOpenCallBack: () => void;
	onUpdateSelectedStore: (store: StockStore) => void;
	product: Product;
	selectedStore: StoreIdName | undefined;
	currentProductStock: InventoryItem | undefined;
	isLoadingStock: boolean;
	isLoadingInitialStock: boolean;
	isLoadingNearbyStoresStock: boolean;
	onTabChange: (tab: string) => void;
}

function StockInformation({
	className,
	currentStoreStock,
	allStoresStock,
	nearbyStoresStock,
	onOpenCallBack,
	onUpdateSelectedStore,
	onTabChange,
	product,
	selectedStore,
	currentProductStock,
	isLoadingStock,
	isLoadingInitialStock,
	isLoadingNearbyStoresStock,
}: Props) {
	const { storesEnabled } = useFeatureToggle();
	return (
		<div
			className={clsx(
				className,
				'divide-y-1 divide-grey rounded-border border-1 border-grey',
			)}
		>
			<WebStockBalance
				product={product}
				webStock={currentProductStock?.webStock}
				isLoadingInitialStock={isLoadingInitialStock}
			/>
			{storesEnabled && (
				<StoreStockBalance
					currentProductStock={currentProductStock}
					currentStoreStock={currentStoreStock}
					allStoresStock={allStoresStock}
					nearbyStoresStock={nearbyStoresStock}
					onOpenCallBack={onOpenCallBack}
					onUpdateSelectedStore={onUpdateSelectedStore}
					product={product}
					selectedStore={selectedStore}
					isLoadingInitialStock={isLoadingInitialStock}
					isLoadingStock={isLoadingStock}
					isLoadingNearbyStoresStock={isLoadingNearbyStoresStock}
					onTabChange={onTabChange}
				/>
			)}
		</div>
	);
}

export default StockInformation;
StockInformation.displayName = 'ProductDetails_StockInformation';
