/**
 * ProductLine
 */

import React from 'react';
import type { AnchorHTMLAttributes } from 'react';
import clsx from 'clsx';

import HighlightedText from 'components/HighlightedText';
import Img from 'components/Img';
import Link from 'components/Link';
import Price from 'components/Price';
import Text from 'components/Text';
import { ProductCard } from 'models/productCard';
import {
	checkSupercheap,
	getPriceProps,
	getProductCardImage,
} from 'utils/business-logic';

interface Props extends AnchorHTMLAttributes<HTMLAnchorElement> {
	/** Additional class names on the anchor. */
	className?: string;

	/** Text to highlight in the product title. */
	highlight?: string;

	/** Product data. */
	product: ProductCard;
}

/** A slim line for a product with image, title and price. */
export default function ProductLine({
	className,
	highlight,
	product,
	...attrs
}: Props) {
	return (
		<Link
			key={product.id}
			href={product.url}
			className={clsx('group flex items-center', className)}
			{...attrs}
		>
			<Img
				src={getProductCardImage(product)}
				width={40}
				height={40}
				service="nextjs"
				className="mr-2 h-10 w-10 shrink-0"
				useFallbackOnError
			/>
			<Text as="span" className="mr-6 grow group-hover:underline">
				{highlight ? (
					<HighlightedText text={product.title} highlight={highlight} />
				) : (
					product.title
				)}
			</Text>
			{!product.expired && product.listPrice && (
				<Price
					{...getPriceProps(product.listPrice, false, checkSupercheap(product))}
					size="microCompact"
				/>
			)}
		</Link>
	);
}
ProductLine.displayName = 'ProductLine';
