/**
 * ContactReferences
 */

import React, { ChangeEvent } from 'react';
import { useSelector } from '@xstate/react';

import { Select } from 'components/FormUi/';
import InfoBox, { type InfoboxVariants } from 'components/InfoBox';
import { sendToast } from 'components/Toast';
import { useCheckoutContext } from 'contexts';
import type { ApiJulaModelsCartCartErrorModel } from 'models/api';
import { selectContactReferencesActor } from 'state-machines/checkout/';
import {
	selectContactReferences,
	selectErrors,
	selectSelectedContactReference,
} from 'state-machines/checkout/contactReferences';
import { useI18n } from 'utils/i18n';

/** Helper to get error box props */
const getErrorBoxProps = (
	error: ApiJulaModelsCartCartErrorModel,
): { icon: string; variant: InfoboxVariants } | null => {
	switch (error.type) {
		case 'ContactReferenceNotSelected':
			return { icon: 'error', variant: 'error' };
		default:
			return null;
	}
};

/** Component for contactReferences methods, uses the contactReferencesActor from the checkout machine. */
export default function ContactReferences() {
	const { t } = useI18n();
	const { checkoutService } = useCheckoutContext();
	const contactReferencesActor = useSelector(
		checkoutService,
		selectContactReferencesActor,
	);
	const { send } = contactReferencesActor;
	const contactReferences = useSelector(
		contactReferencesActor,
		selectContactReferences,
	);
	const selectedContactReference = useSelector(
		contactReferencesActor,
		selectSelectedContactReference,
	);
	const errors = useSelector(contactReferencesActor, selectErrors);

	/** Handle change of contactReference */
	const contactReferencesOnChange = (e: ChangeEvent<HTMLSelectElement>) => {
		const {
			target: { value },
		} = e;
		const contactReference = contactReferences?.filter(
			(c) => c.id === value,
		)[0];

		if (!contactReference) {
			sendToast(t('checkout_contact_references_selection_error'), 'error');
		}

		if (contactReference?.id === selectedContactReference?.id) {
			return;
		}

		send('SELECT_CONTACT_REFERENCE', {
			value: contactReference,
		});
	};

	if (!contactReferences) {
		return null;
	}

	return (
		<>
			<div className="relative my-10">
				<Select
					id="contactReferences"
					name="contactReferences"
					label={t('checkout_contact_references_label')}
					value={selectedContactReference?.id}
					onChange={contactReferencesOnChange}
					options={contactReferences?.map((option) => ({
						value: option.id,
						label: option.code,
					}))}
				/>
			</div>

			{errors?.map((error) => {
				const boxProps = getErrorBoxProps(error);
				return boxProps ? (
					<div className="mt-4" key={error.key}>
						<InfoBox
							icon={boxProps.icon}
							variant={boxProps.variant}
							message={error.description}
						/>
					</div>
				) : null;
			})}
		</>
	);
}
ContactReferences.displayName = 'ContactReferences';
