import React from 'react';
import { useSelector } from '@xstate/react';

import AccountMenuLinks from 'components/AccountMenuLinks';
import { useGlobalStateContext } from 'contexts';
import type { AccountMenuItem } from 'models/accountMenu';
import { selectIsLoggingOut } from 'state-machines/authentication';

export interface Props {
	fields?: {
		accountLinks: AccountMenuItem[];
	};
}

/** User account menu on the account pages. */
export default function AccountMenu({ fields }: Props) {
	const { userService } = useGlobalStateContext();
	const isLoggingOut = useSelector(userService, selectIsLoggingOut);

	if (!fields) {
		return null;
	}

	return (
		<AccountMenuLinks
			items={fields.accountLinks}
			onLogoutClick={() => {
				userService.send('LOGOUT');
			}}
			isLogoutLoading={isLoggingOut}
			logoutClassName="text-julaRed pl-8"
			withBorder
		/>
	);
}
AccountMenu.displayName = 'AccountMenu';
