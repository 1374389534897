import React from 'react';
import { Field } from '@sitecore-jss/sitecore-jss-nextjs';

import { BlockBrandHeading as BlockBrandHeadingBase } from 'components/BlockBrandHeading';
import { LayoutContainer } from 'components/Layout';
import { JulaComponentProps } from 'lib/component-props';
import { Asset, DigizuiteAssetList } from 'models/asset';
import { getEditorMargin } from 'utils/business-logic';
import { getAsset } from 'utils/helpers';

type Props = JulaComponentProps & {
	fields: {
		title?: Field<string>;
		longTitle?: Field<string>;
		brandAssetsJson?: Asset[];
		descriptionFormatted?: Field<string>;
		brandImage: DigizuiteAssetList;
	};
};

export default function BlockBrandHeading({ params, fields }: Props) {
	const { longTitle, brandAssetsJson, descriptionFormatted, brandImage } =
		fields;

	if (!fields) {
		return null;
	}

	const img = getAsset('Templated 2:1', brandImage);

	const primaryLogo = brandAssetsJson
		?.find((item) => item.type === 'Logotype')
		?.versions?.find((logo) => logo.subType === 'PrimaryLogo')
		?.formats?.find(
			(formats) => formats.type === 'ODL 300px height transparent',
		);

	return (
		<LayoutContainer
			id={params?.anchor}
			outerClassName={getEditorMargin(params)}
		>
			<BlockBrandHeadingBase
				title={longTitle?.value}
				logo={primaryLogo?.url?.location || ''}
				description={descriptionFormatted?.value}
				image={img?.src || ''}
				imageAlt={img?.alt}
			/>
		</LayoutContainer>
	);
}
BlockBrandHeading.displayName = 'BlockBrandHeading';
