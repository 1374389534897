import React from 'react';
import { useSelector } from '@xstate/react';

import AuthJulaConnect from 'components/AuthJulaConnect';
import { renderNonInputContent } from 'components/GenericForm';
import { ProLogin } from 'components/Login';
import { useGlobalStateContext } from 'contexts';
import { LoginMethod } from 'models/connect/oauth';
import type { FormFields, FormInfo } from 'models/form';
import {
	selectErrorMessage,
	selectIsAwaitingAuthAction,
} from 'state-machines/authentication';
import { getLoginOptions } from 'utils/business-logic';

interface Props {
	authButtonText: string;
	heading: string;
	importantInformation?: FormFields;
	message: string;
}

export default function JulaProIdentification({
	heading,
	message,
	importantInformation,
	authButtonText,
}: Props) {
	const { authService } = useGlobalStateContext();

	const infoFields =
		importantInformation?.filter(
			(field): field is FormInfo =>
				field.type === 'InfoField' || field.type === 'HighlightField',
		) || [];

	const isAwaitingAuthAction = useSelector(
		authService,
		selectIsAwaitingAuthAction,
	);

	const errorMessage = useSelector(authService, selectErrorMessage);

	const selectLoginMethod = (loginMethod: LoginMethod) =>
		authService.send({
			type: 'SELECT_AUTH_JULA_PRO_BECOME_MEMBER',
			loginMethod,
		});

	return (
		<div className="mt-4 h-full w-full">
			{isAwaitingAuthAction && (
				<ProLogin
					introHeading={heading}
					introText={message}
					errorMessage={errorMessage}
					importantInformation={
						<div className="py-4">
							{infoFields.map((infoField) =>
								renderNonInputContent(infoField, 'my-4'),
							)}
						</div>
					}
					onSelectLoginMethod={selectLoginMethod}
					loginOptions={getLoginOptions('Pro').map((option) => ({
						...option,
						text: authButtonText,
					}))}
				/>
			)}
			{!isAwaitingAuthAction && <AuthJulaConnect />}
		</div>
	);
}
JulaProIdentification.displayName =
	'AccountJulaProSignupForm_JulaProIdentification';
