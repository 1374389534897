/**
 * ProductCard updated
 */

import React from 'react';
import type { MouseEventHandler } from 'react';

import { ActionButtonState } from 'components/ActionButton';
import ErrorBoundary from 'components/ErrorBoundary';
import Price, { BasePriceText } from 'components/Price';
import { getFlagVariant } from 'components/ProductFlag';
import {
	useFeatureToggle,
	useGlobalStateContext,
	useSelectedStore,
} from 'contexts';
import {
	AccessoryCard,
	ProductCard as ProductCardType,
} from 'models/productCard';
import {
	checkSupercheap,
	getPriceProps,
	getProductCardImage,
} from 'utils/business-logic';

import ProductCardColumn from './ProductCardColumn';
import ProductCardRow from './ProductCardRow';

interface BaseProps {
	/** Control the ActionButton from the 'outside' state */
	actionButtonState?: ActionButtonState;

	/** CTA button text. ONLY concerns orientation 'row'. */
	buttonText?: string;

	/** Extra container class names */
	className?: string;

	/** Link click callback */
	onLinkClick?: MouseEventHandler<HTMLAnchorElement>;

	/** Sets the orientation of the component */
	orientation?: 'column' | 'row';

	/** Product data */
	product: ProductCardType | AccessoryCard;

	/** Should we open the additional sales modal? */
	requestAdditionalSales?: boolean;

	/** Should the button be rendered? */
	showAddToCartButton?: boolean;
}

interface WithPurchaseButtonCallback extends BaseProps {
	onAddToCart?: never;
	/** Custom add to cart button click handler */
	onPurchaseButtonClick?: () => void;
}
interface WithDefaultButtonCallback extends BaseProps {
	/** Add to cart callback for default button click handler */
	onAddToCart?: () => void;
	onPurchaseButtonClick?: never;
}

type Props = WithPurchaseButtonCallback | WithDefaultButtonCallback;

/** ProductCard component. */
export default function ProductCard({
	actionButtonState,
	product,
	onPurchaseButtonClick,
	orientation = 'column',
	className,
	buttonText,
	showAddToCartButton,
	onLinkClick,
	requestAdditionalSales = false,
	onAddToCart,
}: Props) {
	const { cartService } = useGlobalStateContext();
	const { selectedStore } = useSelectedStore();
	const { onlineCommerceEnabled, storesEnabled } = useFeatureToggle();

	if (!product) {
		return null;
	}

	const addProductToCart =
		onPurchaseButtonClick ??
		(() => {
			cartService.send({
				type: 'ADD_ONE_FROM_BUY_BUTTON',
				variantId: product.id,
				requestAdditionalSales,
				showToast: true,
				GTMData: {
					type: 'add_to_cart',
					payload: { productData: { product, quantity: 1 } },
				},
			});

			if (onAddToCart) {
				onAddToCart();
			}
		});

	const energySymbolSrc = product.energySymbol?.[0]?.location;
	const flagVariant = getFlagVariant(product);

	const stockInfo =
		'productInStockAtStores' in product
			? {
					storeStock: {
						name: selectedStore?.name,
						inStock: Boolean(
							product.productInStockAtStores?.some(
								({ id }) => id.toString() === selectedStore?.id,
							),
						),
						isSellableInStore: product.isSellableInStore,
						productInStockAtStores: product.productInStockAtStoresCount ?? 0,
						isSellableOnWeb: product.isSellableOnWeb,
					},
					webStock: {
						isSellable: product.isSellable,
						isSellableOnWeb: product.isSellableOnWeb,
						productReStockWeb: product.productReStockWeb,
						isSellableInStore: product.isSellableInStore,
					},
				}
			: undefined;

	const isExpired = 'expired' in product ? product.expired : false;
	const isSellable = 'isSellable' in product ? product.isSellable : true;
	const usps = 'usPs' in product ? product.usPs : undefined;
	const basePrice = 'basePrice' in product ? product.basePrice : undefined;
	const hasMultipleVariants =
		'hasMultipleVariants' in product ? product.hasMultipleVariants : false;
	const price = !isExpired && product.listPrice && (
		<Price
			{...getPriceProps(product.listPrice, false, checkSupercheap(product))}
			size="small"
		/>
	);

	return (
		<ErrorBoundary>
			{orientation === 'row' ? (
				<ProductCardRow
					actionButtonState={actionButtonState}
					className={className}
					imageSrc={
						getProductCardImage(product) ||
						'/assets/images/placeholder-image.png'
					}
					href={product.url || '#'}
					imageWidth={160}
					imageHeight={160}
					heading={product.brand?.title || ''}
					subHeading={product.title}
					price={price}
					score={product.reviewScore?.score ?? 0}
					reviews={product.reviewScore?.count}
					buttonText={buttonText}
					showAddToCartButton={!isExpired && showAddToCartButton}
					flagVariant={flagVariant}
					energySymbolSrc={energySymbolSrc}
					productSheetLink={product.productSheetLink}
					productSheetLinkDescription={product.productSheetLinkDescription}
					addToCart={addProductToCart}
					disabled={!isSellable || !onlineCommerceEnabled}
					onLinkClick={onLinkClick}
				/>
			) : (
				<ProductCardColumn
					actionButtonState={actionButtonState}
					className={className}
					productId={product.id}
					imageSrc={
						getProductCardImage(product) ||
						'/assets/images/placeholder-image.png'
					}
					imageWidth={160}
					href={product.url || '#'}
					imageHeight={160}
					heading={product.brand?.title || ''}
					subHeading={product.title}
					hasMultipleVariants={hasMultipleVariants}
					price={
						<>
							{price}
							<BasePriceText
								basePrice={basePrice}
								displayedPriceType={product.listPrice?.priceType}
								recentLowestPriceKey="product_list_recent_lowest_price_text"
								className="mt-1 text-xs"
							/>
						</>
					}
					score={product.reviewScore?.score ?? 0}
					reviews={product.reviewScore?.count}
					checklist={usps}
					energySymbolSrc={energySymbolSrc}
					flagVariant={flagVariant}
					productSheetLink={product.productSheetLink}
					productSheetLinkDescription={product.productSheetLinkDescription}
					addToCart={addProductToCart}
					showAddToCartButton={!isExpired && showAddToCartButton}
					disabled={!isSellable || !onlineCommerceEnabled}
					storesEnabled={storesEnabled}
					onLinkClick={onLinkClick}
					stockInfo={stockInfo}
				/>
			)}
		</ErrorBoundary>
	);
}
ProductCard.displayName = 'ProductCard';
