/**
 * ProductList
 */

import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-nextjs';
import { useRouter } from 'next/router';

import ProductListComponent, { OFFSET_QUERY_VAR } from 'components/ProductList';
import Text from 'components/Text';
import { withRequiredProps } from 'hoc';
import { useSitecoreContext } from 'hooks';
import type { JulaComponentProps } from 'lib/component-props';
import type { ProductList as ProductListModel } from 'models/productlist';
import { pushToGTM } from 'utils/GoogleTagManager';

type Props = JulaComponentProps & {
	fields: ProductListModel;
};

function ProductList({ params, fields, rendering }: Props) {
	const { brand, category, heading, datasourceName } = fields;
	const { sitecoreContext } = useSitecoreContext();
	const router = useRouter();

	const categoryName = category?.title?.value;
	const gtmListName = categoryName
		? `Category: ${categoryName}`
		: brand
			? `Category: ${brand.title.value}`
			: `Block: ${datasourceName}`;

	return (
		<ProductListComponent
			// This is deliberately a fallback stack rather than a template string
			// with every value. Having them all at the same time can result in the
			// list resetting when certain facet items are selected.
			key={category?.id.value || brand?.id.value || heading?.value}
			editorMargin={params?.margin}
			id={params?.anchor}
			fields={fields}
			headingLevel={params?.heading}
			isEditing={sitecoreContext?.pageEditing}
			placeholderComponentName="ProductList"
			layoutArea1Content={
				<>
					{rendering.placeholders && (
						<Placeholder
							name="breadcrumbs"
							rendering={rendering}
							render={(components) => <div className="mb-8">{components}</div>}
						/>
					)}
					<Text
						as="h1"
						className="text-left"
						text={heading?.value || categoryName}
					/>
				</>
			}
			onProductListUpdate={(visibleProducts) => {
				pushToGTM({
					type: 'view_item_list',
					payload: {
						products: visibleProducts,
						pageSize: router.query[OFFSET_QUERY_VAR]
							? Number.parseInt(router.query[OFFSET_QUERY_VAR].toString(), 10)
							: fields.nextPageOffset,
						listName: gtmListName,
					},
				});
			}}
			onProductCardClick={(product, visibleProducts) => {
				pushToGTM({
					type: 'select_item',
					payload: {
						selectedProduct: product,
						productList: visibleProducts,
						listName: gtmListName,
					},
				});
			}}
		/>
	);
}
ProductList.displayName = 'ProductList_JSS';

export default withRequiredProps(ProductList, 'fields');
