import React from 'react';
import { Field } from '@sitecore-jss/sitecore-jss-nextjs';

import ArrowLink from 'components/ArrowLink';
import { LayoutContainer } from 'components/Layout/';
import RichText from 'components/RichText';
import Text from 'components/Text';
import { JulaComponentProps } from 'lib/component-props';
import { getEditorMargin } from 'utils/business-logic';
import { is } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

interface RelatedArticle {
	url: string;
	summary: string;
}
type FAQArticleProps = JulaComponentProps & {
	fields: {
		id: Field<number>;
		content: Field<string>;
		summary: Field<string>;
		title: Field<string>;
		relatedArticles: Field<RelatedArticle[]>;
	};
};
function FAQArticle({ fields, params }: FAQArticleProps) {
	const { t } = useI18n();
	if (!fields) {
		return null;
	}
	const { content, title, relatedArticles } = fields;
	return (
		<LayoutContainer
			className={getEditorMargin(params)}
			id={params?.anchor}
			withGrid
		>
			<div className="col-span-full md:col-span-6">
				<Text field={title} className="mb-4" as="h1" />

				<RichText className="richtext--faq" field={content} />
				{is.arrayWithLength(relatedArticles.value) && (
					<>
						<Text
							as="h2"
							text={t('faq_article_related_articles_heading')}
							className="col-span-full mt-8"
						/>
						<ul className="col-span-full mt-4 flex flex-col gap-4">
							{relatedArticles.value.map((article) => {
								const { url, summary } = article;
								return (
									<li key={url}>
										<ArrowLink text={summary} href={url} />
									</li>
								);
							})}
						</ul>
					</>
				)}
			</div>
		</LayoutContainer>
	);
}
FAQArticle.displayName = 'FAQArticle';

export default FAQArticle;
