import React, { useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import type { Field } from '@sitecore-jss/sitecore-jss-nextjs';
import { useInterpret, useSelector } from '@xstate/react';
import { waitFor } from 'xstate/lib/waitFor';

import ActionButton, { type ActionButtonState } from 'components/ActionButton';
import Button from 'components/Button';
import { TextInput } from 'components/FinalForm';
import { getValuesForSubmit } from 'components/GenericForm';
import {
	ErrorMessageInfoBox,
	useGlobalPopoverContext,
} from 'components/GlobalPopover';
import InfoBox from 'components/InfoBox';
import LoadingSpinner from 'components/LoadingSpinner';
import Popover from 'components/Popover';
import Text from 'components/Text';
import { useFeatureToggle, useGlobalStateContext } from 'contexts';
import { useEffectOnce } from 'hooks';
import type { JulaComponentProps } from 'lib/component-props';
import type { FormFields, Section } from 'models/form';
import { selectUnregisteredToken } from 'state-machines/authentication';
import {
	createJulaProCustomerMachine,
	selectAddCustomerButtonState,
	selectBankIdSignUrl,
	selectCompanyName,
	selectCreditApplicationData,
	selectCustomerInfo,
	selectDisplayBankIdSignInModal,
	selectErrorSearchingOrganization,
	selectIsCheckingCustomerCreationStatus,
	selectIsCustomerCreated,
	selectIsCustomerCreationError,
	selectIsCustomerCreationFailed,
	selectIsGetCustomerIdFromUser,
	selectIsInStepOne,
	selectIsInStepThree,
	selectIsInStepTwo,
	selectIsLoading,
	selectIsSearchingOrganization,
	selectIsSigning,
	selectIsSigningCanceled,
	selectName,
	selectOpenSignTabError,
	selectOrganizationList,
	selectRequiresAdditionalInformation,
	selectSelectedCredit,
	selectSelectedWorksite,
	selectSelectOrganizationButtonState,
	selectSerchingOrganizationSuccess,
	selectShouldDisplayAbortButton,
	selectSubmitCustomerIdButtonState,
	selectWaitingForNewOrganizationSearch,
} from 'state-machines/createJulaProCustomer';
import { FormattedErrors, is } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

import JulaProIdentification from './JulaProIdentification';
import JulaProInfo from './JulaProInfo';
import JulaProStepper from './JulaProStepper';

type Props = JulaComponentProps & {
	fields: {
		submitText: Field<string>;
		name: Field<string>;
		heading: Field<string>;
		description: Field<string>;
		form: FormFields;
		sections: Section[];
		datasourceName: string;
	};
};

export default function AccountJulaProSignupForm({ fields }: Props) {
	const { authService, globalPopoverService } = useGlobalStateContext();
	const { creditFunctionsEnabled } = useFeatureToggle();
	const createProCustomerService = useInterpret(createJulaProCustomerMachine, {
		context: { creditFunctionsEnabled },
		devTools: true,
	});
	const {
		sections = [],
		heading: { value: identificationHeading },
		description: { value: identificationMessage },
		submitText: { value: identificationAuthButtonText },
		form: identificationInfoBox = [],
	} = fields;
	const isStepOne = useSelector(createProCustomerService, selectIsInStepOne);
	const showCustomerIdForm = useSelector(
		createProCustomerService,
		selectIsGetCustomerIdFromUser,
	);
	const [showNotificationErrors, setShowNotificationErrors] = useState(false);
	const [stepThreeValidationErrors, setStepThreeValidationErrors] = useState<
		string[] | null
	>(null);
	const isRequiresAdditionalInformation = useSelector(
		createProCustomerService,
		selectRequiresAdditionalInformation,
	);
	const isStepTwo = useSelector(createProCustomerService, selectIsInStepTwo);
	const isStepThree = useSelector(
		createProCustomerService,
		selectIsInStepThree,
	);
	const isCheckingCustomerCreationStatus = useSelector(
		createProCustomerService,
		selectIsCheckingCustomerCreationStatus,
	);
	const isCustomerCreated = useSelector(
		createProCustomerService,
		selectIsCustomerCreated,
	);
	const creditApplicationData = useSelector(
		createProCustomerService,
		selectCreditApplicationData,
	);
	const isSearchingOrganizations = useSelector(
		createProCustomerService,
		selectIsSearchingOrganization,
	);
	const name = useSelector(createProCustomerService, selectName);
	const companyName = useSelector(createProCustomerService, selectCompanyName);
	const isInSearchOrganizationSuccess = useSelector(
		createProCustomerService,
		selectSerchingOrganizationSuccess,
	);
	const isInWaitingForNewOrganizationSearch = useSelector(
		createProCustomerService,
		selectWaitingForNewOrganizationSearch,
	);
	const searchingOrganizationFailed = useSelector(
		createProCustomerService,
		selectErrorSearchingOrganization,
	);
	const addCustomerButtonState = useSelector(
		createProCustomerService,
		selectAddCustomerButtonState,
	);
	const selectOrganizationButtonState = useSelector(
		createProCustomerService,
		selectSelectOrganizationButtonState,
	);
	const submitCustomerIdButtonState = useSelector(
		createProCustomerService,
		selectSubmitCustomerIdButtonState,
	);
	const isCustomerCreationFailed = useSelector(
		createProCustomerService,
		selectIsCustomerCreationFailed,
	);
	const isSigningCanceled = useSelector(
		createProCustomerService,
		selectIsSigningCanceled,
	);
	const isCustomerCreationError = useSelector(
		createProCustomerService,
		selectIsCustomerCreationError,
	);
	const selectedCredit = useSelector(
		createProCustomerService,
		selectSelectedCredit,
	);
	const organizationList = useSelector(
		createProCustomerService,
		selectOrganizationList,
	);
	const julaProBecomeMemberToken = useSelector(
		authService,
		selectUnregisteredToken,
	);
	const selectedWorksite = useSelector(
		createProCustomerService,
		selectSelectedWorksite,
	);
	const displayBankIdSigninModal = useSelector(
		createProCustomerService,
		selectDisplayBankIdSignInModal,
	);
	const isSigning = useSelector(createProCustomerService, selectIsSigning);
	const bankIdSignUrl = useSelector(
		createProCustomerService,
		selectBankIdSignUrl,
	);
	// open sign tab error selector
	const isOpenSignTabError = useSelector(
		createProCustomerService,
		selectOpenSignTabError,
	);

	// should display abort button selector
	const shouldDisplayAbortButton = useSelector(
		createProCustomerService,
		selectShouldDisplayAbortButton,
	);
	const shouldDisplayLoader = useSelector(
		createProCustomerService,
		selectIsLoading,
	);
	// selector for customer info
	const customerInfo = useSelector(
		createProCustomerService,
		selectCustomerInfo,
	);

	const { t } = useI18n();

	const submitOrganizationSearch = (searchString: string) => {
		createProCustomerService.send({
			type: 'SEARCH_ORGANIZATION',
			searchString,
		});
	};
	const selectOrganization = (id: string) => {
		createProCustomerService.send({
			type: 'SELECT_ORGANIZATION',
			bisnodeWorksiteId: id,
		});
	};

	const sendNewSearchInProgress = () => {
		createProCustomerService.send({ type: 'NEW_SEARCH_IN_PROGRESS' });
	};
	const submitBecomeProMemberForm = async (
		form: FormFields,
		values: Record<string, unknown>,
	): Promise<FormattedErrors['fieldValidationErrors']> => {
		createProCustomerService.send({
			type: 'REGISTER_CUSTOMER',
			form: { ...getValuesForSubmit(form, values) },
		});
		const doneData = await waitFor(createProCustomerService, (state) =>
			state.hasTag('waitForStepThreeValidation'),
		);
		if (doneData.context.errors) {
			let errorTexts: string[] = [];
			const { fieldValidationErrors, businessLogicErrors } =
				doneData.context.errors;
			if (fieldValidationErrors) {
				errorTexts = [
					...errorTexts,
					...Object.values<string[]>(fieldValidationErrors).flat(),
				];
			}
			if (businessLogicErrors) {
				errorTexts = [
					...errorTexts,
					...businessLogicErrors
						.map((error) => error.text || '')
						.filter(Boolean),
				];
			}

			setStepThreeValidationErrors(errorTexts);
		}
		return doneData.context?.errors?.fieldValidationErrors;
	};

	const resetToSearchOrganization = () => {
		createProCustomerService.send('RESET_TO_SEARCH_ORGANIZATION');
	};
	const resetToSelectOrganization = () => {
		createProCustomerService.send('RESET_TO_SELECT_ORGANIZATION');
	};
	const resetFromStepThree = () => {
		createProCustomerService.send({ type: 'RESET_TO_PREVIOUS_STEP' });
		setStepThreeValidationErrors(null);
	};
	const submitCustomerId = async ({ customerId }) => {
		createProCustomerService.send({ type: 'ADD_CUSTOMER_ID', id: customerId });

		const state = await waitFor(createProCustomerService, (currentState) =>
			currentState.hasTag('customerIdSent'),
		);
		if (
			state.context.selectedWorksite?.worksiteRegistrationStatus ===
			'SpecifiedCustomerIdDontExist'
		) {
			return {
				customerId: [state.context.selectedWorksite.worksiteRegistrationText],
			};
		}
		return undefined;
	};

	const selectCredit = (credit: number) => {
		createProCustomerService.send({
			type: 'SELECT_CREDIT',
			credit,
		});
	};

	// if user has become menber we make sure they are properly signed in
	// by seding CONTINUE to createCustomer on cleanup
	useEffectOnce(() => () => {
		createProCustomerService.send({ type: 'RELOAD' });
	});
	useEffect(() => {
		if (julaProBecomeMemberToken) {
			createProCustomerService.send({
				type: 'CREATE_CUSTOMER',
				token: julaProBecomeMemberToken,
			});
		}
	}, [julaProBecomeMemberToken]);

	useEffect(() => {
		if (stepThreeValidationErrors) {
			setShowNotificationErrors(true);
		}
	}, [stepThreeValidationErrors]);

	const constCloseAndReset = () => {
		globalPopoverService.send('CLOSE');
		// authService.send('RESET_JULA_PRO_SIGN_UP');
	};
	const popoverContext = useGlobalPopoverContext();
	const [
		additionalInformationSubmitButtonState,
		setAdditionalInformationSubmitButtonState,
	] = useState<ActionButtonState>('idle');
	const phoneNumberFallbackSubmit = async ({
		mobileNumber,
	}: {
		mobileNumber: string;
	}) => {
		popoverContext?.setTopMessage(undefined);
		setAdditionalInformationSubmitButtonState('loading');
		createProCustomerService.send({
			type: 'CREATE_CUSTOMER',
			token: julaProBecomeMemberToken!,
			mobileNumber,
		});
		const doneData = await waitFor(createProCustomerService, (state) =>
			state.hasTag('requestJulaProApiAuthDone'),
		);
		// TODO this is a bit of a hack, we should probably have a better way of handling this
		const event = doneData.event as any;
		if (
			'data' in event &&
			(event.data?.fieldValidationErrors || event.data?.businessLogicErrors)
		) {
			setAdditionalInformationSubmitButtonState('failure');
			popoverContext?.setTopMessage(
				<ErrorMessageInfoBox errors={event.data?.businessLogicErrors} />,
			);
			if (is.objectWithKeys(event.data?.fieldValidationErrors)) {
				return event.data?.fieldValidationErrors;
			}
			return { FORM_ERROR: 'businessLogicErrors' };
		}
		return undefined;
	};

	if (isRequiresAdditionalInformation) {
		return (
			<div className="flex h-full flex-col content-between">
				<div>
					<Text
						as="h2"
						className="mb-2 mt-8"
						text={t('jula_pro_sign_up_additional_information_heading')}
					/>
					<Text
						as="p"
						className="mt-4"
						text={t('jula_pro_sign_up_additional_information_message')}
					/>

					<div className="mt-8">
						<Form
							onSubmit={phoneNumberFallbackSubmit}
							render={({ handleSubmit }) => (
								<form onSubmit={handleSubmit}>
									<TextInput
										id="mobileNumber"
										name="mobileNumber"
										type="text"
										required
										label={t(
											'jula_pro_sign_up_additional_information_phone_number_label',
										)}
									/>
									<ActionButton
										customState={additionalInformationSubmitButtonState}
										variant="cta"
										displayWidth="full"
										type="submit"
										className="mt-6"
										minimunLoadingTime={0}
									>
										{t('jula_pro_sign_up_additional_information_button')}
									</ActionButton>
								</form>
							)}
						/>
					</div>
				</div>
			</div>
		);
	}

	if (shouldDisplayLoader) {
		return (
			<div className="flex h-full justify-center">
				<LoadingSpinner
					variant="dashing"
					spinnerColor="julaRed"
					trackColor="transparent"
					size="medium"
				/>
			</div>
		);
	}

	if (julaProBecomeMemberToken) {
		if (isSigning || isCheckingCustomerCreationStatus) {
			return (
				<>
					<Popover
						isOpen={displayBankIdSigninModal}
						variant="window"
						title={t('sign_frame_heading')}
						headerColor="red"
						padContent={false}
					>
						{displayBankIdSigninModal && bankIdSignUrl && (
							<iframe
								src={bankIdSignUrl}
								title="BankID"
								className="h-full w-full border-0 sm:min-h-[39rem]"
								onLoad={() => {
									authService.send({ type: 'SIGN_FRAME_LOAD_SUCCESS' });
								}}
							/>
						)}
					</Popover>
					<div className="relative flex h-full justify-center max-sm:h-[26rem]">
						<div className="flex flex-col">
							<div className="relative flex justify-center">
								<LoadingSpinner
									variant="dashing"
									spinnerColor="julaRed"
									trackColor="transparent"
									size="medium"
								/>
							</div>
							{shouldDisplayAbortButton && (
								<Button
									variant="text"
									displayWidth="full"
									onClick={() => {
										createProCustomerService.send({
											type: 'ABORT_AND_CLOSE_SIGNICAT_WINDOW',
										});
										constCloseAndReset();
									}}
								>
									{t('account_cancel_login_button')}
								</Button>
							)}
						</div>
					</div>
				</>
			);
		}
		return (
			<div className="h-full">
				{!isCustomerCreated && (isStepOne || isStepTwo || isStepThree) && (
					<div className="relative">
						{((showNotificationErrors && stepThreeValidationErrors) ||
							isOpenSignTabError) &&
							isStepThree && (
								<InfoBox
									className="sticky top-0 z-[1000]"
									icon="error"
									variant="error"
								>
									{stepThreeValidationErrors &&
										stepThreeValidationErrors.length > 0 &&
										stepThreeValidationErrors.map((error: string) => (
											<Text
												key={error}
												as="p"
												className="text-sm leading-[1.40625rem]"
												text={t(`${error}`)}
											/>
										))}
									{isOpenSignTabError && (
										<Text
											as="p"
											className="text-sm leading-[1.40625rem]"
											text={t('jula_pro_sign_tab_error_text')}
										/>
									)}
								</InfoBox>
							)}
						<JulaProStepper
							isStepOne={isStepOne}
							isStepTwo={isStepTwo}
							isStepThree={isStepThree}
							resetToSearchOrganization={resetToSearchOrganization}
							isInWaitingForNewOrganizationSearch={
								isInWaitingForNewOrganizationSearch
							}
							creditFunctionsEnabled={creditFunctionsEnabled}
							showCustomerIdForm={showCustomerIdForm}
							submitOrganizationSearch={submitOrganizationSearch}
							selectOrganization={selectOrganization}
							selectCredit={selectCredit}
							resetToSelectOrganization={resetToSelectOrganization}
							organizationList={organizationList}
							selectedWorksite={selectedWorksite}
							addCustomerButtonState={addCustomerButtonState}
							sendNewSearchInProgress={sendNewSearchInProgress}
							isInSearchOrganizationSuccess={isInSearchOrganizationSuccess}
							isSearchingOrganizations={isSearchingOrganizations}
							selectedCredit={selectedCredit}
							submitBecomeProMemberForm={submitBecomeProMemberForm}
							submitCustomerId={submitCustomerId}
							searchingOrganizationFailed={searchingOrganizationFailed}
							selectOrganizationButtonState={selectOrganizationButtonState}
							submitCustomerIdButtonState={submitCustomerIdButtonState}
							resetFromStepThree={resetFromStepThree}
							customerInfo={customerInfo}
							sections={sections}
						/>
					</div>
				)}
				{isCustomerCreated && (
					<JulaProInfo
						image="/assets/images/graphic-congrats-julapro.png"
						header={`${t('julapro_customer_created_header', { name })}`}
						creditInfo={
							creditApplicationData?.CreditLimit
								? t('account_membership_credit_approved_text', {
										creditLimit: creditApplicationData?.CreditLimit,
									})
								: undefined
						}
						infoOne={`${t('julapro_customer_created_admin_info', {
							companyName,
						})}`}
						infoTwo={t('julapro_customer_created_myjula_info')}
						buttonText={t('julapro_customer_created_button_text')}
						onClick={() => {
							createProCustomerService.send({ type: 'RELOAD' });
						}}
					/>
				)}
				{isCustomerCreationFailed && (
					<JulaProInfo
						image="/assets/images/graphic-congrats-julapro.png"
						header={t('julapro_customer_creation_failed_Header')}
						infoOne={t('SigningFailed')}
						buttonText={t('julapro_customer_creation_failed_botton_text')}
						onClick={constCloseAndReset}
					/>
				)}
				{isSigningCanceled && (
					<JulaProInfo
						image="/assets/images/graphic-congrats-julapro.png"
						header={t('julapro_customer_creation_failed_Header')}
						infoOne={t('SigningCanceled')}
						buttonText={t('julapro_customer_creation_failed_botton_text')}
						onClick={constCloseAndReset}
					/>
				)}
				{isCustomerCreationError && (
					<JulaProInfo
						image="/assets/images/graphic-congrats-julapro.png"
						header={t('julapro_customer_creation_error_Header')}
						infoOne={t('julapro_customer_creation_error_description')}
						buttonText={t('julapro_customer_creation_error_button_text')}
						onClick={constCloseAndReset}
					/>
				)}
			</div>
		);
	}

	return (
		<div className="h-full">
			<JulaProIdentification
				heading={identificationHeading}
				message={identificationMessage}
				authButtonText={identificationAuthButtonText}
				importantInformation={identificationInfoBox}
			/>
		</div>
	);
}
AccountJulaProSignupForm.displayName = 'AccountJulaProSignupForm';
