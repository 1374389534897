/**
 * TotalWithVat
 */

import React from 'react';
import clsx from 'clsx';

import Price from 'components/Price';
import Text from 'components/Text';
import { useI18n } from 'utils/i18n';
import { formatPriceText } from 'utils/price';

interface Props {
	/** Extra container class names */
	className?: string;

	/** Larger, bold title? */
	hasLargeTitle?: boolean;

	/** The price number */
	price?: string | number;

	/** Currency symbol for the price */
	priceSymbol?: string;

	/** Text before the price */
	title?: string;

	/** The VAT portion of the price */
	vat?: string | number;

	/** Currency symbol for the VAT */
	vatCurrencySymbol?: string;
}

/** Summary price with a title. */
export default function TotalWithVat({
	className,
	hasLargeTitle,
	price,
	priceSymbol,
	title,
	vat,
	vatCurrencySymbol,
}: Props) {
	const { t } = useI18n();

	if (!price) {
		return null;
	}

	const vatText = formatPriceText(vat, vatCurrencySymbol);

	return (
		<div className={clsx('flex', className)}>
			<Text
				as={hasLargeTitle ? 'pLarge' : 'p'}
				className={clsx('mb-2', hasLargeTitle && 'font-bold')}
			>
				{title}
			</Text>
			<div className="ml-auto text-right">
				<Price
					price={price}
					currency={priceSymbol}
					theme="regular"
					size="small"
				/>
				{vatText && (
					<Text as="pXSmall">
						{t('cart_summary_vat_amount_label')} {vatText}
					</Text>
				)}
			</div>
		</div>
	);
}
TotalWithVat.displayName = 'TotalWithVat';
