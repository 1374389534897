/**
 * AddGiftCard
 */

import React, { useState } from 'react';
import { Form } from 'react-final-form';

import ActionButton from 'components/ActionButton';
import { TextInput } from 'components/FinalForm';
import GiftCardButton from 'components/GiftCardButton';
import InfoBox from 'components/InfoBox';
import Text from 'components/Text';
import { useI18n } from 'utils/i18n';

interface Props {
	giftCardInvalidWithInvoice: string | undefined;
	onSubmit: ({
		giftCardNr,
		pinCode,
	}: {
		giftCardNr: string;
		pinCode: string;
	}) => void;
}

export default function AddGiftCard({
	onSubmit,
	giftCardInvalidWithInvoice,
}: Props) {
	const [isExpanded, setIsExpanded] = useState(false);
	const { t } = useI18n();

	return (
		<>
			<GiftCardButton
				text={t('checkout_add_gift_card_button')}
				onClick={() => setIsExpanded((current) => !current)}
				isExpanded={isExpanded}
			/>
			<div hidden={!isExpanded} className="mt-4">
				{giftCardInvalidWithInvoice && (
					<InfoBox
						className="h-[6.5rem] w-full"
						icon="info"
						variant="information"
						message={giftCardInvalidWithInvoice}
					/>
				)}
				{!giftCardInvalidWithInvoice && (
					<>
						<Text className="col-span-full" as="pSmall">
							{t('checkout_add_gift_card_text')}
						</Text>
						<Form onSubmit={onSubmit}>
							{({
								handleSubmit,
								submitting,
								submitSucceeded,
								hasSubmitErrors,
								form,
								submitErrors,
								modifiedSinceLastSubmit,
							}) => {
								if (submitSucceeded) {
									form.restart();
								}
								return (
									<form
										onSubmit={handleSubmit}
										className="mt-6 flex grid-cols-6 flex-col gap-6 md:grid md:flex-row"
									>
										<div className="col-span-full md:col-start-1 md:col-end-4">
											<TextInput
												id="giftCardNr"
												name="giftCardNr"
												label={t('checkout_gift_card_number_input_label')}
												type="number"
											/>
										</div>
										<div className="col-span-full flex flex-col gap-4 md:col-start-4 md:col-end-7 md:flex-row md:items-start">
											<TextInput
												label={t('checkout_gift_card_pin_input_label')}
												id="pinCode"
												name="pinCode"
												type="number"
											/>
											<ActionButton
												variant="cta"
												size="large"
												displayWidth="full"
												minimunLoadingTime={500}
												customState={
													submitting
														? 'loading'
														: hasSubmitErrors
															? 'failure'
															: 'idle'
												}
												type="submit"
											>
												{t('checkout_add_button')}
											</ActionButton>
										</div>
										{!modifiedSinceLastSubmit &&
											submitErrors?.giftCardErrorMessages && (
												<InfoBox
													icon="error"
													variant="error"
													className="col-span-full"
													message={submitErrors.giftCardErrorMessages}
												/>
											)}
									</form>
								);
							}}
						</Form>
					</>
				)}
			</div>
		</>
	);
}
AddGiftCard.displayName = 'AddGiftCard';
