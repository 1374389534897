/**
 * VideoBackground
 */

import React, { useEffect, useRef } from 'react';
import clsx from 'clsx';

import type { DigizuiteAsset } from 'models/asset';
import { ignorePromiseRejection } from 'utils/helpers';

interface Props {
	asset: DigizuiteAsset;
	className?: string;
	cover?: boolean;
	shouldPlay?: boolean;
}

/** A looping, muted video. */
export default function VideoBackground({
	asset,
	className,
	cover = false,
	shouldPlay,
}: Props) {
	const videoRef = useRef<HTMLVideoElement>(null);
	const hasAlt = Boolean(asset.alt);

	useEffect(() => {
		if (shouldPlay && videoRef.current) {
			ignorePromiseRejection(videoRef.current.play());
		} else if (videoRef.current) {
			videoRef.current.pause();
		}
	}, [shouldPlay]);

	return (
		<video
			ref={videoRef}
			autoPlay
			loop
			muted
			playsInline
			aria-label={hasAlt ? asset.alt : undefined}
			aria-hidden={hasAlt ? undefined : 'true'}
			tabIndex={hasAlt ? undefined : -1}
			className={clsx(className, cover && 'h-full w-full object-cover')}
		>
			<source type={asset.contentType} src={asset.src} />
		</video>
	);
}
VideoBackground.displayName = 'VideoBackground';
