/**
 * ProductListFacetChips
 */

import React from 'react';
import { useRouter } from 'next/router';

import Chip from 'components/Chip';
import SwipeWrapper from 'components/SwipeWrapper';
import { FacetResponse } from 'models/api';
import type { WithRequired } from 'types';
import { asArray } from 'utils/helpers';
import { useI18n } from 'utils/i18n';
import { createUrl } from 'utils/url';

const facetDictionaryKeyPrefixes = {
	ProductType: 'product_type_facet_',
};

interface Props {
	activeFacets: FacetResponse[];
	className?: string;
}

/** Facet item as linked chips. */
export default function ProductListFacetChips({
	activeFacets,
	className,
}: Props) {
	const { t } = useI18n();
	const router = useRouter();
	// Only include facets that have at least two items.
	const visibleFacets = activeFacets.filter(
		(facet): facet is WithRequired<FacetResponse, 'facetItems'> =>
			Boolean(facet.facetItems && facet.facetItems.length > 1),
	);

	if (visibleFacets.length === 0) {
		return null;
	}

	const getText = (facetId: string, itemText: string) =>
		t(`${facetDictionaryKeyPrefixes[facetId] || ''}${itemText}`);

	return (
		<SwipeWrapper
			className={className}
			activeClassName="md:hidden"
			inactiveClassName="max-md:hidden flex flex-wrap items-start gap-4"
			pullGutters
		>
			{visibleFacets.flatMap((facet) =>
				facet.facetItems.map((item) => (
					<Chip
						key={`${facet.id}-${item.item}`}
						className={
							asArray(router.query[facet.id]).includes(item.item)
								? 'outline outline-2 outline-greyDarker'
								: undefined
						}
						color="grey"
						href={createUrl(
							router.asPath,
							{ [facet.id]: item.item },
							{ replaceParams: true },
						)}
						text={`${getText(facet.id, item.item)} (${item.count})`}
					/>
				)),
			)}
		</SwipeWrapper>
	);
}
ProductListFacetChips.displayName = 'ProductListFacetChips';
