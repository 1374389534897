import React from 'react';
import clsx from 'clsx';

import { LayoutContainer } from 'components/Layout';
import Text from 'components/Text';
import { JulaComponentProps } from 'lib/component-props';
import { getEditorMargin } from 'utils/business-logic';

type ContentHeadlineProps = JulaComponentProps & {
	fields: {
		heading: {
			value: string;
			editable?: string;
		};
	};
};

function ContentHeadline(props: ContentHeadlineProps) {
	const { heading: headingLevel = 'h2' } = props.params || {};

	return (
		<LayoutContainer
			id={props.params?.anchor}
			outerClassName={clsx(getEditorMargin(props.params))}
			withGrid
		>
			<Text
				className="col-span-4 md:col-span-6 md:col-start-4"
				as={headingLevel}
				styleAs="h1"
				field={props.fields?.heading}
			/>
		</LayoutContainer>
	);
}

export default ContentHeadline;
ContentHeadline.displayName = 'ContentHeadline';
