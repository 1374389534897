/**
 * ProductCardColumn
 */

import React, { type MouseEventHandler, type ReactNode } from 'react';
import clsx from 'clsx';

import ActionButton, { type ActionButtonState } from 'components/ActionButton';
import Icon from 'components/Icon';
import Img from 'components/Img';
import Link from 'components/Link';
import {
	PRODUCT_REVIEWS_ID,
	PRODUCT_TECHNICAL_ATTRIBUTES_ID,
} from 'components/ProductDetails/';
import ProductFlag, { type ProductFlagVariant } from 'components/ProductFlag';
import Rating from 'components/Rating';
import Text from 'components/Text';
import { useI18n } from 'utils/i18n';

import ProductCardStockInfo, { type StockInfo } from './ProductCardStockInfo';

interface Props {
	actionButtonState?: ActionButtonState;

	/** Add to cart callback */
	addToCart?: () => void;

	/** The list of items from checklist  */
	checklist?: string[];

	/** Extra container class names */
	className?: string;

	/** Is product disabled? */
	disabled?: boolean;

	/** Energy Symbol */
	energySymbolAlt?: string;

	/** Energy Symbol */
	energySymbolSrc?: string;

	/** Set which news/outlet etc. flag to display, if any. */
	flagVariant?: ProductFlagVariant;

	/** Product has multiple variants ? */
	hasMultipleVariants?: boolean;

	/** The heading in the component */
	heading?: string;

	/** The link to the product page */
	href: string;

	/** Image alt text */
	imageAlt?: string;

	/** Height for the image (mandatory when using imageService 'nextjs') */
	imageHeight?: number;

	/** Image src attribute */
	imageSrc: string;

	/** Width for the image (mandatory when using imageService 'nextjs') */
	imageWidth?: number;

	/** Link click callback */
	onLinkClick?: MouseEventHandler<HTMLAnchorElement>;

	/** The price in the component */
	price: ReactNode;

	/** The product's unique ID */
	productId: string;

	/** Link to product sheet */
	productSheetLink?: string;

	/** Product sheet link text */
	productSheetLinkDescription?: string;

	/** Sets the number of reviews */
	reviews?: number;

	/** Sets the numbers of score/stars */
	score?: number;

	/** Show and hide options for the button */
	showAddToCartButton?: boolean;

	/** Stock object for stock section stock info */
	stockInfo: StockInfo | undefined;

	/** Display information related to store */
	storesEnabled: boolean;

	/** The sub heading in the component */
	subHeading?: string;
}

/** Product card with a vertical layout. */
export default function ProductCardColumn({
	productId,
	href,
	className,
	onLinkClick,
	imageSrc,
	imageAlt = '',
	imageWidth,
	imageHeight,
	heading,
	subHeading,
	energySymbolAlt,
	energySymbolSrc,
	price,
	score = 0,
	reviews,
	checklist,
	hasMultipleVariants = false,
	showAddToCartButton = false,
	flagVariant,
	disabled,
	productSheetLink,
	productSheetLinkDescription,
	addToCart,
	storesEnabled,
	stockInfo,
	actionButtonState,
}: Props) {
	const { t } = useI18n();
	const hasReviews = score > 0;

	// The primary link covers the entire card, place any other clickable
	// components above it and expand their hit area to hopefully reduce
	// accidental clicks on the below primary link.
	const innerTargetClasses =
		'relative z-3 before:absolute before:-inset-2 before:-left-1';

	return (
		<article
			className={clsx(className, 'relative w-full md:w-auto')}
			aria-label={subHeading}
		>
			{flagVariant && (
				<>
					<ProductFlag
						size="small"
						variant={flagVariant}
						className="absolute left-0 top-0 md:hidden"
					/>
					<ProductFlag
						size="medium"
						variant={flagVariant}
						className="absolute left-0 top-0 max-md:hidden"
					/>
				</>
			)}
			<Img
				src={imageSrc}
				alt={imageAlt}
				width={imageWidth}
				height={imageHeight}
				service="nextjs"
				useFallbackOnError
				className="mb-2 block object-contain sm:max-w-[60%] md:h-40"
			/>

			{hasReviews && (
				<div className="mt-2 flex items-start">
					<Rating
						size="small"
						score={score}
						reviewCount={reviews}
						href={`${href}#${PRODUCT_REVIEWS_ID}`}
						className={innerTargetClasses}
					/>
				</div>
			)}

			<header
				className={clsx(
					hasReviews && 'mt-2',
					// Ridiculous, but the computed size of Rating is a value with half
					// a pixel which has an effect on high resolution screens.
					!hasReviews && 'mt-8 pt-[0.5px]',
				)}
			>
				{heading && <Text as="pXSmall">{heading}</Text>}
				{subHeading && (
					<Text
						as="p"
						styleAs="h6"
						className={clsx(
							'min-h-[2.875rem]',
							heading && 'mt-1',
							!heading && 'mt-7',
						)}
					>
						<Link
							href={href}
							onClick={onLinkClick}
							cover
							className="hover:underline"
						>
							{subHeading}
						</Link>
					</Text>
				)}
			</header>

			<div className="my-2">{price}</div>
			{checklist && (
				<ul className="mt-3 flex list-inside list-disc flex-col gap-1 text-sm">
					{checklist.map((item) => (
						<li key={item}>{item}</li>
					))}
				</ul>
			)}

			{(energySymbolSrc || productSheetLink) && (
				<div
					className={clsx(
						'mt-4 flex items-center gap-3 text-xs',
						// The energy symbol as some size to it but the sheet link is small
						// and feels a bit flimsy to hit even with an expanded hit area.
						// Place the entire container as a blocking layer above the primary
						// link when there is a sheet link.
						productSheetLink && 'relative z-3',
					)}
				>
					{energySymbolSrc && (
						<Link
							href={`${href}#${PRODUCT_TECHNICAL_ATTRIBUTES_ID}`}
							onClick={onLinkClick}
							className={clsx(innerTargetClasses, 'hover:opacity-80')}
						>
							<Img
								src={energySymbolSrc}
								alt={
									energySymbolAlt ||
									t('product_details_technical_specification_heading')
								}
								className="h-6 object-contain"
							/>
						</Link>
					)}
					{productSheetLink && (
						<Link
							href={productSheetLink}
							target="_blank"
							rel="nofollow"
							underline
							className={innerTargetClasses}
						>
							{productSheetLinkDescription ||
								t('product_details_product_sheet_button')}
						</Link>
					)}
				</div>
			)}

			{stockInfo && !hasMultipleVariants && (
				<ProductCardStockInfo
					storeStockVisible={storesEnabled}
					stockInfo={stockInfo}
					productId={productId}
					className="mt-4"
				/>
			)}
			{hasMultipleVariants && (
				<p className="mt-4 flex items-center text-xs">
					<Icon
						size={16}
						icon="arrow"
						color="white"
						backgroundColor="julaRed"
						className="mr-1"
					/>
					{t('product_list_multiple_variants_label')}
				</p>
			)}

			{showAddToCartButton && (
				<ActionButton
					data-cid="addToCart"
					className={clsx('mt-4', innerTargetClasses)}
					size="small"
					variant="cta"
					customState={actionButtonState}
					disabled={disabled}
					onClick={addToCart}
				>
					{t('product_Card_buy_button')}
				</ActionButton>
			)}
		</article>
	);
}
ProductCardColumn.displayName = 'ProductCardColumn';
