import { formatNumber } from './format';
import { is } from './helpers';
import { ENTITIES, makeSpacesNoBreak } from './string';

const REGEXP = {
	whitespace: /\s/g,
	// Positive lookahead, only match periods before other periods.
	periodsExcludingLast: /\.(?=.*\.)/g,
	// Optional leading minus or hyphen for negative numbers, then a digit
	// followed by zero or more digits, whitespace, commas or periods, followed
	// by a final digit. This won't match a single digit but that doesn't need
	// any formatting anyway.
	priceNumbers: new RegExp(`([${ENTITIES.minus}-]?\\d[\\d\\s,.]*\\d)`, 'g'),
};

function formatPriceDecimal(decimal: string | number | undefined): string {
	if (!decimal) {
		return '';
	}
	// Remove anything over two decimal places and pad with zero in case
	// there's less than two numbers.
	const formatted = String(decimal).slice(0, 2).padEnd(2, '0');
	const parsed = Number(formatted);
	// Ignore NaN and zeroes.
	return is.number(parsed) && parsed > 0 ? formatted : '';
}

function formatPriceNumber(
	price: string | number | undefined,
	options: { keepDecimals?: boolean } = {},
): string {
	if (!price) {
		return '';
	}

	let formatted = String(price)
		.replaceAll(REGEXP.whitespace, '')
		.replaceAll(ENTITIES.minus, '-')
		// Commas to periods for consistency with JS numbers.
		.replaceAll(',', '.')
		.replaceAll(REGEXP.periodsExcludingLast, '');

	if (options.keepDecimals) {
		// Split on the first period only.
		const [intPrice, rawDecimal] = formatted.split('.', 2);
		const decimal = formatPriceDecimal(rawDecimal);
		formatted = decimal ? `${intPrice}.${decimal}` : intPrice;
	} else {
		// Get rid of decimals by parsing to integer.
		formatted = String(Number.parseInt(formatted, 10));
	}

	// Add back the thousand delimiter.
	return formatted.replaceAll(
		/\B(?=(\d{3})+(?!\d))/g,
		ENTITIES.narrowNoBreakSpace,
	);
}

/**
 * Convert price numbers to a consistent format.
 *
 * Affects all numbers in the supplied price, so something like "1234 för 1234"
 * will have both numbers formatted.
 */
export function formatPrice(
	price: string | number | undefined,
	options: { keepDecimals?: boolean } = {},
): string {
	if (!price) {
		return '';
	}
	return String(price)
		.replaceAll(REGEXP.priceNumbers, (match) =>
			formatPriceNumber(match, options),
		)
		.trim();
}

/**
 * Format a price and its symbol.
 */
export function formatPriceText(
	price: string | number | undefined,
	symbol: string | undefined,
): string {
	// Don't just check falsy since that will catch zero.
	if (price === '' || price === undefined) {
		return '';
	}
	const formattedPrice = is.number(price) ? formatNumber(price) : price;
	return makeSpacesNoBreak(`${formattedPrice}${symbol || ''}`);
}

/**
 * Extract the decimals from a price. May be an empty string.
 */
export function getPriceDecimals(price: string | number | undefined): string {
	if (!price) {
		return '';
	}
	const formatted = String(price)
		.replaceAll(REGEXP.whitespace, '')
		// Commas to periods for consistency with JS numbers.
		.replaceAll(',', '.')
		.replaceAll(REGEXP.periodsExcludingLast, '');

	// Split on the first period only.
	return formatPriceDecimal(formatted.split('.', 2)[1]);
}
