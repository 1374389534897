import React from 'react';
import clsx from 'clsx';

import BlockMedia from 'components/BlockMedia/';
import ComponentPlaceholder from 'components/ComponentPlaceholder';
import { LayoutContainer } from 'components/Layout';
import { JulaComponentProps } from 'lib/component-props';
import { DigizuiteAssetList } from 'models/asset';
import { ProductCard as ProductCardType } from 'models/productCard';
import { getEditorMargin } from 'utils/business-logic';
import { getAsset } from 'utils/helpers';

type MediaLandscapeProps = JulaComponentProps & {
	fields: {
		landscapeImage: DigizuiteAssetList;
		isWide: {
			value: boolean;
		};
		products: ProductCardType[];
	};
};

function MediaLandscape({ params, fields }: MediaLandscapeProps) {
	if (!fields) return null;
	const { landscapeImage, isWide, products } = fields;

	const landscapeImg = getAsset('Templated 2:1', landscapeImage);

	if (!landscapeImg) {
		return (
			<ComponentPlaceholder
				componentName="MediaLandscape"
				className={getEditorMargin(params)}
			/>
		);
	}

	return (
		<LayoutContainer
			id={params?.anchor}
			gutters
			withGrid
			outerClassName={getEditorMargin(params)}
		>
			<div
				className={clsx(
					!isWide?.value &&
						'relative col-span-full md:col-span-6 md:col-start-4',
					isWide?.value && 'col-span-full',
				)}
			>
				<BlockMedia
					variant="landscapeWide"
					imgSrc={landscapeImg?.src}
					imgAlt={landscapeImg?.alt}
					products={products}
				/>
			</div>
		</LayoutContainer>
	);
}

MediaLandscape.displayName = 'MediaLandscape';

export default MediaLandscape;
