/**
 * SearchField
 */

import React, { forwardRef, InputHTMLAttributes, MouseEvent } from 'react';
import clsx from 'clsx';

import Icon from 'components/Icon';
import cn from 'utils/cn';
import { useI18n } from 'utils/i18n';

export interface Props extends InputHTMLAttributes<HTMLInputElement> {
	className?: string;
	handleInputClearClick: (e: MouseEvent<HTMLButtonElement>) => void;
	hasInputClearButton: boolean;
	id: string;
	inputClassName?: string;
	inputLabel: React.ReactNode;
	inputPlaceholder?: string;
	isLabelVisible?: boolean;
	isLoading?: boolean;
	submitButtonLabel: string;
}

/** A search field with clear button, placeholder and button with icon */
const SearchField = forwardRef<HTMLInputElement, Props>(
	(
		{
			id,
			submitButtonLabel,
			inputClassName,
			inputLabel,
			inputPlaceholder,
			handleInputClearClick,
			hasInputClearButton,
			className,
			isLoading = false,
			isLabelVisible = false,
			...inputAttrs
		},
		ref,
	) => {
		const { t } = useI18n();
		return (
			<>
				<label className={clsx(!isLabelVisible && 'sr-only')} htmlFor={id}>
					{inputLabel}
				</label>
				<div className={cn('flex w-full', className)}>
					<div className="relative w-full">
						<input
							{...inputAttrs}
							type="text"
							id={id}
							placeholder={inputPlaceholder}
							autoCapitalize="none"
							autoComplete="off"
							autoCorrect="off"
							enterKeyHint="search"
							inputMode="search"
							ref={ref}
							className={clsx(
								'h-12 w-full px-4',
								'border border-r-0 focus:border-greyDark',
								'rounded rounded-r-none',
								// Prevent iOS from zooming on focus by having the text at 16px+.
								'text-[1rem]',
								'focus:outline-none',
								'placeholder:text-inherit',
								// Match right padding with clear button width below
								hasInputClearButton && 'pr-10',
								inputClassName,
							)}
						/>
						<button
							type="button"
							className={clsx(
								// Match width with input right padding above
								'w-10',
								'absolute right-0 top-1/2 flex h-12 -translate-y-1/2 items-center justify-center border-none bg-none hover:opacity-80',
								!hasInputClearButton && 'hidden',
							)}
							onClick={handleInputClearClick}
						>
							<span className="sr-only">{t('screenreader_text_clear')}</span>
							<Icon icon="clear" color="grey" />
						</button>
					</div>
					<button
						type="submit"
						className="relative size-12 flex-shrink-0 rounded-r border-none bg-julaRed hover:bg-julaRedDarken"
					>
						<span className="sr-only">
							{isLoading ? t('general_loading_text') : submitButtonLabel}
						</span>
						{isLoading && (
							<Icon icon="spinner" color="white" className="animate-spinFast" />
						)}
						{!isLoading && <Icon icon="search" color="white" />}
					</button>
				</div>
			</>
		);
	},
);

export default SearchField;

SearchField.displayName = 'SearchField';
