import React, { useState } from 'react';
import clsx from 'clsx';

import Accordion from 'components/Accordion';
import Img from 'components/Img';
import Popover from 'components/Popover';
import {
	getDocuments,
	RelatedProductsAccordion,
} from 'components/ProductDetails';
import RichText from 'components/RichText';
import { Skeleton, SkeletonItem } from 'components/Skeleton';
import TechnicalSpecification from 'components/TechnicalSpecification';
import Text from 'components/Text';
import { ProductResponse } from 'models/api/variant';
import { ProductCard } from 'models/productCard';
import {
	getAllImages,
	getBrandLogoImage,
	getMainImageDetail,
	getVerticalEnergyLabel,
	type Thumbnail,
} from 'utils/business-logic';
import { is } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

interface ContentProps {
	onSparePartsLoadMore: () => void;
	product: ProductResponse | undefined;
	spareParts: ProductCard[] | undefined;
	sparePartsHasNextPage: boolean;
	sparePartsIsLoading: boolean;
	sparePartsIsLoadingMore: boolean;
}

function Content({
	product,
	spareParts,
	sparePartsHasNextPage,
	sparePartsIsLoading,
	sparePartsIsLoadingMore,
	onSparePartsLoadMore,
}: ContentProps) {
	const { t } = useI18n();
	const noPicsMain: Thumbnail = {
		alt: '',
		id: 1,
		src: '/assets/images/placeholder-image@2x.png',
	};
	const [selectedImg, setSelectedImg] = useState(
		getMainImageDetail(product) ?? noPicsMain,
	);

	if (!product) return null;

	const { technicalAttributes, packageSize } = product;
	const documents = getDocuments(product);
	const verticalEnergyLabel = getVerticalEnergyLabel(product);
	const brandLogoSrc = getBrandLogoImage(product);

	const noPics = [noPicsMain];
	const allImages = getAllImages(product) ?? noPics;
	return (
		<>
			<Text as="h2" text={product.title} />
			<Text as="p" text={product.brand?.title} />
			<div className="flex justify-center">
				<Img service="nextjs" src={selectedImg.src} width={272} height={272} />
			</div>
			<div className="flex flex-wrap gap-2">
				{allImages.map((img, i) => (
					<button
						key={img.id}
						type="button"
						onClick={() => {
							setSelectedImg(img);
						}}
						aria-label={t('product_details_show_image_number_label', {
							num: i + 1,
						})}
						className={clsx(
							'group w-16 rounded-button border p-2',
							selectedImg.id === img.id &&
								'border-grey hover:border-greyDarken',
							selectedImg.id !== img.id &&
								'border-greyLight hover:border-greyLightDarken',
						)}
					>
						<Img
							alt={img.alt}
							service="nextjs"
							width={64}
							height={64}
							className="w-full group-hover:scale-[1.07]"
							src={img.src}
						/>
					</button>
				))}
			</div>
			<Accordion
				id="product-archive-search-item-popover-description"
				title={t('product_details_about_product_section_heading')}
				className="mt-6"
				titleTag="h2"
			>
				<Img
					src={brandLogoSrc}
					alt={product.brand?.title}
					useFallbackOnError
					className="mb-4 max-h-16 max-w-[10rem]"
				/>
				<Text as="p">
					<span className="font-bold">
						{t('product_details_article_number_label')}:
					</span>{' '}
					{product.id}
				</Text>
				<RichText className="mt-6" html={product.webTextFormatted} />
			</Accordion>
			{(is.arrayWithLength(technicalAttributes?.categories) ||
				is.arrayWithLength(documents) ||
				packageSize) && (
				<Accordion
					id="product-archive-search-item-popover-tech-spec"
					title={t('product_details_technical_specification_heading')}
					titleTag="h2"
				>
					<TechnicalSpecification
						className="my-4"
						categories={technicalAttributes?.categories}
						packageSize={packageSize}
						energyLabelSrc={verticalEnergyLabel?.url.location}
						documents={documents}
					/>
				</Accordion>
			)}
			<RelatedProductsAccordion
				accordionId="spare-parts-search-item-popover-spare-parts"
				hasNextPage={sparePartsHasNextPage}
				initialOpen
				isLoadingInitial={sparePartsIsLoading}
				isLoadingMore={sparePartsIsLoadingMore}
				onLoadMoreClick={onSparePartsLoadMore}
				products={spareParts}
				title={t('product_details_spare_parts_heading')}
			/>
		</>
	);
}

Content.displayName = 'ProductArchiveSearchItemPopoverContent';

interface Props {
	isLoadingProduct: boolean;
	isOpen: boolean;
	onClose: () => void;
	onSparePartsLoadMore: () => void;
	product: ProductResponse | undefined;
	spareParts: ProductCard[] | undefined;
	sparePartsHasNextPage: boolean;
	sparePartsIsLoading: boolean;
	sparePartsIsLoadingMore: boolean;
}

export default function ProductArchiveSearchItemPopover({
	isOpen,
	product,
	onClose,
	sparePartsHasNextPage,
	sparePartsIsLoading,
	sparePartsIsLoadingMore,
	onSparePartsLoadMore,
	spareParts,
	isLoadingProduct,
}: Props) {
	const { t } = useI18n();

	return (
		<Popover
			isOpen={isOpen}
			onClose={onClose}
			title={t('product_archive_search_item_popover_heading')}
		>
			{isLoadingProduct && (
				<Skeleton>
					<SkeletonItem height="3rem" width="100%" />
					<SkeletonItem height="1.5rem" className="mt-2" width="30%" />
					<SkeletonItem height="17rem" className="mt-6" width="17rem" />
					<SkeletonItem height="4rem" className="mt-6" width="100%" />
					<div className="mt-6 flex justify-between border-t-1 border-greyDark py-4">
						<SkeletonItem height="2.5rem" width="40%" />
						<SkeletonItem
							height="2.5rem"
							width="2.5rem"
							className="rounded-full"
						/>
					</div>
					<div className="flex justify-between border-y-1 border-greyDark py-4">
						<SkeletonItem height="2.5rem" width="40%" />
						<SkeletonItem
							height="2.5rem"
							width="2.5rem"
							className="rounded-full"
						/>
					</div>
				</Skeleton>
			)}
			{!isLoadingProduct && (
				<Content
					product={product}
					spareParts={spareParts}
					sparePartsHasNextPage={sparePartsHasNextPage}
					sparePartsIsLoading={sparePartsIsLoading}
					sparePartsIsLoadingMore={sparePartsIsLoadingMore}
					onSparePartsLoadMore={onSparePartsLoadMore}
				/>
			)}
		</Popover>
	);
}
ProductArchiveSearchItemPopover.displayName = 'ProductArchiveSearchItemPopover';
