import React from 'react';
import type { Field } from '@sitecore-jss/sitecore-jss-nextjs';
import Script from 'next/script';

import ComponentPlaceholder from 'components/ComponentPlaceholder';
import { LayoutContainer } from 'components/Layout';
import { useEffectOnce } from 'hooks';
import type { JulaComponentProps } from 'lib/component-props';
import { SitecoreLink } from 'models/link';
import { getEditorMargin } from 'utils/business-logic';

type Props = JulaComponentProps & {
	fields?: {
		link: Field<SitecoreLink>;
	};
};

export default function TestFreaksAllReviews({ params, fields }: Props) {
	useEffectOnce(() => {
		if (window.testFreaks) {
			window.testFreaks.push(['load', ['all-reviews']]);
		}
	});

	if (!fields) {
		return null;
	}

	if (!fields.link) {
		return (
			<ComponentPlaceholder
				className={getEditorMargin(params)}
				componentName="TestFreaksAllReviews"
				description="Requires a link"
			/>
		);
	}

	return (
		<>
			<LayoutContainer outerClassName={getEditorMargin(params)}>
				<div id="testfreaks-all-reviews" />
			</LayoutContainer>
			<Script
				src={fields?.link.value.href}
				id="testfreaks-all-reviews-js-embed"
				strategy="afterInteractive"
				onLoad={() => {
					const testfreaks = window.testFreaks ?? [];
					testfreaks.push(['load', ['all-reviews']]);
				}}
			/>
		</>
	);
}
TestFreaksAllReviews.displayName = 'TestFreaksAllReviews';
