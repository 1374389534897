import { WebStock as InventoryWebStock } from 'models/inventory';
import { ProductCard } from 'models/productCard';
import { Extends } from 'types';

import { StatusType } from './statusType';

export function getVariantPickerWebStockStatus({
	isSellable,
	isSellableOnWeb,
	reStockWithinAcceptableTimespan,
	availableStockLevel,
}: VariantPickerWebStock): VariantPickerWebStockStatusType {
	switch (true) {
		case isSellable && isSellableOnWeb && availableStockLevel > 0:
			return 'InStock';
		case !isSellable &&
			isSellableOnWeb &&
			!reStockWithinAcceptableTimespan &&
			availableStockLevel === 0:
			return 'OutOfStock';
		case !isSellable &&
			isSellableOnWeb &&
			reStockWithinAcceptableTimespan &&
			availableStockLevel === 0:
		case !isSellable && isSellableOnWeb && reStockWithinAcceptableTimespan:
			return 'ReStock';
		case !isSellableOnWeb:
			return 'InStoreOnly';
		default:
			return 'MatchFailed';
	}
}

export type VariantPickerWebStockStatusType = Extends<
	StatusType,
	'InStock' | 'OutOfStock' | 'ReStock' | 'InStoreOnly' | 'MatchFailed'
>;

interface VariantPickerWebStock
	extends Pick<ProductCard, 'isSellable' | 'isSellableOnWeb'>,
		Pick<
			InventoryWebStock,
			'reStockWithinAcceptableTimespan' | 'availableStockLevel'
		> {}
