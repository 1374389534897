import React, { useState } from 'react';
import type { Field } from '@sitecore-jss/sitecore-jss-nextjs';
import ProductArchiveSearchBlock from 'src/components/ProductArchiveSearch';

import { LayoutContainer } from 'components/Layout';
import { ProductArchiveSearchItemPopover } from 'components/ProductArchiveSearch/';
import { EMPTY_SELECT_VALUE } from 'components/ProductArchiveSearch/ProductArchiveSearch';
import {
	useProductArchiveSearch,
	useProductVariant,
	useRelatedProducts,
} from 'hooks';
import type { JulaComponentProps } from 'lib/component-props';
import { getEditorMargin, getProductImageFormat } from 'utils/business-logic';
import { useI18n } from 'utils/i18n';

interface Category {
	categories?: Category[];
	id: string;
	name: string;
}
interface SortOption {
	attribute: string;
	default: boolean;
	name: string;
}
type Props = JulaComponentProps & {
	fields?: {
		categories: Category[];
		category1Selector: Field<string>;
		category2Selector: Field<string>;
		category3Selector: Field<string>;
		category4Selector: Field<string>;
		sortOptions: SortOption[];
	};
};

function mapCategoryOptions(
	defaultValue: string,
	categories: Category[] | undefined,
) {
	const categoryOptions = [{ label: defaultValue, value: EMPTY_SELECT_VALUE }];
	if (!categories) {
		return categoryOptions;
	}
	categories.forEach((category) => {
		categoryOptions.push({
			label: category.name,
			value: category.id,
		});
	});
	return categoryOptions;
}
const DEFAULT_BRAND_FACET = 'default';

export default function ProductArchiveSearch({ params, fields }: Props) {
	const { t } = useI18n();
	const [collection, setCollection] = useState<Category | undefined>(undefined);
	const [category, setCategory] = useState<Category | undefined>(undefined);
	const [subCategory, setSubCategory] = useState<Category | undefined>(
		undefined,
	);
	const [productType, setProductType] = useState<Category | undefined>(
		undefined,
	);
	const [query, setQuery] = useState('');
	const [productVariantId, setProductVariantId] = useState('');
	const [brandFacet, setBrandFacet] = useState(DEFAULT_BRAND_FACET);
	const [sortOption, setSortOption] = useState('');
	const {
		items,
		facets,
		total,
		hasNextPage,
		loadMore,
		isLoading,
		isLoadingMore,
	} = useProductArchiveSearch(
		{
			searchString: query,
			category4Id: productType?.id,
			brand: brandFacet === DEFAULT_BRAND_FACET ? undefined : brandFacet,
			sortOption,
		},
		Boolean(query || productType),
	);
	const { isLoading: isLoadingProductVariant, data: productVariant } =
		useProductVariant(productVariantId);
	const {
		isLoading: isLoadingProductVariantSpareParts,
		isLoadingMore: isLoadingMoreProductVariantSpareParts,
		loadMore: loadMoreProductVariantSpareParts,
		items: productVariantSpareParts,
		hasNextPage: productVariantSparePartsHasNextPage,
	} = useRelatedProducts(
		productVariantId,
		'SpareParts',
		Boolean(productVariantId),
	);
	if (!fields) {
		return null;
	}

	return (
		<LayoutContainer outerClassName={getEditorMargin(params)}>
			<ProductArchiveSearchBlock
				collectionSelectLabel={fields.category1Selector.value}
				categorySelectLabel={fields.category2Selector.value}
				subCategorySelectLabel={fields.category3Selector.value}
				productTypeSelectLabel={fields.category4Selector.value}
				collectionOption={collection?.id ?? EMPTY_SELECT_VALUE}
				collectionOptions={mapCategoryOptions(
					fields.category1Selector.value,
					fields.categories,
				)}
				onCollectionOptionChange={(option) => {
					const categoryOptions = fields.categories.find(
						(collectionCategory) => collectionCategory.id === option,
					);
					setCollection(categoryOptions);
					setCategory(undefined);
					setSubCategory(undefined);
					setProductType(undefined);
				}}
				categorySelectDisabled={!collection}
				categoryOption={category?.id ?? EMPTY_SELECT_VALUE}
				categoryOptions={mapCategoryOptions(
					fields.category2Selector.value,
					collection?.categories,
				)}
				onCategoryOptionChange={(option) => {
					const categoryOptions = collection?.categories?.find(
						(categoryCategory) => categoryCategory.id === option,
					);
					setCategory(categoryOptions);
					setSubCategory(undefined);
					setProductType(undefined);
				}}
				subCategoryOption={subCategory?.id ?? EMPTY_SELECT_VALUE}
				subCategoryOptions={mapCategoryOptions(
					fields.category3Selector.value,
					category?.categories,
				)}
				subCategorySelectDisabled={!category}
				onSubCategoryOptionChange={(option) => {
					const categoryOptions = category?.categories?.find(
						(subCategoryCategory) => subCategoryCategory.id === option,
					);
					setSubCategory(categoryOptions);
					setProductType(undefined);
				}}
				productTypeSelectDisabled={!subCategory}
				productTypeOption={productType?.id ?? EMPTY_SELECT_VALUE}
				productTypeOptions={mapCategoryOptions(
					fields.category4Selector.value,
					subCategory?.categories,
				)}
				onProductTypeOptionChange={(option) => {
					const categoryOptions = subCategory?.categories?.find(
						(productTypeCategory) => productTypeCategory.id === option,
					);
					setProductType(categoryOptions);
					setQuery('');
				}}
				query={query}
				onSearchSubmit={({ search }) => {
					setQuery(search);
					setCollection(undefined);
					setCategory(undefined);
					setSubCategory(undefined);
					setProductType(undefined);
				}}
				hasNextPage={hasNextPage}
				isLoadingMoreProducts={Boolean(isLoadingMore)}
				isLoadingProducts={isLoading}
				brandOption={brandFacet || DEFAULT_BRAND_FACET}
				brandOptions={[
					{
						label: t('product_list_brand_facet_heading'),
						value: DEFAULT_BRAND_FACET,
					},
					...(facets
						?.find((facet) => facet.facetType === 'Brand')
						?.facetItems?.map((facet) => ({
							label: facet.item,
							value: facet.item,
						})) ?? []),
				]}
				onBrandOptionChange={(option) => {
					setBrandFacet(option);
				}}
				onLoadMoreProductsClick={() => {
					loadMore();
				}}
				onProductClick={(id) => {
					setProductVariantId(id);
				}}
				selectedCategoryName={productType?.name}
				products={items?.map((product) => ({
					brand: product.brand?.name ?? '',
					id: product.id ?? '',
					imgSrc:
						getProductImageFormat(
							'Templated square transparent',
							product.assets,
						)?.location || '/assets/images/placeholder-image.png',
					title: product.title ?? '',
				}))}
				sortingOption={
					sortOption ||
					fields.sortOptions.find((option) => option.default)?.attribute ||
					''
				}
				sortingOptions={fields.sortOptions.map((option) => ({
					label: option.name,
					value: option.attribute,
				}))}
				onSortingOptionChange={(option) => {
					setSortOption(option);
				}}
				toResultsLinkVisible={Boolean(productType)}
				totalProductsCount={total}
				visibleProductsCount={items.length}
			/>
			<ProductArchiveSearchItemPopover
				isLoadingProduct={isLoadingProductVariant}
				isOpen={Boolean(productVariantId)}
				onClose={() => {
					setProductVariantId('');
				}}
				onSparePartsLoadMore={loadMoreProductVariantSpareParts}
				product={productVariant}
				spareParts={productVariantSpareParts}
				sparePartsHasNextPage={productVariantSparePartsHasNextPage}
				sparePartsIsLoading={isLoadingProductVariantSpareParts}
				sparePartsIsLoadingMore={Boolean(isLoadingMoreProductVariantSpareParts)}
			/>
		</LayoutContainer>
	);
}
ProductArchiveSearch.displayName = 'ProductArchiveSearch';
