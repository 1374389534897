import React from 'react';
import { useRouter } from 'next/router';

import Button from 'components/Button';
import Icon from 'components/Icon';
import InfoBox from 'components/InfoBox';
import { AccountLayoutContainer } from 'components/Layout';
import LoadingSpinner from 'components/LoadingSpinner';
import Placeholder from 'components/Placeholder';
import PriceRow from 'components/PriceRow';
import { SummaryProduct } from 'components/Summary';
import Text from 'components/Text';
import TotalWithVat from 'components/TotalWithVat';
import {
	useCustomerInformation,
	useIsEditing,
	useOrderHistoryDetails,
	useSitecoreContext,
} from 'hooks';
import { JulaComponentProps } from 'lib/component-props';
import { getProductImageFormat } from 'utils/business-logic';
import { formatDate } from 'utils/format';
import { useI18n } from 'utils/i18n';
import { removeTrailingSlash } from 'utils/url';

type Props = JulaComponentProps & {};

function Line({ children }: { children: React.ReactNode }) {
	return <Text as="p">{children}</Text>;
}
Line.displayName = 'AccountOrderDetails_Line';

export default function AccountOrderDetails({ rendering }: Props) {
	const { sitecoreContext } = useSitecoreContext();
	const { globalLinks } = sitecoreContext;
	const { t } = useI18n();
	const router = useRouter();
	const isEditing = useIsEditing();

	const { orderDetails, isLoading, error } = useOrderHistoryDetails(
		removeTrailingSlash(router.asPath).split('/').pop() || '',
	);
	const { customerInformation } = useCustomerInformation();

	const loadedAndOk = !isLoading && !error;
	const {
		transactionHead,
		transactionInformation,
		totalSum,
		discounts,
		vatAmount,
		shippingCost,
		lines,
	} = orderDetails ?? {};

	const formattedPurchaseDate = formatDate(transactionHead?.dateOfPurchase);

	if (isEditing) {
		return (
			<AccountLayoutContainer
				heading={`${t('account_purchases_order')}: Exp editor placeholder`}
				rendering={rendering}
			>
				<Placeholder
					rendering={rendering}
					name="jula-account-details"
					isInNestedPlaceholder
				/>
			</AccountLayoutContainer>
		);
	}

	return (
		<AccountLayoutContainer
			rendering={rendering}
			heading={
				loadedAndOk ? (
					<div className="flex items-start justify-between">
						{formattedPurchaseDate &&
							`${t('account_purchases_order')}: ${formattedPurchaseDate}`}
						<Button
							size="small"
							className="max-lg:hidden print:hidden"
							onClick={() => window.print()}
							rounded
						>
							<Icon icon="printer" className="mr-2" />
							{t('print_button')}
						</Button>
					</div>
				) : null
			}
		>
			{error && (
				<InfoBox icon="error" variant="error">
					{t('jula_pro_order_fetch_error_text')}
				</InfoBox>
			)}
			{isLoading && (
				<div className="flex items-center justify-center">
					<LoadingSpinner />
				</div>
			)}
			{loadedAndOk && (
				<>
					<div className="mb-8">
						{transactionHead?.orderNumber && (
							<Line>
								{t('purchases_order_number_text')}:{' '}
								{transactionHead.orderNumber}
							</Line>
						)}
						{formattedPurchaseDate && (
							<Line>
								{t('purchases_purchase_date_text')}: {formattedPurchaseDate}
							</Line>
						)}
						{transactionHead?.pointOfPurchase && (
							<Line>
								{t('purchases_point_of_purchase_text')}:{' '}
								{transactionHead.pointOfPurchase}
							</Line>
						)}
					</div>

					{transactionHead?.type === 'EcomOrder' && (
						<>
							<div>
								{transactionHead?.primaryPaymentMethod && (
									<Line>
										{t('purchases_payment_method_text')}:{' '}
										{transactionHead.primaryPaymentMethod}
									</Line>
								)}
								{transactionHead?.deliveryMethod && (
									<Line>
										{t('purchases_delivery_method_text')}:{' '}
										{transactionHead.deliveryMethod}
									</Line>
								)}
								{(transactionHead?.status?.text ||
									transactionHead?.trackingLink) && (
									<div className="flex items-center">
										<Line>
											{`${t('purchases_status_text')}: ${
												transactionHead.status.text
											}`}
										</Line>
									</div>
								)}
								{transactionHead?.orderId &&
									transactionHead?.type === 'EcomOrder' &&
									customerInformation?.id &&
									globalLinks?.track && (
										<a
											href={`${globalLinks?.track}?id=${transactionHead.orderId}&c=${customerInformation.id}`}
											className="underline hover:no-underline"
											target="_blank"
											rel="noopener noreferrer"
										>
											{`${t('purchase_track_order_button_text')}`}
										</a>
									)}
							</div>
							{transactionHead.primaryPaymentMethodId === 'RES' && (
								<Placeholder
									rendering={rendering}
									name="jula-account-details"
									isInNestedPlaceholder
								/>
							)}
							{(transactionInformation?.invoiceAddress ||
								transactionInformation?.deliveryAddress) && (
								<div className="mb-9 sm:mb-10">
									<Text as="h2" styleAs="h3" className="mb-4 mt-8 font-bold">
										{t('purchases_your_information_heading')}
									</Text>
									<div className="md:flex">
										{transactionInformation?.deliveryAddress && (
											<div className="mb-4 md:mb-0 md:w-1/2">
												<Text as="p" className="mb-2 font-bold">
													{t('purchases_order_will_send_to_text')}
												</Text>
												<Line>
													{transactionInformation.deliveryAddress.name}
													<br />
													{transactionInformation?.deliveryAddress.co && (
														<>
															{transactionInformation.deliveryAddress.co}
															<br />
														</>
													)}
													{transactionInformation.deliveryAddress.street}
													<br />
													{`${transactionInformation.deliveryAddress.postalCode}, ${transactionInformation.deliveryAddress.city}`}
												</Line>
											</div>
										)}
										{transactionInformation?.invoiceAddress && (
											<div className="md:w-1/2">
												<Text as="p" className="mb-2 font-bold">
													{t('purchases_order_invoice_address_text')}
												</Text>
												<Line>
													{Boolean(
														transactionInformation?.invoiceAddress
															.vatRegistrationNumber,
													) && (
														<>
															{
																transactionInformation.invoiceAddress
																	.vatRegistrationNumber
															}
															<br />
														</>
													)}
													{transactionInformation.invoiceAddress.name}
													<br />
													{transactionInformation?.invoiceAddress.co && (
														<>
															{transactionInformation.invoiceAddress.co}
															<br />
														</>
													)}
													{transactionInformation.invoiceAddress.street}
													<br />
													{`${transactionInformation.invoiceAddress.postalCode}, ${transactionInformation.invoiceAddress.city}`}
												</Line>
											</div>
										)}
									</div>
								</div>
							)}

							{transactionInformation?.contactDetails && (
								<div className="my-4">
									<Text as="p" className="mb-2 font-bold">
										{t('purchases_contact_info_heading')}
									</Text>
									{transactionInformation?.contactDetails?.email && (
										<Line>{transactionInformation.contactDetails.email}</Line>
									)}
									{transactionInformation?.contactDetails?.phoneNumber && (
										<Line>
											{transactionInformation.contactDetails.phoneNumber}
										</Line>
									)}
								</div>
							)}
						</>
					)}

					{(transactionInformation?.fixedReferenceText ||
						transactionInformation?.freeReferenceText ||
						transactionInformation?.buyer) && (
						<div className="mb-9 sm:mb-10">
							<div className="md:flex">
								{transactionInformation?.buyer && (
									<div className="mb-4 md:mb-0 md:w-1/2">
										<Text as="p" className="mb-2 font-bold">
											{t('purchases_order_buyer_heading')}
										</Text>
										<Line>{transactionInformation.buyer}</Line>
									</div>
								)}
								{(transactionInformation?.fixedReferenceText ||
									transactionInformation?.freeReferenceText) && (
									<div className="md:w-1/2">
										<Text as="p" className="mb-2 font-bold">
											{t('purchases_order_reference_heading')}
										</Text>
										{transactionInformation?.fixedReferenceText && (
											<Line>{transactionInformation.fixedReferenceText}</Line>
										)}
										{transactionInformation?.freeReferenceText && (
											<Line>{transactionInformation.freeReferenceText}</Line>
										)}
									</div>
								)}
							</div>
						</div>
					)}
					{lines && (
						<div className="mb-9 sm:mb-10">
							<Text as="h2" styleAs="h3" className="mb-4 mt-8 font-bold">
								{t('purchases_your_order_heading')}
							</Text>
							{lines?.map((line) => (
								<SummaryProduct
									link={line?.url}
									amount={line?.quantity}
									heading={line.title}
									subHeading={line.price?.displayValue}
									imageSrc={
										getProductImageFormat(
											'Templated square transparent',
											line?.mainImage?.formats || [],
										)?.url.location || '/assets/images/placeholder-image.png'
									}
									articleNumber={line.variantId}
									rowSum={line?.price}
									discount={false}
									key={line.variantId}
								/>
							))}
						</div>
					)}
					{discounts?.bonusDiscount && (
						<div className="mt-2">
							<PriceRow
								variant="minusPrice"
								title={t('purchases_bonus_discount_text')}
								price={discounts.bonusDiscount.displayValue}
								priceSymbol={discounts.bonusDiscount.displaySymbol}
							/>
						</div>
					)}
					{discounts?.campaignDiscount && (
						<div className="mt-2">
							<PriceRow
								variant="minusPrice"
								title={t('purchases_campaign_discount_text')}
								price={discounts.campaignDiscount.displayValue}
								priceSymbol={discounts.campaignDiscount.displaySymbol}
							/>
						</div>
					)}
					{discounts?.customerDiscount && (
						<div className="mt-2">
							<PriceRow
								variant="minusPrice"
								title={t('purchases_customer_discount_text')}
								price={discounts.customerDiscount.displayValue}
								priceSymbol={discounts.customerDiscount.displaySymbol}
							/>
						</div>
					)}
					{discounts?.miscDiscount && (
						<div className="mt-2">
							<PriceRow
								variant="minusPrice"
								title={t('purchases_misc_discount_text')}
								price={discounts.miscDiscount.displayValue}
								priceSymbol={discounts.miscDiscount.displaySymbol}
							/>
						</div>
					)}
					{shippingCost && (
						<div className="mt-3">
							<PriceRow
								variant="regular"
								title={t('purchases_shippingcost_text')}
								price={shippingCost.displayValue}
								priceSymbol={shippingCost.displaySymbol}
							/>
						</div>
					)}
					{totalSum && (
						<TotalWithVat
							className="mt-3"
							title={t('purchases_your_total_text')}
							price={totalSum.displayValue}
							priceSymbol={totalSum.displaySymbol}
							vat={vatAmount?.displayValue}
							vatCurrencySymbol={vatAmount?.displaySymbol}
						/>
					)}
				</>
			)}
			<div className="mx-auto mt-10 flex justify-center lg:hidden print:hidden">
				<Button size="small" onClick={() => window.print()} rounded>
					<Icon icon="printer" className="mr-2" />
					{t('print_button')}
				</Button>
			</div>
		</AccountLayoutContainer>
	);
}
AccountOrderDetails.displayName = 'AccountOrderDetails';
