import React from 'react';
import clsx from 'clsx';

import { useI18n } from 'utils/i18n';
import { getWeightedPercentage } from 'utils/math';
import { makeSpacesNoBreak } from 'utils/string';

interface Point {
	count: number;
	text: string;
}
interface Props {
	className?: string;
	description?: string;
	points: Point[];
	title: string;
}

/**
 * Takes an array of options and calculates the weighted average of them based
 * on the count in each one. That average is displayed on a line scale.
 */
export default function WeightedAverageScale({
	className,
	description,
	points,
	title,
}: Props) {
	const { t } = useI18n();
	const percentage = getWeightedPercentage(...points.map((p) => p.count));
	const lastPointIndex = points.length - 1;

	return (
		<div className={className}>
			<p
				className={clsx(
					'font-bold',
					description && 'mb-1',
					!description && 'mb-6',
				)}
			>
				{title}
			</p>
			{description && <p className="mb-6 text-sm">{description}</p>}
			<div aria-hidden>
				<div className="relative h-0.5 w-full bg-black">
					<div
						className="absolute -top-2 size-4 rounded-full bg-black"
						style={{ left: `${percentage}%` }}
					/>
				</div>
				<div className="mt-4 flex">
					{points.map((point, index) => (
						<p
							key={point.text}
							className={clsx(
								'min-w-0 flex-1 break-words',
								index === 0 && 'pr-2',
								index > 0 && index < lastPointIndex && 'px-2 text-center',
								index === lastPointIndex && 'pl-2 text-right',
							)}
						>
							{`${point.text} (${makeSpacesNoBreak(t('reviews_grade_count_text', { ratingCount: point.count }))})`}
						</p>
					))}
				</div>
			</div>
		</div>
	);
}
WeightedAverageScale.displayName = 'WeightedAverageScale';
