/**
 * ProductListMicroContentItem
 */

import React from 'react';

import {
	MicroContentChipLinks,
	MicroContentMedia,
	MicroContentText,
} from 'components/MicroContent';
import { pushToGTM } from 'utils/GoogleTagManager';

import type { FilteredMicroContent } from './helpers';

interface Props {
	data: FilteredMicroContent;
}

/** A single card of micro content. */
export default function ProductListMicroContentItem({ data }: Props) {
	const handleLinkClick = () => {
		pushToGTM({ type: 'select_promotion', payload: data });
	};

	if (data.type === 'ChipLinks') {
		return (
			<MicroContentChipLinks
				links={data.links}
				title={data.title}
				onChipClick={handleLinkClick}
			/>
		);
	}
	if (data.type === 'Media') {
		return (
			<MicroContentMedia
				orientation="portrait"
				href={data.link.href}
				text={data.link.text}
				imgSrc={data.image.src}
				imgWidth={300}
				imgHeight={400}
				onClick={handleLinkClick}
			/>
		);
	}
	if (data.type === 'Text') {
		return (
			<MicroContentText
				title={data.title}
				text={data.text}
				className="self-stretch"
			/>
		);
	}

	return null;
}
ProductListMicroContentItem.displayName = 'ProductListMicroContentItem';
