/**
 * ExpandableDescription
 */

import React, { useEffect, useState } from 'react';
import clsx from 'clsx';

import Button, { ButtonVariant } from 'components/Button';
import RichText from 'components/RichText';
import Text from 'components/Text';
import { useI18n } from 'utils/i18n';

interface Props {
	/** Content after the description text */
	afterDescription?: React.ReactNode;

	/** Content before the description text */
	beforeDescription?: React.ReactNode;

	/** Container class name */
	className?: string;

	/** Description text class name */
	descriptionClassName?: string;

	/** The description text */
	description: string | undefined;

	/** Description heading */
	heading?: string;

	/** Should it be open by default? */
	open?: boolean;

	/** If the heading should be visible, otherwise just for screen readers */
	showHeading?: boolean;

	/** Character limit to display the expand button */
	textLengthBreakPoint: number;

	/** Hidden height */
	textHeight?: 'short' | 'tall';

	/** Button variant */
	buttonVariant?: ButtonVariant;

	/** Button placement */
	buttonPlacement?: 'left' | 'middle' | 'right';
}

/** Text content with a max height, expandable with a 'read more' button. */
export default function ExpandableDescription({
	afterDescription,
	beforeDescription,
	className,
	descriptionClassName,
	description,
	heading,
	open = false,
	showHeading = false,
	textLengthBreakPoint,
	buttonVariant = 'primary',
	buttonPlacement = 'middle',
	textHeight = 'tall',
}: Props) {
	const { t } = useI18n();
	const isLessThanBreakPoint =
		description && description.length < textLengthBreakPoint;
	const [isExpanded, setIsExpanded] = useState(open || isLessThanBreakPoint);

	useEffect(() => {
		if (!isExpanded && isLessThanBreakPoint) {
			setIsExpanded(true);
		}
	}, [description, textLengthBreakPoint, isExpanded, isLessThanBreakPoint]);

	const handleExpandClick = () => {
		setIsExpanded(true);
	};

	return (
		<div className={className}>
			{heading && showHeading && (
				<Text as="h2" className="mb-2">
					{heading}
				</Text>
			)}
			<div
				className={clsx(
					'relative overflow-hidden',
					// When collapsed, the expand button area has bottom spacing.
					// Add some when expanded to match the look.
					isExpanded && 'mb-6',
					!isExpanded && {
						'h-[15rem]': textHeight === 'short',
						'max-h-[20rem] sm:max-h-[25rem]': textHeight === 'tall',
					},
				)}
			>
				{beforeDescription}
				{description && (
					<RichText className={descriptionClassName} html={description} />
				)}
				{afterDescription}

				{!isExpanded && (
					<div
						className={clsx(
							'absolute bottom-0 left-0 right-0 flex h-[8.5rem] items-end bg-gradient-to-t from-white to-transparent pb-6',
							buttonPlacement === 'left' && 'justify-start',
							buttonPlacement === 'middle' && 'justify-center',
							buttonPlacement === 'right' && 'justify-end',
							buttonVariant === 'text' ? 'from-50%' : 'from-65%',
						)}
					>
						<Button variant={buttonVariant} onClick={handleExpandClick}>
							{t('product_details_description_read_more_button')}
							<Text as="screenReader">, {heading}</Text>
						</Button>
					</div>
				)}
			</div>
		</div>
	);
}
ExpandableDescription.displayName = 'ExpandableDescription';
