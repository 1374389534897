/**
 * Checkout
 */

import React from 'react';

import Link from 'components/Link';
import Text from 'components/Text';
import {
	ApiJulaModelsCartCartResponseDiscountModel,
	ApiJulaModelsCartCartResponseGiftCardModel,
	ApiJulaModelsCartSummaryModel,
} from 'models/api';

import PriceSummaries from './PriceSummaries';

interface Props {
	/** React children */
	children?: React.ReactNode;

	/** Heading text of the cart summary component */
	heading?: string;

	/** The heading of the link text in the header */
	headingLinkText?: string;
	/** Summaries for the cart, used by the summary component */
	summaries: ApiJulaModelsCartSummaryModel[] | undefined;
	/** All gift cards added to the cart, used by the summary component */
	giftCards: ApiJulaModelsCartCartResponseGiftCardModel[] | undefined;
	/** All bonus discount codes added to the cart, used by the summary component */
	bonusDiscounts: ApiJulaModelsCartCartResponseDiscountModel[] | undefined;
	/** Total savings, if any, used by the summary component */
	savingTotalSumSummary: ApiJulaModelsCartSummaryModel | undefined;
}

/** The cart summary component used in the cart page. */
export default function CheckoutSummary({
	heading,
	headingLinkText,
	children,
	summaries,
	giftCards,
	bonusDiscounts,
	savingTotalSumSummary,
}: Props) {
	return (
		<>
			<div className="mb-2 mt-20 flex items-center justify-between md:mt-2">
				<Text as="h2">{heading}</Text>
				{headingLinkText && (
					<Text as="pSmall">
						<Link href="/Cart" underline>
							{headingLinkText}
						</Link>
					</Text>
				)}
			</div>
			{children}
			<PriceSummaries
				className="mt-4"
				summaries={summaries}
				bonusDiscounts={bonusDiscounts}
				savingTotalSumSummary={savingTotalSumSummary}
				giftCards={giftCards}
			/>
		</>
	);
}
CheckoutSummary.displayName = 'CheckoutSummary';
