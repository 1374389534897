import * as React from 'react';
import { RichTextField, TextField } from '@sitecore-jss/sitecore-jss-react';

import { LayoutContainer } from 'components/Layout';
import RichText from 'components/RichText';
import Text from 'components/Text';
import { JulaComponentProps } from 'lib/component-props';
import { getEditorMargin } from 'utils/business-logic';

type ContentBlockInfoProps = JulaComponentProps & {
	fields: {
		heading: TextField;
		content: RichTextField;
	};
};

/**
 * A simple Content Block Information component, with a yellow background, heading and rich text block.
 * This is the most basic building block of a content site, and the most basic
 * JSS component that's useful.
 */
function ContentBlockInfo({ params, fields }: ContentBlockInfoProps) {
	if (!fields) {
		return null;
	}

	const { heading: headingLevel = 'h2' } = params || {};

	return (
		<LayoutContainer
			id={params?.anchor}
			outerClassName={getEditorMargin(params)}
			withGrid
			withPopoverLayout
		>
			<div className="col-span-4 bg-information p-4 md:col-span-6 md:col-start-4">
				{fields?.heading && (
					<Text
						as={headingLevel}
						styleAs="cursiveHeader"
						field={fields?.heading}
						className="mb-2"
					/>
				)}

				<RichText field={fields?.content} />
			</div>
		</LayoutContainer>
	);
}
ContentBlockInfo.displayName = 'ContentBlockInfo';

export default ContentBlockInfo;
