import { WebStock as InventoryWebStock } from 'models/inventory';
import { Product } from 'models/product';
import { Extends } from 'types';

import { StatusType } from './statusType';

export interface ProductDetailsWebStock
	extends Pick<
			Required<Product>['salesAttributes'],
			| 'isSellable'
			| 'isSellableOnWeb'
			| 'isClickAndCollectOnly'
			| 'isWatchableIfOutOfStock'
		>,
		Pick<
			InventoryWebStock,
			| 'reStockWithinAcceptableTimespan'
			| 'nextDeliveryInMonth'
			| 'availableStockLevel'
		> {}

export function getProductDetailsWebStockStatus({
	isSellable,
	isSellableOnWeb,
	isClickAndCollectOnly,
	isWatchableIfOutOfStock,
	reStockWithinAcceptableTimespan,
	nextDeliveryInMonth,
	availableStockLevel,
}: ProductDetailsWebStock): ProductDetailsWebStockStatusType {
	switch (true) {
		case isSellable &&
			isSellableOnWeb &&
			!isClickAndCollectOnly &&
			availableStockLevel > 0:
			return 'InStock';
		case isSellable &&
			isSellableOnWeb &&
			isClickAndCollectOnly &&
			availableStockLevel > 0:
			return 'ClickAndCollect';
		case !isSellable &&
			isSellableOnWeb &&
			!isWatchableIfOutOfStock &&
			!reStockWithinAcceptableTimespan &&
			availableStockLevel === 0:
			return 'OutOfStock';
		case !isSellable &&
			isSellableOnWeb &&
			isWatchableIfOutOfStock &&
			reStockWithinAcceptableTimespan &&
			nextDeliveryInMonth &&
			availableStockLevel === 0:
			return 'ReStock-Watchable';
		case !isSellable &&
			isSellableOnWeb &&
			isWatchableIfOutOfStock &&
			reStockWithinAcceptableTimespan &&
			!nextDeliveryInMonth:
			return 'ReStock-Watchable-Delayed';
		case !isSellable &&
			isSellableOnWeb &&
			!isWatchableIfOutOfStock &&
			reStockWithinAcceptableTimespan &&
			nextDeliveryInMonth &&
			availableStockLevel === 0:
			return 'ReStock-NotWatchable';
		case !isSellable &&
			isSellableOnWeb &&
			!isWatchableIfOutOfStock &&
			reStockWithinAcceptableTimespan &&
			!nextDeliveryInMonth:
			return 'ReStock-NotWatchable-Delayed';
		case !isSellableOnWeb:
			return 'InStoreOnly';
		default:
			return 'MatchFailed';
	}
}

export type ProductDetailsWebStockStatusType = Extends<
	StatusType,
	| 'InStock'
	| 'ClickAndCollect'
	| 'OutOfStock'
	| 'ReStock-Watchable'
	| 'ReStock-Watchable-Delayed'
	| 'ReStock-NotWatchable'
	| 'ReStock-NotWatchable-Delayed'
	| 'InStoreOnly'
	| 'MatchFailed'
>;
