import React from 'react';

import type { JulaSitecoreServiceData, UserInfo } from 'lib/page-props';

import BaseLayout from './BaseLayout';

type Props = {
	layoutData: JulaSitecoreServiceData;
	userInfo?: UserInfo;
};

export default function Layout({ layoutData, userInfo }: Props) {
	const { route } = layoutData.sitecore;

	return (
		<BaseLayout layoutData={layoutData} route={route} userInfo={userInfo} />
	);
}
Layout.displayName = 'Layout';
