/**
 * PopularProducts
 */

import React from 'react';
import type { HTMLAttributes } from 'react';

import ProductLine from 'components/ProductLine';
import { Skeleton, SkeletonItem } from 'components/Skeleton';
import Text from 'components/Text';
import { usePopularProducts } from 'hooks';
import { ProductCard } from 'models/productCard';
import { range } from 'utils/helpers';

interface BaseProps extends HTMLAttributes<HTMLDivElement> {
	/** Heading text. */
	heading?: string;

	/** Number of products to fetch. */
	itemLimit?: number;
}

interface Props extends BaseProps {
	/** Category to get popular products for. */
	productCategory?: string;
}

interface ListProps extends BaseProps {
	products: ProductCard[];
}

export function PopularProductsList({
	heading,
	itemLimit,
	products,
	...props
}: ListProps) {
	const hasProducts = products.length > 0;
	const itemMarginClasses = 'first:mt-0 mt-6 md:mt-4';

	return (
		<div {...props}>
			{heading && (
				<Text as="h3" className="mb-6">
					{heading}
				</Text>
			)}
			{hasProducts && (
				<ul>
					{products.map((product) => (
						<li key={product.id} className={itemMarginClasses}>
							<ProductLine product={product} />
						</li>
					))}
				</ul>
			)}
			{!hasProducts && (
				<Skeleton>
					{range(itemLimit || 5).map((i) => (
						<SkeletonItem
							key={i}
							width="100%"
							height="40px"
							className={itemMarginClasses}
						/>
					))}
				</Skeleton>
			)}
		</div>
	);
}
PopularProductsList.displayName = 'PopularProductsList';

/** List of X popular products, optinally with a heading. */
export default function PopularProducts({
	itemLimit,
	productCategory,
	...props
}: Props) {
	const { products } = usePopularProducts({
		category: productCategory,
		itemLimit,
	});

	return (
		<PopularProductsList products={products} itemLimit={itemLimit} {...props} />
	);
}
PopularProducts.displayName = 'PopularProducts';
