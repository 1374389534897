/**
 * SearchResultsPage
 */

import React from 'react';
import { useRouter } from 'next/router';

import Link from 'components/Link';
import ProductListComponent, { OFFSET_QUERY_VAR } from 'components/ProductList';
import Text from 'components/Text';
import { withRequiredProps } from 'hoc';
import type { JulaComponentProps } from 'lib/component-props';
import type { ProductList as ProductListModel } from 'models/productlist';
import { pushToGTM } from 'utils/GoogleTagManager';
import { useI18n } from 'utils/i18n';

type Props = JulaComponentProps & {
	fields: ProductListModel & {
		spellingSuggestions: string[];
	};
};

function SearchResultsPage({ fields, params }: Props) {
	const { t } = useI18n();
	const router = useRouter();
	const query = router.query.query?.toString() || '';

	if (!query) {
		return (
			<div className="mx-4 my-20 text-center">
				<Text as="h1" text={t('search_no_search_results_text')} />
			</div>
		);
	}

	const gtmListName = 'Search result';
	const hasSpellSuggestions = fields.spellingSuggestions?.length > 0;

	return (
		<ProductListComponent
			key={query}
			editorMargin={params?.margin}
			id={params?.anchor}
			fields={fields}
			placeholderComponentName="SearchResultsPage"
			searchQuery={query}
			layoutArea1Content={
				<>
					<Text as="h1" className="text-left">
						<>
							{t('search_query_label')}{' '}
							<span className="[quotes:auto] before:content-[open-quote] after:content-[close-quote]">
								{query}
							</span>
						</>
					</Text>
					{hasSpellSuggestions && (
						<Text as="p" className="mt-4 text-left">
							{t('search_results_spelling_suggestion_text')}{' '}
							{fields.spellingSuggestions.map((suggestion, index) => (
								<React.Fragment key={suggestion}>
									<Link
										href={`/search/?query=${suggestion}`}
										className="underline hover:no-underline"
									>
										{suggestion}
									</Link>
									{index < fields.spellingSuggestions.length - 1 ? ', ' : ''}
								</React.Fragment>
							))}
							?
						</Text>
					)}
				</>
			}
			onProductListUpdate={(visibleProducts) => {
				pushToGTM({
					type: 'site_search',
					payload: {
						products: visibleProducts,
						pageSize: router.query[OFFSET_QUERY_VAR]
							? Number.parseInt(router.query[OFFSET_QUERY_VAR].toString(), 10)
							: fields.nextPageOffset,
						listName: gtmListName,
						itemListId: query,
					},
				});
			}}
			onProductCardClick={(product, visibleProducts) => {
				pushToGTM({
					type: 'search_list_select_item',
					payload: {
						selectedProduct: product,
						productList: visibleProducts,
						listName: gtmListName,
						itemListId: query,
					},
				});
			}}
		/>
	);
}
SearchResultsPage.displayName = 'SearchResultsPage';

export default withRequiredProps(SearchResultsPage, 'fields');
