import React, { useEffect } from 'react';
import { useRouter } from 'next/router';

import ConfirmationPageHeader from 'components/ConfirmationPageHeader';
import InfoBox from 'components/InfoBox';
import LoadingSpinner from 'components/LoadingSpinner';
import {
	ConfirmationSummary,
	Summary,
	SummaryProduct,
} from 'components/Summary';
import Text from 'components/Text';
import { useGlobalStateContext } from 'contexts';
import { useEffectOnce, useOrderInformation } from 'hooks';
import { getProductImageFormat } from 'utils/business-logic';
import { is } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

export default function CommerceConfirmationPage() {
	const router = useRouter();
	const { cartService } = useGlobalStateContext();
	useEffectOnce(() => {
		cartService.send('GET_CART');
	});
	const { t } = useI18n();
	const orderId = is.arrayWithLength(router?.query.orderId)
		? router?.query.orderId[0]
		: router?.query.orderId;
	const { orderInformation, isLoading, error } = useOrderInformation(orderId);
	const {
		variants,
		email,
		orderNumber,
		summaries,
		savingSummaries,
		giftCards,
		bonusDiscounts,
	} = orderInformation || {};
	const savingTotalSumSummary = savingSummaries?.find(
		(summary) => summary.sumType === 'SavingTotalSum',
	);
	useEffect(() => {
		router.beforePopState(() => {
			router.push('/Cart');
			return false;
		});
		window?.scrollTo(0, 0);
		return () => {
			router.beforePopState(() => true);
		};
	}, []);

	return (
		<div className="mx-4 mt-14 max-w-pageStandard md:mx-auto md:mt-20">
			<div className="flex justify-between">
				<div className=" md:w-1/4" />
				{isLoading && (
					<LoadingSpinner className="fixed left-1/2 top-1/2 z-pageLoadingSpinner -mb-20 -ml-20 -mt-20 mr-0" />
				)}
				{error && (
					<InfoBox icon="error" iconColor="julaRed" variant="error">
						<Text
							className="mb-2"
							as="p"
							text={t('order_confirmation_failure_loading_order')}
						/>
					</InfoBox>
				)}
				{!isLoading && !error && (
					<div className="max-w-[50.625rem] flex-grow">
						<div className="mb-14 md:mb-20">
							<ConfirmationPageHeader
								heading={t('order_confirmation_heading', {
									firstName: orderInformation?.deliveryAddress?.firstName,
								})}
							>
								<Text as="p" className="mb-2">
									{t('order_confirmation_order_number_text')}{' '}
									<strong>{orderNumber}</strong>
								</Text>
								<Text as="p" className="mb-8 md:mb-14">
									{t('order_confirmation_delivery_message_text')}{' '}
									<strong>{email}</strong>
								</Text>
							</ConfirmationPageHeader>
						</div>
						<div className="mx-auto max-w-810">
							<Summary variant="confirmation">
								<ConfirmationSummary
									heading={t('order_confirmation_order_summary_heading')}
									summaries={summaries}
									giftCards={giftCards}
									bonusDiscounts={bonusDiscounts}
									savingTotalSumSummary={savingTotalSumSummary}
								>
									{variants?.map((variant) => (
										<SummaryProduct
											key={variant.id}
											link={variant.url}
											heading={variant.title}
											subHeading={variant.subTitle}
											imageSrc={
												getProductImageFormat(
													'Templated square transparent',
													variant.listImageFormats,
												)?.location || '/assets/images/placeholder-image.png'
											}
											articleNumber={variant.id}
											rowSum={variant.rowSum}
											amount={variant.qty}
											discount={
												is.defined(variant.price.saveIncVat)
													? variant.price.saveIncVat.value > 0
													: false
											}
										/>
									))}
								</ConfirmationSummary>
							</Summary>
						</div>
					</div>
				)}

				<div className="md:w-1/4" />
			</div>
		</div>
	);
}
CommerceConfirmationPage.displayName = 'CommerceConfirmationPage';
