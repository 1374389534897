/**
 * StoreInfo
 */

import React from 'react';
import clsx from 'clsx';

import Icon from 'components/Icon';
import Status from 'components/Status';
import Text from 'components/Text';
import { OpeningHours as OpeningHoursType } from 'models/store';
import cn from 'utils/cn';
import { is } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

interface OpeningHoursProps {
	className?: string;
	openingHours: OpeningHoursType[];
}

function OpeningHours({ className, openingHours }: OpeningHoursProps) {
	if (openingHours.length === 0) {
		return null;
	}

	return (
		<dl className={clsx('font-standard text-base', className)}>
			{openingHours.map(
				({ title, type, weekday, dateStr, shortDescription }) => (
					<div
						key={`${weekday}-${title}-${dateStr}`}
						className="mb-2 flex justify-between gap-6"
					>
						<dt>
							{type?.toUpperCase() === 'SPECIAL'
								? `${title} (${dateStr})`
								: weekday}
						</dt>
						<dd>{shortDescription}</dd>
					</div>
				),
			)}
		</dl>
	);
}

OpeningHours.displayName = 'OpeningHours';

interface Props {
	name: string;
	todaysOpeningHours: OpeningHoursType;
	storeArea: string;
	streetAddress: string;
	postalCode: string;
	city: string;
	latitude: string;
	longitude: string;
	regularOpeningHours: OpeningHoursType[] | undefined;
	specialOpeningHours: OpeningHoursType[] | undefined;
	// these two are only needed because we don't have container queries yet
	addressSectionClassName?: string;
	iconClassName?: string;
}

export default function StoreInfo({
	city,
	latitude,
	longitude,
	name,
	postalCode,
	regularOpeningHours,
	specialOpeningHours,
	storeArea,
	streetAddress,
	todaysOpeningHours,
	addressSectionClassName = 'max-md:flex max-md:flex-wrap max-md:justify-between',
	iconClassName,
}: Props) {
	const { t } = useI18n();

	return (
		<>
			{name && <Text as="h1" text={name} className="mb-6" />}

			<Status
				color={
					todaysOpeningHours.state?.toUpperCase() === 'CLOSED' ? 'red' : 'green'
				}
				text={todaysOpeningHours.description || ''}
				className="mb-4"
			/>

			<div className={addressSectionClassName}>
				<Text as="p" className="mb-4 md:mb-6">
					{storeArea}
					<br />
					{streetAddress}
					<br />
					{postalCode} {city}
				</Text>

				<a
					href={`https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`}
					target="_blank"
					className={cn(
						'group mb-8 inline-flex items-center gap-1 max-md:flex-col md:mb-10 md:gap-2',
						iconClassName,
					)}
					aria-label={t('stores_find_store_long_text_button')}
				>
					<span className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-julaRed">
						<Icon icon="location" color="white" />
					</span>
					<span className="underline group-hover:no-underline">
						{t('stores_find_store_short_text_button')}
					</span>
				</a>
			</div>

			{(is.arrayWithLength(regularOpeningHours) ||
				is.arrayWithLength(specialOpeningHours)) && (
				<>
					<Text
						as="h2"
						text={t('stores_opening_hours_heading')}
						className="mb-2 md:mb-4"
					/>
					{regularOpeningHours && (
						<OpeningHours openingHours={regularOpeningHours} />
					)}
					{specialOpeningHours && (
						<>
							<Text
								as="h3"
								text={t('stores_special_opening_hours_heading')}
								className="mb-2 mt-4 md:mb-4"
							/>
							<OpeningHours
								openingHours={specialOpeningHours}
								className="mt-6"
							/>
						</>
					)}
				</>
			)}
		</>
	);
}
StoreInfo.displayName = 'StoreInfo';
