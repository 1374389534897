import clsx from 'clsx';
import { Field } from '@sitecore-jss/sitecore-jss-nextjs';
import { JulaComponentProps } from 'lib/component-props';

type spaces = 'half' | 'single' | 'double';

type SpacerProps = JulaComponentProps & {
	fields: {
		space: Field<spaces>;
	};
};

const Spacer = (props: SpacerProps): JSX.Element => {
	const { space } = props.fields;

	return (
		<div
			className={clsx(
				space.value === 'half' && 'h-6',
				space.value === 'single' && 'h-10',
				space.value === 'double' && 'h-20',
			)}
		></div>
	);
};
Spacer.displayName = 'Spacer';

export default Spacer;
