import React from 'react';

import { MicroContentMedia } from 'components/MicroContent';
import Text from 'components/Text';
import type { InspirationLink } from 'models/sitecore';
import { getAsset } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

interface Props {
	className?: string;
	inspirationLink: InspirationLink | undefined;
}

export default function PageHeaderInspirationMedia({
	className,
	inspirationLink,
}: Props) {
	const { t } = useI18n();
	const image = getAsset(
		'Templated 2:1',
		inspirationLink?.fields?.landscapeImage,
	);
	const linkText = inspirationLink?.fields?.navigationTitle?.value;

	if (!image?.src || !linkText) {
		return null;
	}

	return (
		<div className={className}>
			<Text as="h3" className="mb-4 sm:mb-6">
				{t('mega_drop_inspiration_link_heading')}
			</Text>
			<MicroContentMedia
				href={inspirationLink.href}
				text={linkText}
				imgSrc={image.src}
				imgHeight={image.height}
				imgWidth={image.width}
			/>
		</div>
	);
}
PageHeaderInspirationMedia.displayName = 'PageHeaderInspirationMedia';
