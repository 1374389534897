/**
 * Teaser
 */

import React from 'react';

import ArrowLink from 'components/ArrowLink';
import Icon from 'components/Icon';
import Img from 'components/Img';
import Link from 'components/Link';
import Text from 'components/Text';
import { HeadingLevel } from 'lib/component-props';

interface Props {
	/** Teaser heading */
	heading: string;

	/** Teaser heading level */
	headingLevel?: HeadingLevel;

	/** Teaser link */
	link: string;

	/** Teaser link text */
	linkText: string;

	/** Teaser video */
	video?: string;

	/** Teaser image src */
	imageSrc?: string;

	/** Teaser image alt */
	imageAlt?: string;
}

/** Teaser component */

function Teaser({
	heading,
	headingLevel = 'h2',
	link,
	linkText,
	video,
	imageSrc,
	imageAlt = '',
}: Props) {
	return (
		<div className="mr-6 flex w-full flex-col justify-center no-underline max-sm:mx-auto max-sm:mb-8 max-sm:mt-0 max-sm:items-center sm:last:mr-0">
			<Link href={link} className="hover:cursor-pointer">
				{video && (
					<div className="relative h-full">
						<video
							muted
							autoPlay
							loop
							playsInline
							className="block h-[24.6875rem] w-full max-w-full object-cover sm:h-full"
						>
							<source src={video} />
							{imageSrc && (
								<Img
									src={imageSrc}
									alt={imageAlt}
									width={550}
									height={733}
									service="nextjs"
									className="w-full sm:object-cover"
								/>
							)}
						</video>
					</div>
				)}
				{!video && imageSrc && (
					<Img
						src={imageSrc}
						alt={imageAlt}
						width={550}
						height={733}
						service="nextjs"
						className="w-full sm:object-cover"
					/>
				)}
			</Link>
			<div className="w-full flex-auto sm:flex sm:flex-col">
				<Text
					as={headingLevel}
					className="mb-2.5 mt-5 text-center text-greyDarker md:text-left"
				>
					{heading}
				</Text>
				<ArrowLink href={link} text={linkText} />
			</div>
		</div>
	);
}

Teaser.displayName = 'Teaser';

export default Teaser;
