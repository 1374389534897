import type { UserInfoMachineState } from './userInfo.types';

export const selectEmail = (state: UserInfoMachineState) => state.context.email;

export const selectPostalCode = (state: UserInfoMachineState) =>
	state.context.deliveryAddress?.postalCode;

export const selectTelephoneNumber = (state: UserInfoMachineState) =>
	state.context.telephoneNumber;

export const selectCustomerAddress = (state: UserInfoMachineState) =>
	state.context.customerAddress;

export const selectDeliveryAddress = (state: UserInfoMachineState) =>
	state.context.deliveryAddress;

export const selectIsAlternativeDeliveryAddressSelectable = (
	state: UserInfoMachineState,
) => state.context.isAlternativeDeliveryAddressSelectable;

export const selectAlternativeDeliveryAddress = (state: UserInfoMachineState) =>
	state.context.alternativeDeliveryAddress;

export const selectUnregisteredCompanyInfo = (state: UserInfoMachineState) =>
	state.context.unregisteredCompanyInfo;

export const selectCompanyIdentificationNumber = (
	state: UserInfoMachineState,
) => state.context.companyIdentificationNumber;

export const selectIsIdle = (state: UserInfoMachineState) =>
	state.matches('idle');

export const selectHasCustomerAddressPostalCodeValidationError = (
	state: UserInfoMachineState,
) =>
	state?.context?.error?.some(
		(error) => error.key === 'customerAddress.postalCode',
	);

export const selectCustomerInfo = (state: UserInfoMachineState) =>
	state.context.customer;
