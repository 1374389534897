/**
 * BlockInspirationLandscape
 */

import React from 'react';
import type { Field } from '@sitecore-jss/sitecore-jss-nextjs';
import clsx from 'clsx';

import ArrowLink from 'components/ArrowLink';
import BlockMedia, {
	type BlockMediaProductsDisplayType,
} from 'components/BlockMedia';
import { LayoutContainer } from 'components/Layout';
import { OptionalLink } from 'components/Link';
import Text from 'components/Text';
import type { HeadingLevel } from 'lib/component-props';
import type { DigizuiteAsset } from 'models/asset';
import type { SimpleLink } from 'models/link';
import type { ProductCard } from 'models/productCard';
import { is } from 'utils/helpers';

interface Props {
	basePriceTextBackground?: string;
	className?: string;
	heading?: Field<string>;
	headingLevel?: HeadingLevel;
	id?: string;
	isMirrored?: boolean;
	links?: SimpleLink[];
	preventAutoplay?: boolean;
	primaryImageAlt: string | undefined;
	primaryImageSrc: string | undefined;
	primaryLinkHref?: string;
	products?: ProductCard[];
	productsDisplayType?: BlockMediaProductsDisplayType;
	secondaryImageAlt: string | undefined;
	secondaryImageSrc: string | undefined;
	text?: Field<string>;
	video?: DigizuiteAsset;
}

export default function BlockInspirationLandscape({
	basePriceTextBackground,
	className,
	heading,
	headingLevel = 'h2',
	id,
	isMirrored,
	links,
	preventAutoplay,
	primaryImageAlt,
	primaryImageSrc,
	primaryLinkHref,
	products,
	productsDisplayType,
	secondaryImageAlt,
	secondaryImageSrc,
	text,
	video,
}: Props) {
	const primaryPart = (
		<BlockMedia
			className={clsx(
				'col-span-4',
				isMirrored ? 'md:col-start-5 md:col-end-13' : 'md:col-span-8',
			)}
			variant="landscape"
			imgSrc={primaryImageSrc}
			imgAlt={primaryImageAlt}
			video={video}
			products={products}
			productsDisplayType={productsDisplayType}
			preventAutoplay={preventAutoplay}
			basePriceTextBackground={basePriceTextBackground}
			productAnimationPlacementClassName="absolute inset-y-[15%] right-12 flex justify-end md:inset-y-20 md:right-36"
		/>
	);

	// max-sm margin is for LayoutContainer whose margin is removed below.
	const secondaryPartContent = (
		<div className="max-sm:mx-4">
			{heading && <Text as={headingLevel} field={heading} />}
			{text && <Text as="p" className="mb-6 mt-2 md:mt-4" field={text} />}
			{is.arrayWithLength(links) && (
				<div className="flex flex-col items-start justify-start gap-4">
					{links.map((link) => (
						<ArrowLink
							key={link.href}
							href={link.href}
							text={link.text}
							target={link.target}
						/>
					))}
				</div>
			)}
		</div>
	);

	const secondaryImageBlock = secondaryImageSrc ? (
		<BlockMedia
			variant="landscapeSmall"
			imgSrc={secondaryImageSrc}
			imgAlt={secondaryImageAlt}
			imgClassName="w-full"
		/>
	) : null;
	const secondaryPart = (
		<div
			className={clsx(
				'col-span-4',
				!secondaryImageBlock && 'flex items-center',
			)}
		>
			{secondaryImageBlock && (
				<OptionalLink
					href={primaryLinkHref}
					className="mb-4 block max-md:hidden md:mb-8"
					isScreenReaderHidden={!secondaryImageAlt}
				>
					{secondaryImageBlock}
				</OptionalLink>
			)}
			{secondaryPartContent}
		</div>
	);

	return (
		<LayoutContainer
			id={id}
			outerClassName={clsx(className, 'max-sm:mx-0')}
			className="items-start"
			withGrid
			withVerticalGridGap
		>
			{/* The secondary text should always be below the primary part on small screens. */}
			{isMirrored && (
				<>
					<div className="contents max-md:hidden">{secondaryPart}</div>
					{primaryPart}
					<div className="col-span-4 md:hidden">{secondaryPartContent}</div>
				</>
			)}
			{!isMirrored && (
				<>
					{primaryPart}
					{secondaryPart}
				</>
			)}
		</LayoutContainer>
	);
}
BlockInspirationLandscape.displayName = 'BlockInspirationLandscape';
