import React from 'react';
import type { Field } from '@sitecore-jss/sitecore-jss-nextjs';
import clsx from 'clsx';

import ActionLink from 'components/ActionLink';
import ComponentPlaceholder from 'components/ComponentPlaceholder';
import { LayoutContainer } from 'components/Layout';
import type { JulaComponentProps } from 'lib/component-props';
import type { ActionLink as ActionLinkModel } from 'models/link';
import { getEditorMargin } from 'utils/business-logic';

type Props = JulaComponentProps & {
	fields?: {
		action?: ActionLinkModel;
		isPrimary?: Field<boolean>;
	};
};

const NON_NUMBERS_REGEXP = /\D/g;

export default function ActionButton({ isLeftWide, params, fields }: Props) {
	const action = fields?.action;
	const containerMargin = params?.margin ? getEditorMargin(params) : 'mt-4';
	const isPrimary = fields?.isPrimary?.value;
	const phoneNr = action?.text?.replace(NON_NUMBERS_REGEXP, '') || '';
	const href = action?.type === 'Phone' ? `tel:${phoneNr}` : action?.href;

	if (!action?.type || !action?.text) {
		return (
			<ComponentPlaceholder
				className={containerMargin}
				componentName="ActionButton"
				description="Requires an action type and text"
			/>
		);
	}

	if (action?.type === 'Phone' && phoneNr.length < 5) {
		return (
			<ComponentPlaceholder
				className={containerMargin}
				componentName="Phone ActionButton"
				description="Text must contain a phone number of at least 5 digits"
			/>
		);
	}

	return (
		<LayoutContainer
			id={params?.anchor}
			className={clsx(containerMargin, !isLeftWide && 'text-center')}
		>
			<ActionLink
				{...action}
				href={href}
				buttonVariant={isPrimary ? 'primary' : undefined}
			/>
		</LayoutContainer>
	);
}
ActionButton.displayName = 'ActionButton';
