import React, { useState } from 'react';
import type { Field } from '@sitecore-jss/sitecore-jss-nextjs';

import Button from 'components/Button';
import GdprAccordion from 'components/GdprAccordion';
import GenericForm from 'components/GenericForm';
import { ErrorMessageInfoBox } from 'components/GlobalPopover';
import RichText from 'components/RichText';
import Text from 'components/Text';
import type {
	JulaComponentProps,
	PartialPopoverProps,
} from 'lib/component-props';
import type { FormFields } from 'models/form';
import fetchData, { API_URL } from 'utils/fetchData';
import { is } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

export interface QuestionsAndAnswersFormPartialProps {
	productId: string;
}

type Props = JulaComponentProps & {
	fields: {
		content: Field<string>;
		description: Field<string>;
		form: FormFields;
		heading: Field<string>;
		name: Field<string>;
		submitText: Field<string>;
	};
	partialProps: PartialPopoverProps<QuestionsAndAnswersFormPartialProps>;
};

// TODO: <QuestionsAndAnswersForm> and <ContactForm> have very similar structure
// and error/submit handling so we could look in to something combined for either
// the form or the error/submit handling, relates to TODO in <GenericForm>
// TODO: Talk to BE, content and description could probably be the same rich text field
export default function QuestionsAndAnswersForm({
	fields,
	partialProps,
}: Props) {
	const { t } = useI18n();

	const [submitSuccess, setSubmitSuccess] = useState(false);
	const [businessLogicErrors, setBusinessLogicErrors] = useState(undefined);
	if (!fields) {
		return null;
	}

	const onSubmit = async (formData) => {
		setBusinessLogicErrors(undefined);

		const response = await fetchData(
			`${API_URL}ProductQuestions/${partialProps?.productId}`,
			{
				method: 'POST',
				body: JSON.stringify(formData),
				headers: {
					'Content-Type': 'application/json',
				},
			},
		).catch((error) => error);
		if (
			(response?.fieldValidationErrors &&
				is.objectWithKeys(response.fieldValidationErrors)) ||
			response?.businessLogicErrors
		) {
			setBusinessLogicErrors(response.businessLogicErrors);
			if (is.objectWithKeys(response.fieldValidationErrors)) {
				return response.fieldValidationErrors;
			}
			return { FORM_ERROR: 'businessLogicErrors' };
		}
		setSubmitSuccess(true);
		return undefined;
	};

	return (
		<>
			{!submitSuccess && (
				<>
					{businessLogicErrors && (
						<ErrorMessageInfoBox
							errors={businessLogicErrors}
							className="relative -top-4 -mx-4 lg:-mx-6"
						/>
					)}
					<Text className="mb-2" as="h3" field={fields.heading} />
					<Text className="mb-2" as="p" field={fields.description} />
					<RichText field={fields.content} className="mt-4" />
					<GdprAccordion />
					<GenericForm
						inputSpace="mt-4"
						fields={fields?.form}
						className="mt-4"
						fileUploadUrl={`${API_URL}CustomerService/attachments`}
						onSubmitCallback={onSubmit}
						resetForm
						submitButtonClassName="mt-6"
						submitButtonText={fields.submitText.value}
					/>
					<Button
						displayWidth="full"
						className="mt-6"
						onClick={partialProps.onPopoverClose}
						variant="text"
					>
						{t('question_answer_form_abort_button')}
					</Button>
				</>
			)}
			{submitSuccess && (
				<>
					<Text
						className="mb-4"
						as="h3"
						text={t('question_answer_form_success_heading')}
					/>
					<Text as="p" text={t('question_answer_form_success_text')} />
					<Button
						onClick={partialProps.onPopoverClose}
						displayWidth="full"
						variant="primary"
						className="mt-8"
					>
						{t('question_answer_form_success_button')}
					</Button>
				</>
			)}
		</>
	);
}
QuestionsAndAnswersForm.displayName = 'QuestionsAndAnswersForm';
