import React, { useCallback, useEffect } from 'react';
import type { Field } from '@sitecore-jss/sitecore-jss-nextjs';

import ComponentPlaceholder from 'components/ComponentPlaceholder';
import { LayoutContainer } from 'components/Layout';
import ProductCard from 'components/ProductCard';
import ProductCarouselComponent from 'components/ProductCarousel';
import Text from 'components/Text';
import { useIsEditing, useMinWidth } from 'hooks';
import type { JulaComponentProps } from 'lib/component-props';
import type { ProductCard as ProductCardModel } from 'models/productCard';
import { getEditorMargin } from 'utils/business-logic';
import { pushToGTM } from 'utils/GoogleTagManager/googleTagManager';
import { is } from 'utils/helpers';

type Props = JulaComponentProps & {
	fields: {
		allowQuickBuy?: Field<boolean>;
		datasourceName?: string;
		heading: Field<string>;
		products: ProductCardModel[];
	};
};

export default function ProductCarousel({ params, fields }: Props) {
	const isMediumOrLarger = useMinWidth('md');
	const isLargeOrLarger = useMinWidth('lg');
	const isEditing = useIsEditing();

	const { heading, products, allowQuickBuy, datasourceName } = fields;
	const hasProducts = products.length > 0;
	const pageSize = isLargeOrLarger ? 5 : isMediumOrLarger ? 4 : 2;

	const sendViewToGTM = useCallback(
		(nextIndex: number) => {
			if (hasProducts) {
				pushToGTM({
					type: 'view_item_list',
					payload: {
						products: products.slice(0, nextIndex + pageSize),
						pageSize,
						listName: datasourceName ?? 'ProductCarouselUnknown',
					},
				});
			}
		},
		[hasProducts, products, pageSize, datasourceName],
	);

	useEffect(() => {
		if (is.defined(isLargeOrLarger) && is.defined(isMediumOrLarger)) {
			sendViewToGTM(0);
		}
	}, [sendViewToGTM, isLargeOrLarger, isMediumOrLarger]);

	if (!fields) {
		return null;
	}

	if (
		(isEditing && !heading?.value && !hasProducts) ||
		(!isEditing && !hasProducts)
	) {
		return (
			<ComponentPlaceholder
				componentName="ProductCarousel"
				description="Requires products"
				className={getEditorMargin(params)}
			/>
		);
	}

	return (
		<LayoutContainer
			id={params?.anchor}
			outerClassName={getEditorMargin(params)}
		>
			<Text as={params?.heading || 'h2'} field={heading} className="mb-8" />
			<ProductCarouselComponent
				onNext={(nextIndex) => sendViewToGTM(nextIndex)}
			>
				{products.map((product) => (
					<ProductCard
						key={product.id}
						product={product}
						showAddToCartButton={
							allowQuickBuy?.value &&
							product.isSellable &&
							!product.hasMultipleVariants
						}
					/>
				))}
			</ProductCarouselComponent>
		</LayoutContainer>
	);
}
ProductCarousel.displayName = 'ProductCarousel_JSS';
