/**
 * ImageSlider
 */

import React, { useState } from 'react';
import clsx from 'clsx';
import { useKeenSlider } from 'keen-slider/react';

import Bullets from 'components/Bullets';
import Image from 'components/Img';
import { useDebouncedCallback } from 'hooks';
import type { Thumbnail } from 'utils/business-logic';

interface Props {
	images: Thumbnail[];
	onSlideChange: (activeImageId: number | undefined) => void;
	onSliderClick: () => void;
	sliderButtonLabel: string;
}

/** Horizontal image slider with bullets */
export default function ImageSlider({
	images,
	onSlideChange,
	onSliderClick,
	sliderButtonLabel,
}: Props) {
	const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
	// Keen runs slideChanged for every slide, e.g. even when moving from
	// index 1 to index 5 with a single call. Debounce to only run callback
	// for the slide landed on.
	const [debouncedOnSlideChange] = useDebouncedCallback(onSlideChange, 100, [
		onSlideChange,
	]);
	const [sliderRef, instanceRef] = useKeenSlider({
		initial: 0,
		slides: { perView: 1 },
		slideChanged(slider) {
			const currentIndex = slider.track.details.rel;
			debouncedOnSlideChange(images[currentIndex]?.id);
			setCurrentSlideIndex(currentIndex);
		},
	});

	return (
		<div>
			<div ref={sliderRef} className="keen-slider overflow-x-auto">
				{images.map((image, i) => (
					<button
						key={image.src}
						type="button"
						className={clsx('keen-slider__slide relative', [
							// The slide hides overflow which means no outline is visible,
							// use a pseudo element as replacement.
							'focus-visible:after:absolute',
							'focus-visible:after:inset-0',
							'focus-visible:after:z-5',
							'focus-visible:after:pointer-events-none',
							// TODO: Replace with a common outline style
							'focus-visible:after:border',
							'focus-visible:after:border-2',
							'focus-visible:after:border-black',
						])}
						aria-label={
							image.alt
								? `${sliderButtonLabel}: ${image.alt}`
								: sliderButtonLabel
						}
						tabIndex={i === currentSlideIndex ? undefined : -1}
						onClick={onSliderClick}
					>
						<Image
							src={image.src}
							alt={image.alt}
							service="nextjs"
							priority={i === 0}
							width={384}
							height={384}
						/>
					</button>
				))}
			</div>
			{images.length > 1 && (
				<div className="mt-1 flex justify-center">
					<Bullets
						buttons={images.map((thumb) => ({
							...thumb,
							label: thumb.alt,
						}))}
						activeId={currentSlideIndex + 1}
						onClick={(thumb) => {
							instanceRef.current?.moveToIdx(thumb.id - 1);
						}}
					/>
				</div>
			)}
		</div>
	);
}
ImageSlider.displayName = 'ImageSlider';
