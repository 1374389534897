import React from 'react';

import { MicroContentMediaLink } from 'components/MicroContent';
import Text from 'components/Text';
import type { ContentLink } from 'models/pageHeader';
import { filterTruthy } from 'utils/collection';
import { getAsset } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

interface Props {
	className?: string;
	links: ContentLink[] | undefined;
}

export default function PageHeaderInspirationLinks({
	className,
	links,
}: Props) {
	const { t } = useI18n();
	const filteredLinks = filterTruthy(
		links?.map((link) => ({
			href: link.href,
			imgSrc: getAsset('Templated 1:1', link.image)?.src,
			text: link.text,
		})),
		'href',
		'imgSrc',
		'text',
	);

	if (filteredLinks.length === 0) {
		return null;
	}

	return (
		<div className={className}>
			<Text as="h3" className="mb-4 sm:mb-6">
				{t('mega_drop_inspiration_links_heading')}
			</Text>
			<ul className="space-y-4">
				{filteredLinks.map((link) => (
					<li key={`${link.href}-${link.text}`}>
						<MicroContentMediaLink {...link} displayType="block" />
					</li>
				))}
			</ul>
		</div>
	);
}
PageHeaderInspirationLinks.displayName = 'PageHeaderInspirationLinks';
