import React, { useState } from 'react';

import { StockBalance } from 'components/StockBalance';
import StockListItem from 'components/StockListItem';
import StorePopover from 'components/StorePopover';
import { StoreIdName } from 'contexts';
import { InventoryItem, Stock, StockStore } from 'models/inventory';
import { Product } from 'models/product';
import {
	getFormattedStockAmount,
	getProductDetailsStoreStockStatus,
} from 'utils/business-logic/stock';
import { pushToGTM } from 'utils/GoogleTagManager';
import { assertUnreachable, sendGlobalEvent } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

interface SelectStorePopoverProps {
	allStoresStock: Stock[] | undefined;
	isLoading: boolean;
	isOpen: boolean;
	nearbyStoresStock: Stock[] | undefined;
	onClose: () => void;
	onTabChange: (tab: string) => void;
	onUpdateSelectedStore: (store: StockStore) => void;
	selectedStore: StoreIdName | undefined;
}

function SelectStorePopover({
	isLoading,
	isOpen,
	onClose,
	onUpdateSelectedStore,
	onTabChange,
	selectedStore,
	allStoresStock,
	nearbyStoresStock,
}: SelectStorePopoverProps) {
	const renderStockListItem = (storeStock: Stock) => (
		<StockListItem
			key={storeStock.store?.id}
			title={storeStock.store?.name || ''}
			isSelected={selectedStore?.id === storeStock.store?.id}
			inStock={storeStock.inStock}
			availableStockLevel={storeStock.availableStockLevel}
			onClick={() => {
				onUpdateSelectedStore(storeStock.store);
				sendGlobalEvent('engagement', {
					type: 'checkAvailabilityInStore',
					data: {
						storeId: storeStock.store?.id,
					},
				});
				if (storeStock.store.name && storeStock.availableStockLevel) {
					pushToGTM({
						type: 'select_store_choice',
						payload: {
							storeName: storeStock.store.name,
							storeStock: storeStock.availableStockLevel,
						},
					});
				}

				onClose();
			}}
		/>
	);
	const allStores = allStoresStock?.map((storeStock) => ({
		filterKey: storeStock.store?.name || '',
		node: renderStockListItem(storeStock),
	}));
	const nearbyStores = nearbyStoresStock?.map((storeStock) => ({
		filterKey: storeStock.store?.name || '',
		node: renderStockListItem(storeStock),
	}));

	return (
		<StorePopover
			allStores={allStores}
			nearbyStores={nearbyStores}
			hasSelectedStore={Boolean(selectedStore)}
			onClose={onClose}
			onTabChange={onTabChange}
			isLoading={isLoading}
			isOpen={isOpen}
		/>
	);
}
SelectStorePopover.displayName =
	'ProductDetails_StockInformation_SelectStorePopover';

interface StoreStockProps {
	currentProductStock: InventoryItem | undefined;
	currentStoreStock: Stock | undefined;
	onOpenStoreSelector: () => void;
	product: Product;
	selectedStore: StoreIdName | undefined;
}
function StoreStock({
	selectedStore,
	currentStoreStock,
	product,
	currentProductStock,
	onOpenStoreSelector,
}: StoreStockProps) {
	const { t } = useI18n();
	const status = getProductDetailsStoreStockStatus({
		storeSelected: Boolean(selectedStore),
		inStock: currentStoreStock?.inStock || false,
		productInStockAtStores: currentProductStock?.storeStock?.inStockCount || 0,
		webStockInStock: currentProductStock?.webStock?.inStock || false,
	});
	switch (status) {
		case 'Store-InStock':
			return (
				<StockBalance
					statusType={status}
					locationIcon="store"
					header={selectedStore?.name || ''}
					stockStatus={{
						inStock: true,
						statusText: t('stock_general_amount_exact', {
							amount: getFormattedStockAmount(
								currentStoreStock?.availableStockLevel,
							),
						}),
					}}
					storeInfo={{
						articleInfo: {
							department: product.category1Name,
							shelf: currentStoreStock?.primaryLocation,
							artno: product.id,
							color: product.category1Color,
						},
						openingHours:
							currentStoreStock?.store?.todaysOpeningHours?.description || '',
					}}
					buttonText={t('product_details_store_accordion_change_store_button')}
					onClick={onOpenStoreSelector}
				/>
			);
		case 'Store-OutOfStock-WebStock':
			return (
				<StockBalance
					statusType={status}
					locationIcon="store"
					header={selectedStore?.name || ''}
					stockStatus={{
						inStock: false,
						statusText: t('stock_general_out_of_stock'),
					}}
					onClick={onOpenStoreSelector}
					buttonText={t('product_details_store_accordion_change_store_button')}
					infoText={t('product_details_store_accordion_online_only_text')}
				/>
			);
		case 'Store-OutOfStock-NoWebStock':
			return (
				<StockBalance
					statusType={status}
					locationIcon="store"
					header={selectedStore?.name || ''}
					stockStatus={{
						inStock: false,
						statusText: t('stock_general_out_of_stock'),
					}}
					onClick={onOpenStoreSelector}
					buttonText={t('product_details_store_accordion_change_store_button')}
				/>
			);
		case 'NoStore-InStock':
			return (
				<StockBalance
					statusType={status}
					locationIcon="store"
					header={t('product_details_store_accordion_heading')}
					onClick={onOpenStoreSelector}
					buttonText={t('product_details_store_accordion_select_store_button')}
					stockStatus={{
						inStock: true,
						statusText: t('stock_stores_amount_exact', {
							amount: currentProductStock?.storeStock?.inStockCount,
						}),
					}}
				/>
			);
		case 'NoStore-OutOfStock-WebStock':
			return (
				<StockBalance
					statusType={status}
					locationIcon="store"
					header={t('product_details_store_accordion_heading')}
					stockStatus={{
						inStock: false,
						statusText: t('stock_stores_amount_exact', {
							amount: currentProductStock?.storeStock?.inStockCount,
						}),
					}}
					infoText={t('product_details_store_accordion_online_only_text')}
					onClick={onOpenStoreSelector}
					buttonText={t('product_details_store_accordion_select_store_button')}
				/>
			);
		case 'NoStore-OutOfStock-NoWebStock':
			return (
				<StockBalance
					statusType={status}
					locationIcon="store"
					header={t('product_details_store_accordion_heading')}
					stockStatus={{
						inStock: false,
						statusText: t('stock_stores_amount_exact', {
							amount: currentProductStock?.storeStock?.inStockCount,
						}),
					}}
					onClick={onOpenStoreSelector}
					buttonText={t('product_details_store_accordion_select_store_button')}
				/>
			);

		case 'MatchFailed':
			return null;
		default:
			assertUnreachable(status);
	}
}
StoreStock.displayName = 'ProductDetails_StockInformation_StoreStock';

interface Props {
	allStoresStock: Stock[] | undefined;
	currentProductStock: InventoryItem | undefined;
	currentStoreStock: Stock | undefined;
	isLoadingInitialStock: boolean;
	isLoadingNearbyStoresStock: boolean;
	isLoadingStock: boolean;
	nearbyStoresStock: Stock[] | undefined;
	onOpenCallBack: () => void;
	onTabChange: (tab: string) => void;
	onUpdateSelectedStore: (store: StockStore) => void;
	product: Product;
	selectedStore: StoreIdName | undefined;
}

export default function StoreStockBalance({
	currentStoreStock,
	currentProductStock,
	allStoresStock,
	nearbyStoresStock,
	onOpenCallBack,
	onUpdateSelectedStore,
	onTabChange,
	product,
	selectedStore,
	isLoadingStock,
	isLoadingInitialStock,
	isLoadingNearbyStoresStock,
}: Props) {
	const { salesAttributes } = product;
	const [storeSelectorOpen, setStoreSelectorOpen] = useState(false);
	if (isLoadingInitialStock || !salesAttributes) {
		return (
			<StockBalance
				statusType="Loading"
				locationIcon="store"
				stockStatus={{
					inStock: true,
					statusText: 'In Stock',
					statusInfo: 'Available in 1-2 days',
				}}
				loading
			/>
		);
	}

	return (
		<div>
			<StoreStock
				currentStoreStock={currentStoreStock}
				currentProductStock={currentProductStock}
				selectedStore={selectedStore}
				product={product}
				onOpenStoreSelector={() => {
					onOpenCallBack();
					setStoreSelectorOpen(true);
				}}
			/>
			<SelectStorePopover
				selectedStore={selectedStore}
				allStoresStock={allStoresStock}
				nearbyStoresStock={nearbyStoresStock}
				isOpen={storeSelectorOpen}
				onUpdateSelectedStore={onUpdateSelectedStore}
				onClose={() => setStoreSelectorOpen(false)}
				onTabChange={onTabChange}
				isLoading={isLoadingStock || isLoadingNearbyStoresStock}
			/>
		</div>
	);
}
StoreStockBalance.displayName =
	'ProductDetails_StockInformation_StoreStockBalance';
