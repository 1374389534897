import React from 'react';
import { Field, LinkFieldValue } from '@sitecore-jss/sitecore-jss-nextjs';
import Script from 'next/script';

import {
	Breadcrumbs as BreadcrumbsBase,
	BreadcrumbsItem,
} from 'components/Breadcrumbs';
import { JulaComponentProps } from 'lib/component-props';
import { filterTruthy } from 'utils/collection';

type Props = JulaComponentProps & {
	fields: {
		ariaLabel?: Field<string>;
		links?: LinkFieldValue[];
		structuredData?: string[];
	};
};

export default function Breadcrumbs({ fields }: Props) {
	const { ariaLabel, links, structuredData } = fields;
	const visibleLinks = filterTruthy(links || [], 'href', 'text');

	if (visibleLinks.length === 0) {
		return null;
	}

	return (
		<BreadcrumbsBase ariaLabel={ariaLabel?.value} className="my-4 md:my-6">
			{visibleLinks.map((link, i) => (
				<BreadcrumbsItem
					key={link.href}
					text={link.text}
					href={link.href}
					isLast={visibleLinks.length - 1 === i}
					isLastMobile={visibleLinks.length - 2 === i}
				/>
			))}
			{structuredData && (
				<Script
					type="application/ld+json"
					id="breadcrumbs"
					dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredData) }}
				/>
			)}
		</BreadcrumbsBase>
	);
}
Breadcrumbs.displayName = 'Breadcrumbs';
