import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-nextjs';
import { useInterpret, useSelector } from '@xstate/react';

import ErrorBoundary from 'components/ErrorBoundary';
import MiniHeader from 'components/MiniHeader';
import { CheckoutContextProvider } from 'contexts';
import type { JulaSitecoreServiceData, UserInfo } from 'lib/page-props';
import {
	checkoutMachine,
	selectCheckoutStatus,
} from 'state-machines/checkout/';
import { useI18n } from 'utils/i18n';

import BaseLayout from './BaseLayout';

type Props = {
	layoutData: JulaSitecoreServiceData;
	userInfo?: UserInfo;
};

export default function LayoutCheckout({ userInfo, layoutData }: Props) {
	const { t } = useI18n();
	const { route } = layoutData.sitecore;

	const checkoutService = useInterpret(checkoutMachine, {
		devTools: true,
	});
	const checkoutStatus = useSelector(checkoutService, selectCheckoutStatus);

	return (
		<CheckoutContextProvider checkoutService={checkoutService}>
			<BaseLayout
				forceNoindexNofollow
				layoutData={layoutData}
				route={route}
				renderFooter={false}
				renderHeader={false}
				renderMain={false}
				userInfo={userInfo}
			>
				<MiniHeader
					linkText={t('checkout_back_to_cart_button')}
					linkUrl={
						checkoutStatus !== 'PaymentPending' && checkoutStatus !== undefined
							? '/Cart/'
							: undefined
					}
				/>
				{route && (
					<Placeholder
						name="jula-main"
						rendering={route}
						render={(components) => (
							<ErrorBoundary isPageWidth>{components}</ErrorBoundary>
						)}
					/>
				)}
			</BaseLayout>
		</CheckoutContextProvider>
	);
}
LayoutCheckout.displayName = 'LayoutCheckout';
