/**
 * JulaLogo
 */

import React from 'react';

import Img from 'components/Img';
import { useIsEditing } from 'hooks';
import cn from 'utils/cn';

interface Props {
	className?: string;
}

export default function JulaLogo({ className }: Props) {
	const isEditing = useIsEditing();

	return (
		<Img
			className={cn('h-auto w-[100px]', className)}
			// Sitecore sometimes does something with the relative URL in the
			// Experience Editor, breaking the image. Editors dislike this so it's
			// been decided to just force a full .se URL there.
			src={
				isEditing
					? 'https://www.jula.se/images/logo-jula.svg'
					: '/images/logo-jula.svg'
			}
			alt="Jula"
		/>
	);
}
JulaLogo.displayName = 'JulaLogo';
