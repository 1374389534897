import React, { type FocusEventHandler, useState } from 'react';
import clsx from 'clsx';

import Icon from 'components/Icon';
import { LayoutContainer } from 'components/Layout';
import Link, { OptionalLink } from 'components/Link';
import Rating from 'components/Rating';
import { useMaxWidth, useTransitionInterval } from 'hooks';
import type { Link as LinkType, Review, USP } from 'models/pageHeader';
import { is } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

const USP_INTERVAL = 5000;

interface Props {
	/** Menu links */
	menuLinks?: LinkType[];
	/** Jula ecommerce page review data */
	review?: Review | undefined;
	/** USP texts */
	uspLinks?: USP[];
}

/** Red header top bar */
export default function PageHeaderTopBar({
	menuLinks,
	uspLinks,
	review,
}: Props) {
	const { t } = useI18n();
	const [currentUspIndex, setCurrentUspIndex] = useState(0);
	const [uspInterval, setUspInterval] = useState<number | null>(USP_INTERVAL);
	const usps = uspLinks ? uspLinks.filter((usp) => usp.fields?.text) : [];
	const uspCount = usps.length;
	const isSmallScreen = useMaxWidth('sm');

	const isTransitioning = useTransitionInterval(
		() => {
			setCurrentUspIndex((current) =>
				current === uspCount - 1 ? 0 : current + 1,
			);
		},
		isSmallScreen && uspCount ? uspInterval : null,
		150,
	);

	// Pause interval when USP links receive keyboard focus, to avoid losing
	// that focus by the active USP changing.
	const handleUspListFocus: FocusEventHandler = () => {
		setUspInterval(null);
	};
	const handleUspListBlur: FocusEventHandler = () => {
		setUspInterval(USP_INTERVAL);
	};

	return (
		<div className="page-header-part relative flex min-h-[3rem] bg-julaRed py-2 text-sm text-white sm:items-center md:min-h-[3.5rem] md:text-base">
			<LayoutContainer
				outerClassName="grow max-sm:flex"
				className="flex items-center max-sm:w-full"
			>
				{is.arrayWithLength(usps) && (
					<ul
						className={clsx(
							'max-sm:relative max-sm:w-full',
							'sm:flex sm:flex-wrap',
						)}
						onFocus={handleUspListFocus}
						onBlur={handleUspListBlur}
					>
						{usps.map((usp, i) => {
							const { link, text } = usp.fields;
							const isHidden = i !== currentUspIndex || isTransitioning;

							const content = (
								<>
									<Icon icon="check" />
									<span
										className={clsx(
											'inline-block align-middle font-semibold',
											'max-sm:max-w-[calc(100%-24px)]',
											link && 'group-hover:underline',
										)}
									>
										{text}
									</span>
								</>
							);

							return (
								<li
									key={usp.name}
									className={clsx(
										// Visually align the left edge of the check icon
										'-ml-[5px] overflow-hidden',
										'sm:mr-6',
										[
											'max-sm:absolute max-sm:left-0 max-sm:top-1/2 max-sm:-translate-y-1/2 max-sm:transition-opacity',
											isHidden &&
												'max-sm:w-0 max-sm:whitespace-nowrap max-sm:opacity-0',
											!isHidden && 'max-sm:w-full',
										],
									)}
								>
									{!link?.href && content}
									{link?.href && (
										<Link
											href={link.href}
											target={link.target}
											className="group"
											aria-hidden={isHidden}
											tabIndex={isHidden ? -1 : undefined}
										>
											{content}
										</Link>
									)}
								</li>
							);
						})}
					</ul>
				)}

				{is.arrayWithLength(menuLinks) && (
					<nav
						className="ml-auto max-md:hidden"
						aria-label={t('header_top_bar_menu_label')}
					>
						<ul className="flex">
							{menuLinks.map(({ fields }) => {
								if (!fields?.link?.href) {
									return null;
								}
								return (
									<li key={fields.link.id || fields.link.text} className="ml-6">
										<Link href={fields.link.href} className="hover:underline">
											{fields.link.text}
										</Link>
									</li>
								);
							})}
						</ul>
					</nav>
				)}
				{review && (
					<OptionalLink
						href={review?.reviewLink}
						linkClassName="hover:bg-greyLight"
						className="ml-6 inline-flex items-center rounded-full bg-white px-2 py-1 text-black md:px-4 md:py-2"
					>
						<Rating
							className="md:hidden"
							reviewCount={review.numberOfReviews}
							hasVisibleScore
							reviewCountVariant="hidden"
							score={review.averageStars}
							size="small"
						/>
						<Rating
							className="max-md:hidden"
							hasVisibleScore
							reviewCount={review.numberOfReviews}
							score={review.averageStars}
						/>
					</OptionalLink>
				)}
			</LayoutContainer>
		</div>
	);
}
PageHeaderTopBar.displayName = 'PageHeaderTopBar';
