import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-nextjs';

import { BlockPromoTeaser as PromoTeaserComponent } from 'components/BlockPromoTeaser';
import ComponentPlaceholder from 'components/ComponentPlaceholder';
import { useSitecoreContext } from 'hooks';
import { PromoTeaserItemProps as PromoTeaserItem } from 'jsscomponents/PromoTeaserItem';
import { JulaComponentProps } from 'lib/component-props';
import { getEditorMargin } from 'utils/business-logic';

type PromoTeaserProps = JulaComponentProps & {
	placeholders: {
		julaTeaser: PromoTeaserItem[];
	};
};

function PromoTeaser({ params, rendering }: PromoTeaserProps) {
	const { sitecoreContext } = useSitecoreContext();
	const isEditing = sitecoreContext?.pageEditing;
	if (isEditing) {
		return (
			<ComponentPlaceholder
				componentName="PromoTeaser"
				className={getEditorMargin(params)}
				isDeprecated
			/>
		);
	}
	return (
		<div id={params?.anchor} className={getEditorMargin(params)}>
			<PromoTeaserComponent>
				<Placeholder name="jula-teaser" rendering={rendering} />
			</PromoTeaserComponent>
		</div>
	);
}

PromoTeaser.displayName = 'PromoTeaser';

export default PromoTeaser;
