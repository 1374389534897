import React, { type ReactNode } from 'react';

import { StockStatus } from 'components/StockBalance';

interface Props {
	inStock: boolean;
	restock?: boolean;
	inStockText: ReactNode;
	outOfStockText: ReactNode;
	reStockText?: string;
}
export default function ListItemStockInfo({
	inStock,
	inStockText,
	outOfStockText,
	reStockText = '',
	restock,
}: Props) {
	const text = inStock ? inStockText : restock ? reStockText : outOfStockText;
	return <StockStatus inStock={inStock} text={text} textSize="small" />;
}
ListItemStockInfo.displayName = 'ListItemStockInfo';
