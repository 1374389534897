/**
 * CreditSimulation
 */

import React, { useState } from 'react';

import Button from 'components/Button';
import { Range } from 'components/FormUi/';
import InfoBox from 'components/InfoBox';
import Popover from 'components/Popover';
import RichText from 'components/RichText';
import { Skeleton, SkeletonItem } from 'components/Skeleton';
import Text, { Tags } from 'components/Text';
import { useGlobalStateContext } from 'contexts';
import { useCreditSimulation } from 'hooks';
import type {
	CreditSimulation as CreditSimulationModel,
	CreditSimulationActionLink,
} from 'models/api';
import { useI18n } from 'utils/i18n';
import { formatPriceText } from 'utils/price';

function PriceRow({
	title,
	displayValue,
	displaySymbol,
	priceStyle = 'p',
}: {
	title: string;
	displayValue: string | undefined;
	displaySymbol?: string;
	priceStyle?: Tags;
}) {
	return (
		<div className="border-x-1 border-t-1 border-greyLight last:border-b-1">
			<div className=" mx-4 flex min-h-[3rem] items-center justify-between">
				<Text as="span" styleAs="p">
					{title}
				</Text>
				<Text as="span" styleAs={priceStyle}>
					{formatPriceText(displayValue, displaySymbol)}
				</Text>
			</div>
		</div>
	);
}
PriceRow.displayName = 'PriceRow';

interface CreditSimulationProps extends CreditSimulationModel {
	onActionLinkClick: (actionLink: CreditSimulationActionLink) => void;
}

export function CreditSimulation({
	heading,
	content,
	example,
	defaultMonth,
	actionLink,
	months,
	onActionLinkClick,
	minMonth,
	maxMonth,
	monthInterval,
}: CreditSimulationProps) {
	const { t } = useI18n();
	const monthKeys = months?.map((month) => month.key);
	const [selectedMonth, setSelectedMonth] = useState(
		months?.find((month) => month.key === defaultMonth),
	);

	return (
		<>
			<Text as="h3" styleAs="h2" className="mb-2">
				{heading}
			</Text>
			{content && <RichText html={content} checkMarkList />}

			<Text className="mb-2" as="p">
				{t('credit_simulation_possible_monthly_cost_text')}
			</Text>
			<div className="mt-4 border-x-1 border-t-1 p-4">
				<Range
					label={t('credit_simulation_number_of_months_text')}
					onChange={(e) => {
						const month = months?.find(
							(m) => m.key === Number.parseInt(e.target.value, 10),
						);
						if (month) setSelectedMonth(month);
					}}
					steps={monthKeys}
					max={maxMonth}
					min={minMonth}
					step={monthInterval}
					value={selectedMonth?.key}
					id="credit-simulation-months"
				/>
			</div>
			<div className="flex flex-col">
				<PriceRow
					title={t('credit_simulation_monthly_cost_text')}
					displayValue={selectedMonth?.value.monthlyCost.displayValue}
					displaySymbol={selectedMonth?.value.monthlyCost.displaySymbol}
					priceStyle="h2"
				/>
				<PriceRow
					title={t('credit_simulation_startup_fee_text')}
					displayValue={selectedMonth?.value.startupFee.displayValue}
					displaySymbol={selectedMonth?.value.startupFee.displaySymbol}
				/>
				<PriceRow
					title={t('credit_simulation_invoice_fee_text')}
					displayValue={selectedMonth?.value.invoiceFee.displayValue}
					displaySymbol={selectedMonth?.value.invoiceFee.displaySymbol}
				/>
				<PriceRow
					title={t('credit_simulation_intrest_rate_text')}
					displayValue={selectedMonth?.value.effectiveInterestRate}
				/>
				<PriceRow
					title={t('credit_simulation_total_amount_text')}
					displayValue={selectedMonth?.value.totalAmount.displayValue}
					displaySymbol={selectedMonth?.value.totalAmount.displaySymbol}
				/>
			</div>
			{actionLink && (
				<Button
					className="mt-6"
					variant="primary"
					displayWidth="full"
					onClick={() => onActionLinkClick(actionLink)}
				>
					{actionLink?.title}
				</Button>
			)}
			<Text as="pSmall" className="mb-2 mt-4">
				{example}
			</Text>
		</>
	);
}
CreditSimulation.displayName = 'CreditSimulation';

interface CreditSimulationContentProps {
	price: number | undefined;
	onClose: () => void;
}
function CreditSimulationContent({
	price,
	onClose,
}: CreditSimulationContentProps) {
	const { globalPopoverService } = useGlobalStateContext();
	const { t } = useI18n();

	const { creditSimulationData, isLoading, error } = useCreditSimulation(
		price || 0,
	);

	return (
		<>
			{!error && !isLoading && creditSimulationData && (
				<CreditSimulation
					heading={creditSimulationData.heading}
					content={creditSimulationData.content}
					actionLink={creditSimulationData.actionLink}
					example={creditSimulationData.example}
					defaultMonth={creditSimulationData.defaultMonth}
					months={creditSimulationData.months}
					maxMonth={creditSimulationData.maxMonth}
					minMonth={creditSimulationData.minMonth}
					monthInterval={creditSimulationData.monthInterval}
					onActionLinkClick={({ title, link }) => {
						globalPopoverService.send({
							type: 'OPEN',
							target: link,
							heading: title,
						});
						onClose();
					}}
				/>
			)}
			{!error && isLoading && (
				<Skeleton>
					<SkeletonItem height="5rem" className="mt-4" />
					<SkeletonItem height="10rem" className="mt-4" />
					<SkeletonItem height="20rem" className="mt-4" />
					<SkeletonItem height="3rem" className="mt-4" />
					<SkeletonItem height="6rem" className="mt-4" />
				</Skeleton>
			)}
			{error && (
				<InfoBox
					icon="error"
					variant="error"
					message={t('general_critical_error_text')}
				/>
			)}
		</>
	);
}
CreditSimulationContent.displayName = 'CreditSimulationContent';

interface CreditSimulationPopoverProps {
	isOpen: boolean;
	onClose: () => void;
	price: number | undefined;
}
export default function CreditSimulationPopover({
	isOpen,
	onClose,
	price,
}: CreditSimulationPopoverProps) {
	const { t } = useI18n();

	return (
		<Popover
			title={t('credit_simulation_popver_heading')}
			isOpen={isOpen}
			onClose={onClose}
		>
			{isOpen && <CreditSimulationContent price={price} onClose={onClose} />}
		</Popover>
	);
}
CreditSimulationPopover.displayName = 'CreditSimulationPopover';
