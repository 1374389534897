import React, { useState } from 'react';
import clsx from 'clsx';

import ActionButton, { type ActionButtonState } from 'components/ActionButton';
import Button from 'components/Button';
import { Input } from 'components/FormUi';
import GenericForm from 'components/GenericForm';
import Icon from 'components/Icon';
import IconButton from 'components/IconButton';
import InfoBox from 'components/InfoBox';
import ListItem from 'components/ListItemNew';
import LoadingSpinner from 'components/LoadingSpinner';
import { Step, Stepper } from 'components/Stepper';
import Text from 'components/Text';
import type { Section } from 'models/form';
import type {
	Organization,
	Worksite,
} from 'state-machines/createJulaProCustomer';
import { is } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

import {
	renderDescription as baseRenderDescription,
	renderHeader as baseRenderHeader,
} from './helpers';

const getWorkSiteErrorText = (
	worksite: null | Worksite,
	errors: Worksite['worksiteRegistrationStatus'][],
) =>
	worksite && errors.includes(worksite.worksiteRegistrationStatus)
		? worksite.worksiteRegistrationText
		: undefined;

interface Props {
	renderDescription: typeof baseRenderDescription;
	renderHeader: typeof baseRenderHeader;
	showCustomerIdForm: boolean;
	resetToSelectOrganization: () => void;
	resetToSearchOrganization: () => void;
	sendNewSearchInProgress: () => void;
	isSearchingOrganizations: boolean;
	creditFunctionsEnabled: boolean;
	isInSearchOrganizationSuccess: boolean;
	isInWaitingForNewOrganizationSearch: boolean;
	submitCustomerId: ({ customerId }: { customerId: string }) => void;
	submitOrganizationSearch: (searchSstring: string) => void;
	selectedWorksite: Worksite | null;
	selectOrganization: (id: string) => void;
	organizationList?: Organization[] | null;
	searchingOrganizationFailed: boolean;
	selectOrganizationButtonState: ActionButtonState;
	submitCustomerIdButtonState: ActionButtonState;
	companySearchSection: Section | undefined;
	customerIdInputSection: Section | undefined;
}

export default function StepOneContent({
	renderHeader,
	showCustomerIdForm,
	resetToSelectOrganization,
	isSearchingOrganizations,
	submitCustomerId,
	renderDescription,
	selectedWorksite,
	creditFunctionsEnabled,
	resetToSearchOrganization,
	isInWaitingForNewOrganizationSearch,
	sendNewSearchInProgress,
	isInSearchOrganizationSuccess,
	submitOrganizationSearch,
	selectOrganization,
	organizationList,
	searchingOrganizationFailed,
	selectOrganizationButtonState,
	submitCustomerIdButtonState,
	companySearchSection,
	customerIdInputSection,
}: Props) {
	const [value, setValue] = useState('');
	const { t } = useI18n();

	const [currentSelectedWorksiteId, setCurrentSelectedWorksiteId] =
		useState('');

	return (
		<div className="relative mb-14 h-full w-full">
			{creditFunctionsEnabled && (
				<div className={clsx('mx-8 mt-6')}>
					<Stepper className="mt-4 flex items-center">
						<Step number={1} status="active" />
						<Step number={2} status="pending" />
						<Step number={3} status="pending" />
					</Stepper>
				</div>
			)}
			{!showCustomerIdForm && (
				<>
					{renderHeader(
						companySearchSection?.heading.value,
						creditFunctionsEnabled ? 'mt-9' : 'mt-4',
					)}
					<div className="my-4">
						<form
							tabIndex={-1}
							onSubmit={(e) => {
								e.preventDefault();
								if (value) {
									submitOrganizationSearch(value);
								}
							}}
							className="relative h-full w-full items-center"
						>
							<div className="relative w-full">
								<Input
									id="search-organization"
									label={t('julapro_search_organization_label')}
									placeholder={t('julapro_search_organization_placeholder')}
									hiddenLabel
									inputMode="search"
									autoComplete="off"
									autoCapitalize="none"
									inputClassName="pr-14"
									value={value}
									onChange={(e) => {
										if (
											searchingOrganizationFailed ||
											isInSearchOrganizationSuccess ||
											isInWaitingForNewOrganizationSearch
										) {
											sendNewSearchInProgress();
										}
										setValue(e.target.value);
									}}
								/>
								{Boolean(value) && (
									<IconButton
										icon="clear"
										iconColor="grey"
										text={t('screenreader_text_clear')}
										className="absolute right-1 top-1/2 -translate-y-1/2"
										onClick={() => {
											setValue('');
											resetToSearchOrganization();
										}}
									/>
								)}
								{!value && (
									<Icon
										icon="search"
										className="pointer-events-none absolute right-4 top-1/2 -translate-y-1/2"
									/>
								)}
							</div>
						</form>
					</div>
				</>
			)}

			{isSearchingOrganizations && (
				<div className="absolute flex h-full w-full items-center justify-center">
					<LoadingSpinner
						variant="dashing"
						spinnerColor="julaRed"
						trackColor="transparent"
						size="medium"
					/>
				</div>
			)}
			{is.arrayWithLength(organizationList) && !showCustomerIdForm && (
				<ul className="flex flex-col gap-2">
					{organizationList.map((organization) => {
						let errorText: string | undefined;
						const isActive =
							organization.worksiteId === currentSelectedWorksiteId;
						if (isActive) {
							errorText = getWorkSiteErrorText(selectedWorksite, [
								'NotOk',
								'HasNotRightToSign',
								'CompanyHasAdmin',
								'UserIsAdmin',
								'MissingCompanyInformation',
							]);
						}
						return (
							<ListItem
								as="li"
								key={organization.worksiteId}
								title={organization.worksiteName}
								endContent={
									<ActionButton
										variant="cta"
										size="small"
										className="self-center"
										onClick={() => {
											selectOrganization(organization.worksiteId);
											setCurrentSelectedWorksiteId(organization.worksiteId);
										}}
										customState={
											isActive ? selectOrganizationButtonState : 'idle'
										}
									>
										<Text
											as="pSmall"
											text={t('julapro_choicebox_button_text')}
										/>
									</ActionButton>
								}
							>
								<>
									<Text
										as="pSmall"
										className="mb-2"
										text={organization.address}
									/>
									<Text as="pSmall" text={organization.city} />
									{errorText && (
										<InfoBox icon="error" variant="error" className="mt-4">
											<Text text={errorText} as="pSmall" className="mt-1" />
										</InfoBox>
									)}
								</>
							</ListItem>
						);
					})}
				</ul>
			)}
			{(searchingOrganizationFailed ||
				(organizationList &&
					organizationList.length === 0 &&
					isInSearchOrganizationSuccess)) && (
				<>
					<Text as="p" className="mt-4">
						{t('julapro_organsation_search_no_result_text', {
							searchString: value,
						})}
					</Text>{' '}
					<Text as="p" className="mt-6">
						{t('julapro_organsation_search_no_result_description', {
							searchString: value,
						})}
					</Text>
				</>
			)}

			{showCustomerIdForm && customerIdInputSection && (
				<>
					{renderHeader(customerIdInputSection.heading.value, 'mt-9 mb-2')}
					{renderDescription(
						customerIdInputSection.description.value,
						'',
						'mb-2',
					)}
					<Text
						as="p"
						className="mb-8 font-bold"
						text={selectedWorksite?.companyName}
					/>

					<GenericForm
						inputSpace="my-4"
						fields={customerIdInputSection.form}
						onSubmitCallback={submitCustomerId}
						button={
							<ActionButton
								data-cid="verifyCustomerNumber"
								displayWidth="full"
								type="submit"
								size="large"
								variant="cta"
								className="mt-6"
								customState={submitCustomerIdButtonState}
							>
								{customerIdInputSection.submitText.value}
							</ActionButton>
						}
					/>

					<div className="mt-6 flex justify-center">
						<Button
							variant="text"
							size="small"
							onClick={() => resetToSelectOrganization()}
						>
							{customerIdInputSection.backText.value}
						</Button>
					</div>
				</>
			)}
		</div>
	);
}
StepOneContent.displayName = 'AccountJulaProSignupForm_StepOneContent';
