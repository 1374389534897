import React, { useState } from 'react';
import { useSelector } from '@xstate/react';

import InfoBox from 'components/InfoBox';
import Login from 'components/Login';
import PageHeaderComponent from 'components/PageHeader';
import RichText from 'components/RichText';
import Skiplink from 'components/Skiplink';
import {
	HEADER_SEARCH_FIELD_ID,
	HEADER_SEARCH_FIELD_MOBILE_ID,
	MAIN_CONTENT_CONTAINER_ID,
} from 'constants/ids';
import { useGlobalStateContext } from 'contexts';
import { useGlobalEvent, useRouteChange } from 'hooks';
import type { JulaComponentProps } from 'lib/component-props';
import type { PageHeaderFields } from 'models/pageHeader';
import type { SystemMessage } from 'models/systemMessage';
import {
	selectIsAuthenticatedUser,
	selectIsInitialisingUser,
} from 'state-machines/authentication';
import { selectCartQuantity } from 'state-machines/cart';
import { selectWishlistQuantity } from 'state-machines/wishlist';
import type { LoginPanelType } from 'types';
import { useI18n } from 'utils/i18n';

type Props = JulaComponentProps & {
	fields?: PageHeaderFields;
};

function SystemMessageInfo({ message }: { message: SystemMessage }) {
	return (
		<InfoBox icon="error" variant="error" type="wide" heading={message.heading}>
			{message.message && (
				<RichText html={message.message} className="text-sm" />
			)}
		</InfoBox>
	);
}

SystemMessageInfo.displayName = 'PageHeader/SystemMessageInfo';

export default function PageHeader({ fields }: Props) {
	const { userService, cartService, myMenuService, wishlistService } =
		useGlobalStateContext();
	const { t } = useI18n();

	// Auth
	const isInitialisingUser = useSelector(userService, selectIsInitialisingUser);

	// Auth user
	const isAuthenticatedUser = useSelector(
		userService,
		selectIsAuthenticatedUser,
	);

	// Cart
	const cartQuantity = useSelector(cartService, selectCartQuantity);

	// Wishlist
	const wishlistQuantity = useSelector(wishlistService, selectWishlistQuantity);

	const [isLoginOpen, setIsLoginOpen] = useState<boolean>(false);
	const [loginType, setLoginType] = useState<LoginPanelType>('LoginJulaClub');

	useRouteChange('routeChangeComplete', (url) => {
		if (url.includes('returnPath')) {
			setIsLoginOpen(true);
		}
	});

	useGlobalEvent('open-login', (event) => {
		if (!isLoginOpen) {
			setLoginType(event.detail.loginType);
			setIsLoginOpen(true);
		}
	});
	useGlobalEvent('close-login', () => {
		setIsLoginOpen(false);
	});

	const handleLoginClick = () => {
		setLoginType('LoginJulaClub');
		setIsLoginOpen(true);
	};
	const handleLoginClose = () => {
		setIsLoginOpen(false);
	};
	const handleAccountClick = () => {
		myMenuService.send('OPEN_MENU');
	};

	if (!fields) {
		return null;
	}

	return (
		<>
			{fields.systemMessage?.activated && (
				<SystemMessageInfo message={fields.systemMessage} />
			)}
			<Skiplink
				target={MAIN_CONTENT_CONTAINER_ID}
				text={t('skiplink_to_content_text')}
			/>
			{!fields.hideSearchBar && (
				<>
					<Skiplink
						target={HEADER_SEARCH_FIELD_ID}
						text={t('skiplink_to_search_text')}
						className="max-headerRow:hidden"
					/>
					<Skiplink
						target={HEADER_SEARCH_FIELD_MOBILE_ID}
						text={t('skiplink_to_search_text')}
						className="headerRow:hidden"
					/>
				</>
			)}
			<PageHeaderComponent
				cartQuantity={cartQuantity}
				hideSearchBar={fields.hideSearchBar}
				isInitialisingUser={isInitialisingUser}
				isAuthenticatedUser={isAuthenticatedUser}
				mainMenu={fields.mainMenu}
				onAccountClick={handleAccountClick}
				onLoginClick={handleLoginClick}
				primaryTopLinks={fields.primaryTopLinks}
				secondaryTopLinks={fields.secondaryTopLinks}
				uspLinks={fields.uspLinks}
				review={fields.review}
				wishlistQuantity={wishlistQuantity}
			/>
			<Login
				header={t('account_login_button')}
				id="page-header-login"
				isOpen={!isAuthenticatedUser && isLoginOpen}
				onClose={handleLoginClose}
				initialTab={loginType}
			/>
		</>
	);
}
PageHeader.displayName = 'PageHeader';
