/**
 * BlockMedia - Divider
 */

import React from 'react';

import Img from 'components/Img';

interface Props {
	/** The image src */
	largeImgSrc?: string;

	largeImgWidth?: number;

	largeImgHeight?: number;

	/** The image src */
	largeImgAlt?: string;

	/** The xsmall image src (make sure that the small images can handle all small device sizes up to 425px) */
	smallImageSrc?: string;

	smallImageWidth?: number;

	smallImageHeight?: number;

	/** The xsmall image alt */
	smallImageAlt?: string;

	className?: string;
}

/** The divider variant of the block media component. */
function Divider({
	largeImgSrc,
	largeImgWidth,
	largeImgHeight,
	largeImgAlt,
	smallImageSrc,
	smallImageWidth,
	smallImageHeight,
	smallImageAlt,
	className,
}: Props) {
	return (
		<div className={className}>
			<Img
				src={smallImageSrc}
				alt={smallImageAlt}
				width={smallImageWidth}
				height={smallImageHeight}
				service="nextjs"
				className="sm:hidden"
			/>
			<Img
				src={largeImgSrc}
				alt={largeImgAlt}
				width={largeImgWidth}
				height={largeImgHeight}
				service="nextjs"
				className="hidden sm:block"
			/>
		</div>
	);
}

export default Divider;
Divider.displayName = 'Divider';
