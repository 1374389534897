import React from 'react';
import { Field } from '@sitecore-jss/sitecore-jss-nextjs';
import clsx from 'clsx';

import ArrowLink from 'components/ArrowLink';
import ComponentPlaceholder from 'components/ComponentPlaceholder';
import Img from 'components/Img';
import { LayoutContainer } from 'components/Layout';
import Text from 'components/Text';
import { useSitecoreContext } from 'hooks';
import { JulaComponentProps } from 'lib/component-props';
import { DigizuiteAssetList } from 'models/asset';
import { SitecoreLink } from 'models/link';
import { getEditorMargin } from 'utils/business-logic';
import { filterTruthy } from 'utils/collection';
import { getAsset, is } from 'utils/helpers';

interface TeaserItem {
	heading: Field<string>;
	landscapeImage: DigizuiteAssetList;
	link: Field<SitecoreLink>;
	video: DigizuiteAssetList;
}

type Props = JulaComponentProps & {
	fields: {
		heading: Field<string>;
		items: TeaserItem[];
		small: Field<boolean>;
	};
};

function mapTeaserItem(item: TeaserItem) {
	return {
		heading: item?.heading?.value,
		href: item?.link?.value.href,
		linkText: item?.link?.value.text,
		linkTarget: item?.link?.value.target,
		image: getAsset('Templated 2:1', item?.landscapeImage),
	};
}

function mapTeaserItems(items: TeaserItem[]) {
	const teasers = filterTruthy(
		items?.map(mapTeaserItem),
		'heading',
		'href',
		'linkText',
		'image',
	);
	return is.arrayWithLength(teasers) && teasers.length === 2
		? teasers
		: undefined;
}

export default function DoubleTeaser({ params, fields }: Props) {
	const { sitecoreContext } = useSitecoreContext();
	const isEditing = sitecoreContext?.pageEditing;
	if (!fields) return null;

	const { heading, small, items } = fields;
	const { heading: headingLevel = 'h2' } = params ?? {};

	const teasers = mapTeaserItems(items);
	if (!teasers && isEditing) {
		return (
			<ComponentPlaceholder
				componentName="DoubleTeaser"
				className={getEditorMargin(params)}
				description="This component requires two teaser items with heading, link, link text and image to function."
			/>
		);
	}
	if (!teasers) return null;
	return (
		<LayoutContainer
			outerClassName={getEditorMargin(params)}
			id={params?.anchor}
			className="grid grid-cols-4 gap-4 md:grid-cols-12 md:gap-6"
		>
			<Text
				as={headingLevel}
				field={heading}
				className={clsx('col-span-full', small.value && 'md:col-start-4')}
			/>
			{teasers.map((teaser, index) => {
				const {
					image,
					href,
					linkText,
					linkTarget,
					heading: teaserHeading,
				} = teaser;
				return (
					<div
						key={href + linkText + teaserHeading}
						className={clsx(
							'col-span-full',
							small?.value
								? index === 0
									? 'md:col-span-3 md:col-start-4'
									: 'md:col-span-3'
								: 'md:col-span-6',
						)}
					>
						<Img
							src={image.src}
							alt={image.alt}
							width={image.width}
							height={image.height}
							service="nextjs"
							className="h-auto w-full object-cover"
						/>
						<Text as="h3" text={teaserHeading} className="mt-4 md:mt-6" />
						<ArrowLink
							className="mt-4"
							href={href}
							text={linkText}
							target={linkTarget}
						/>
					</div>
				);
			})}
		</LayoutContainer>
	);
}
DoubleTeaser.displayName = 'DoubleTeaser';
