/**
 * Price
 */

import React from 'react';
import clsx from 'clsx';

import { PriceSize, PriceTheme } from 'models/price';
import cn from 'utils/cn';
import { is } from 'utils/helpers';
import { formatPrice, getPriceDecimals } from 'utils/price';

export interface Props {
	/** Additional container class names. */
	className?: string;

	/** The currency symbol of the price. */
	currency?: string;

	/** The main price of the component */
	price: string | number;

	/** The text for the price label (displayed above the price). */
	priceLabel?: string;

	/** Alignment of the `priceLabel` */
	priceLabelAlign?: 'left' | 'right';

	/** The size of the price. */
	size?: PriceSize;

	/** The text for the bottom tag (displayed below the "plate"). */
	tagBottom?: string;

	/** The text for the top tag (displayed above the "plate"). */
	tagTop?: string;

	/** The theme of the component. */
	theme?: PriceTheme;
}

/** Price component with different themes and sizes. */
export default function Price({
	className,
	currency,
	price,
	priceLabel,
	priceLabelAlign = 'right',
	size = 'large',
	tagBottom,
	tagTop,
	theme = 'regular',
}: Props) {
	const hasTagTop =
		is.truthy(tagTop) &&
		is.oneOf(theme, 'julaPro', 'julaClub', 'julaClubSmart');
	const hasTagBottom =
		is.truthy(tagBottom) &&
		is.oneOf(size, 'large', 'medium', 'small', 'mini', 'micro');

	const isRotated =
		theme !== 'regular' &&
		is.oneOf(size, 'micro', 'mini', 'small', 'medium', 'large');

	const mainPartClasses = clsx(
		'inline-block min-w-[1em] font-alt font-bold leading-none',

		// Text size
		size === 'extraNano' && 'text-[0.9375rem]',
		size === 'nano' && 'text-[1.125rem]',
		size === 'microCompact' && 'text-[1.5rem]',
		size === 'micro' && 'text-[1.5rem]',
		size === 'mini' && 'text-[2rem]',
		size === 'small' && 'text-[2.5rem]',
		size === 'medium' && 'text-[3.75rem]',
		size === 'large' && 'text-[4.5rem]',

		// Colors
		theme === 'regular' && 'text-greyDarker',
		is.oneOf(theme, 'campaign', 'superCheap') && 'bg-campaign text-julaRedDark',
		theme === 'julaClub' && 'bg-julaRedDark text-white',
		theme === 'julaClubSmart' && 'bg-grey text-white',
		theme === 'julaPro' && 'bg-greyDarker text-white',

		// Plate dimensions
		theme !== 'regular' && [
			// Padding
			is.oneOf(size, 'extraNano', 'nano', 'microCompact', 'micro') &&
				'py-[0.2222em] px-[0.25em]',
			is.oneOf(size, 'mini', 'small') && 'py-[0.2222em] px-[0.2em]',
			is.oneOf(size, 'medium', 'large') && 'py-[0.18em] px-[0.1666em]',

			// Radius
			is.oneOf(size, 'extraNano', 'nano') && 'rounded',
			is.oneOf(size, 'microCompact', 'micro', 'mini') && 'rounded-md',
			size === 'small' && 'rounded-lg',
			size === 'medium' && 'rounded-xl',
			size === 'large' && 'rounded-2xl',
		],
	);

	const decimals = getPriceDecimals(price);
	const hasDecimals = decimals.length > 0;
	const renderMainText = (...extraClasses: Array<string | undefined>) => (
		<p
			className={clsx(
				'inline-block whitespace-nowrap text-right',
				extraClasses,
			)}
		>
			{formatPrice(price)}
			{hasDecimals && (
				<sup className="top-0 text-[50%] leading-none">{decimals}</sup>
			)}
			{currency && !hasDecimals && (
				<span
					className={clsx(
						'pr-[0.08em] -tracking-[0.125em]',
						is.oneOf(size, 'mini', 'small', 'medium', 'large') &&
							'-ml-[0.02em]',
					)}
				>
					{currency}
				</span>
			)}
		</p>
	);

	// To reduce the amount of DOM nodes, skip the outer containers when it's
	// just a regular price without any extra themes or labels.
	if (theme === 'regular' && !hasTagTop && !priceLabel && !hasTagBottom) {
		return renderMainText(className, mainPartClasses);
	}

	const tagBaseClasses = clsx(
		'inline-block whitespace-nowrap font-standard leading-none pointer-events-auto',
		is.oneOf(size, 'medium', 'large')
			? 'rounded font-bold'
			: 'rounded-sm font-normal',

		// For tags with background
		theme !== 'regular' && [
			'text-white',
			is.oneOf(size, 'medium', 'large')
				? 'py-[0.3333em] px-[0.5em]'
				: 'py-[0.25em] px-[0.4em]',
		],

		// Text size
		is.oneOf(size, 'extraNano', 'nano') && 'text-[0.4375rem]',
		is.oneOf(size, 'microCompact', 'micro') && 'text-[0.5625rem]',
		size === 'mini' && 'text-[0.6875rem]',
		size === 'small' && 'text-[0.8125rem]',
		is.oneOf(size, 'medium', 'large') && 'text-[0.9375rem]',
	);

	return (
		<div
			className={cn(
				// Disable pointer events to prevent the plate from ever stopping
				// text selection close to the price. Events are restored on the
				// actual text parts.
				'pointer-events-none inline-flex flex-col items-start align-middle',
				isRotated && [
					'origin-top-left rotate-4',
					// Rotation doesn't affect layout so compensate with some padding.
					size === 'micro' && 'pt-0.5',
					size === 'mini' && 'pt-[0.1875rem]',
					size === 'small' && 'pt-1.5',
					size === 'medium' && 'pt-2',
					size === 'large' && 'pt-[0.625rem]',
				],
				className,
			)}
		>
			{hasTagTop && (
				<p
					className={clsx(
						tagBaseClasses,
						theme === 'julaPro' && 'bg-greyDarker',
						theme === 'julaClub' && 'bg-julaRedDark',
						theme === 'julaClubSmart' && 'bg-grey',
						is.oneOf(size, 'medium', 'large') ? 'mb-1' : 'mb-0.5',
					)}
				>
					{tagTop}
				</p>
			)}

			<div className={clsx(mainPartClasses, 'pointer-events-auto')}>
				{priceLabel && (
					<p
						className={clsx(
							'block whitespace-nowrap leading-none',
							is.oneOf(size, 'medium', 'large')
								? '-mb-[0.3333em] -mt-[0.1em]'
								: '-my-[0.15em]',

							// Text style
							priceLabelAlign === 'right' && 'text-right',
							is.oneOf(size, 'medium', 'large') ? 'font-alt' : 'font-standard',
							is.oneOf(size, 'small', 'medium', 'large')
								? 'font-bold'
								: 'font-normal',

							// Text size
							is.oneOf(size, 'extraNano', 'nano') && 'text-[0.4375rem]',
							is.oneOf(size, 'microCompact', 'micro') && 'text-[0.5625rem]',
							size === 'mini' && 'text-[0.6875rem]',
							size === 'small' && 'text-[0.8125rem]',
							size === 'medium' && 'text-[1.3125rem]',
							size === 'large' && 'text-[1.5rem]',
						)}
					>
						{priceLabel}
					</p>
				)}

				{renderMainText()}
			</div>

			{hasTagBottom && (
				<p
					className={clsx(
						tagBaseClasses,
						'relative z-1 self-end',
						theme === 'regular' && [
							'-mr-[0.25em]',
							is.oneOf(size, 'medium', 'large')
								? '-mt-[0.5em]'
								: '-mt-[0.3125em]',
						],
						theme !== 'regular' && [
							'-mr-[0.5em] ml-[0.625em]',
							is.oneOf(size, 'medium', 'large')
								? '-mt-[0.875em]'
								: '-mt-[0.625em]',
							theme === 'superCheap' ? 'bg-greyDarker' : 'bg-julaRedLight',
						],
					)}
				>
					{tagBottom}
				</p>
			)}
		</div>
	);
}
Price.displayName = 'Price';
