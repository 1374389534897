import * as React from 'react';
import { TextField } from '@sitecore-jss/sitecore-jss-react';

import { LayoutContainer } from 'components/Layout';
import Text from 'components/Text';
import { JulaComponentProps } from 'lib/component-props';
import { getEditorMargin } from 'utils/business-logic';

type QuoteProps = JulaComponentProps & {
	fields: {
		quote: TextField;
		author: TextField;
	};
};

/**
 * A simple Content Block component, with a heading and rich text block.
 * This is the most basic building block of a content site, and the most basic
 * JSS component that's useful.
 */
function Quote({ params, fields }: QuoteProps) {
	if (!fields) {
		return null;
	}

	return (
		<LayoutContainer
			id={params?.anchor}
			outerClassName={getEditorMargin(params)}
			className="grid grid-cols-4 gap-4 md:grid-cols-12 md:gap-6"
		>
			<div className="col-span-4 md:col-span-6 md:col-start-4">
				<blockquote>
					<Text
						as="p"
						styleAs="h1"
						field={fields?.quote}
						className="mb-2 text-left"
					/>
				</blockquote>

				<Text as="p" className="text-left font-bold" field={fields?.author} />
			</div>
		</LayoutContainer>
	);
}
Quote.displayName = 'Quote';

export default Quote;
