import React from 'react';

import BlockMedia from 'components/BlockMedia/';
import ComponentPlaceholder from 'components/ComponentPlaceholder';
import { useSitecoreContext } from 'hooks';
import { JulaComponentProps } from 'lib/component-props';
import { DigizuiteAssetList } from 'models/asset';
import { getEditorMargin } from 'utils/business-logic';
import { getAsset } from 'utils/helpers';

type MediaLandscapeProps = JulaComponentProps & {
	fields: {
		squareImage: DigizuiteAssetList;
	};
	params?: {
		margin: 'None' | 'Single' | 'Double';
	};
};

function MediaInspirationSquare({ fields, params }: MediaLandscapeProps) {
	const { sitecoreContext } = useSitecoreContext();
	const isEditing = sitecoreContext?.pageEditing;
	if (!fields) return null;

	const { squareImage } = fields;

	const squareImg = getAsset('Templated 1:1', squareImage);

	if (isEditing) {
		return (
			<ComponentPlaceholder
				componentName="MediaInspirationSquare"
				className={getEditorMargin(params)}
				isDeprecated
			/>
		);
	}
	if (!squareImg) {
		return (
			<ComponentPlaceholder
				componentName="MediaInspirationSquare"
				className={getEditorMargin(params)}
			/>
		);
	}

	return (
		<div id={params?.anchor} className={getEditorMargin(params)}>
			<BlockMedia
				variant="landscape"
				imgSrc={squareImg?.src}
				imgAlt={squareImg?.alt}
			/>
		</div>
	);
}

MediaInspirationSquare.displayName = 'MediaInspirationSquare';

export default MediaInspirationSquare;
