import type { GiftCardBonusMachineState } from './giftCardBonus.types';

export const selectGiftCards = (current: GiftCardBonusMachineState) =>
	current.context.giftCards;

export const selectErrorList = (current: GiftCardBonusMachineState) =>
	current.context.errorList;

export const selectGiftCardAmountInfo = (current: GiftCardBonusMachineState) =>
	current.context.errorList.find(
		(error) => error.type === 'GiftCardAmountInfo',
	);

export const selectGiftCardDisabledWithSelectedPaymentMethod = (
	current: GiftCardBonusMachineState,
) =>
	current.context.errorList.find(
		(error) => error.type === 'GiftCardDisabledWithSelectedPaymentMethod',
	);

export const selectAvailableBonusDiscounts = (
	current: GiftCardBonusMachineState,
) => current.context.availableBonusDiscounts;

export const selectBonusDiscounts = (current: GiftCardBonusMachineState) =>
	current.context.bonusDiscounts;
