import React from 'react';

import Icon from 'components/Icon';
import ListItem from 'components/ListItemNew';
import Status from 'components/Status';
import Text from 'components/Text';

type Props = ItemWithClickProps | ItemWithUrlProps;
interface BaseProps {
	address: string;
	isOpen: boolean;
	isSelected?: boolean;
	name: string;
	openHours: string;
	storeArea: string;
}
interface ItemWithClickProps extends BaseProps {
	onClick: () => void;
	url?: never;
}
interface ItemWithUrlProps extends BaseProps {
	url: string;
	onClick?: never;
}

export default function StoreListItem({
	address,
	isOpen,
	isSelected,
	name,
	onClick,
	openHours,
	storeArea,
	url,
}: Props) {
	return (
		<ListItem
			as="li"
			isSelected={isSelected}
			href={url}
			onClick={onClick}
			title={name}
			endContent={<Icon icon="arrow" className="self-center" />}
		>
			<Text as="p" text={storeArea} />
			<Text as="p" text={address} className="mb-2" />
			<Status color={isOpen ? 'green' : 'red'} text={openHours} />
		</ListItem>
	);
}
StoreListItem.displayName = 'StoreListItem';
