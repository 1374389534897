import React from 'react';
import type {
	RichTextField,
	TextField,
} from '@sitecore-jss/sitecore-jss-react';
import clsx from 'clsx';

import { LayoutContainer } from 'components/Layout';
import RichText from 'components/RichText';
import Text from 'components/Text';
import { useSitecoreContext } from 'hooks';
import type { ColorField, JulaComponentProps } from 'lib/component-props';
import { getEditorMargin } from 'utils/business-logic';

type Props = JulaComponentProps & {
	fields?: {
		backgroundColor?: ColorField;
		content: RichTextField;
		heading: TextField;
	};
};

/**
 * A simple Content Block component, with a heading and rich text block.
 * This is the most basic building block of a content site, and the most basic
 * JSS component that's useful.
 */
export default function ContentBlock({
	isInNestedPlaceholder,
	isLeftWide,
	params,
	fields,
}: Props) {
	const { sitecoreContext } = useSitecoreContext();
	const isEditing = sitecoreContext?.pageEditing;

	if (
		!fields ||
		(!isEditing && !fields.heading.value && !fields.content.value)
	) {
		return null;
	}

	return (
		<LayoutContainer
			id={params?.anchor}
			withGrid={!isInNestedPlaceholder}
			maxWidth={isInNestedPlaceholder ? 'none' : undefined}
			gutters={!isInNestedPlaceholder}
			withPopoverLayout
			outerClassName={getEditorMargin(params)}
			backgroundColor={fields.backgroundColor?.fields?.colorHex?.value}
		>
			<div
				className={clsx(
					'relative col-span-4 md:col-span-6',
					!isLeftWide && 'md:col-start-4',
				)}
			>
				{fields.heading && (
					<Text
						className="mb-2"
						as={params?.heading || 'h2'}
						field={fields.heading}
					/>
				)}
				<RichText field={fields.content} />
			</div>
		</LayoutContainer>
	);
}
ContentBlock.displayName = 'ContentBlock';
