import { Wishlist } from 'models/api';
import { is } from 'utils/helpers';

export const formatWishlistdataForCart = (wishlist: Wishlist) => {
	const variants: { variantId: string; quantity: number }[] = [];
	wishlist?.categories.forEach((category) => {
		category.variants.forEach((variant) => {
			if (variant.id) {
				variants.push({ variantId: variant.id, quantity: variant.qty });
			}
		});
	});
	return variants;
};
export const formatWishListDataForGTM = (wishlist: Wishlist) =>
	wishlist.categories
		.flatMap((category) =>
			category.variants.map((variant) => {
				if (variant.id) {
					return {
						product: variant,
						quantity: variant.qty,
					};
				}
				return undefined;
			}),
		)
		.filter(is.defined);
