/**
 * ProductDetailsImage
 */

import React, { useState } from 'react';

import ImageLayover from 'components/ImageLayover';
import ImageSlider from 'components/ImageSlider';
import Img from 'components/Img';
import ProductFlag, { getFlagVariant } from 'components/ProductFlag';
import ThumbnailList from 'components/ThumbnailList';
import { useMinWidth } from 'hooks';
import { Product } from 'models/product';
import { getAllImages, getMainImageDetail } from 'utils/business-logic';
import { useI18n } from 'utils/i18n';

interface Props {
	product: Product;
}

const PLACEHOLDER_SRC = '/assets/images/placeholder-image.png';

/** Image component for Product Details. */
export default function ProductDetailsImage({ product }: Props) {
	const { t } = useI18n();
	const isMediumOrLarger = useMinWidth('md');

	const [imageLayoverIsOpen, setImageLayoverIsOpen] = useState(false);
	const [carouselIndex, setCarouselIndex] = useState(0);

	const allImages = getAllImages(product) ?? [];
	const hasImages = allImages.length > 0;

	const [selectedImageId, setSelectedImageId] = useState(
		getMainImageDetail(product)?.id ?? 1,
	);
	const selectedImage = allImages.find((image) => image.id === selectedImageId);

	const flagVariant = getFlagVariant(product);
	const flag = flagVariant ? (
		<ProductFlag
			variant={flagVariant}
			size={isMediumOrLarger ? 'large' : 'medium'}
			className="absolute left-0 top-0 z-1"
		/>
	) : null;

	const mainImageSrc = selectedImage?.src || PLACEHOLDER_SRC;
	const mainImage = (
		<Img
			className="mt-6 max-w-[24rem]"
			src={mainImageSrc}
			alt={selectedImage?.alt}
			service="nextjs"
			priority
			width={384}
			height={384}
		/>
	);

	return (
		<>
			<ImageLayover
				images={allImages}
				isOpen={imageLayoverIsOpen}
				onClose={() => setImageLayoverIsOpen(false)}
				selectedImageId={selectedImageId}
				onThumbnailClick={(imageId) => setSelectedImageId(imageId)}
				carouselIndex={carouselIndex}
				onThumbnailSlideChange={(index) => setCarouselIndex(index)}
				imageService="nextjs"
			/>
			<div className="relative md:hidden">
				{flag}
				{hasImages && (
					<ImageSlider
						images={allImages}
						sliderButtonLabel={t('product_details_show_large_image_label')}
						onSlideChange={(imageId) => {
							if (imageId !== undefined) {
								setSelectedImageId(imageId);
							}
						}}
						onSliderClick={() => setImageLayoverIsOpen(true)}
					/>
				)}
				{!hasImages && (
					<Img
						src={PLACEHOLDER_SRC}
						width={128}
						height={128}
						className="mx-auto size-32"
					/>
				)}
			</div>

			<div className="grid grid-cols-8 gap-6 max-md:hidden">
				{allImages.length > 1 && (
					<ThumbnailList
						images={allImages}
						selectedImageId={selectedImageId}
						onImageClick={(imageId) => setSelectedImageId(imageId)}
						carouselIndex={carouselIndex}
						onSlideChange={(index) => setCarouselIndex(index)}
						className="col-span-1"
					/>
				)}
				<div className="relative col-span-5 col-start-3">
					{flag}
					{mainImageSrc === PLACEHOLDER_SRC && mainImage}
					{mainImageSrc !== PLACEHOLDER_SRC && (
						<button
							type="button"
							aria-label={t('product_details_show_large_image_label')}
							className="min-h-24 min-w-24"
							onClick={() => setImageLayoverIsOpen(true)}
						>
							{mainImage}
						</button>
					)}
				</div>
			</div>
		</>
	);
}
ProductDetailsImage.displayName = 'ProductDetailsImage';
