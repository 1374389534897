import React from 'react';

import BonusChequeQrCode from 'components/BonusChequeQrCode';
import InfoBox from 'components/InfoBox';
import { AccountLayoutContainer } from 'components/Layout';
import LoadingSpinner from 'components/LoadingSpinner';
import { useBonusChecks, useCustomerInformation } from 'hooks';
import type { JulaComponentProps } from 'lib/component-props';
import { useI18n } from 'utils/i18n';

type Props = JulaComponentProps & {};

export default function AccountCoupons({ rendering }: Props) {
	const { t } = useI18n();

	const {
		customerInformation,
		isLoading: isLoadingCustomerInformation,
		error: errorCustomerInformation,
	} = useCustomerInformation();
	const { id, fullName } = customerInformation || {};
	const {
		bonusChecks,
		isLoading: isLoadingBonusChecks,
		error: errorBonusChecks,
	} = useBonusChecks();

	const isLoading = isLoadingCustomerInformation || isLoadingBonusChecks;
	const error = errorCustomerInformation || errorBonusChecks;
	const loadedAndOk = !isLoading && !error;

	return (
		<AccountLayoutContainer
			rendering={rendering}
			heading={t('account_coupons_heading')}
		>
			{loadedAndOk && (
				/* TODO: md:grid-cols-2 when switched to new grid system */
				<div className="mt-8 grid grid-cols-1 gap-4 md:grid-cols-1 md:gap-6">
					{bonusChecks?.length
						? bonusChecks.map((check, i) => (
								<BonusChequeQrCode
									key={check.checkNumber || i}
									number={check.checkNumber || ''}
									truncateNumber={false}
									validThru={check.validTo || ''}
									amount={check.amountWithCurrencySymbol || ''}
									customerNo={id || ''}
									customerFullName={fullName || ''}
								/>
						  ))
						: t('account_coupons_no_coupons_text')}
				</div>
			)}
			{isLoading && <LoadingSpinner className="mt-8" />}
			{error && (
				<InfoBox
					icon="error"
					heading={t('julapro_unable_to_fetch_customer_data_heading')}
					variant="error"
					message={t('julapro_unable_to_fetch_customer_data_text')}
					className="mt-4"
				/>
			)}
		</AccountLayoutContainer>
	);
}
AccountCoupons.displayName = 'AccountCoupons';
