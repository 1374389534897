import React from 'react';
import clsx from 'clsx';

import Button from 'components/Button';
import CreditSimulationPopover from 'components/CreditSimulation';
import IconList from 'components/IconList';
import Img from 'components/Img';
import InfoBox from 'components/InfoBox';
import { OptionalLink, ScrollLink } from 'components/Link';
import Price from 'components/Price';
import {
	MIN_ANSWER_COUNT,
	TOO_LARGE_PERCENTAGE,
	TOO_SMALL_PERCENTAGE,
} from 'components/ProductFit';
import Rating from 'components/Rating';
import { Skeleton, SkeletonItem } from 'components/Skeleton';
import Staffling from 'components/Staffling';
import Text from 'components/Text';
import { StoreIdName } from 'contexts';
import type { QuestionSummary } from 'models/api';
import type { InventoryItem, Stock, StockStore } from 'models/inventory';
import type { Product } from 'models/product';
import type { ProductCard } from 'models/productCard';
import type { ButtonStateWithId } from 'state-machines/cart';
import { getPriceProps } from 'utils/business-logic';
import { useI18n } from 'utils/i18n';
import { getWeightedPercentage } from 'utils/math';

import {
	PRODUCT_REVIEWS_ID,
	PRODUCT_TECHNICAL_ATTRIBUTES_ID,
} from './helpers/constants';
import ButtonPanel from './ButtonPanel';
import Prices from './Prices';
import ProductDetailsCampaignPopover from './ProductDetailsCampaignPopover';
import StockInformation from './StockInformation';
import VariantPicker from './VariantPicker';

export interface Props {
	allStoresStock: Stock[] | undefined;
	className?: string;
	creditSimulationPopoverIsOpen: boolean;
	currentProductStock: InventoryItem | undefined;
	currentStoreStock: Stock | undefined;
	fit3Summary?: QuestionSummary;
	isLoadingFit?: boolean;
	isLoadingInitialStock: boolean;
	isLoadingNearbyStoresStock: boolean;
	isLoadingStock: boolean;
	isLoadingVariantsStock: boolean;
	nearbyStoresStock: Stock[] | undefined;
	onCampaignPopoverPurchaseButtonClick: (variant: ProductCard) => void;
	onCreditSimulationClick: () => void;
	onCreditSimulationPopoverClose: () => void;
	onEnergyLabelClick: () => void;
	onMainPurchaseButtonClick: () => void;
	onRatingClick: () => void;
	onSizeFitClick: () => void;
	onSizeGuideClick: () => void;
	onStockInformationOpen: () => void;
	onStockInformationTabChange: (tab: string) => void;
	onUpdateSelectedStore: (store: StockStore) => void;
	onVariantPickerOpen: () => void;
	onWishlistButtonClick: () => void;
	product: Product;
	purchaseButtonDisabled: boolean;
	purchaseButtonState: ButtonStateWithId;
	selectedStore: StoreIdName | undefined;
	variantsStock: InventoryItem[] | undefined;
	wishlistButtonState: ButtonStateWithId;
}

export default function ProductDetailsSidebar({
	allStoresStock,
	className,
	creditSimulationPopoverIsOpen,
	currentProductStock,
	currentStoreStock,
	fit3Summary,
	isLoadingFit = false,
	isLoadingInitialStock,
	isLoadingNearbyStoresStock,
	isLoadingStock,
	isLoadingVariantsStock,
	nearbyStoresStock,
	onCampaignPopoverPurchaseButtonClick,
	onCreditSimulationClick,
	onCreditSimulationPopoverClose,
	onEnergyLabelClick,
	onMainPurchaseButtonClick,
	onRatingClick,
	onSizeFitClick,
	onSizeGuideClick,
	onStockInformationOpen,
	onStockInformationTabChange,
	onUpdateSelectedStore,
	onVariantPickerOpen,
	onWishlistButtonClick,
	product,
	purchaseButtonDisabled,
	purchaseButtonState,
	selectedStore,
	variantsStock,
	wishlistButtonState,
}: Props) {
	const { t } = useI18n();
	const {
		brand,
		energyInformation,
		productSheetLink,
		reviewScore,
		salesAttributes,
		showCreditSimulationLink,
		sizeGuideUrl,
		staffPrice,
		variants,
		volumePrices,
	} = product;

	const energySymbolSrc = energyInformation?.symbol?.versions?.find(
		(x) => x.subType === 'EfficiencySymbol',
	)?.formats?.[0]?.url?.location;

	const rating = (
		<div className="mb-2 mt-2 sm:mb-6">
			{reviewScore?.score && reviewScore.score > 0 ? (
				<Rating
					reviewCountVariant="fullText"
					href={`#${PRODUCT_REVIEWS_ID}`}
					onClick={onRatingClick}
					score={reviewScore.score}
					reviewCount={reviewScore.count}
				/>
			) : (
				<div className="pb-4" />
			)}
		</div>
	);

	const perceivedFitPercentage =
		fit3Summary?.options?.length && fit3Summary.count >= MIN_ANSWER_COUNT
			? getWeightedPercentage(...fit3Summary.options.map((opt) => opt.count))
			: undefined;
	const perceivedFitText =
		// Explicitly check undefined to not exclude zero.
		perceivedFitPercentage === undefined
			? undefined
			: perceivedFitPercentage < TOO_SMALL_PERCENTAGE
				? t('question_summary_fit_is_small_title')
				: perceivedFitPercentage > TOO_LARGE_PERCENTAGE
					? t('question_summary_fit_is_large_title')
					: // No text for a perfect fit.
						undefined;

	return (
		<div className={clsx('flex flex-col', className)}>
			<div>
				<div className="sm:hidden">{rating}</div>
				<OptionalLink
					href={brand?.relativeUrl}
					linkClassName="underline mb-1 block"
					className="sm:hidden"
					fallbackTag="p"
				>
					{brand?.title}
				</OptionalLink>
				<Text as="h1" styleAs="h2" className="mb-2" text={product.title} />
				<OptionalLink
					href={brand?.relativeUrl}
					linkClassName="underline hover:no-underline"
					className="mb-2 max-sm:hidden"
					fallbackTag="p"
				>
					{brand?.title}
				</OptionalLink>
			</div>

			<div className="max-sm:hidden">{rating}</div>
			{!salesAttributes?.expired && (
				<div>
					<Prices
						displayPrice={product.displayPrice}
						basePrice={product.basePrice}
						salesAttributes={product.salesAttributes}
					/>

					{Boolean(energySymbolSrc || productSheetLink) && (
						<div className="my-8 flex flex-grow items-center">
							{energySymbolSrc && (
								<ScrollLink
									anchor={PRODUCT_TECHNICAL_ATTRIBUTES_ID}
									onClick={onEnergyLabelClick}
									className="mr-2 hover:opacity-80"
								>
									<Img
										src={energySymbolSrc}
										alt={t('product_details_technical_specification_heading')}
										className="h-12 object-contain"
									/>
								</ScrollLink>
							)}
							{productSheetLink && (
								<Text as="pSmall">
									<a
										href={productSheetLink}
										target="_blank"
										rel="nofollow noopener noreferrer"
										className="underline hover:no-underline"
									>
										{product.productSheetLinkDescription ||
											t('product_details_product_sheet_button')}
									</a>
								</Text>
							)}
						</div>
					)}

					{staffPrice && (
						<div className="mb-3 sm:mb-6">
							<InfoBox
								heading={t('product_details_staff_price_heading')}
								icon="info"
								className="!mt-8"
							>
								<div className="mb-4 mt-2 text-sm">
									{t('product_details_staff_price_text')}
								</div>
								<Price {...getPriceProps(staffPrice, true)} size="mini" />
							</InfoBox>
						</div>
					)}

					{volumePrices && volumePrices.length > 0 && (
						<div className="space-between mt-6 flex flex-col gap-4 md:mt-8">
							{volumePrices.map((price, index) => (
								<Staffling key={index} volumePrice={price} />
							))}
						</div>
					)}
					<ProductDetailsCampaignPopover
						campaigns={product.campaigns}
						purchaseButtonState={purchaseButtonState}
						onPurchaseButtonClick={onCampaignPopoverPurchaseButtonClick}
					/>

					{showCreditSimulationLink && (
						<>
							<CreditSimulationPopover
								isOpen={creditSimulationPopoverIsOpen}
								onClose={onCreditSimulationPopoverClose}
								price={product.displayPrice?.priceIncVat?.value}
							/>
							<Button
								variant="text"
								className="mt-6"
								onClick={onCreditSimulationClick}
							>
								{t('product_details_credit_simulation_link_button')}
							</Button>
						</>
					)}
					<VariantPicker
						variants={variants}
						stockData={variantsStock}
						product={product}
						fit3Summary={fit3Summary}
						selectedStore={selectedStore}
						stockIsLoading={isLoadingVariantsStock}
						onOpenCallback={onVariantPickerOpen}
					/>

					{sizeGuideUrl && variants.length > 1 && (
						<div className="flex">
							{isLoadingFit && (
								<Skeleton>
									<SkeletonItem
										width="11rem"
										height="1.5rem"
										className="my-1"
									/>
								</Skeleton>
							)}
							{perceivedFitText && (
								<ScrollLink
									anchor={PRODUCT_REVIEWS_ID}
									onClick={onSizeFitClick}
									// Match size guide button height
									className="group mr-4 inline-flex min-h-[2rem] flex-wrap items-center gap-x-1 py-1"
								>
									{`${t('product_details_size_fit')}: `}
									<span className="underline group-hover:no-underline">
										{perceivedFitText}
									</span>
								</ScrollLink>
							)}
							<Button
								variant="text"
								onClick={onSizeGuideClick}
								className="ml-auto text-right"
							>
								{t('product_details_sizeguide_button')}
							</Button>
						</div>
					)}

					<ButtonPanel
						purchaseButtonDisabled={purchaseButtonDisabled}
						purchaseButtonState={
							purchaseButtonState.buttonId === product.id
								? purchaseButtonState.state
								: 'idle'
						}
						onPurchaseButtonClick={onMainPurchaseButtonClick}
						wishlistButtonState={
							wishlistButtonState.buttonId === product.id
								? wishlistButtonState.state
								: 'idle'
						}
						onWishlistButtonClick={onWishlistButtonClick}
					/>

					<IconList
						className="mt-6"
						iconName="check"
						items={[
							t('product_details_payment_usp_text'),
							t('product_details_return_usp_text'),
							t('product_details_delivery_usp_text'),
						]}
						size="regular"
					/>

					<StockInformation
						className="my-8 sm:mb-20"
						onOpenCallBack={onStockInformationOpen}
						onTabChange={onStockInformationTabChange}
						onUpdateSelectedStore={onUpdateSelectedStore}
						product={product}
						currentStoreStock={currentStoreStock}
						allStoresStock={allStoresStock}
						nearbyStoresStock={nearbyStoresStock}
						currentProductStock={currentProductStock}
						selectedStore={selectedStore}
						isLoadingStock={isLoadingStock}
						isLoadingInitialStock={isLoadingInitialStock}
						isLoadingNearbyStoresStock={isLoadingNearbyStoresStock}
					/>
				</div>
			)}
		</div>
	);
}
ProductDetailsSidebar.displayName = 'ProductDetailsSidebar';
