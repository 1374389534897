import React from 'react';
import type { Field } from '@sitecore-jss/sitecore-jss-nextjs';

import BlockMedia from 'components/BlockMedia';
import ComponentPlaceholder from 'components/ComponentPlaceholder';
import { LayoutContainer } from 'components/Layout';
import { useIsEditing } from 'hooks';
import type { ColorField, JulaComponentProps } from 'lib/component-props';
import type { DigizuiteAsset, DigizuiteAssetList } from 'models/asset';
import type { ProductCard } from 'models/productCard';
import { getEditorMargin } from 'utils/business-logic';
import { getAsset } from 'utils/helpers';

type Props = JulaComponentProps & {
	fields?: {
		backgroundColor?: ColorField;
		mirror?: Field<boolean>;
		preventAutoplay?: Field<boolean>;
		primaryImage?: DigizuiteAssetList;
		primaryImageMobile?: DigizuiteAssetList;
		products?: ProductCard[];
		secondaryImage?: DigizuiteAssetList;
		secondaryImageMobile?: DigizuiteAssetList;
		video?: DigizuiteAssetList;
	};
};

interface ViewProps {
	mirror?: Field<boolean>;
	preventAutoplay?: Field<boolean>;
	primaryImg: DigizuiteAsset | undefined;
	products?: ProductCard[];
	secondaryImg: DigizuiteAsset | undefined;
	video: DigizuiteAsset | undefined;
}

function DesktopView({
	mirror,
	primaryImg,
	secondaryImg,
	video,
	preventAutoplay,
	products,
}: ViewProps) {
	const leftBlock = (
		<BlockMedia
			className="hidden md:col-span-6 md:block"
			variant="landscapeWide"
			imgSrc={primaryImg?.src}
			imgAlt={primaryImg?.alt}
			preventAutoplay={preventAutoplay?.value}
			video={video}
		/>
	);
	const rightBlock = (
		<BlockMedia
			className="col-span-6 hidden md:block"
			variant="landscapeWide"
			imgSrc={secondaryImg?.src}
			imgAlt={secondaryImg?.alt}
			products={products}
		/>
	);
	return (
		<>
			{mirror?.value ? rightBlock : leftBlock}
			{mirror?.value ? leftBlock : rightBlock}
		</>
	);
}
DesktopView.displayName = 'LegacyMediaDoubleWide/DesktopView';
function MobileView({
	mirror,
	primaryImg,
	secondaryImg,
	video,
	preventAutoplay,
	products,
}: ViewProps) {
	const leftBlock = (
		<BlockMedia
			className="col-span-2 md:hidden"
			variant="landscapeWide"
			imgSrc={primaryImg?.src}
			imgAlt={primaryImg?.alt}
			preventAutoplay={preventAutoplay?.value}
			video={video}
		/>
	);
	const rightBlock = (
		<BlockMedia
			className="col-span-2 md:hidden"
			variant="landscapeWide"
			imgSrc={secondaryImg?.src}
			imgAlt={secondaryImg?.alt}
			products={products}
		/>
	);
	return (
		<>
			{mirror?.value ? rightBlock : leftBlock}
			{mirror?.value ? leftBlock : rightBlock}
		</>
	);
}
MobileView.displayName = 'LegacyMediaDoubleWide/MobileView';

export default function LegacyMediaDoubleWide({ fields, params }: Props) {
	const isEditing = useIsEditing();

	if (!fields) {
		return null;
	}

	const {
		primaryImage,
		primaryImageMobile,
		secondaryImage,
		secondaryImageMobile,
		products,
		video,
		preventAutoplay,
		mirror,
	} = fields;

	const primaryImg = getAsset('Templated 2:1', primaryImage);
	const secondaryImg = getAsset('Templated 2:1', secondaryImage);
	const primaryImgMobile = getAsset('Templated 3:4', primaryImageMobile);
	const secondaryImgMobile = getAsset('Templated 3:4', secondaryImageMobile);
	const videoAsset = getAsset('Source Copy', video);

	if (isEditing) {
		return (
			<ComponentPlaceholder
				componentName="LegacyMediaDoubleWide"
				className={getEditorMargin(params)}
				isDeprecated
			/>
		);
	}

	if (!primaryImg || !secondaryImg) {
		return (
			<ComponentPlaceholder
				componentName="LegacyMediaDoubleWide"
				description="Requires a primary and secondary image"
				className={getEditorMargin(params)}
			/>
		);
	}

	return (
		<LayoutContainer
			id={params?.anchor}
			outerClassName={getEditorMargin(params)}
			withGrid
			backgroundColor={fields.backgroundColor?.fields?.colorHex?.value}
		>
			<DesktopView
				mirror={mirror}
				primaryImg={primaryImg}
				preventAutoplay={preventAutoplay}
				video={videoAsset}
				secondaryImg={secondaryImg}
				products={products}
			/>
			<MobileView
				mirror={mirror}
				primaryImg={primaryImgMobile}
				secondaryImg={secondaryImgMobile}
				preventAutoplay={preventAutoplay}
				video={videoAsset}
				products={products}
			/>
		</LayoutContainer>
	);
}
LegacyMediaDoubleWide.displayName = 'LegacyMediaDoubleWide';
