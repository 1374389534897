/**
 * ThumbnailList
 */

import React, { useEffect } from 'react';
import clsx from 'clsx';
import { useKeenSlider } from 'keen-slider/react';

import IconButton from 'components/IconButton';
import { Thumbnail } from 'utils/business-logic';
import { is } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

import ThumbnailItem from './ThumbnailItem';

interface Props {
	carouselIndex: number;

	/** Extra container class names */
	className?: string;

	/** Image objects */
	images: Thumbnail[];

	/** Callback for when a button is clicked */
	onImageClick: (imageId: number) => void;

	/** Callback for when the visible thumbnails change */
	onSlideChange: (index: number) => void;

	/** ID for the `images` item that should be selected */
	selectedImageId: number;
}

/** List of image thumbnails */
export default function ThumbnailList({
	carouselIndex,
	className,
	images,
	onImageClick,
	onSlideChange,
	selectedImageId,
}: Props) {
	const { t } = useI18n();
	const [sliderRef, instanceRef] = useKeenSlider({
		mode: 'snap',
		vertical: true,
		initial: 0,
		slides: {
			perView: 5,
			spacing: 8,
		},
		slideChanged(slider) {
			onSlideChange(slider.track.absToRel(slider.track.details.abs));
		},
	});

	const shouldShowArrows =
		is.arrayWithLength(instanceRef.current?.slides) &&
		instanceRef.current.slides.length > 5;

	useEffect(() => {
		if (instanceRef.current) {
			instanceRef.current?.moveToIdx(carouselIndex);
		}
	}, [carouselIndex, instanceRef]);

	return (
		<div
			className={clsx('flex w-[64px] flex-col items-center gap-y-4', className)}
		>
			{shouldShowArrows && (
				<IconButton
					size="small"
					icon="arrow"
					iconDirection="up"
					text={t('slider_prev_button')}
					onClick={() => {
						instanceRef.current?.prev();
					}}
				/>
			)}
			<div ref={sliderRef} className="keen-slider h-[352px]">
				{images?.map((image, i) => (
					<ThumbnailItem
						className="keen-slider__slide"
						key={image.src}
						src={image.src}
						isSelected={selectedImageId === image.id}
						alt={image.alt}
						onClick={() => {
							onImageClick(image.id);
						}}
						label={t('product_details_show_image_number_label', {
							num: i + 1,
						})}
					/>
				))}
			</div>
			{shouldShowArrows && (
				<IconButton
					size="small"
					icon="arrow"
					iconDirection="down"
					text={t('slider_next_button')}
					onClick={() => {
						instanceRef.current?.next();
					}}
				/>
			)}
		</div>
	);
}
ThumbnailList.displayName = 'ThumbnailList';
