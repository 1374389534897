import React from 'react';
import { Field } from '@sitecore-jss/sitecore-jss-nextjs';

import ComponentPlaceholder from 'components/ComponentPlaceholder';
import { LayoutContainer } from 'components/Layout';
import { Step, Stepper } from 'components/Stepper';
import { JulaComponentProps } from 'lib/component-props';
import { getEditorMargin } from 'utils/business-logic';

type Props = JulaComponentProps & {
	fields: {
		number: Field<string>;
	};
};

function StepIndicator({ fields, params }: Props) {
	if (!fields) {
		return null;
	}

	const { number } = fields;

	if (!number) {
		return (
			<ComponentPlaceholder
				componentName="StepIndicator"
				description="Requires a number"
				className={getEditorMargin(params)}
			/>
		);
	}

	return (
		<LayoutContainer
			id={params?.anchor}
			outerClassName={getEditorMargin(params)}
			withGrid
			withPopoverLayout
		>
			<div className="col-span-4 md:col-span-6 md:col-start-4">
				<Stepper>
					<Step number={Number.parseInt(number.value, 10)} status="active" />
				</Stepper>
			</div>
		</LayoutContainer>
	);
}
StepIndicator.displayName = 'StepIndicator';

export default StepIndicator;
