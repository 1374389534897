import { type ActorRef, Machine, type State } from 'xstate';

interface ActionButtonStateType {
	states: {
		idle: Record<string, unknown>;
		loading: Record<string, unknown>;
		success: Record<string, unknown>;
		failure: Record<string, unknown>;
	};
}

export type ActionButtonState = keyof ActionButtonStateType['states'];

type ActionButtonMachineContext = { showSuccess: boolean };

type ActionButtonMachineEvents =
	| { type: 'LOAD' }
	| { type: 'RESOLVE' }
	| { type: 'REJECT' }
	| { type: 'RETRY' };

export type ActionButtonMachineState = State<
	ActionButtonMachineContext,
	ActionButtonMachineEvents
>;
export type ActionButtonMachineActor = ActorRef<
	ActionButtonMachineEvents,
	ActionButtonMachineState
>;

export const selectCurrentActionButtonState = (
	state: ActionButtonMachineState,
) => state.value as ActionButtonState;

export const actionButtonMachine = Machine<
	ActionButtonMachineContext,
	ActionButtonStateType,
	ActionButtonMachineEvents
>(
	{
		id: 'loaderButton',
		initial: 'idle',
		context: {
			showSuccess: true,
		},
		states: {
			idle: {
				on: {
					LOAD: 'loading',
				},
			},
			loading: {
				on: {
					RESOLVE: [
						{
							target: 'success',
							cond: 'shouldShowSuccess',
						},
						{ target: 'idle' },
					],
					REJECT: 'failure',
				},
			},
			success: {
				after: {
					2500: 'idle',
				},
			},
			failure: {
				on: {
					RETRY: {
						target: 'loading',
					},
				},
				after: {
					3500: 'idle',
				},
			},
		},
	},
	{
		guards: {
			shouldShowSuccess: (context) => context.showSuccess,
		},
	},
);
