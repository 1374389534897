import React from 'react';

import PageFooterComponent from 'components/PageFooter';
import { withRequiredProps } from 'hoc';
import type { JulaComponentProps } from 'lib/component-props';
import type { DigizuiteAsset } from 'models/asset';
import type { IconLinkField } from 'models/link';
import { Column, FooterLinkField, ImageColumn } from 'models/pageFooter';

type Props = JulaComponentProps & {
	fields: {
		columns: Column[];
		imageColumns: ImageColumn[];
		secondaryLinks: FooterLinkField[];
		socialMedia: IconLinkField[];
		svgImages: {
			value: {
				assets: DigizuiteAsset[];
			};
		};
		text?: {
			value: string;
		};
		triggerbeeCampaignId?: {
			value: string;
		};
	};
};

function PageFooter({ fields }: Props) {
	const triggerbeeId = fields.triggerbeeCampaignId?.value;

	return (
		<>
			{triggerbeeId && <div id={triggerbeeId} />}
			<PageFooterComponent
				menuColumns={fields.columns}
				imageColumns={fields.imageColumns}
				infoText={fields.text?.value}
				secondaryLinks={fields.secondaryLinks}
				socialMediaLinks={fields.socialMedia}
			/>
		</>
	);
}
PageFooter.displayName = 'PageFooter_JSS';

export default withRequiredProps(PageFooter, 'fields');
