import React from 'react';
import type { Field } from '@sitecore-jss/sitecore-jss-nextjs';

import ComponentPlaceholder from 'components/ComponentPlaceholder';
import Img from 'components/Img';
import InfoBox from 'components/InfoBox';
import { LayoutContainer } from 'components/Layout';
import Link from 'components/Link';
import Text from 'components/Text';
import type { JulaComponentProps } from 'lib/component-props';
import { DigizuiteAssetList } from 'models/asset';
import { SitecoreLink } from 'models/link';
import { getEditorMargin } from 'utils/business-logic';
import { getAsset } from 'utils/helpers';

type Props = JulaComponentProps & {
	fields?: {
		assetIcon: {
			value: DigizuiteAssetList;
		};
		bottomImage: {
			value: DigizuiteAssetList;
		};
		error: Field<boolean>;
		heading: Field<string>;
		libraryIcon: Field<string>;
		link: Field<SitecoreLink>;
		text: Field<string>;
	};
};

export default function InformationNotice({
	isInNestedPlaceholder,
	params,
	fields,
}: Props) {
	if (!fields) {
		return null;
	}
	const icon =
		fields.libraryIcon.value ||
		getAsset('Source Copy', fields.assetIcon.value)?.src;

	if (!fields.text || !icon) {
		return (
			<ComponentPlaceholder
				className={getEditorMargin(params)}
				componentName="InformationNotice"
				description="The component requires an icon and a text"
			/>
		);
	}
	const img = getAsset('Source Copy', fields.bottomImage.value);
	const link = fields.link?.value.href;
	const linkText = fields.link?.value.text;
	return (
		<LayoutContainer
			withPopoverLayout
			gutters={!isInNestedPlaceholder}
			maxWidth={isInNestedPlaceholder ? 'none' : undefined}
			outerClassName={getEditorMargin(params)}
		>
			<InfoBox
				className="max-w-[33rem]"
				icon={icon}
				heading={fields.heading.value}
				variant={fields.error.value ? 'error' : 'information'}
			>
				<Text as="pSmall">{fields.text.value}</Text>
				{img && <Img src={img.src} className="mt-4 max-h-6" />}
				{link && linkText && (
					<Link className="mt-4 block" href={link} underline>
						{linkText}
					</Link>
				)}
			</InfoBox>
		</LayoutContainer>
	);
}
InformationNotice.displayName = 'InformationNotice';
