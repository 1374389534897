/**
 * BlockPromoTeaser
 */

import React from 'react';
import clsx from 'clsx';

interface Props {
	/** Array of teaser items */
	children: any;
}

/** Block promo teaser component */
export default function BlockPromoTeaser({ children }: Props) {
	// TODO: Replace with layoutlogic and remove from dumb (this) component.
	const noElements =
		children?.props?.rendering?.placeholders['jula-teaser']?.length;

	return (
		<div
			className={clsx(
				'flex h-full w-full justify-center max-sm:flex-col sm:mx-auto',
				noElements <= 2 ? 'sm:w-3/4 md:w-2/4' : 'md:w-2/3',
			)}
		>
			{children}
		</div>
	);
}
BlockPromoTeaser.displayName = 'BlockPromoTeaser';
