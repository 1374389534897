/**
 * BlockShortcutInspiration
 */

import React from 'react';

import { MicroContentMedia } from 'components/MicroContent';

import ImagesGrid from './ImagesGrid';
import type { ItemProps } from './types';

interface Props {
	className?: string;
	items: ItemProps[];
}

/** Linked images with an arrow icon and text below. */
export default function BlockShortcutInspiration({ className, items }: Props) {
	return (
		<ImagesGrid className={className} size="large">
			{items.map((item) => (
				<MicroContentMedia
					{...item}
					key={item.key || item.href}
					className="col-span-1"
					imgWidth={393}
					imgHeight={197}
				/>
			))}
		</ImagesGrid>
	);
}
BlockShortcutInspiration.displayName = 'BlockShortcutInspiration';
