import React from 'react';
import {
	Placeholder,
	useSitecoreContext,
} from '@sitecore-jss/sitecore-jss-nextjs';

import { InspirationLandscapeOld as BlockInspirationLandscape } from 'components/BlockFocusGrid';
import ComponentPlaceholder from 'components/ComponentPlaceholder';
import Icon from 'components/Icon';
import { LayoutContainer } from 'components/Layout';
import Link from 'components/Link';
import { useIsSmallOrLarger } from 'hooks';
import { JulaComponentProps } from 'lib/component-props';
import { DigizuiteAssetList } from 'models/asset';
import { SitecoreLink } from 'models/link';
import { getEditorMargin } from 'utils/business-logic';
import { getAsset } from 'utils/helpers';

type InspirationLandscapeOldProps = JulaComponentProps & {
	fields: {
		description: {
			editable?: string;
			value: string;
		};
		heading: {
			editable?: string;
			value: string;
		};
		landscapeImage: DigizuiteAssetList;
		link: { value: SitecoreLink };
		links: SitecoreLink[];
		mirror: {
			value: boolean;
		};
	};
};

const InspirationLandscapeOld: React.FC<InspirationLandscapeOldProps> = ({
	params,
	fields,
	rendering,
}): JSX.Element | null => {
	const { sitecoreContext }: { sitecoreContext: any } = useSitecoreContext();
	const isEditing = sitecoreContext?.pageEditing;

	if (!fields) {
		return null;
	}
	const { landscapeImage, heading, links, link, description, mirror } = fields;
	const mainLink = links?.[0]?.href || undefined;
	const image = getAsset('Templated 2:1', landscapeImage, {
		width: 822,
		height: 411,
	});
	const direction = mirror?.value ? 'left' : 'right';

	if (!image && !isEditing) return null;

	if (isEditing) {
		return (
			<ComponentPlaceholder
				componentName="InspirationLandscape"
				className={getEditorMargin(params)}
				isDeprecated
			/>
		);
	}

	const isSmallOrLarger = useIsSmallOrLarger();

	return (
		<LayoutContainer
			id={params?.anchor}
			outerClassName={getEditorMargin(params)}
			gutters={isSmallOrLarger}
		>
			<BlockInspirationLandscape
				imageAlt={image?.alt || ''}
				imageSrc={image?.src || ''}
				imageWidth={image?.width || 0}
				imageHeight={image?.height || 0}
				mainLink={mainLink}
				direction={direction}
				heading={heading?.value}
				preamble={description?.value}
				link={
					link?.value.text &&
					link?.value.href && (
						<Link
							href={link?.value.href}
							target={link?.value.target}
							rel={
								link?.value.linktype === 'external' ? 'noreferrer noopener' : ''
							}
							className="no-underline hover:cursor-pointer hover:underline "
						>
							<Icon
								aria-hidden
								icon="arrow"
								color="white"
								className="mr-2 rounded-full bg-julaRed"
							/>
							<span className="font-standard text-base text-greyDarker">
								{link?.value.text}
							</span>
						</Link>
					)
				}
				links={links?.map((link, i) =>
					link?.text && link?.href ? (
						<div className="mb-4 inline-flex items-center last:mb-0" key={i}>
							<Link
								href={link?.href}
								className="no-underline hover:cursor-pointer hover:underline "
							>
								<Icon
									aria-hidden
									icon="arrow"
									color="white"
									className="mr-2 rounded-full bg-julaRed"
								/>
								<span className="font-standard text-base text-greyDarker">
									{link?.text}
								</span>
							</Link>
						</div>
					) : null,
				)}
			>
				<Placeholder name="jula-layout-landscape" rendering={rendering} />
			</BlockInspirationLandscape>
		</LayoutContainer>
	);
};
InspirationLandscapeOld.displayName = 'InspirationLandscape';

export default InspirationLandscapeOld;
