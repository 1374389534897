/**
 * ActionLink
 */

import React from 'react';

import Button, { type ButtonVariant } from 'components/Button';
import Icon from 'components/Icon';
import { useGlobalStateContext } from 'contexts';
import type { ActionLink as ActionLinkModel } from 'models/link';
import { is, sendGlobalEvent } from 'utils/helpers';

function openOneTrustSettings() {
	if (globalThis.OneTrust !== undefined) {
		globalThis.OneTrust.ToggleInfoDisplay();
	}
}
function OpenChat() {
	// @ts-expect-error
	document?.querySelector('.vngage-tab.vngage-focusable')?.click();
}

interface Props extends ActionLinkModel {
	/** Button variant for the types that result in buttons. */
	buttonVariant?: ButtonVariant;

	/** Additional class names for the button/link. */
	className?: string;
}

export default function ActionLink({
	buttonVariant = 'secondary',
	className,
	href,
	target,
	icon,
	text,
	type,
}: Props) {
	const { globalPopoverService } = useGlobalStateContext();

	if (!type || !text) {
		return null;
	}

	const children = (
		<>
			{icon && <Icon icon={icon} />}
			{text}
		</>
	);

	// TypeScript doesn't like href + target when both are potentially undefined,
	// be explicit about the link case.
	if (is.oneOf(type, 'Phone', 'Link') && href) {
		return (
			<Button
				className={className}
				href={href}
				target={target}
				underline={false}
				variant={buttonVariant}
			>
				{children}
			</Button>
		);
	}

	return (
		<Button
			className={className}
			underline={false}
			variant={buttonVariant}
			onClick={() => {
				if (type === 'Popover' && href) {
					globalPopoverService.send({
						type: 'OPEN',
						target: href,
						heading: text,
					});
				}
				if (type === 'Cookies') {
					openOneTrustSettings();
				}
				if (type === 'Chat') {
					OpenChat();
				}
				if (is.oneOf(type, 'LoginJulaClub', 'LoginJulaPro')) {
					sendGlobalEvent('open-login', { loginType: type });
				}
			}}
		>
			{children}
		</Button>
	);
}
ActionLink.displayName = 'ActionLink';
