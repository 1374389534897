/**
 * ClubProUserForm
 */

import React from 'react';
import { Form } from 'react-final-form';
import { useSelector } from '@xstate/react';
import { waitFor } from 'xstate/lib/waitFor';

import {
	Checkbox,
	FieldOnChange,
	FormOnChange,
	TextInput,
} from 'components/FinalForm';
import GdprAccordion from 'components/GdprAccordion';
import InfoBox from 'components/InfoBox';
import Text from 'components/Text';
import {
	useCheckoutContext,
	useFeatureToggle,
	useGlobalStateContext,
} from 'contexts';
import type { UnregisteredCompanyInfo } from 'models/api';
import { selectCustomerType } from 'state-machines/authentication';
import {
	selectErrorList,
	selectTelephoneNumberValidationError,
	selectUnregisteredCompanyNameValidationError,
	selectUnregisteredCompanyVatNumberValidationError,
	selectUserInfoActor,
} from 'state-machines/checkout/';
import {
	selectAlternativeDeliveryAddress,
	selectCompanyIdentificationNumber,
	selectCustomerAddress,
	selectEmail,
	selectIsAlternativeDeliveryAddressSelectable,
	selectTelephoneNumber,
	selectUnregisteredCompanyInfo,
} from 'state-machines/checkout/userInfo';
import { setFieldData } from 'utils/formHelpers';
import { useI18n } from 'utils/i18n';

import ContactReferences from './ContactReferences';

export const UNREGISTERED_COMPANY_INFO_FORM_ID = 'unregisteredCompanyInfo';
export const TELEPHONE_NUMBER_FORM_ID = 'telephoneNrInput';

interface FormValues {
	unregisteredCompanyInfo: UnregisteredCompanyInfo;
}

/** Form to edit club and pro user info. */
export default function ClubProUserForm() {
	const { t } = useI18n();
	const { userService } = useGlobalStateContext();
	const { checkoutService } = useCheckoutContext();
	const userInfoActor = useSelector(checkoutService, selectUserInfoActor);
	const customerType = useSelector(userService, selectCustomerType);
	const { send } = userInfoActor;
	const email = useSelector(userInfoActor, selectEmail);
	const telephoneNumber = useSelector(userInfoActor, selectTelephoneNumber);
	const telephoneNumberValue = useSelector(
		userInfoActor,
		selectTelephoneNumber,
	);
	const { cartAllowUnregisteredCompanyFields } = useFeatureToggle();

	const unregisteredCompanyInfo = useSelector(
		userInfoActor,
		selectUnregisteredCompanyInfo,
	);
	const customerAddress = useSelector(userInfoActor, selectCustomerAddress);
	const companyIdentificationNumber = useSelector(
		userInfoActor,
		selectCompanyIdentificationNumber,
	);
	const telephoneNumberValidationError = useSelector(
		checkoutService,
		selectTelephoneNumberValidationError,
	);
	const unregisteredCompanyNameValidationError = useSelector(
		checkoutService,
		selectUnregisteredCompanyNameValidationError,
	);
	const unregisteredCompanyVatNumberValidationError = useSelector(
		checkoutService,
		selectUnregisteredCompanyVatNumberValidationError,
	);
	const isAlternativeDeliveryAddressSelectable = useSelector(
		userInfoActor,
		selectIsAlternativeDeliveryAddressSelectable,
	);
	const alternativeDeliveryAddress = useSelector(
		userInfoActor,
		selectAlternativeDeliveryAddress,
	);
	const errorList = useSelector(checkoutService, selectErrorList);
	const alternativeDeliveryAddressNotSelectableInfoMessage = errorList?.find(
		(error) => error.type === 'AlternativeDeliveryAddressNotSelectable',
	)?.description;
	const showUnregisteredCompanyForm =
		cartAllowUnregisteredCompanyFields && customerType !== 'Pro';

	const validateField = (value, field, options?) => {
		let errorMsg;
		let shouldShowValidationError = false;

		// Value must be null not undefined else the shouldShowValidationError check won't work
		if (value === undefined) {
			if (options?.required) {
				return t('FieldIsRequired');
			}
			value = null;
		}

		switch (field.name) {
			case 'telephoneNumber':
				errorMsg = telephoneNumberValidationError?.map(
					(error) => error.description,
				);
				shouldShowValidationError = telephoneNumberValue === value;
				break;

			case 'unregisteredCompanyInfo.companyName':
				errorMsg = unregisteredCompanyNameValidationError?.map(
					(error) => error.description,
				);
				shouldShowValidationError =
					unregisteredCompanyInfo?.companyName === value;
				break;

			case 'unregisteredCompanyInfo.vatRegistrationNumber':
				errorMsg = unregisteredCompanyVatNumberValidationError?.map(
					(error) => error.description,
				);
				shouldShowValidationError =
					unregisteredCompanyInfo?.vatRegistrationNumber === value;
				break;

			default:
			// No default
		}

		if (shouldShowValidationError && errorMsg && errorMsg.length > 0) {
			return errorMsg;
		}
		return null;
	};

	// This form works by auto saving the users input data
	// to make this work several parts are involved
	// When the form initializes the initial values are with initialValues in the finalForm Form component
	// This uses data coming from the api going to the checkout machine passed on to the userInfo machine
	// Since the data is set in initialValues the data from the api is only set in the form in init
	// to make the auto save work the <FieldOnChange> component is first in line, this reacts to changes on a single field
	// and makes sure only actual value changes are sent
	// when a value changes the data via a related event to the userInfoMachine
	// based on the event the userInfoMachine might debounce the update based on the event
	// if debounced the machine saves data to it's internal context and transitions out of the debounce state
	// resetting the debounce timer, until the timer runs out, at which point it passes on the data to the checkout machine
	// the checkout machine uses the update cart method to request an update, here we have actions and variables to make sure no data is lost
	// when the update is done and the api has replied we pass the data back to userInfo machine so that it can be updated
	// with the latest changes
	// while this has happened the <FieldOnChange> has been waiting for the userInfo machine to return to idle
	// the reason we do this is to trigger a form Update and revalidation
	// and to only do this when the field error selectors have the latest possible errors supplied by the api
	const headingTKey =
		customerType === 'Pro'
			? 'checkout_jula_pro_member_details_heading'
			: 'checkout_jula_club_member_details_heading';
	return (
		<>
			<Text as="h2" className="mb-6">
				{t(headingTKey)}
			</Text>
			{showUnregisteredCompanyForm && (
				<Form
					onSubmit={() => {}}
					mutators={{ setFieldData }}
					initialValues={{ unregisteredCompanyInfo }}
					initialValuesEqual={() => true}
					render={({ form, handleSubmit }) => (
						<form
							onSubmit={handleSubmit}
							id={UNREGISTERED_COMPANY_INFO_FORM_ID}
							className="mb-6"
						>
							<FormOnChange<FormValues>>
								{async (values) => {
									send({
										type: 'UPDATE_CLUB_USER_UNREGISTERED_COMPANY_INFO',
										...values,
									});
									await waitFor(
										userInfoActor,
										(state) => state.matches('idle'),
										{
											timeout: 120_000,
										},
									);
									form.mutators.setFieldData?.();
								}}
							</FormOnChange>
							<TextInput
								id="unregisteredCompanyInfo.companyName"
								name="unregisteredCompanyInfo.companyName"
								type="text"
								className="mb-4"
								label={t('checkout_unreg_company_name_input_label')}
								validate={(value, _values, field) =>
									validateField(value, field, {
										required: false,
									})
								}
							/>
							<TextInput
								id="unregisteredCompanyInfo.vatRegistrationNumber"
								name="unregisteredCompanyInfo.vatRegistrationNumber"
								type="text"
								label={t('checkout_unreg_company_vat_nr_input_label')}
								validate={(value, _values, field) =>
									validateField(value, field, {
										required: false,
									})
								}
							/>
						</form>
					)}
				/>
			)}

			<div className="mb-6">
				{customerType === 'Pro' && (
					<>
						<Text as="p" text={customerAddress?.companyName} />
						<Text
							as="p"
							text={`${t(
								'julapro_organization_number',
							)}: ${companyIdentificationNumber}`}
						/>
					</>
				)}
				{customerType === 'Club' && (
					<Text
						as="p"
						text={`${customerAddress?.firstName} ${customerAddress?.lastName}`}
					/>
				)}
				<Text as="p">{customerAddress?.address}</Text>
				<Text as="p">
					{`${customerAddress?.postalCode}, ${customerAddress?.city}`}
				</Text>
				<Text as="p">{email}</Text>
				<div className="mt-4">
					<InfoBox
						icon="info"
						variant="information"
						message={t(
							customerType === 'Club'
								? 'checkout_club_userinfo_not_editable_description'
								: 'checkout_pro_userinfo_not_editable_description',
						)}
					/>
				</div>
			</div>
			<GdprAccordion className="mb-6" />
			<Form
				onSubmit={() => {}}
				mutators={{ setFieldData }}
				initialValues={{
					telephoneNumber,
					alternativeDeliveryAddress,
				}}
				initialValuesEqual={() => true}
				render={({ form, handleSubmit }) => (
					<form
						onSubmit={handleSubmit}
						id={TELEPHONE_NUMBER_FORM_ID}
						className="mb-8"
					>
						<TextInput
							id="telephoneNumber"
							name="telephoneNumber"
							helpText={t('checkout_mobile_phone_number_usage_info_text')}
							label={t('checkout_mobile_phone_nr_input_label')}
							validate={(value, _values, field) =>
								validateField(value, field, {
									required: true,
								})
							}
						/>
						<FieldOnChange<string> name="telephoneNumber">
							{async (value) => {
								send({
									type: 'UPDATE_USER_INFO_PHONE',
									value: { telephoneNumber: value },
								});
								await waitFor(userInfoActor, (state) => state.matches('idle'), {
									timeout: 120_000,
								});
								form.mutators.setFieldData?.();
							}}
						</FieldOnChange>

						<ContactReferences />

						<div className="mb-4 mt-8">
							<Checkbox
								name="alternativeDeliveryAddress"
								disabled={!isAlternativeDeliveryAddressSelectable}
								id="alternativeDeliveryAddress"
								label={t('checkout_send_to_alt_address_checkbox_button')}
							/>
							<FieldOnChange<boolean> name="alternativeDeliveryAddress">
								{async (value) => {
									send({
										type: 'UPDATE_USER_INFO_USE_ALT_ADDRESS',
										value: { useAlternativeDeliveryAddress: value },
									});
									await waitFor(
										userInfoActor,
										(state) => state.matches('idle'),
										{
											timeout: 120_000,
										},
									);
									form.mutators.setFieldData?.();
								}}
							</FieldOnChange>
						</div>
						{alternativeDeliveryAddressNotSelectableInfoMessage && (
							<InfoBox
								variant="information"
								icon="info"
								message={alternativeDeliveryAddressNotSelectableInfoMessage}
							/>
						)}
					</form>
				)}
			/>
		</>
	);
}
ClubProUserForm.displayName = 'ClubProUserForm';
