/**
 * AnonUserForm
 */

import React, { useState } from 'react';
import { Form } from 'react-final-form';
import { useSelector } from '@xstate/react';
import { waitFor } from 'xstate/lib/waitFor';

import Button from 'components/Button';
import { Checkbox, FormOnChange, TextInput } from 'components/FinalForm';
import GdprAccordion from 'components/GdprAccordion';
import InfoBox from 'components/InfoBox';
import Text from 'components/Text';
import { useCheckoutContext, useFeatureToggle } from 'contexts';
import type {
	ApiJulaModelsCartCartDeliveryAddress,
	UnregisteredCompanyInfo,
} from 'models/api';
import {
	selectCustomerAddressCityValidationError,
	selectCustomerAddressCoValidationError,
	selectCustomerAddressFirstNameValidationError,
	selectCustomerAddressLastNameValidationError,
	selectCustomerAddressPostalCodeValidationError,
	selectCustomerAddressValidationError,
	selectEmailValidationError,
	selectErrorList,
	selectTelephoneNumberValidationError,
	selectUnregisteredCompanyNameValidationError,
	selectUnregisteredCompanyVatNumberValidationError,
	selectUserInfoActor,
} from 'state-machines/checkout/';
import {
	selectAlternativeDeliveryAddress,
	selectCustomerAddress,
	selectEmail,
	selectIsAlternativeDeliveryAddressSelectable,
	selectTelephoneNumber,
	selectUnregisteredCompanyInfo,
} from 'state-machines/checkout/userInfo';
import { setFieldData } from 'utils/formHelpers';
import { useI18n } from 'utils/i18n';

import { updateAddress } from './helpers';

export const ANON_USER_FORM_ID = 'anonUserForm';
const FORM_NAME = 'customerAddress';

interface FormValues {
	[FORM_NAME]: ApiJulaModelsCartCartDeliveryAddress;
	email: string;
	telephoneNumber: string;
	unregisteredCompanyInfo: UnregisteredCompanyInfo;
	useAlternativeDeliveryAddress: boolean;
}

/** Form to edit user customer address info. */
export default function AnonUserForm() {
	const { t } = useI18n();
	const { checkoutService } = useCheckoutContext();
	const userInfoActor = useSelector(checkoutService, selectUserInfoActor);
	const { send } = userInfoActor;
	const customerAddress = useSelector(userInfoActor, selectCustomerAddress);
	const {
		cartAllowUnregisteredCompanyFields,
		cartAllowAltDeliveryAddressForAnonymousUsers,
	} = useFeatureToggle();

	const [open, setOpen] = useState<boolean>(false);
	const email = useSelector(userInfoActor, selectEmail);
	const telephoneNumber = useSelector(userInfoActor, selectTelephoneNumber);
	const customerAddressFirstNameValidationError = useSelector(
		checkoutService,
		selectCustomerAddressFirstNameValidationError,
	);
	const customerAddressLastNameValidationError = useSelector(
		checkoutService,
		selectCustomerAddressLastNameValidationError,
	);
	const telephoneNumberValidationError = useSelector(
		checkoutService,
		selectTelephoneNumberValidationError,
	);
	const emailValidationError = useSelector(
		checkoutService,
		selectEmailValidationError,
	);
	const customerAddressValidationError = useSelector(
		checkoutService,
		selectCustomerAddressValidationError,
	);

	const customerAddressCoValidationError = useSelector(
		checkoutService,
		selectCustomerAddressCoValidationError,
	);

	const customerAddressPostalCodeValidationError = useSelector(
		checkoutService,
		selectCustomerAddressPostalCodeValidationError,
	);

	const customerAddressCityValidationError = useSelector(
		checkoutService,
		selectCustomerAddressCityValidationError,
	);
	const isAlternativeDeliveryAddressSelectable = useSelector(
		userInfoActor,
		selectIsAlternativeDeliveryAddressSelectable,
	);
	const alternativeDeliveryAddress = useSelector(
		userInfoActor,
		selectAlternativeDeliveryAddress,
	);
	const unregisteredCompanyInfo = useSelector(
		userInfoActor,
		selectUnregisteredCompanyInfo,
	);
	const unregisteredCompanyNameValidationError = useSelector(
		checkoutService,
		selectUnregisteredCompanyNameValidationError,
	);
	const unregisteredCompanyVatNumberValidationError = useSelector(
		checkoutService,
		selectUnregisteredCompanyVatNumberValidationError,
	);
	const errorList = useSelector(checkoutService, selectErrorList);
	const alternativeDeliveryAddressNotSelectableInfoMessage = errorList?.find(
		(error) => error.type === 'AlternativeDeliveryAddressNotSelectable',
	)?.description;
	const validateField = (value, field, options?) => {
		let errorMsg;
		let shouldShowValidationError = false;

		// Value must be null not undefined else the shouldShowValidationError check won't work
		if (value === undefined || value === null) {
			if (options?.required) {
				return t('FieldIsRequired');
			}
			value = null;
		}
		switch (field.name) {
			case `${FORM_NAME}.firstName`:
				errorMsg = customerAddressFirstNameValidationError?.map(
					(error) => error.description,
				);
				shouldShowValidationError = customerAddress?.firstName === value;
				break;

			case `${FORM_NAME}.lastName`:
				errorMsg = customerAddressLastNameValidationError?.map(
					(error) => error.description,
				);
				shouldShowValidationError = customerAddress?.lastName === value;
				break;

			case `${FORM_NAME}.coAddress`:
				errorMsg = customerAddressCoValidationError?.map(
					(error) => error.description,
				);
				shouldShowValidationError = customerAddress?.coAddress === value;
				break;

			case `${FORM_NAME}.address`:
				errorMsg = customerAddressValidationError?.map(
					(error) => error.description,
				);
				shouldShowValidationError = customerAddress?.address === value;
				break;

			case `${FORM_NAME}.postalCode`:
				errorMsg = customerAddressPostalCodeValidationError?.map(
					(error) => error.description,
				);
				shouldShowValidationError = customerAddress?.postalCode === value;
				break;

			case `${FORM_NAME}.city`:
				errorMsg = customerAddressCityValidationError?.map(
					(error) => error.description,
				);
				shouldShowValidationError = customerAddress?.city === value;
				break;

			case 'email':
				errorMsg = emailValidationError?.map((error) => error.description);
				shouldShowValidationError = email === value;
				break;

			case 'telephoneNumber':
				errorMsg = telephoneNumberValidationError?.map(
					(error) => error.description,
				);
				shouldShowValidationError = telephoneNumber === value;
				break;

			case 'unregisteredCompanyInfo.companyName':
				errorMsg = unregisteredCompanyNameValidationError?.map(
					(error) => error.description,
				);
				shouldShowValidationError =
					unregisteredCompanyInfo?.companyName === value;
				break;

			case 'unregisteredCompanyInfo.vatRegistrationNumber':
				errorMsg = unregisteredCompanyVatNumberValidationError?.map(
					(error) => error.description,
				);
				shouldShowValidationError =
					unregisteredCompanyInfo?.vatRegistrationNumber === value;
				break;

			default:
			// No default
		}

		if (shouldShowValidationError && errorMsg && errorMsg.length > 0) {
			return errorMsg;
		}
		return undefined;
	};

	// This form works by auto saving the users input data
	// to make this work several parts are involved
	// When the form initializes the initial values are with initialValues in the finalForm Form component
	// This uses data coming from the api going to the checkout machine passed on to the userInfo machine
	// Since the data is set in initialValues the data from the api is only set in the form in init
	// to make the auto save work the <FormOnChange> component is first in line, this reacts to changes on a single field
	// and makes sure only actual value changes are sent
	// when a value changes the data via a related event to the userInfoMachine
	// based on the event the userInfoMachine might debounce the update based on the event
	// if debounced the machine saves data to it's internal context and transitions out of the debounce state
	// resetting the debounce timer, until the timer runs out, at which point it passes on the data to the checkout machine
	// the checkout machine uses the update cart method to request an update, here we have actions and variables to make sure no data is lost
	// when the update is done and the api has replied we pass the data back to userInfo machine so that it can be updated
	// with the latest changes
	// while this has happened the <FormOnChange> has been waiting for the userInfo machine to return to idle
	// the reason we do this is to trigger a form Update and revalidation
	// and to only do this when the field error selectors have the latest possible errors supplied by the api
	return (
		<>
			<Text as="h2">{t('checkout_guest_details_form_heading')}</Text>
			<GdprAccordion className="mb-6 mt-2" />

			<Form
				onSubmit={() => {}}
				mutators={{
					setFieldData,
				}}
				initialValues={{
					customerAddress,
					email,
					telephoneNumber,
					useAlternativeDeliveryAddress: alternativeDeliveryAddress,
					unregisteredCompanyInfo,
				}}
				initialValuesEqual={() => true}
				render={(renderProps) => {
					const {
						form: { change, batch, mutators },
						handleSubmit,
					} = renderProps;
					return (
						<form id={ANON_USER_FORM_ID} onSubmit={handleSubmit}>
							<FormOnChange<FormValues>>
								{async (values) => {
									send({
										type: 'UPDATE_ANONYMOUS_USER_CUSTOMER_INFO',
										...values,
									});

									const doneData = await waitFor(
										userInfoActor,
										(state) => state.matches('idle'),
										{
											timeout: 120_000,
										},
									);
									updateAddress({
										formName: FORM_NAME,
										cartLatestAddress: doneData.context.customerAddress,
										batch,
										change,
									});

									mutators.setFieldData?.();
								}}
							</FormOnChange>

							{cartAllowUnregisteredCompanyFields && (
								<>
									<TextInput
										id="unregisteredCompanyInfo.companyName"
										name="unregisteredCompanyInfo.companyName"
										type="text"
										className="mb-4"
										label={t('checkout_unreg_company_name_input_label')}
										validate={(value, _values, field) =>
											validateField(value, field, {
												required: false,
											})
										}
									/>
									<TextInput
										id="unregisteredCompanyInfo.vatRegistrationNumber"
										name="unregisteredCompanyInfo.vatRegistrationNumber"
										type="text"
										className="mb-4"
										label={t('checkout_unreg_company_vat_nr_input_label')}
										validate={(value, _values, field) =>
											validateField(value, field, {
												required: false,
											})
										}
									/>
								</>
							)}

							<TextInput
								id="firstName"
								name={`${FORM_NAME}.firstName`}
								type="text"
								className="mb-4"
								label={t('checkout_first_name_input_label')}
								validate={(value, _values, field) =>
									validateField(value, field, {
										required: true,
									})
								}
							/>
							<TextInput
								id="lastName"
								name={`${FORM_NAME}.lastName`}
								type="text"
								className="mb-4"
								label={t('checkout_last_name_input_label')}
								validate={(value, _values, field) =>
									validateField(value, field, {
										required: true,
									})
								}
							/>

							<div className="relative">
								<TextInput
									id="address"
									className="mb-4"
									inputClassName="pr-24"
									name={`${FORM_NAME}.address`}
									label={t('checkout_address_input_label')}
									validate={(value, _values, field) =>
										validateField(value, field, {
											required: true,
										})
									}
								/>
								<Button
									variant="text"
									size="small"
									onClick={() => setOpen(!open)}
									className="absolute right-0 top-0 h-14 pr-4"
								>
									{t('checkout_add_co_address_button')}
								</Button>
							</div>
							{open && (
								<TextInput
									id="coAddress"
									className="mb-4"
									name={`${FORM_NAME}.coAddress`}
									label={t('checkout_co_address_input_label')}
									validate={(value, _values, field) =>
										validateField(value, field)
									}
								/>
							)}
							<TextInput
								id="postalCode"
								className="mb-4"
								name={`${FORM_NAME}.postalCode`}
								label={t('checkout_postal_code_input_label')}
								validate={(value, _values, field) =>
									validateField(value, field, {
										required: true,
									})
								}
							/>

							<TextInput
								id="city"
								className="mb-4"
								name={`${FORM_NAME}.city`}
								label={t('checkout_city_input_label')}
								validate={(value, _values, field) =>
									validateField(value, field, {
										required: true,
									})
								}
							/>
							<div>
								<TextInput
									type="email"
									id="email"
									className="mb-4"
									label={t('checkout_email_input_label')}
									name="email"
									validate={(value, _values, field) =>
										validateField(value, field, {
											required: true,
										})
									}
								/>
							</div>
							<div className="mb-4">
								<TextInput
									id="telephoneNumber"
									name="telephoneNumber"
									className="mb-8"
									label={t('checkout_mobile_phone_nr_input_label')}
									inputMode="tel"
									validate={(value, _values, field) =>
										validateField(value, field, {
											required: true,
										})
									}
								/>
							</div>
							{cartAllowAltDeliveryAddressForAnonymousUsers && (
								<>
									<Checkbox
										name="useAlternativeDeliveryAddress"
										disabled={!isAlternativeDeliveryAddressSelectable}
										id="alternativeDeliveryAddress"
										label={t('checkout_send_to_alt_address_checkbox_button')}
									/>
									{alternativeDeliveryAddressNotSelectableInfoMessage && (
										<InfoBox
											variant="information"
											icon="info"
											message={
												alternativeDeliveryAddressNotSelectableInfoMessage
											}
										/>
									)}
								</>
							)}
						</form>
					);
				}}
			/>
		</>
	);
}
AnonUserForm.displayName = 'AnonUserForm';
