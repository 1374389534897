/**
 * BlockFocusGridInspiration
 */

import React, { type ReactNode } from 'react';
import clsx from 'clsx';

import Img from 'components/Img';
import Link from 'components/Link';
import Text from 'components/Text';
import { HeadingLevel } from 'lib/component-props';

interface Props {
	/** React children */
	children?: ReactNode;

	/** Src for small Content image */
	smallImageSrc: string;

	/** Alt-text for small Content image */
	smallImageAlt: string;

	/** Sets the direction of the inspiration square variant */
	direction: 'left' | 'right';

	/** Content heading */
	heading: string;

	headingLevel?: HeadingLevel;

	/** Content preamble */
	preamble: string;

	/** Array of incoming link objects zero/many */
	links?: ReactNode;

	/** First and main link to wrap image with */
	mainLink?: string;

	/** link that is added after the list, but together with it, can be external */
	link?: ReactNode;
}

/** Block Focus Grid - Inspiration Square. */
export default function BlockFocusGridInspiration({
	smallImageAlt,
	smallImageSrc,
	direction,
	heading,
	headingLevel = 'h2',
	preamble,
	links,
	children,
	mainLink,
	link,
}: Props) {
	const reversed = direction === 'right';

	return (
		<div className="grid grid-cols-4 gap-4 gap-y-0 sm:grid-cols-8 sm:gap-6 sm:gap-y-0 md:grid-cols-12">
			<div
				className={clsx(
					reversed
						? `order-2 col-span-4`
						: `order-1 col-span-4 sm:col-end-5 md:col-start-3 md:col-end-7`,
				)}
			>
				<Link href={mainLink || ''}>
					<Img
						src={smallImageSrc}
						alt={smallImageAlt}
						width={532}
						height={266}
						service="nextjs"
						className={clsx(
							'mb-4 md:mb-6',
							'w-full',
							'h-auto',
							'object-contain',
						)}
					/>
				</Link>
				<div className="max-sm:ml-4">
					<Text as={headingLevel} className="text-left">
						{heading || ''}
					</Text>
					<div className="mb-6 mt-2 md:mt-4">
						<Text as="p" className="mb-6 text-left">
							{preamble || ''}
						</Text>
					</div>
					{(links || link) && (
						<div className="flex flex-col justify-start md:items-start">
							{links}
							{link}
						</div>
					)}
				</div>
			</div>
			<div
				className={clsx(
					'max-sm:hidden',
					reversed
						? `order-1 col-span-4 md:col-start-3 md:col-end-7`
						: `order-2 col-span-4 md:col-start-7`,
				)}
			>
				{children}
			</div>
		</div>
	);
}
BlockFocusGridInspiration.displayName = 'BlockFocusGridInspiration';
