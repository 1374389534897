/**
 * Payment
 */

import React from 'react';
import { useSelector } from '@xstate/react';
import clsx from 'clsx';

import AdyenPayment from 'components/AdyenPayment';
import InfoBox from 'components/InfoBox';
import Text from 'components/Text';
import { useCheckoutContext } from 'contexts';
import type { PaymentType } from 'models/api';
import { selectCartIsReady, selectPaymentActor } from 'state-machines/checkout';
import {
	selectAvailableDeliveryMethods,
	selectErrors,
	selectPaymentHasAdditionalAction,
	selectPaymentResult,
	selectSelectedAdyenPaymentMethod,
	selectSelectedPaymentMethod,
	selectStateIsWaitingForVerifyPaymentEvent,
} from 'state-machines/checkout/payment';
import { useI18n } from 'utils/i18n';

import Bonus from '../Bonus';
import GiftCardSection from '../GiftCardSection';

import PaymentMethod from './PaymentMethod';

interface Props {
	bonusClassName?: string;
	giftCardClassName?: string;
	paymentMethodsClassName?: string;
	titleClassName?: string;
}

/** Component for payment methods, uses the paymentActor from the checkout machine. */
export default function Payment({
	bonusClassName,
	giftCardClassName,
	paymentMethodsClassName,
	titleClassName,
}: Props) {
	const { checkoutService } = useCheckoutContext();
	const { t } = useI18n();
	const cartIsReady = useSelector(checkoutService, selectCartIsReady);

	const paymentActor = useSelector(checkoutService, selectPaymentActor);
	const { send } = paymentActor;
	const availablePaymentMethods = useSelector(
		paymentActor,
		selectAvailableDeliveryMethods,
	);
	const selectedPaymentMethod = useSelector(
		paymentActor,
		selectSelectedPaymentMethod,
	);
	const adyenPaymentMethodInfo = useSelector(
		paymentActor,
		selectSelectedAdyenPaymentMethod,
	);
	const isWaitingForVerifyPaymentEvent = useSelector(
		paymentActor,
		selectStateIsWaitingForVerifyPaymentEvent,
	);
	const errors = useSelector(paymentActor, selectErrors);
	const paymentResult = useSelector(paymentActor, selectPaymentResult);
	const paymentHasAdditionalAction = useSelector(
		paymentActor,
		selectPaymentHasAdditionalAction,
	);

	// cartIsReady will be false when waiting for a pending payment, it should
	// possibly be renamed.
	if (!cartIsReady && !paymentHasAdditionalAction) {
		return null;
	}

	const onPaymentMethodChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const updatedPaymentMethod = {
			selectedPaymentType: e.target.value as PaymentType,
			selectedStoredPaymentMethodId: '',
		};
		send({
			type: 'SELECT_PAYMENT_METHOD',
			value: updatedPaymentMethod,
		});
	};

	const paymentResultAction = paymentResult?.action;
	const paymentNotNeededInfo = errors?.find(
		(error) => error.type === 'PaymentMethodNotNeeded',
	);

	return (
		<>
			<Text as="h2" className={clsx('mb-2', titleClassName)}>
				{t('checkout_payment_method_heading')}
			</Text>

			<GiftCardSection className={giftCardClassName} />

			<div
				className={clsx(
					paymentMethodsClassName,
					'mt-8 flex flex-col gap-2 md:mt-10 md:gap-4',
				)}
			>
				{availablePaymentMethods?.paymentMethods?.map(
					({
						images,
						paymentType,
						paymentProviderMethodId,
						name,
						description,
						longDescriptionHTML,
						links,
						isValid,
						paymentProvider,
					}) => (
						<PaymentMethod
							key={paymentType}
							paymentType={paymentType}
							paymentProvider={paymentProvider}
							name={name}
							description={description}
							longDescriptionHTML={longDescriptionHTML}
							links={links}
							isValid={isValid}
							images={images}
							onPaymentMethodChange={onPaymentMethodChange}
							paymentProviderMethodId={paymentProviderMethodId}
							isSelected={
								selectedPaymentMethod?.selectedPaymentType === paymentType
							}
							errors={errors?.filter((error) => error.key === paymentType)}
							adyenComponent={
								<>
									{paymentType === 'scheme' && (
										<AdyenPayment
											paymentType={paymentType}
											adyenPaymentMethodInfo={adyenPaymentMethodInfo}
											onChange={(state) => {
												send({ type: 'SET_PAYMENT_COMPONENT_STATE', state });
											}}
										/>
									)}
									{paymentType === 'blik' && (
										<AdyenPayment
											paymentType={paymentType}
											action={
												paymentHasAdditionalAction
													? paymentResultAction
													: undefined
											}
											onChange={(state) => {
												send({ type: 'SET_PAYMENT_COMPONENT_STATE', state });
											}}
										/>
									)}
									{paymentType === 'swish' &&
										isWaitingForVerifyPaymentEvent && (
											<AdyenPayment
												paymentType={paymentType}
												action={paymentResultAction}
												onAdditionalDetails={(state) => {
													send({
														type: 'VERIFY_REDIRECT_PAYMENT',
														paymentVerificationPayload: {
															details: {
																redirectResult: state.data.details.payload,
															},
															paymentData: state.data.paymentData,
														},
													});
												}}
											/>
										)}
									{paymentType === 'paypal' &&
										isWaitingForVerifyPaymentEvent && (
											<AdyenPayment
												paymentType={paymentType}
												action={paymentResultAction}
												onSubmit={(state, component) => {
													setTimeout(() => {
														if (paymentResultAction) {
															component.handleAction(paymentResultAction);
														}
													}, 100);
												}}
											/>
										)}
								</>
							}
						/>
					),
				)}

				{paymentNotNeededInfo && (
					<InfoBox
						icon="info"
						variant="information"
						message={paymentNotNeededInfo.description}
					/>
				)}
			</div>

			<Bonus className={bonusClassName} />
		</>
	);
}
Payment.displayName = 'Payment';
