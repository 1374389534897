import { MutableState, Mutator } from 'final-form';

import { FormFields, SelectField } from 'models/form';

export function initSelectFields(fields: FormFields | undefined) {
	const selectFields = {};
	if (!fields) {
		return undefined;
	}
	const allSelects = fields?.filter(
		(field) => field.type === 'SelectField',
	) as SelectField[];
	if (allSelects.length === 0) {
		return undefined;
	}
	allSelects.forEach((selectField) => {
		selectFields[selectField.name] = selectField.options[0]?.value;
	});
	return selectFields;
}
export const setFieldData: Mutator = function setfieldData<FormValues>(
	args: any[],
	state: MutableState<FormValues>,
) {
	const [name, data] = args;
	const field = state.fields[name];
	if (field) {
		field.data = { ...field.data, ...data };
	}
};
