/**
 * PaymentMethods
 */

import React from 'react';

import Img from 'components/Img';
import Text from 'components/Text';
import { cleanAltText } from 'utils/string';

interface Image {
	alt: string;
	src: string;
}

interface Props {
	className?: string;
	heading?: string;
	images: Image[] | undefined;
}

/** Payment method logos. */
export default function PaymentMethodLogos({
	className,
	heading,
	images,
}: Props) {
	if (!images?.length) {
		return null;
	}

	return (
		<div className={className}>
			{heading && (
				<Text as="p" className="mb-2 font-bold">
					{heading}
				</Text>
			)}
			<ul className="flex flex-wrap gap-3">
				{images.map(({ alt, src }) => (
					<li key={src}>
						<Img
							className="h-6 max-w-[5rem]"
							src={src}
							alt={cleanAltText(alt)}
						/>
					</li>
				))}
			</ul>
		</div>
	);
}
PaymentMethodLogos.displayName = 'PaymentMethods';
