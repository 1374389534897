/**
 * SelectButton
 */

import React from 'react';
import clsx from 'clsx';

import Icon from 'components/Icon';
import Text from 'components/Text';

interface Props {
	label: string;

	onClick: () => void;

	invertArrow: boolean;

	children: React.ReactNode;

	hiddenLabel?: boolean;
}

/** A button that looks like a select and has children rendered in the 'box' */
export default function SelectButton({
	label,
	hiddenLabel,
	onClick,
	invertArrow,
	children,
}: Props) {
	const id = `variant-picker-${label}`;
	return (
		<div className="relative">
			<label
				htmlFor={id}
				className={clsx(
					'absolute -top-3 left-4 z-1 text-greyDark',
					hiddenLabel && 'sr-only',
				)}
			>
				<Text as="span" className="bg-white" styleAs="pSmall">
					{label}
				</Text>
			</label>
			<button
				id={id}
				type="button"
				onClick={onClick}
				className="relative mt-2 flex h-14 w-full cursor-pointer items-center rounded-button border border-grey bg-white px-4 transition-colors hover:bg-greyLighter"
			>
				{children}
				<div className="absolute bottom-2 right-0 top-2 flex w-10 items-center justify-center border-l border-greyLight">
					<Icon
						icon="arrow"
						color="black"
						direction={invertArrow ? 'up' : 'down'}
						className="md:hidden"
					/>
					<Icon
						icon="arrow"
						color="black"
						direction={invertArrow ? 'left' : 'right'}
						className="hidden md:block"
					/>
				</div>
			</button>
		</div>
	);
}
SelectButton.displayName = 'SelectButton';
