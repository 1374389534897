/**
 * ImageLayover
 */

import React, { useCallback, useEffect, useRef } from 'react';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import clsx from 'clsx';

import Icon from 'components/Icon';
import Img from 'components/Img';
import ThumbnailList from 'components/ThumbnailList';
import { useDialog } from 'hooks';
import { Thumbnail } from 'utils/business-logic';
import { afterNextPaint } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

interface Props {
	carouselIndex: number;

	/** Optional image service to use */
	imageService?: 'nextjs';

	/** An array of images to show when open */
	images: Thumbnail[];

	/** Whether the layover is open or not */
	isOpen: boolean;

	/** Function to call when the layover should be closed */
	onClose: () => void;

	onThumbnailClick: (activeImageId: number) => void;

	onThumbnailSlideChange: (index: number) => void;

	/** ID of the image that should be selected and scrolled to */
	selectedImageId: number;
}

/** A component that displays images in a layover. */
export default function ImageLayover({
	carouselIndex,
	images,
	imageService,
	isOpen,
	onClose: onCloseProp,
	onThumbnailClick,
	onThumbnailSlideChange,
	selectedImageId,
}: Props) {
	const { t } = useI18n();
	const scrollRef = useRef<HTMLDivElement>(null);
	const largeImages = useRef<(HTMLDivElement | null)[]>([]);

	const scrollToImage = useCallback(
		(imageId: number) => {
			const img = largeImages.current[imageId];
			if (img && isOpen) {
				afterNextPaint(() => {
					img.scrollIntoView({
						behavior: 'smooth',
						block: 'start',
					});
				});
				setTimeout(() => {
					img.focus();
				}, 250);
			}
		},
		[isOpen],
	);

	const onOpen = useCallback(() => {
		if (scrollRef.current) {
			disableBodyScroll(scrollRef.current, { reserveScrollBarGap: true });
		}
		scrollToImage(selectedImageId);
	}, [scrollToImage, selectedImageId]);

	const onClose = useCallback(() => {
		onCloseProp();
		clearAllBodyScrollLocks();
	}, [onCloseProp]);

	const { focusTrapEndProps, focusTrapStartProps, dialogProps } = useDialog({
		'aria-label': t('product_details_number_images_label', {
			num: images.length,
		}),
		isOpen,
		onClose,
		onOpen,
	});

	const handleCloseButtonClick = () => {
		onClose();
	};

	useEffect(() => {
		scrollToImage(selectedImageId);
	}, [scrollToImage, selectedImageId]);

	return (
		<>
			<div {...focusTrapStartProps} />

			<div
				{...dialogProps}
				className={clsx(
					'fixed inset-0 z-imageLayover bg-white outline-none transition-fadeTransform duration-300 ease-out',
					!isOpen &&
						'pointer-events-none invisible translate-y-[10%] opacity-0',
				)}
			>
				{images.length > 0 && (
					<div className="fixed bottom-0 left-12 top-0 z-10 flex flex-col justify-center py-4 max-sm:hidden">
						<ThumbnailList
							images={images}
							selectedImageId={selectedImageId}
							onImageClick={onThumbnailClick}
							carouselIndex={carouselIndex}
							onSlideChange={onThumbnailSlideChange}
						/>
					</div>
				)}

				<div
					ref={scrollRef}
					className="absolute inset-0 flex flex-col items-center overflow-auto p-4 max-sm:pb-20 sm:px-40 sm:py-4"
				>
					{images.map((image) => (
						<div
							key={image.src}
							tabIndex={-1}
							ref={(element) => {
								if (element) {
									largeImages.current[image.id] = element;
								}
							}}
						>
							<Img
								src={image.src}
								alt={image.alt}
								service={imageService}
								width={1024}
								height={1024}
							/>
						</div>
					))}
				</div>

				<div className="absolute bottom-0 h-24 w-full bg-gradient-to-t from-white" />

				<button
					type="button"
					onClick={handleCloseButtonClick}
					aria-label={t('product_details_close_image_layover_button')}
					className={clsx(
						'group fixed flex items-center',
						'max-sm:bottom-6 max-sm:left-1/2 max-sm:-translate-x-1/2',
						'sm:right-12 sm:top-10',
					)}
				>
					<span className="mr-3 text-lg font-bold group-hover:underline max-sm:hidden">
						{t('product_details_close_image_layover_button')}
					</span>
					<span className="flex h-12 w-12 items-center justify-center rounded-full bg-julaRed group-hover:bg-julaRedDarken">
						<Icon icon="close" color="white" />
					</span>
				</button>
			</div>

			<div {...focusTrapEndProps} />
		</>
	);
}
ImageLayover.displayName = 'ImageLayover';
