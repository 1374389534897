import React from 'react';
import type { Field } from '@sitecore-jss/sitecore-jss-nextjs';

import BlockMedia from 'components/BlockMedia';
import ComponentPlaceholder from 'components/ComponentPlaceholder';
import { LayoutContainer } from 'components/Layout';
import type { ColorField, JulaComponentProps } from 'lib/component-props';
import type { DigizuiteAssetList } from 'models/asset';
import type { ProductCard } from 'models/productCard';
import { getEditorMargin } from 'utils/business-logic/getEditorMargin';
import { getAsset, is } from 'utils/helpers';

type Props = JulaComponentProps & {
	fields?: {
		backgroundColor?: ColorField;
		items?: {
			portraitImage: DigizuiteAssetList;
			portraitVideo?: DigizuiteAssetList;
			preventAutoplay?: Field<boolean>;
			products: ProductCard[];
			squareImage: DigizuiteAssetList;
			video: DigizuiteAssetList;
		}[];
	};
};

export default function MediaDouble({ fields, params }: Props) {
	if (!fields) {
		return null;
	}

	const items = fields.items?.filter((item) => {
		const hasMobileContent = item.portraitImage || item.portraitVideo;
		const hasDesktopContent = item.squareImage || item.video;
		return hasMobileContent && hasDesktopContent;
	});

	if (!is.arrayWithLength(items) || items.length < 2) {
		return (
			<ComponentPlaceholder
				componentName="MediaDouble"
				description="Requires two items with conent (image or video) for both mobile and desktop to function"
				className={getEditorMargin(params)}
			/>
		);
	}

	return (
		<LayoutContainer
			id={params?.anchor}
			outerClassName={getEditorMargin(params)}
			withGrid
			backgroundColor={fields.backgroundColor?.fields?.colorHex?.value}
		>
			{items.map(
				({
					video,
					portraitVideo,
					portraitImage,
					squareImage,
					products,
					preventAutoplay,
				}) => {
					const squareImg = getAsset('Templated 1:1', squareImage);
					const portraitImg = getAsset('Templated 3:4', portraitImage);
					const videoAsset = getAsset('Source Copy', video);
					const portraitVideoAsset = getAsset('Source Copy', portraitVideo);

					return (
						<>
							<BlockMedia
								className="col-span-3 first-of-type:col-start-4 first-of-type:col-end-7 max-md:hidden"
								variant="square"
								imgSrc={squareImg?.src}
								imgAlt={squareImg?.alt}
								video={videoAsset}
								preventAutoplay={preventAutoplay?.value}
								products={products}
							/>
							<BlockMedia
								className="col-span-2 md:hidden"
								variant="portrait"
								imgSrc={portraitImg?.src}
								imgAlt={portraitImg?.alt}
								preventAutoplay={preventAutoplay?.value}
								video={portraitVideoAsset}
								products={products}
							/>
						</>
					);
				},
			)}
		</LayoutContainer>
	);
}
MediaDouble.displayName = 'MediaDouble';
