/**
 * ProductDetailsAdditionalSales
 */

import React from 'react';

import { ActionButtonState } from 'components/ActionButton';
import InfoBox from 'components/InfoBox';
import Popover from 'components/Popover';
import ProductCard from 'components/ProductCard';
import { AccessoryCard } from 'models/productCard';
import { ButtonStateWithId } from 'state-machines/cart';
import { useI18n } from 'utils/i18n';

interface AdditionalSalesPopoverProps {
	buttonState: { buttonId?: string; state: ActionButtonState };
	buttonText?: string;
	id: string;
	isOpen: boolean;
	onAddItem: (product: AccessoryCard) => void;
	onClose: () => void;
	products: AccessoryCard[] | undefined;
	title: string;
	toastLink: string;
	toastLinkText: string;
	toastText: string;
}

function AdditionalSalesPopover({
	buttonState,
	buttonText,
	id,
	isOpen,
	onAddItem,
	onClose,
	products,
	title,
	toastLink,
	toastLinkText,
	toastText,
}: AdditionalSalesPopoverProps) {
	return (
		<Popover
			isOpen={isOpen}
			id={id}
			beforeHeader={
				<InfoBox
					variant="success"
					icon="info"
					heading={toastText}
					link={toastLink}
					linkText={toastLinkText}
				/>
			}
			title={title}
			onClose={onClose}
		>
			{products &&
				products.length > 0 &&
				products.map((product) => (
					<ProductCard
						orientation="row"
						key={product.id}
						onPurchaseButtonClick={() => onAddItem(product)}
						product={product}
						showAddToCartButton
						actionButtonState={
							product.id === buttonState.buttonId
								? buttonState.state
								: undefined
						}
						buttonText={buttonText}
					/>
				))}
		</Popover>
	);
}

AdditionalSalesPopover.displayName = 'AdditionalSalesPopover';

interface Props {
	additionalSalesIsOpen: boolean;
	additionalSalesProducts: AccessoryCard[] | undefined;
	additionalSalesToastText: string;
	onAdditionalSalesButtonClick: (product: AccessoryCard) => void;
	onAdditionalSalesClose: () => void;
	onWishlistAdditionalSalesButtonClick: (product: AccessoryCard) => void;
	onWishlistAdditionalSalesClose: () => void;
	productId: string;
	purchaseButtonState: ButtonStateWithId;
	wishlistAdditionalSalesIsOpen: boolean;
	wishlistAdditionalSalesProducts: AccessoryCard[] | undefined;
	wishlistAdditionalSalesToastText: string | undefined;
	wishlistButtonState: ButtonStateWithId;
}

/** Additional sales popover panel for product detail. */
export default function ProductDetailsAdditionalSales({
	productId,
	onWishlistAdditionalSalesButtonClick,
	onAdditionalSalesButtonClick,
	onWishlistAdditionalSalesClose,
	onAdditionalSalesClose,
	wishlistAdditionalSalesToastText,
	wishlistAdditionalSalesProducts,
	wishlistAdditionalSalesIsOpen,
	wishlistButtonState,
	purchaseButtonState,
	additionalSalesToastText,
	additionalSalesProducts,
	additionalSalesIsOpen,
}: Props) {
	const { t } = useI18n();

	return (
		<>
			<AdditionalSalesPopover
				id={`additional-sales-${productId}`}
				title={t('product_details_additional_sales_popover_heading')}
				isOpen={additionalSalesIsOpen}
				onClose={onAdditionalSalesClose}
				toastText={additionalSalesToastText}
				toastLink="/cart/"
				toastLinkText={t('continue_to_cart')}
				products={additionalSalesProducts}
				buttonState={purchaseButtonState}
				onAddItem={onAdditionalSalesButtonClick}
			/>
			<AdditionalSalesPopover
				id={`additional-sales-wishlist-${productId}`}
				title={t('product_details_wishlist_additional_sales_popover_heading')}
				isOpen={wishlistAdditionalSalesIsOpen}
				onClose={onWishlistAdditionalSalesClose}
				toastText={wishlistAdditionalSalesToastText || ''}
				toastLink="/wishlist/"
				toastLinkText={t('continue_to_wishlist')}
				products={wishlistAdditionalSalesProducts}
				buttonState={wishlistButtonState}
				buttonText={t(
					'product_details_wishlist_additional_sales_popover_button',
				)}
				onAddItem={onWishlistAdditionalSalesButtonClick}
			/>
		</>
	);
}
ProductDetailsAdditionalSales.displayName = 'ProductDetailsAdditionalSales';
