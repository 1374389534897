import React from 'react';

import ActionButton, { ActionButtonState } from 'components/ActionButton';
import Icon from 'components/Icon';
import { useFeatureToggle } from 'contexts';
import { useI18n } from 'utils/i18n';

interface Props {
	purchaseButtonDisabled: boolean;
	onPurchaseButtonClick: () => void;
	purchaseButtonState: ActionButtonState;
	onWishlistButtonClick: () => void;
	wishlistButtonState: ActionButtonState;
}
function ButtonPanel({
	purchaseButtonDisabled,
	onPurchaseButtonClick,
	purchaseButtonState,
	onWishlistButtonClick,
	wishlistButtonState,
}: Props) {
	const { t } = useI18n();
	const { wishlistEnabled, onlineCommerceEnabled } = useFeatureToggle();

	return (
		<div className="mt-4 space-y-2 md:mt-6">
			{onlineCommerceEnabled && (
				<ActionButton
					data-cid="addToCart"
					displayWidth="full"
					size="large"
					variant="cta"
					customState={purchaseButtonState}
					disabled={purchaseButtonDisabled}
					onClick={onPurchaseButtonClick}
				>
					<Icon
						icon={purchaseButtonDisabled ? 'cartDisabled' : 'cart'}
						className="mr-2"
					/>
					{t('product_details_buy_online_button')}
				</ActionButton>
			)}
			{wishlistEnabled && (
				<div className="w-full">
					<ActionButton
						data-cid="addToWishlist"
						displayWidth="full"
						size="medium"
						variant="secondary"
						customState={wishlistButtonState}
						onClick={onWishlistButtonClick}
					>
						<Icon icon="shoppinglistAdd" className="mr-2" />
						{t('product_details_add_to_wishlist_button')}
					</ActionButton>
				</div>
			)}
		</div>
	);
}

export default ButtonPanel;
ButtonPanel.displayName = 'ButtonPanel';
