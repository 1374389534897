/**
 * BlockFocusGrids - Inspiration Landscape
 */

import React, { type ReactNode } from 'react';
import clsx from 'clsx';

import Img from 'components/Img';
import Link from 'components/Link';
import Text from 'components/Text';
import { useIsMediumOrLarger } from 'hooks';

import styles from './BlockFocusGrid.module.css';

interface Props {
	/** React children */
	children?: ReactNode;

	/** Sets the direction of the inspiration landscape variant */
	direction: 'left' | 'right';

	/** The heading rendered below the small image */
	heading?: string;

	/** Alt-text for content image */
	imageAlt: string;

	/** Height for content image */
	imageHeight: number;

	/** Src for content image */
	imageSrc: string;

	/** Width for content image */
	imageWidth: number;

	/** link that is added after the list, but together with it, can be external* */
	link?: ReactNode;

	/** The links with the arrow icon to the left of the text */
	links?: ReactNode;

	/** First and main link to wrap image with */
	mainLink?: string;

	/** The preamble text rendered below the heading */
	preamble?: string;
}

// TODO: remove useIsMediumOrLarger hook when removing

/** Block focus grid - Inspiration landscape. */
export default function InspirationLandscapeOld({
	imageSrc,
	imageAlt,
	imageWidth,
	imageHeight,
	direction,
	heading,
	preamble,
	links,
	mainLink,
	children,
	link,
}: Props) {
	const reversed = useIsMediumOrLarger() ? direction === 'right' : true;

	return (
		<div className="grid grid-cols-4 gap-4 gap-y-0 sm:grid-cols-8 sm:gap-6 sm:gap-y-0 md:grid-cols-12">
			<div
				className={clsx(
					reversed
						? `order-2 col-span-4 sm:col-span-8 md:col-span-4`
						: `order-1 col-span-4 sm:col-span-8 md:col-start-1 md:col-end-5`,
				)}
			>
				<Link href={mainLink || ''}>
					<Img
						src={imageSrc}
						alt={imageAlt}
						width={imageWidth}
						height={imageHeight}
						service="nextjs"
						className={clsx(
							'mb-6 hidden h-auto w-full object-cover md:block',
							styles['inspiration-landscape-small-image'],
						)}
					/>
				</Link>

				<div className="mt-4 max-sm:ml-4 md:mt-0">
					<Text as="h2" className="text-left">
						{heading || ''}
					</Text>
					<div className="mb-6 mt-2 md:mt-4 ">
						<Text as="p" className="mb-6 text-left text-base leading-[1.4rem]">
							{preamble || ''}
						</Text>
					</div>
					{(links || link) && (
						<div className="flex flex-col justify-start md:items-start">
							{links}
							{link}
						</div>
					)}
				</div>
			</div>
			<div
				className={clsx(
					reversed
						? `order-1 col-span-8 md:col-start-1 md:col-end-9`
						: `order-2 col-span-8 sm:col-start-5 md:col-start-5 md:block`,
				)}
			>
				{children}
			</div>
		</div>
	);
}
InspirationLandscapeOld.displayName = 'InspirationLandscapeOld';
