import React from 'react';
import type { Field } from '@sitecore-jss/sitecore-jss-nextjs';

import ArrowLink from 'components/ArrowLink';
import Button from 'components/Button';
import ComponentPlaceholder from 'components/ComponentPlaceholder';
import { LayoutContainer } from 'components/Layout';
import Text from 'components/Text';
import type { JulaComponentProps } from 'lib/component-props';
import type { FaqArticle } from 'models/faqArticle';
import { SitecoreLink } from 'models/link';
import { getEditorMargin } from 'utils/business-logic';
import { is } from 'utils/helpers';

type Props = JulaComponentProps & {
	fields?: {
		heading: {
			value: string;
			editable?: string;
		};
		articles: FaqArticle[];
		link: Field<SitecoreLink>;
	};
};

export default function FAQs({ params, fields }: Props) {
	if (!fields) {
		return null;
	}

	if (!fields.heading || !is.arrayWithLength(fields.articles) || !fields.link) {
		return (
			<ComponentPlaceholder
				className={getEditorMargin(params)}
				componentName="FAQs"
				description="Requires a heading, one or more articles, and a link"
			/>
		);
	}

	return (
		<LayoutContainer
			id={params?.anchor}
			className={getEditorMargin(params)}
			withGrid
		>
			<Text
				as={params?.heading || 'h2'}
				field={fields.heading}
				className="col-span-full"
			/>
			<ul className="col-span-full mt-4 flex flex-col gap-4">
				{fields.articles.map((article) => (
					<li key={article.id}>
						<ArrowLink text={article.fields.summary.value} href={article.url} />
					</li>
				))}
			</ul>
			<Button
				variant="primary"
				href={fields.link.value.href}
				className="col-span-full mt-6 md:col-span-2"
			>
				{fields.link.value.text}
			</Button>
		</LayoutContainer>
	);
}
FAQs.displayName = 'FAQs';
