import React from 'react';

import BlockMedia from 'components/BlockMedia/';
import ComponentPlaceholder from 'components/ComponentPlaceholder';
import { useSitecoreContext } from 'hooks';
import { JulaComponentProps } from 'lib/component-props';
import { DigizuiteAssetList } from 'models/asset';
import { ProductCard as ProductCardType } from 'models/productCard';
import { getEditorMargin } from 'utils/business-logic';
import { getAsset } from 'utils/helpers';

type MediaPortraitProps = JulaComponentProps & {
	fields: {
		portraitImage: DigizuiteAssetList;
		products: ProductCardType[];
	};
};

function MediaPortrait({ params, fields }: MediaPortraitProps) {
	const { sitecoreContext } = useSitecoreContext();
	const isEditing = sitecoreContext?.pageEditing;

	if (!fields) return null;
	const { portraitImage, products } = fields;

	const portraitImg = getAsset('Templated 3:4', portraitImage);

	if (isEditing) {
		return (
			<ComponentPlaceholder
				componentName="MediaPortrait"
				className={getEditorMargin(params)}
				isDeprecated
			/>
		);
	}
	if (!portraitImg) {
		return (
			<ComponentPlaceholder
				componentName="MediaInspirationLandscape"
				className={getEditorMargin(params)}
			/>
		);
	}

	return (
		<div id={params?.anchor} className={getEditorMargin(params)}>
			<BlockMedia
				variant="portrait"
				imgSrc={portraitImg?.src}
				imgAlt={portraitImg?.alt}
				products={products}
			/>
		</div>
	);
}

MediaPortrait.displayName = 'MediaPortrait';

export default MediaPortrait;
